import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { noop } from 'lodash-es';

import { getConfig } from './configs';

class FirebaseAnalytics {
  private analytics: Analytics | null = null;

  initializeApp() {
    const isSSR = typeof window === 'undefined';
    const app = !isSSR && getConfig() ? initializeApp(getConfig()!) : null;

    this.analytics = app ? getAnalytics(app) : null;
  }

  get analitycsInstance(): Analytics {
    return this.analytics as Analytics;
  }

  sendAnalytics: (...args: Tail<Parameters<typeof logEvent>>) => void = this
    .analytics
    ? (...args) => logEvent(this.analytics as Analytics, ...args)
    : noop;
}

export const FirebaseAnalyticsService = new FirebaseAnalytics();
