import { observer } from 'mobx-react-lite';

import { useRequestCycle } from './useRequestCycle';
import { useRN } from './useRN';

export default observer(() => {
  useRN();
  useRequestCycle();

  return null;
});
