import React from 'react';

import styles from './FeatureCard.module.scss';

type FeatureCardProps = {
  imageSrc: string;
  number: number;
  title: string;
  description: string;
};

const FeatureCard: React.FC<FeatureCardProps> = ({
  imageSrc,
  number,
  title,
  description,
}) => {
  return (
    <div className={styles.badge}>
      <div
        className={styles.image__box}
        style={{ backgroundImage: `url(${imageSrc})` }}
      />
      <div className={styles.content__group}>
        <div className={styles.group__description}>
          <div className={styles.group__title}>
            <div className={styles.number}>
              <span />
              {number}
            </div>
            <div className={styles.title}>
              <span />
              <h3>{title}</h3>
            </div>
          </div>
          <div className={styles.content__box}>{description}</div>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
