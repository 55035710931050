import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { KYCStatus } from '~/api/KYCStatus';
import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';

import { REFERRAL_PROGRAM_PAGE_URL } from './constants';
import { ReferralProgramMenuItemProps } from './interfaces';
import styles from './ReferralProgramMenuItem.module.scss';

const ReferralProgramMenuItem = (
  props: PropsWithChildren<ReferralProgramMenuItemProps>,
) => {
  const { t } = useTranslation();

  const {
    className,
    labelClassName,
    children,
    onClick,
    link = REFERRAL_PROGRAM_PAGE_URL,
    label = t('inviteFriend'),
    icon = 'friends',
    iconSize = 24,
    hideIcon = false,
  } = props;

  const promotion = catalogStore.promotionStore;

  if (
    company.needKycCheck &&
    userStore.personalData?.kycStatus !== KYCStatus.Verified
  ) {
    return <></>;
  }

  return (
    <Link
      to={link}
      className={classes([styles.root, className])}
      onClick={onClick}
    >
      {!hideIcon && <Icon type={icon} size={iconSize} />}
      <p className={classes([styles.label, labelClassName])}>{label}</p>
      <span className={styles.badges}>
        {promotion.promocodeRewardStatus.total > 0 && (
          <span className={classes([styles.badge, styles.danger])}>
            {promotion.promocodeRewardStatus.total}
          </span>
        )}
      </span>
      {children}
    </Link>
  );
};

export default observer(ReferralProgramMenuItem);
