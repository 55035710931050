import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import CD_Brand from '~/assets/img/cd_brand.png';
import CD_Brand_botton from '~/assets/img/cd_brand_botton.png';
import CD_Brand_card_1 from '~/assets/img/cd_brand_card_1.png';
import CD_Brand_card_2 from '~/assets/img/cd_brand_card_2.png';
import CD_Brand_card_3 from '~/assets/img/cd_brand_card_3.png';
import CD_Brand_card_4 from '~/assets/img/cd_brand_card_4.png';
import CD_Brand_card_5 from '~/assets/img/cd_brand_card_5.png';
import { company } from '~/company/Company';
import FeatureCard from '~/components/FeatureCard';
import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import СontactInformation from '../../components/СontactInformation';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Brand.module.scss';

interface Items {
  img: string;
  title: string;
  description: string;
}

const dataOfBenefits: Items[] = [
  {
    img: CD_Brand_card_1,
    title: 'Exposure to a Discerning Audience',
    description:
      'Showcase your brand to our community of enthusiasts who value quality and innovation.',
  },
  {
    img: CD_Brand_card_2,
    title: 'Regional Reach',
    description:
      'Tap into a platform that delivers to various locations, reaching consumers not just locally but regionally.',
  },
  {
    img: CD_Brand_card_3,
    title: 'Brand Storytelling',
    description:
      'Share the narrative behind your brand, connecting with consumers on a deeper level.',
  },
  {
    img: CD_Brand_card_4,
    title: 'Marketing Support',
    description:
      'Benefit from our marketing initiatives, including featured promotions, newsletters, and social media exposure.',
  },
  {
    img: CD_Brand_card_5,
    title: 'Data Insights',
    description:
      'Access valuable data insights to understand consumer preferences and enhance your brand strategy.\n',
  },
];

const influencerProgram: Partial<Items>[] = [
  {
    title: 'Submission of Interest:',
    description: `Send an email expressing your interest in partnering with CityDrinks to <a href="mailto:${company.config.email}">${company.config.email}</a>.`,
  },
  {
    title: 'Brand Evaluation:',
    description:
      'Our team will review your brand to ensure it aligns with our commitment to quality and excellence.',
  },
  {
    title: 'Collaboration Agreement:',
    description:
      "Once approved, we'll work together to establish a collaboration agreement that benefits both parties.",
  },
  {
    title: 'Showcasing Your Brand:',
    description:
      'We will showcase your brand across our various platforms, giving consumers the opportunity to discover and indulge in your offerings.',
  },
];

export default observer(() => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const { state } = useLocation();
  const source = state?.source || 'home';

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      {isTablet && (
        <MobileHeaderLayout content={<MobileHeaderTitle text={t('brand')} />} />
      )}
      <div className={classes(['content-layout', styles.root])}>
        {!isTablet && (
          <>
            <Breadcrumbs
              items={
                [
                  {
                    text: t('home'),
                    link: '/',
                  },
                  source === 'cabinet' && {
                    text: t('cabinet'),
                    link: '/cabinet',
                  },
                  {
                    text: t('brand'),
                    link: '/brand',
                  },
                ].filter(Boolean) as BreadcrumbsItem[]
              }
              className={styles.breadcrumbs}
            />
          </>
        )}
        <section className={styles.header}>
          <h1 className={styles.header__welcome}>
            Collaborate with&nbsp;CityDrinks:
            <br /> Showcasing&nbsp;Your&nbsp;Brand to&nbsp;the&nbsp;World
          </h1>

          <img src={CD_Brand} className={styles.image} />

          <h3 className={styles.content__article_first}>
            Why Partner with&nbsp;CityDrinks?
          </h3>
          <p className={styles.content__article_text}>
            As the preferred online alcohol delivery platform in Abu Dhabi,
            CityDrinks celebrates the craftsmanship and stories behind each
            premium beverage. Partnering with us gives your brand a unique
            opportunity to reach a diverse audience of discerning consumers who
            appreciate and seek the finest spirits, wines, and craft beers.
          </p>

          <h3 className={styles.content__article_first}>
            Benefits of Partnering with&nbsp;CityDrinks:
          </h3>
          <div className={styles.benefits_container}>
            {dataOfBenefits?.map((x: Items, i: number) => (
              <FeatureCard
                key={i}
                imageSrc={x?.img}
                number={Number(i) + 1}
                title={x?.title}
                description={x?.description}
              />
            ))}
          </div>

          <h3 className={styles.content__article_first}>
            How to Partner with CityDrinks:
          </h3>
          <div className={styles.application_card_container}>
            {influencerProgram?.map((x: Partial<Items>, i: number) => (
              <div key={i} className={styles.application_card}>
                <div className={styles.application_card_content}>
                  <span className={styles.application_card_title}>
                    {x?.title}
                  </span>
                  <span
                    className={styles.application_card_content_text}
                    dangerouslySetInnerHTML={{ __html: x?.description ?? '' }}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className={styles.row_wrapper}>
            <div className={styles.wrapper_info}>
              <h3>
                Join Us in Elevating <br />
                the Drinking Experience!
              </h3>
              <div className={styles.wrapper_info_mobile}>
                <p>
                  CityDrinks brings together enthusiasts, connoisseurs, and
                  brands that appreciate the artistry behind premium beverages.
                </p>
                <p>
                  If you&apos;re ready to introduce your brand to a passionate
                  audience and the world, we welcome you to join our vibrant
                  community.
                </p>
              </div>
            </div>
            <div className={styles.wrapper_bg}>
              <img src={CD_Brand_botton} />
            </div>
          </div>
        </section>
      </div>

      <СontactInformation
        content={`For partnership inquiries, please contact, <br/> our partnerships team at`}
        className={styles.contactInfo}
        buttonClassName={styles.contactInfoCTA}
        titleClassName={styles.contactInfoTitle}
        subtitleClassName={styles.contactInfoSubtitle}
      />
    </>
  );
});
