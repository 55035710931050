import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useModal } from '~/hooks/useModal';

import successfulFeedback from '../../assets/img/successfulFeedback.svg';

import Icon from '../Icon/Icon';

import styles from './ModalFeedback.module.scss';

const ModalFeedbackSuccess = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className={classes([
            'button _no-padding _no-color',
            styles.closeCross,
          ])}
          onClick={closeModal}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <div className={styles.rootSuccess}>
          <img
            src={successfulFeedback}
            className={styles.image}
            alt={t('thanksForFeedback')}
          />
          <p className={styles.title}>{t('thanksForFeedback')}</p>
          <p className={styles.description}>{t('thanksForOpinion')}</p>
          <button
            className={classes(['button _primary', styles.button])}
            onClick={closeModal}
          >
            {t('okButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalFeedbackSuccess);
