import { classes } from 'html-classes';
import { isEqual } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import {
  useCallback,
  useEffect,
  useState,
  KeyboardEvent,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { company } from '~/company/Company';
import { useDisclosure } from '~/hooks/useDisclosure';
import { useSearchRecommendations } from '~/pages/SearchResults/hooks/useSearchRecommendations';
import { SearchRecommendations } from '~/pages/SearchResults/SearchRecommendations';
import { Product } from '~/stores/CategoriesStore';
import { searchStore } from '~/stores/SearchStore';
import { PaginationResult } from '~/stores/shared/Pagination';

import { userStore } from '../../stores/UserStore';

import Icon from '../Icon/Icon';

const HeaderSearch = observer(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const popup = useDisclosure(false);
  const [recommendationsData, handleSelectBadge] = useSearchRecommendations();

  const [searchWrapperRef, _setSearchWrapperRef] = useState<
    HTMLElement | undefined
  >();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const isOnSearchResultPage = pathname.startsWith('/search');

  const setSearchWrapperRef = (node: HTMLElement | null): void => {
    if (node && !isEqual(node, searchWrapperRef)) {
      _setSearchWrapperRef(node);
    }
  };

  const handleFocusInput = () => {
    if (isFocused) {
      return;
    }

    setIsFocused(true);
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    const value = (e.target as HTMLInputElement).value;

    if (e.code !== 'Enter' || !value) {
      return;
    }

    e.preventDefault();
    searchStore.paginationRequest.debounceLoadPage(1, value);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    searchStore.setQuery(e.target.value);
    searchStore.paginationRequest.debounceLoadPage(1, e.target.value);
  };

  const handleOutsideClick = useCallback(
    (e: Event): void => {
      const target = e.target as HTMLElement;

      if (searchWrapperRef?.contains(target)) {
        return;
      }

      if (isFocused) {
        setIsFocused(false);
      }
    },
    [searchWrapperRef, isFocused],
  );

  useEffect(() => {
    const handler = (data: PaginationResult<Product> | undefined) => {
      if (data && !isOnSearchResultPage) {
        navigate('/search', { state: { source: pathname } });
      }
    };

    searchStore.paginationRequest.on('success', handler);
    return () => searchStore.paginationRequest.off('success', handler);
  }, [navigate, isOnSearchResultPage]);

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <>
      <div
        className={classes([
          'header-search',
          { 'header-search--focused': isFocused },
        ])}
        ref={setSearchWrapperRef}
        onFocus={handleFocusInput}
        data-company={company.name}
      >
        {searchStore.paginationRequest.isLoading ? (
          <div className="spinner header-search__icon" />
        ) : (
          <Icon className="header-search__icon" type="search" size={24} />
        )}

        <input
          type="text"
          value={searchStore.queryString}
          className="header-search__input"
          placeholder={t('phrases:hiThere', {
            name: userStore.personalData.firstName || 'there',
          })}
          onKeyUp={handleKeyUp}
          onChange={handleInputChange}
          onFocus={popup.open}
          onBlur={() => setTimeout(popup.close, 200)}
        />
        <button
          className="button _no-padding"
          style={{ visibility: searchStore.queryString ? 'visible' : 'hidden' }}
          onClick={() => {
            searchStore.reset();
            window.scrollTo(0, 0);
          }}
        >
          <Icon type="close" />
        </button>
        <div
          className={classes([
            'search-popup',
            popup.visible &&
              recommendationsData.length &&
              'search-popup-visible',
          ])}
        >
          <SearchRecommendations
            data={recommendationsData}
            onSelect={handleSelectBadge}
          />
        </div>
      </div>
    </>
  );
});

export default HeaderSearch;
