import { CSSProperties } from 'react';

import { DEFAULT_COMPANIES, company } from '~/company/Company';

import styles from './Spinner.module.scss';

type Props = {
  layout?: boolean;
};

const Spinner = ({ layout }: Props) => {
  if (DEFAULT_COMPANIES.includes(company.name)) {
    const spinner = (
      <div className={styles.container}>
        {[0, 1, 2, 3, 4, 5, 6, 7].map((i) => (
          <div
            className={styles.item}
            style={{ '--i': `${i}` } as CSSProperties}
            key={i}
          />
        ))}
      </div>
    );

    if (layout) {
      return <div className={styles.root}>{spinner}</div>;
    }

    return spinner;
  } else {
    return null;
  }
};

export default Spinner;
