import { mainStore } from './stores/MainStore';

type TimingTransformFunction = (
  timing: (progress: number) => number,
) => (progress: number) => number;

export const scrollTo = (
  scrollLayout: HTMLElement | string | null,
  target: HTMLElement | number | null,
  duration: number,
  offset = 0,
  axis = 'y',
): void => {
  if (!scrollLayout || target === null) {
    return;
  }
  if (typeof scrollLayout === 'string') {
    scrollLayout = document.querySelector<HTMLElement>('.' + scrollLayout);
    if (!scrollLayout) {
      return;
    }
  }
  let targetPos: number;
  if (typeof target === 'number') {
    targetPos = target;
  } else {
    if (axis === 'y') {
      targetPos = target.offsetTop - scrollLayout.offsetTop;
    } else {
      targetPos = target.offsetLeft - scrollLayout.offsetLeft;
    }
  }
  targetPos += offset;
  if (!duration) {
    if (axis === 'y') {
      scrollLayout.scrollTop = targetPos;
    } else {
      scrollLayout.scrollLeft = targetPos;
    }
  }
  let curPos = axis === 'y' ? scrollLayout.scrollTop : scrollLayout.scrollLeft;
  let distance = targetPos - curPos;
  if (axis === 'y') {
    if (targetPos + scrollLayout.clientHeight > scrollLayout.scrollHeight) {
      targetPos = scrollLayout.scrollHeight - scrollLayout.clientHeight;
      distance = targetPos - curPos;
    }
  } else {
    if (targetPos + scrollLayout.clientWidth > scrollLayout.scrollWidth) {
      targetPos = scrollLayout.scrollWidth - scrollLayout.clientWidth;
      distance = targetPos - curPos;
    }
  }
  if (targetPos < 0) {
    if (axis === 'y') {
      return;
    } else {
      targetPos = 0;
    }
  }
  if (axis === 'y') {
    if (Math.abs(distance) > scrollLayout.clientHeight) {
      if (targetPos > curPos) {
        curPos = targetPos - scrollLayout.clientHeight;
      } else {
        curPos = targetPos + scrollLayout.clientHeight;
      }
      distance = targetPos - curPos;
    }
  } else {
    if (Math.abs(distance) > scrollLayout.clientWidth) {
      if (targetPos > curPos) {
        curPos = targetPos - scrollLayout.clientWidth;
      } else {
        curPos = targetPos + scrollLayout.clientWidth;
      }
      distance = targetPos - curPos;
    }
  }
  animate((progress) => {
    if (!scrollLayout || typeof scrollLayout === 'string') {
      return;
    }
    const newPos =
      curPos + distance * makeEaseInOut(animationTimingQuad)(progress);
    if (axis === 'y') {
      scrollLayout.scrollTop = newPos;
    } else {
      scrollLayout.scrollLeft = newPos;
    }
  }, duration);
};

export class SyncSubcategory {
  scrollLayout;
  subcategoryList;
  subcategoryOffsetList: {
    offset: number;
    safeOffset: number;
    id: string | null;
  }[] = [];
  elSubcategoryBadges: NodeListOf<HTMLElement> | null = null;
  isReadyToScroll = true;
  activeBadgeId: string | null = null;
  subCat2Id: string | undefined = undefined;

  constructor(
    scrollLayout: HTMLElement | null,
    subcategoryList: HTMLElement | null,
    subCat2Id?: string,
  ) {
    this.scrollLayout = scrollLayout;
    this.subcategoryList = subcategoryList;
    this.subCat2Id = subCat2Id;
    this.collectBadgeSubcategoryData();
    this.collectSubcategoryData();
    this.addEvents();
  }

  onScrollWrap = (): void => this.onScroll();

  onResizeWrap = (): void => this.collectSubcategoryData();

  onClickWrap = (e: MouseEvent): void => this.triggerBadge(e);

  addEvents(): void {
    if (!this.scrollLayout || !this.subcategoryList) {
      return;
    }
    this.scrollLayout.addEventListener('scroll', this.onScrollWrap);
    this.subcategoryList.addEventListener('click', this.onClickWrap);
    window.addEventListener('resize', this.onResizeWrap);
  }

  onScroll(): void {
    if (
      !this.isReadyToScroll ||
      !this.scrollLayout ||
      !this.subcategoryList ||
      !this.subcategoryOffsetList ||
      !this.elSubcategoryBadges
    ) {
      return;
    }
    this.setActiveBadge(
      this.getSubcategoryIdByOffset(
        this.scrollLayout.scrollTop + this.scrollLayout.offsetHeight / 2,
      ),
    );
  }

  collectSubcategoryData(): void {
    if (!this.scrollLayout || !this.subcategoryList) {
      return;
    }
    const elSubcategory =
      this.scrollLayout.querySelectorAll<HTMLElement>('.product-list');
    if (!elSubcategory) {
      return;
    }
    this.subcategoryOffsetList = [];
    const layoutOffset = this.scrollLayout.offsetTop;
    let prevOffset = 0;
    const safeOffsetDiff = this.scrollLayout.offsetHeight / 2 + 50;
    elSubcategory.forEach((el, i) => {
      const offset = el.offsetTop - layoutOffset;
      this.subcategoryOffsetList.push({
        offset,
        safeOffset:
          i && offset - prevOffset < safeOffsetDiff
            ? prevOffset + safeOffsetDiff
            : offset,
        id: el.getAttribute('data-id'),
      });
      prevOffset = offset;
    });
    this.onScroll();
  }

  collectBadgeSubcategoryData(): void {
    if (!this.scrollLayout || !this.subcategoryList) {
      return;
    }
    this.elSubcategoryBadges =
      this.subcategoryList.querySelectorAll<HTMLElement>('.badge');
  }

  triggerBadge(e: MouseEvent): void {
    if (!this.scrollLayout || !this.subcategoryList) {
      return;
    }
    const elBadge: HTMLElement | null = e.target as HTMLElement;
    if (!elBadge) {
      return;
    }
    if (!elBadge.classList.contains('badge')) {
      return;
    }
    const badgeId = elBadge.getAttribute('data-id') || null;
    if (!badgeId) {
      return;
    }
    this.activeBadgeId = '';
    this.isReadyToScroll = false;
    this.setActiveBadge(badgeId, elBadge);
    scrollTo(this.scrollLayout, this.getSubcategoryOffsetById(badgeId), 500);
    setTimeout(() => {
      this.isReadyToScroll = true;
    }, 550);
  }

  getSubcategoryOffsetById(id: string): number {
    for (let i = 0; i < this.subcategoryOffsetList.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (this.subcategoryOffsetList[i].id === id) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        return this.subcategoryOffsetList[i].offset;
      }
    }
    return -1;
  }

  getSubcategoryIdByOffset(offset: number): string | null {
    let id: string | null = null;
    for (let i = 0; i < this.subcategoryOffsetList.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (this.subcategoryOffsetList[i].safeOffset <= offset) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        id = this.subcategoryOffsetList[i].id;
      } else {
        break;
      }
    }
    return id;
  }

  getSubcategoryBadgeById(id: string): HTMLElement | undefined {
    return Array.prototype.filter.call(
      this.elSubcategoryBadges,
      (el) => el.getAttribute('data-id') === id,
    )[0];
  }

  setActiveBadge(id: string | null, elBadge?: HTMLElement): void {
    if (!id) {
      return;
    }
    if (
      !this.scrollLayout ||
      !this.subcategoryList ||
      !this.elSubcategoryBadges ||
      this.activeBadgeId === id
    ) {
      return;
    }
    if (!elBadge) {
      elBadge = this.getSubcategoryBadgeById(id);
    }
    if (!elBadge) {
      return;
    }
    this.elSubcategoryBadges.forEach((el) =>
      el.classList.remove('_active', 'transition-none'),
    );
    if (this.activeBadgeId === null) {
      elBadge.classList.add('transition-none');
    }
    elBadge.classList.add('_active');
    scrollTo(
      this.subcategoryList,
      elBadge,
      this.activeBadgeId === null ? 0 : 200,
      this.subcategoryList.offsetWidth / -2 + elBadge.offsetWidth / 2,
      'x',
    );
    this.activeBadgeId = id;
    mainStore.sendToRN('analytics', {
      name: 'Catalog: category scrolled',
      params: {
        parent_category_id: this.subCat2Id,
        category_id: id,
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_category_scrolled',
      params: {
        parent_category_id: this.subCat2Id,
        category_id: id,
      },
    });
  }

  update(): void {
    if (!this.scrollLayout || !this.subcategoryList) {
      return;
    }
    setTimeout(() => {
      this.collectBadgeSubcategoryData();
      this.collectSubcategoryData();
    }, 300);
  }

  destroy(): void {
    if (!this.scrollLayout || !this.subcategoryList) {
      return;
    }
    this.scrollLayout.removeEventListener('scroll', this.onScrollWrap);
    this.subcategoryList.removeEventListener('click', this.onClickWrap);
    window.removeEventListener('resize', this.onResizeWrap);
  }
}

export const listenerOnResize = (): void => {
  window.addEventListener('resize', () => {
    if (document.activeElement) {
      let elScrollLayout =
        document.querySelector<HTMLElement>('.scroll-layout');
      if (elScrollLayout) {
        let elTarget = document.activeElement.parentElement;
        if (elTarget && elTarget.classList.contains('__PrivateStripeElement')) {
          elTarget = elTarget.closest('.input-text__wrap');
        }
        if (
          elTarget &&
          elTarget.classList.contains('input-textarea-feedback')
        ) {
          elScrollLayout = elTarget.closest('.popover__body') as HTMLElement;
        }
        scrollTo(
          elScrollLayout,
          elTarget,
          200,
          elScrollLayout.clientHeight / -3,
        );
      }
    }
  });
};

export const closeTags = (text: string): string => {
  const reg = /<(\/?\w+)+\s?[^>]*>/g;
  const tags: string[] = [];
  const findTags = (match: string, tagName: string) => {
    if (!tagName || tagName === 'br' || tagName === 'hr') {
      return match;
    }
    if (tagName[0] !== '/') {
      tags.push(tagName);
    } else {
      tags.pop();
    }
    return match;
  };
  let res = text.replace(reg, findTags);
  tags.reverse().map((i) => (res = `${res}</${i}>`));
  return res;
};

const animate = (draw: (timeFraction: number) => void, duration: number) => {
  const start = performance.now();
  return requestAnimationFrame(function animate(time) {
    let timeFraction: number = (time - start) / duration;
    if (timeFraction > 1) {
      timeFraction = 1;
    }
    if (timeFraction > 0) {
      draw(timeFraction);
    }
    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  });
};
const animationTimingQuad = (progress: number): number => Math.pow(progress, 2);
//const animationTimingCirc = (progress: number): number => 1 - Math.sin(Math.acos(progress))
//const makeEaseOut: TimingTransformFunction = timing => progress => 1 - timing(1 - progress)
const makeEaseInOut: TimingTransformFunction = (timing) => (progress) =>
  progress < 0.5
    ? timing(2 * progress) / 2
    : (2 - timing(2 * (1 - progress))) / 2;
