import { classes } from 'html-classes';

import { formatPriceWithCurrency } from '~/utils/formaters';

type Props = {
  price: number | string;
  discount?: number | string;
  className?: string;
  classNameOldPrice?: string;
  classNameCurrentPrice?: string;
};

const Price = ({
  price,
  discount,
  className,
  classNameOldPrice,
  classNameCurrentPrice,
}: Props) => {
  const priceText = formatPriceWithCurrency(price);

  return (
    <div className={classes(['price', className])}>
      {discount && (
        <p className={classes(['price__old', classNameOldPrice])}>
          {formatPriceWithCurrency(discount)}
        </p>
      )}
      <p
        className={classes([
          'price__current',
          discount && 'price__discount',
          classNameCurrentPrice,
        ])}
      >
        {priceText}
      </p>
    </div>
  );
};

export default Price;
