import { z } from 'zod';

const phoneRegex = new RegExp(
  /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/,
);

export const ContactUsValidationSchema = z.object({
  name: z.string().min(1),
  email: z.string().min(1).email(),
  phone: z.string().regex(phoneRegex, 'Invalid Number!'),
  message: z.string().min(1),
});

export type ContactUsSchema = z.infer<typeof ContactUsValidationSchema>;
