import { useEffect, useState } from 'react';

export const useStoreInstance = <T extends { new (...args: any[]): unknown }>(
  Store: T,
  ...args: ConstructorParameters<T>
) => {
  const [store, setStore] = useState(() => {
    return new Store(...args);
  });
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    if (domLoaded) {
      setStore(new Store(...args));
    }

    setDomLoaded(true);
  }, [Store, ...args]);

  return store as InstanceType<T>;
};
