import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Price from '~/components/Price';
import Promocode from '~/components/Promocode/Promocode';
import { catalogStore } from '~/stores/CatalogStore';

import styles from './CartCostItemsVariant2.module.scss';

const CartCostItemsVariant2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul className={classes(['subtotal-info__content', styles.contentWr])}>
        <li className="subtotal-info__content-item">
          <div className="subtotal-info__content-item-desc">
            <p className={styles.itemsDesc}>
              {t('itemsN')}{' '}
              <span className="subtotal-info__content-item-desc-count">
                ({catalogStore.selectedItemsTotalCount})
              </span>
            </p>
          </div>
          <Price price={catalogStore.totalCartPrice.base} />
        </li>
        {Number(catalogStore.totalCartPrice.discount) > 0 && (
          <li className="subtotal-info__content-item">
            <div className="subtotal-info__content-item-desc">
              <p>{t('discount')}</p>
            </div>
            <Price
              price={`-${catalogStore.totalCartPrice.discount}`}
              className={styles.discountPrice}
              classNameCurrentPrice="subtotal-info__content-item-price"
            />
          </li>
        )}
        {Number(catalogStore.totalCartPrice.promocodeDiscount) > 0 && (
          <li className="subtotal-info__content-item">
            <div className="subtotal-info__content-item-desc">
              <p>{t('promocode')}</p>
            </div>
            <Price
              price={`-${catalogStore.totalCartPrice.promocodeDiscount}`}
              className={styles.discountPrice}
              classNameCurrentPrice="subtotal-info__content-item-price"
            />
          </li>
        )}
      </ul>

      <Promocode
        className={styles.promocodeForm}
        // submitButtonClassName={styles.submitButton}
        placeholder={t('promocode')}
      />

      <div className="subtotal-info__price">
        <p className={styles.subtotalLabel}>{t('subtotal')}</p>
        <Price
          price={catalogStore.totalCartPrice.paidWithDiscount}
          classNameCurrentPrice="subtotal-info__price-current"
        />
      </div>
    </>
  );
};

export default observer(CartCostItemsVariant2);
