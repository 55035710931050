import { classes } from 'html-classes';

import { CompanyName } from '~/company/interface';

import styles from './ProductsCarouselSliderOneBlock.module.scss';

export const swiperProps: Record<CompanyName, any> = {
  [CompanyName.CityDrinks]: {
    slideClassName: styles.product,
    skeletonClassName: styles.productSkeleton,
    scrollAuto: true,
    leftArrowClassName: classes([styles.swiperIcon]),
    rightArrowClassName: classes([styles.swiperIcon]),
    freeMode: true,
  },
  [CompanyName.Localee]: {
    slideClassName: styles.product,
    skeletonClassName: styles.productSkeleton,
    spaceBetween: 0,
    sliderClassName: styles.sliderClassName,
    scrollAuto: true,
    leftArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowLeft,
      styles.swiperIcon,
    ]),
    rightArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowRight,
      styles.swiperIcon,
    ]),
    freeMode: true,
  },
  [CompanyName.CircleK]: {
    slideClassName: styles.product,
    skeletonClassName: styles.productSkeleton,
    spaceBetween: 0,
    sliderClassName: styles.sliderClassName,
    scrollAuto: true,
    leftArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowLeft,
      styles.swiperIcon,
    ]),
    rightArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowRight,
      styles.swiperIcon,
    ]),
    freeMode: true,
  },
  [CompanyName.Vilo]: {
    slideClassName: styles.product,
    skeletonClassName: styles.productSkeleton,
    spaceBetween: 0,
    sliderClassName: styles.sliderClassName,
    scrollAuto: true,
    leftArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowLeft,
      styles.swiperIcon,
    ]),
    rightArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowRight,
      styles.swiperIcon,
    ]),
    freeMode: true,
  },
  [CompanyName.Jiffy]: {
    slideClassName: styles.product,
    skeletonClassName: styles.productSkeleton,
    spaceBetween: 0,
    sliderClassName: styles.sliderClassName,
    scrollAuto: true,
    leftArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowLeft,
      styles.swiperIcon,
    ]),
    rightArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowRight,
      styles.swiperIcon,
    ]),
    freeMode: true,
  },
  [CompanyName.Kids]: {
    slideClassName: styles.product,
    skeletonClassName: styles.productSkeleton,
    spaceBetween: 0,
    sliderClassName: styles.sliderClassName,
    scrollAuto: true,
    leftArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowLeft,
      styles.swiperIcon,
    ]),
    rightArrowClassName: classes([
      styles.controlArrow,
      styles.controlArrowRight,
      styles.swiperIcon,
    ]),
    freeMode: true,
  },
};
