import { useEffect, useState } from 'react';

import { DESKTOP_HEADER_ID } from '~/components/Header/constants';
import { useGlobal } from '~/hooks/useGlobal';

const getHeaderSize = () => {
  const header = document.getElementById(DESKTOP_HEADER_ID);

  if (!header) {
    return 0;
  }

  return (
    header.offsetHeight + parseInt(window.getComputedStyle(header).marginBottom)
  );
};

export const useHeaderSize = () => {
  const { isSSR } = useGlobal();
  const [headerSize, setHeaderSize] = useState(isSSR ? 0 : getHeaderSize());

  useEffect(() => {
    const handler = () => {
      setHeaderSize(getHeaderSize());
    };

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  });

  return headerSize;
};
