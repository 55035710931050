import { isEqual } from 'lodash-es';
import { ImgHTMLAttributes, memo, useMemo } from 'react';

import { company } from '~/company/Company';

import { CloudflareResizeOptions } from './CloudflareResizeOptions';
import { SrcSetAnchor } from './types';
import { getCloudflareUrl } from './utils';

type CloudflareResponseImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  srcParams?: CloudflareResizeOptions;
  srcSetParams?: Record<SrcSetAnchor, CloudflareResizeOptions>;
};

const CloudflareResponseImage = memo(function CloudflareResponseImage({
  srcParams,
  srcSetParams,
  src,
  ...imageProps
}: CloudflareResponseImageProps) {
  const srcImageUrl = useMemo(
    () =>
      src
        ? getCloudflareUrl(
            src,
            company.envVariables.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
            srcParams,
          )
        : src,
    [src, JSON.stringify(srcParams)],
  );
  let srcSetParamsDPR: any = {};
  if (srcParams?.width) {
    srcSetParamsDPR['1x'] = {
      fit: 'contain',
      width: srcParams?.width,
    };
    srcSetParamsDPR['2x'] = {
      fit: 'contain',
      width: srcParams?.width * 2,
    };
    srcSetParamsDPR['3x'] = {
      fit: 'contain',
      width: srcParams?.width * 3,
    };
    srcSetParamsDPR['4x'] = {
      fit: 'contain',
      width: srcParams?.width * 4,
    };
  } else {
    srcSetParamsDPR = srcSetParams;
  }
  const srcSetImagesUrl = useMemo(() => {
    if (!srcSetParamsDPR || !src) {
      return undefined;
    }

    return Object.entries(srcSetParamsDPR)
      .reduce((result, [anchor, params]) => {
        result.push(
          `${getCloudflareUrl(
            src,
            company.envVariables.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
            params,
          )} ${anchor}`,
        );

        return result;
      }, [] as string[])
      .join(',');
  }, [src, JSON.stringify(srcSetParamsDPR)]);

  const srcSetSizes = useMemo(() => {
    if (!srcSetParamsDPR || !src) {
      return undefined;
    }

    return Object.entries(srcSetParamsDPR)
      .reduce((result, [anchor, params], index) => {
        result.push(
          `(max-width: ${params.width}px) ${anchor.replace(/\D+/g, '')}px`,
        );

        if (index === Object.entries(srcSetParamsDPR).length - 1) {
          result.push(`${anchor.replace(/\D+/g, '')}px`);
        }

        return result;
      }, [] as string[])

      .join(',');
  }, [src, JSON.stringify(srcSetParamsDPR)]);

  // for testing
  // if (!company.config.cloudflareImages.enabled) {
  //   return <img {...props} />;
  // }
  if (!srcParams?.width) {
    imageProps.sizes = srcSetSizes;
  }
  return (
    <img
      src={srcImageUrl}
      srcSet={srcSetImagesUrl}
      {...imageProps}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // to prevent looping
        currentTarget.src = src ?? '';
        currentTarget.srcset = '';
      }}
    />
  );
}, isEqual);

export default CloudflareResponseImage;
