import { observer } from 'mobx-react-lite';

import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';

import { LayoutCD } from './LayoutCD';
import { LayoutLocalee } from './LayoutLocalee';

const COMPONENT_TYPES = {
  [CompanyName.CityDrinks]: LayoutCD,
  [CompanyName.Localee]: LayoutLocalee,
  [CompanyName.CircleK]: LayoutLocalee,
  [CompanyName.Vilo]: LayoutLocalee,
  [CompanyName.Jiffy]: LayoutLocalee,
  [CompanyName.Kids]: LayoutLocalee,
};

export default observer(() => {
  const Content = COMPONENT_TYPES[company.name];

  return <Content />;
});
