import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ETAPaymentMethod } from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { userStore } from '~/stores/UserStore';

const DeliveryInstructions = () => {
  const { t } = useTranslation();
  const [instructionList, setInstructionList] = useState<string[]>([]);
  const handleToggleInstruction = (val: string, index: number) => {
    if (checkoutStore.instructionList[index]) {
      checkoutStore.deleteInstruction(index);
    } else {
      checkoutStore.addInstruction(val, index);
    }
  };

  useEffect(() => {
    const list =
      (t('deliveryInstructionsList', { returnObjects: true }) as string[]) ||
      [];
    if (list.length && catalogStore.isAdultItemInCart) {
      list.shift();
    }
    setInstructionList(list);
  }, [t]);

  useEffect(() => {
    if (
      !instructionList.length ||
      !userStore.deliveryAddress?.instructions?.length
    ) {
      return;
    }
    checkoutStore.resetInstructionList();
    for (let i = 0; i < instructionList.length; i++) {
      if (
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        userStore.deliveryAddress.instructions.indexOf(instructionList[i]) !==
        -1
      ) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        handleToggleInstruction(instructionList[i], i);
      }
    }
    //eslint-disable-next-line
  }, [instructionList, userStore.deliveryAddress?.addressId]);

  useEffect(() => {
    if (
      checkoutStore.activePaymentMethod !== ETAPaymentMethod.Cash ||
      instructionList.length < 3
    ) {
      return;
    }

    if (checkoutStore.instructionList[0]) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      handleToggleInstruction(instructionList[0], 0);
    }
  }, [checkoutStore.activePaymentMethod]);

  if (checkoutStore.deliveryMethod === ETADeliveryMethodType.ClickAndCollect) {
    return <></>;
  }

  return (
    <div className="panel-section__content-comment-checkbox">
      {instructionList.map((el, i) => (
        <label className="control__checkbox" key={el}>
          <input
            type="checkbox"
            className="control__checkbox-input"
            onChange={() => handleToggleInstruction(el, i)}
            checked={!!checkoutStore.instructionList[i]}
          />
          <span className="control__checkbox-mark" />
          {el}
        </label>
      ))}
    </div>
  );
};

export default observer(DeliveryInstructions);
