import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { mainStore } from '~/stores/MainStore';

import { useModal } from '../../hooks/useModal';
import { userStore } from '../../stores/UserStore';

import Icon from '../Icon/Icon';

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await userStore.logout().catch((error) => error && console.error(error));
      navigate('/');
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteProfile = async () => {
    try {
      const isDeletedSuccessfully = await userStore.deleteProfile();
      if (isDeletedSuccessfully) {
        handleLogout().catch(() => undefined);
        mainStore.pushAlert('success', t('alerts:alertDeleteProfile'));
        mainStore.sendToRN('logAmplitudeEvent', {
          name: 'Profile: deleted',
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'profile_deleted',
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const backHandler = () => closeModal();

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__back"
          onClick={backHandler}
        >
          <Icon type="arrow" className="icon__rotate-180" size={24} />
        </button>
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={backHandler}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <div className="input-form__form">
          <div className="input-form__form-header">
            <p className="input-form__form-header-title">
              {t('phrases:wantToDeleteAccount')}
            </p>
          </div>
          <div className="input-form__form-footer">
            <div className="input-form__form-footer-buttons">
              <button className="button _bordered _med" onClick={backHandler}>
                {t('goBack')}
              </button>
              <button
                className="button _primary _med"
                onClick={handleDeleteProfile}
              >
                {t('delete')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
