import { classes } from 'html-classes';

import { company } from '~/company/Company';

import styles from './Badge.module.scss';

type BadgeProps = {
  className?: string;
  size?: 'xs' | 's' | 'm';
  children: React.ReactNode;
  theme: 'primary' | 'secondary';
};
const Badge = ({
  className,
  size = 's',
  theme = 'primary',
  children,
}: BadgeProps) => {
  return (
    <div
      data-company={company.name}
      className={classes([
        styles.container,
        className,
        styles[`size_${size}`],
        styles[`theme_${theme}`],
      ])}
    >
      {children}
    </div>
  );
};

export default Badge;
