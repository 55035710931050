import { classes } from 'html-classes';
import { debounce } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { company } from '~/company/Company';
import { getCloudflareUrl } from '~/components/CloudflareResponsiveImage/utils';
import SwiperIcon from '~/components/Swiper/SwiperIcon';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { userStore } from '~/stores/UserStore';

import { SwiperWithSlidesPerView } from '../BannerProductBlock/interfaces';
import CategoryBlockTitle from '../CategoryBlockTitle';
import { createCategoryUrl } from '../utils';

import styles from './CategoriesGridOneSBlock.module.scss';

export interface CategoriesGridOneSItem {
  img: string | null;
  title: string;
  id: number | string;
}

interface MobileDisplayProps {
  items: CategoriesGridOneSItem[];
}

const gradient =
  'linear-gradient(180deg, rgba(27, 26, 26, 0) 30%, rgba(27, 26, 26, 1) 100%)';

const MobileDisplay = ({ items }: MobileDisplayProps) => {
  const { handleClickCategory } = useCategoryAnalytics();

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(
      src,
      company.envVariables.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
      {
        width: 285,
        height: 285,
      },
    );

  return (
    <div className={styles.grid}>
      {items.slice(0, 4).map((item) => (
        <div
          key={item.title}
          className={styles.item}
          style={{
            backgroundImage: `${gradient}, url('${cfSrcForImage(item.img)}')`,
          }}
        >
          <Link
            onClick={handleClickCategory(item.id)}
            to={createCategoryUrl({ id: item.id })}
            className={styles.link}
          >
            <div className={styles.title}>{item.title}</div>
          </Link>
        </div>
      ))}
    </div>
  );
};

interface DesktopDisplayProps {
  items: CategoriesGridOneSItem[];
}

const DesktopDisplay = ({ items }: DesktopDisplayProps) => {
  const { handleClickCategory } = useCategoryAnalytics();
  const { isMobile } = useGlobal();
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [, setActiveSwiperIndex] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const swiperRef = useRef<SwiperRef>(null);

  const initializeSwiperArrowsDebouncedRef = useRef(
    debounce((swiper: SwiperWithSlidesPerView) => {
      if (!swiper.slides.length) {
        setSlidesPerView(1);
        setIsBeginning(false);
        setIsEnd(false);
        return;
      }

      const slidesPerView = swiper.slidesPerViewDynamic?.() ?? 1;

      setSlidesPerView(slidesPerView);
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }, 100),
  );

  const isArrowsAllowed = () => {
    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return false;
    }

    return !isMobile && swiper.slides.length > slidesPerView;
  };

  const isPrevArrowVisible = () => isArrowsAllowed() && !isBeginning;

  const isNextArrowVisible = () => isArrowsAllowed() && !isEnd;

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(
      src,
      company.envVariables.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
      {
        width: 285,
        height: 285,
      },
    );

  return (
    <div
      className={classes([styles.desktopContainer, 'banner-carousel carousel'])}
    >
      {isPrevArrowVisible() && (
        <SwiperIcon
          position="left"
          variant="fill"
          onClick={() => {
            swiperRef.current?.swiper.slidePrev();
            setIsEnd(false);
            if (swiperRef.current?.swiper.isBeginning) {
              setIsBeginning(true);
            }
          }}
        />
      )}
      <Swiper
        className={styles.grid}
        slidesPerView="auto"
        slidesPerGroupAuto
        spaceBetween={20}
        ref={swiperRef}
        onSlideChange={(swiper) => setActiveSwiperIndex(swiper.activeIndex)}
        onSlidesLengthChange={initializeSwiperArrowsDebouncedRef.current}
        dir={userStore.dir}
        key={userStore.dir}
      >
        {items.map((item) => (
          <SwiperSlide
            className={styles.item}
            key={item.title}
            style={{
              backgroundImage: `${gradient}, url('${cfSrcForImage(item.img)}')`,
            }}
          >
            <Link
              onClick={handleClickCategory(item.id)}
              to={createCategoryUrl({ id: item.id })}
              className={styles.link}
            >
              <div className={styles.title}>{item.title}</div>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      {isNextArrowVisible() && (
        <SwiperIcon
          position="right"
          variant="fill"
          onClick={() => {
            swiperRef.current?.swiper.slideNext();
            setIsBeginning(false);
            if (swiperRef.current?.swiper.isEnd) {
              setIsEnd(true);
            }
          }}
        />
      )}
    </div>
  );
};

interface CategoriesGridOneSBlockProps {
  title: string;
  description: Nullable<string>;
  backgroundColor: Nullable<string>;
  items: CategoriesGridOneSItem[];
  link: string;
  isMobile: boolean;
}

const CategoriesGridOneSBlock = ({
  title,
  description,
  backgroundColor,
  items,
  link,
  isMobile,
}: CategoriesGridOneSBlockProps) => {
  return (
    <div className={styles.container}>
      <CategoryBlockTitle
        text={title}
        linkTo={link}
        description={description}
        backgroundColor={backgroundColor}
      />
      {isMobile ? (
        <MobileDisplay items={items} />
      ) : (
        <DesktopDisplay items={items} />
      )}
    </div>
  );
};

export default observer(CategoriesGridOneSBlock);
