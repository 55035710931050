import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import Modal from '~/components/Modal/Modal';
import { RatingStars } from '~/components/Rating';
import { useModal } from '~/hooks/useModal';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import styles from './ModalRateApp.module.scss';

const FEEDBACK_MAX_LENGTH = 250;

const ModalRateApp = () => {
  const { t } = useTranslation();
  const [ratingValue, setRatingValue] = useState(-1);
  const [feedbackText, setFeedbackText] = useState('');
  const [isSent, setIsSent] = useState<boolean>(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { closeModal } = useModal();

  const [searchParams] = useSearchParams();

  const isTextareaValid = feedbackText.length <= FEEDBACK_MAX_LENGTH;

  const sendHandler = () => {
    if (!isValid()) {
      mainStore.pushAlert(
        'error',
        t('rateAppForm:validationError'),
        undefined,
        styles.root__alert,
      );
      return;
    }

    mainStore.sendToRN('analytics', {
      name: 'Event: App feedback',
      params: {
        score: ratingValue,
        message: feedbackText,
      },
    });
    setFeedbackText('');
    setIsSent(true);
    userStore.setIsRateApp(true);

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = null;
      handleCloseModal();
    }, 5000);
  };

  const handleCloseModal = () => {
    closeModal();
    setIsSent(false);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const handleStarClick = (idx: number) => {
    setRatingValue(idx + 1);
  };

  const isValid = () => {
    return (feedbackText.length > 0 && isTextareaValid) || ratingValue > -1;
  };

  return (
    <Modal
      isShow={searchParams.get('modal') === ModalType.RateApp}
      className={styles.root}
      containerClassName={styles.container}
    >
      <div className={styles.root__closeWr}>
        <span className={styles.root__close} onClick={handleCloseModal}>
          <Icon type="close" size={24} />
        </span>
      </div>
      {isSent ? (
        <div className={styles.root__thanksWr}>
          <div className={styles.root__thanks}>
            <Icon type="rate-stars-circle" size={150} />
          </div>
          <p className={styles.root__title}>
            {t('rateAppForm:thanksFeedback')}
          </p>
          <span className={styles.root__caption}>
            {t('rateAppForm:thanksFeedbackCaption')}
          </span>
        </div>
      ) : (
        <>
          <p
            className={classes([styles.root__title, styles.root__title__space])}
          >
            {t('rateAppForm:title')}
          </p>
          {t('rateAppForm:caption')
            .split('\n')
            .map((line) => (
              <span key={line} className={styles.root__caption}>
                {line}
              </span>
            ))}

          <RatingStars
            rate={ratingValue}
            starSize={40}
            type="star-fill"
            className={styles.root__rateStars}
            getStarClassName={(idx) =>
              classes([
                styles.root__rateStars__star,
                idx < ratingValue && styles.active,
              ])
            }
            onStarClick={handleStarClick}
          />

          <div className={styles.root__textarea}>
            <textarea
              className={classes([
                'input-textarea',
                styles.root__textarea__input,
                !isTextareaValid && styles.invalid,
              ])}
              rows={5}
              placeholder={t('rateAppForm:placeholder')}
              onChange={(e) => setFeedbackText(e.currentTarget.value)}
              value={feedbackText}
              maxLength={FEEDBACK_MAX_LENGTH}
            />
            {!isTextareaValid && (
              <span className={styles.root__textarea__error}>
                {t('rateAppForm:errors.validation')}
              </span>
            )}
          </div>

          <button
            className={classes([
              'button _primary flex-shrink-1 w-100p',
              !isValid() && '_disabled',
              styles.root__submitBtn,
            ])}
            onClick={sendHandler}
            disabled={!isValid()}
          >
            {t('submit')}
          </button>
        </>
      )}
    </Modal>
  );
};

export default observer(ModalRateApp);
