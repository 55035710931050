import { useEffect, useState, useRef } from 'react';

import { OBSERVER_DEFAULT_CONFIG } from './constants';
import { UseIntersectionObserverProps, ObservableRef } from './interface';

function useIntersectionObserver(
  config = OBSERVER_DEFAULT_CONFIG,
): UseIntersectionObserverProps {
  const [isObservableElementVisible, setIsObservableElementVisible] =
    useState(false);
  const observerRef = useRef<IntersectionObserver>();

  useEffect(() => {
    if (!window.IntersectionObserver) {
      return;
    }

    observerRef.current = new IntersectionObserver((entries) => {
      if (entries && entries[0]?.isIntersecting) {
        setIsObservableElementVisible(true);
      } else {
        setIsObservableElementVisible(false);
      }
    }, config);

    return () => {
      stopObserver();
    };
    // eslint-disable-next-line
  }, []);

  // Code below runs observer that watching after the element which was provided as attr
  const runObserver = (ref: ObservableRef) => {
    const element = ref?.current;

    if (element) {
      observerRef.current?.observe(element);
    }
  };

  // Code below stops observer that watching after the element which was provided as attr
  const stopObserver = () => {
    observerRef.current?.disconnect();
  };

  return {
    isObservableElementVisible,
    runObserver,
    stopObserver,
  };
}

export default useIntersectionObserver;
