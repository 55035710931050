import { createContext, useContext } from 'react';

import { CompanyName, EnvVariables } from '~/company/interface';

export interface GlobalContext {
  redirectUrl?: string;
  isMobile: boolean;
  isTablet: boolean;
  isSSR?: boolean;
  serverLang?: string;
  serverWarehouse?: string;
  company: CompanyName;
  variables?: EnvVariables;
  isOriginalLogic: boolean;
}

export const GlobalContext = createContext<GlobalContext>({
  isMobile: false,
  isTablet: false,
  isSSR: false,
  company: CompanyName.CityDrinks,
  isOriginalLogic: true,
});

export const useGlobal = (): GlobalContext => {
  return useContext(GlobalContext);
};
