import { ReactNode } from 'react';

import { SwiperWithSlidesPerView } from '~/components/CategoryBlocks/BannerProductBlock/interfaces';

export enum SliderLayout {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export interface ProductImageSliderProps {
  productTitle?: string;
  images?: string[];
  className?: string;
  badgesContent?: ReactNode | boolean;
  layout?: SliderLayout;
}

export interface SliderThumbsProps {
  onSwiper: (value: SwiperWithSlidesPerView | null) => void;
  swiper: SwiperWithSlidesPerView | null;
}

export interface FullscreenWrapperProps {
  wrapperClassName?: string;
  productTitle?: string;
  onClose?: () => void;
}
