import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { useGlobal } from '~/hooks/useGlobal';

import Icon from '../../components/Icon/Icon';

import './styles.scss';

const OrderSkeleton = () => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();

  if (isMobile) {
    const productItem = (
      <div className="_row _center">
        <Skeleton width={48} height={64} className="_rounded_s" />
        <div>
          <Skeleton width={179} height={16} className="_rounded_full" />
          <Skeleton width={89} height={16} className="_rounded_full" />
        </div>
        <div className="_flex" />
        <Skeleton width={84} height={16} className="_rounded_full" />
      </div>
    );

    return (
      <div className="order-skeletons">
        <Link to="/cabinet/orders" className="_row">
          <Icon type="arrow" className="icon__rotate-180" /> {t('order')}
        </Link>
        <div className="_row">
          <Skeleton width={114} height={32} className="_rounded_s" />
          <Skeleton width={114} height={32} className="_rounded_s" />
          <Skeleton width={114} height={32} className="_rounded_s" />
        </div>
        <Skeleton width="100%" height={172} className="_rounded" />
        {Array(3).fill(productItem)}
        <div style={{ height: 16 }} />
        <div className="_row">
          <Skeleton width={84} height={16} className="_rounded_full" />
          <div className="_flex" />
          <Skeleton width={84} height={16} className="_rounded_full" />
        </div>
        <div>
          <div className="_row">
            <Skeleton width={84} height={16} className="_rounded_full" />
            <div className="_flex" />
            <Skeleton width={84} height={16} className="_rounded_full" />
          </div>
          <div className="_row">
            <Skeleton width={84} height={16} className="_rounded_full" />
            <div className="_flex" />
            <Skeleton width={84} height={16} className="_rounded_full" />
          </div>
        </div>
        <div className="_row">
          <Skeleton width={179} height={16} className="_rounded_full" />
          <div className="_flex" />
          <Skeleton width={84} height={16} className="_rounded_full" />
        </div>
        <div>
          <Skeleton width={89} height={16} className="_rounded_full" />
          <Skeleton width="100%" height={16} className="_rounded_full" />
        </div>
        <div>
          <Skeleton width={89} height={16} className="_rounded_full" />
          <Skeleton width="100%" height={16} className="_rounded_full" />
        </div>
      </div>
    );
  }

  const productItem = (
    <div className="_row">
      <div className="_row _flex">
        <Skeleton width={100} height={140} className="_rounded" />
        <div className="_column">
          <Skeleton width={240} height={24} className="_rounded_full" />
          <Skeleton width={80} height={16} className="_rounded_full" />
          <div className="_flex" />
          <Skeleton width={100} height={24} className="_rounded_full" />
        </div>
      </div>
      <div className="_row _end _gap_s">
        <Skeleton width={40} height={40} className="_rounded_s" />
        <Skeleton width={160} height={40} className="_rounded_s" />
        <Skeleton width={160} height={40} className="_rounded_s" />
      </div>
    </div>
  );

  return (
    <div className="order-skeletons">
      <div className="_row">
        <Icon type="chevron" className="icon__rotate-90" size={24} />
        {t('phrases:backToOrderList')}
      </div>
      <div className="_row">
        <Skeleton width={260} height={40} className="_rounded_full" />
        <div className="_flex" />
        <Skeleton width={260} height={40} className="_rounded_full" />
      </div>
      <Skeleton
        width="100%"
        height={114}
        className="_rounded"
        style={{ marginTop: 32 }}
      />
      <Skeleton
        width="100%"
        height={304}
        className="_rounded"
        style={{ marginBottom: 80 }}
      />
      {Array(3).fill(productItem)}
    </div>
  );
};

export default OrderSkeleton;
