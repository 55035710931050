import GoogleMapReact, { Props } from 'google-map-react';
import { JSX, useMemo, useRef } from 'react';

import WarehouseMarkerIcon from '~/assets/img/warehouse_marker.svg';
import cdConfig from '~/assets/mapStyle/cd.json';
import localeeConfig from '~/assets/mapStyle/localee.json';
import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import { mapZoom } from '~/components/ModalDeliveryAddress/DeliveryAddressStore';
import { GOOGLE_API_KEY } from '~/stores/constants';

import { ITrackingMapProps, GMapMarkerPartial } from './interfaces';

const mapStyle: Record<CompanyName, any> = {
  [CompanyName.CityDrinks]: cdConfig,
  [CompanyName.Localee]: localeeConfig,
  [CompanyName.CircleK]: localeeConfig,
  [CompanyName.Vilo]: localeeConfig,
  [CompanyName.Jiffy]: localeeConfig,
  [CompanyName.Kids]: localeeConfig,
};

const TrackingMap = (props: ITrackingMapProps): JSX.Element => {
  const { address, defaultCenter, defaultZoom, warehouseCoords } = props;

  const mapMarkers = useRef<GMapMarkerPartial[]>([]);

  const center = useMemo(() => {
    if (!address || !address.latitude || !address.longitude) {
      return defaultCenter;
    }

    return { lat: address.latitude, lng: address.longitude };
  }, [address, defaultCenter]);

  const handleMapLoaded: Props['onGoogleApiLoaded'] = ({ map, maps }) => {
    const markers = [center, warehouseCoords];

    if (mapMarkers.current.length) {
      for (const marker of mapMarkers.current) {
        marker.setMap(null);
      }
      mapMarkers.current = [];
    }

    for (const coords of markers) {
      const marker: GMapMarkerPartial = new maps.Marker({
        position: coords,
        map,
        icon: WarehouseMarkerIcon,
      });

      mapMarkers.current.push(marker);
    }
  };

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: GOOGLE_API_KEY,
        language: 'en-GB',
        region: 'GB',
      }}
      defaultZoom={mapZoom}
      center={center}
      zoom={defaultZoom}
      options={{
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        styles: mapStyle[company.name],
      }}
      shouldUnregisterMapOnUnmount
      onGoogleApiLoaded={handleMapLoaded}
    />
  );
};

export default TrackingMap;
