import { useState, useEffect, FC, ReactNode } from 'react';

export const NoSsr: FC<{ children: ReactNode }> = ({ children }) => {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  return canRender ? <>{children}</> : null;
};
