import { Offer } from '~/stores/CategoriesStore';

interface formatOptions {
  prefix?: string;
  defaultVolume?: string;
}

export const formatVolume = (
  properties: Offer['properties'],
  options?: formatOptions,
): string => {
  const { prefix, defaultVolume = '1x' } = options || {};
  const { volume } = properties;

  if (!volume) {
    return prefix ? `${prefix} ${defaultVolume}` : defaultVolume;
  }

  let value: string = `${volume.value}`;

  if (Array.isArray(volume.value)) {
    value = `${volume.value[0]}`;
  }

  if (!prefix) {
    return value;
  }
  return `${prefix} ${value}`;
};

export const pluralizePcLabel = (
  value?: number | string | null,
  label = 'x',
) => {
  return `${value}${label}`;
};
