import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { findNearest } from 'geolib';
import i18n, { t } from 'i18next';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import { v4 as uuidv4 } from 'uuid';

import { GeocoderRequests } from '~/api/Geocoder';
import { KYCStatus } from '~/api/KYCStatus';
import { CheckPromocodeResponse } from '~/api/Order';
import { ReferralProgramRequests } from '~/api/ReferralProgram';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { MetaStore } from '~/stores/shared/MetaStore';
import { getCookieLang } from '~/utils/cookies';
import { clearStoredPromocode } from '~/utils/referralLink';

import {
  CustomerRequests,
  RequestCodeResponse,
  SubscriptionFlag,
  UpdatePersonalFields,
  VerifyCodeResponse,
  Customer,
  BonusesTransaction,
} from '../api/Customer';
import {
  DeliveryRequests,
  Address,
  UpdateAddressRequest,
  AddAddressRequest,
  DeliveryZoneCoverage,
  AutocompleteRequest,
  AutocompleteResponse,
  AddressResponse,
} from '../api/DeliveryAddress';
import {
  AddressComponent,
  AddressComponentType,
  GoogleMapGeocode,
} from '../api/GoogleMapGeocode';
import { refreshTokens, ApiErrorResponse } from '../api/Requests';
import { company } from '../company/Company';

import { catalogStore } from './CatalogStore';
import { checkoutStore } from './CheckoutStore/CheckoutStore';
import { storage as localStorage } from './LocalStorage';
import { mainStore } from './MainStore';
import { orderStore } from './OrderStore';

type AddressComponents = Partial<
  Record<AddressComponentType, Omit<AddressComponent, 'types'>>
>;

export type DeliveryAddress = {
  zip: string;
  country: string;
  city: string;
  region: string;
  address1: string;
  address2: string;
  addressFloor: string;
  addressBld: string;
  addressApt: string;
  shortAddress: string;
  coordinates: GeoCoordinates;
  placeId: string;
  addressId: string | null;
  comment: string;
  instructions: string[];
  type: string;
};

export type DraftUserData = {
  firstName: string;
  lastName: string;
  middleName: string;
  email: string;
};

export type PersonalData = {
  id: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  phone: string | null;
  flat: string;
  dateOfBirth: string | null;
  email: string | null;
  lastLogin: string | null;
  subscriptionFlags: Record<string, SubscriptionFlag>;
  subscriptionId: string;
  isAdult: boolean;
  isRateApp: boolean;
  type: 'User' | 'Staff';
  freeDeliveryDaysLeft: number;
  kycStatus: KYCStatus;
};

type BonusesTransactionsPagination = {
  page: number;
  total: number;
};

interface ReverseGeocodeResponse {
  Match_addr: string;
  LongLabel: string;
  ShortLabel: string;
  Addr_type: string;
  Type: string;
  PlaceName: string;
  AddNum: string;
  Address: string;
  Block: string;
  Sector: string;
  Neighborhood: string;
  District: string;
  City: string;
  MetroArea: string;
  Subregion: string;
  Region: string;
  RegionAbbr: string;
  Territory: string;
  Postal: string;
  PostalExt: string;
  CntryName: string;
  CountryCode: string;
}

export class UserStore {
  isFirstLaunch = true;
  isAuthorized = false;
  deliveryAddress: DeliveryAddress | null = null;
  token = '';
  refreshToken = '';
  lang = getCookieLang();
  dir = 'ltr';

  // A unique token to manage address search sessions.
  // Reset the token after an address has been found.
  addressSearchSessionToken?: string;

  draftUserData: DraftUserData | null = null;

  personalData: PersonalData = {
    id: null,
    firstName: null,
    middleName: null,
    lastName: null,
    phone: null,
    flat: '',
    dateOfBirth: null,
    email: null,
    lastLogin: null,
    subscriptionFlags: {},
    subscriptionId: '',
    isAdult: false,
    isRateApp: false,
    type: 'User',
    freeDeliveryDaysLeft: 0,
    kycStatus: KYCStatus.NotVerified,
  };
  deviceId = '';
  playerId = '';
  sessionList: string[] = [];
  addressList: Address[] = [];
  bonuses: number | null = null;
  bonusesTransactionsList: BonusesTransaction[] = [];
  bonusesTransactionsPagination: BonusesTransactionsPagination = {
    total: 0,
    page: 1,
  };
  addressToDelete: string | null = null;
  refereeInfo: CheckPromocodeResponse | null = null;
  referralInfo: CheckPromocodeResponse | null = null;
  deliveryZonesCoverages: DeliveryZoneCoverage[] = [];
  deliveryZoneCacheTime: number = 0;

  readonly emailChangeMeta: MetaStore = new MetaStore();

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserStore',
      properties: [
        'isFirstLaunch',
        'isAuthorized',
        'deliveryAddress',
        'token',
        'refreshToken',
        'personalData',
        'draftUserData',
        'deviceId',
        'playerId',
        'sessionList',
        'lang',
      ],
      storage: localStorage,
    }).catch((error) => error && console.error(error));
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get fullName(): string {
    return `${this.personalData.firstName || ''} ${
      this.personalData.middleName || ''
    } ${this.personalData.lastName || ''}`
      .trim()
      .replace('  ', ' ');
  }

  get email(): string {
    return this.personalData.email || '';
  }

  get phone() {
    return this.personalData.phone || '';
  }

  get isStaff(): boolean {
    return this.personalData.type === 'Staff';
  }

  // Setters
  setIsFirstLaunch(flag: boolean) {
    this.isFirstLaunch = flag;
  }

  setDeliveryAddress(deliveryAddress: DeliveryAddress | null) {
    this.deliveryAddress = deliveryAddress;
  }

  setDeliveryAddress1(val: string) {
    if (!this.deliveryAddress) {
      return;
    }
    this.deliveryAddress.address1 = val;
    this.deliveryAddress.shortAddress = `${this.deliveryAddress.zip}, ${val}`;
  }

  setDeliveryAddress2(val: string) {
    if (!this.deliveryAddress) {
      return;
    }
    this.deliveryAddress.address2 = val;
  }

  setDeliveryAddressFloor(val: string) {
    if (!this.deliveryAddress) {
      return;
    }
    this.deliveryAddress.addressFloor = val;
  }

  setDeliveryAddressBld(val: string) {
    if (!this.deliveryAddress) {
      return;
    }
    this.deliveryAddress.addressBld = val;
  }

  setDeliveryAddressApt(val: string) {
    if (!this.deliveryAddress) {
      return;
    }
    this.deliveryAddress.addressApt = val;
  }

  setDraftUserData(data: DraftUserData | null) {
    this.draftUserData = data;
  }

  setPersonalDataName(val: string) {
    this.personalData = { ...this.personalData, ...this.splitFullName(val) };
  }

  setPersonalData(data: Omit<Customer, 'app_rating' | 'type'>) {
    if (!data.id) {
      return;
    }
    this.personalData.id = data.id;
    this.personalData.firstName =
      data.first_name || this.draftUserData?.firstName || '';
    this.personalData.middleName =
      data.middle_name || this.draftUserData?.middleName || '';
    this.personalData.lastName =
      data.last_name || this.draftUserData?.lastName || '';
    this.personalData.dateOfBirth = data.date_of_birth;
    this.personalData.email = data.email;
    this.personalData.lastLogin = data.last_login;
    this.personalData.isAdult = data.age_restriction || false;
    this.personalData.type = data.customer_type?.code || 'User';
    this.personalData.freeDeliveryDaysLeft = data.freeDeliveryDaysLeft || 0;
    this.personalData.kycStatus = data.kyc_verification_status;
    if (this.personalData.subscriptionId && data.subscription?.length) {
      for (let i = 0; i < data.subscription.length; i++) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        if (data.subscription[i].id !== this.personalData.subscriptionId) {
          continue;
        }
        for (
          let j = 0;
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          j < data.subscription[i].subscription_flags.length;
          j++
        ) {
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          this.personalData.subscriptionFlags[
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            data.subscription[i].subscription_flags[j].name
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          ] = data.subscription[i].subscription_flags[j];
        }
      }
    }
    const fullName = `${data.first_name || ''} ${data.middle_name || ''} ${
      data.last_name || ''
    }`
      .trim()
      .replace('  ', ' ');

    if (data.email) {
      mainStore.sendToRN('setOneSignalUserEmail', data.email);
    }

    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_KYC_VERIFICATION_STATUS,
      { status: this.personalData.kycStatus },
    );

    mainStore.sendToRN('setUserName', {
      welcomeText: '', //i18n.t('hello'),
      name: fullName,
    });
    mainStore.setSentryUser({
      id: data.id,
      name: fullName,
      email: data.email || '',
    });
    mainStore.sendToRN('sendTags', {
      first_name: data.first_name || '',
    });
    mainStore.sendToRN('setOneSignalUserID', {
      id: data.id,
    });
  }

  setIsAdult(flag: boolean) {
    this.personalData.isAdult = flag;
    this.updatePersonalData({
      age_restriction: flag,
      email: this.personalData.email || '',
    }).catch((error) => error && console.error(error));
  }

  setIsRateApp(flag: boolean) {
    this.personalData.isRateApp = flag;
    this.updatePersonalData({
      app_rating: flag,
      email: this.personalData.email || '',
    }).catch((error) => error && console.error(error));
  }

  setToken(val: string) {
    this.token = val;
  }

  setRefreshToken(val: string) {
    this.refreshToken = val;
  }

  // Resets the addressSearchSessionToken with a new UUID.
  // Call this method after an address has been successfully found.
  resetAddressSearchSessionToken() {
    this.addressSearchSessionToken = uuidv4();
  }

  setDeviceId(val: string) {
    this.deviceId = val;
    if (!val || val === '00000000-0000-0000-0000-000000000000') {
      if (this.isAuthorized) {
        this.logout().catch((error) => error && console.error(error));
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          deviceId: val,
        });
        Sentry.captureMessage(
          '[DeviceID] Received invalid deviceID',
          'warning',
        );
      });
    }
  }

  setPlayerId(val: string) {
    this.playerId = val;
  }

  setSubscriptionFlags(flags: Record<string, SubscriptionFlag>) {
    this.personalData.subscriptionFlags = flags;
  }

  setAddressList(addressList: Address[]) {
    this.addressList = addressList;
  }

  resetCustomerType() {
    this.personalData.type = 'User';
    this.personalData.freeDeliveryDaysLeft = 0;
    orderStore.setIsFirstOrder(true);
  }

  setBonuses(bonuses: number | null) {
    this.bonuses = bonuses;
  }

  setBonusesTransactionsList(list: BonusesTransaction[]) {
    if (!list.length) {
      this.bonusesTransactionsList = [];
    } else {
      this.bonusesTransactionsList.push(...list);
    }
  }

  setBonusesTransactionsPagination(val: BonusesTransactionsPagination) {
    this.bonusesTransactionsPagination = val;
  }

  setAddressToDelete(id: string | null) {
    this.addressToDelete = id;
  }

  setLang(val: string) {
    this.lang = val;
  }

  setDir(val: string) {
    this.dir = val;
  }

  // Actions
  getShortAddress(addressComponents: AddressComponents): string {
    let address = '';
    if (addressComponents.street_number && addressComponents.route) {
      address += addressComponents.street_number.long_name;

      if (addressComponents.route) {
        if (address) {
          address += ' ';
        }
        address += addressComponents.route.long_name;
      }
      if (addressComponents.administrative_area_level_1) {
        if (address) {
          address += ', ';
        }
        address += addressComponents.administrative_area_level_1.long_name;
      }
      if (addressComponents.locality) {
        if (address) {
          address += ', ';
        }
        address += addressComponents.locality.long_name;
      }
      if (addressComponents.country) {
        if (address) {
          address += ', ';
        }
        address += addressComponents.country.long_name;
      }
    } else if (
      addressComponents.premise ||
      addressComponents.route ||
      addressComponents.neighborhood
    ) {
      if (addressComponents.premise) {
        address += addressComponents.premise.long_name;
      }
      if (addressComponents.route) {
        if (address) {
          address += ', ';
        }
        address += addressComponents.route.long_name;
      }
      if (addressComponents.neighborhood) {
        if (address) {
          address += ', ';
        }
        address += addressComponents.neighborhood.long_name;
      }
    } else {
      if (addressComponents.locality) {
        address += addressComponents.locality.long_name;
      }
      if (addressComponents.administrative_area_level_1) {
        if (address) {
          address += ', ';
        }
        address += addressComponents.administrative_area_level_1.long_name;
      }
      if (addressComponents.country) {
        if (address) {
          address += ', ';
        }
        address += addressComponents.country.long_name;
      }
    }
    return address;
  }

  splitFullName(fullName: string): {
    firstName: string;
    middleName: string;
    lastName: string;
  } {
    let firstName = '';
    let middleName = '';
    let lastName = '';
    if (!fullName) {
      return { firstName, middleName, lastName };
    }
    let nameParts = fullName.replace(/\s+/g, ' ').split(' ');
    nameParts = nameParts.filter((item) => item.length);
    if (nameParts.length === 1) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      firstName = nameParts[0];
      middleName = '';
      lastName = '';
    } else if (nameParts.length === 2) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      firstName = nameParts[0];
      middleName = '';
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      lastName = nameParts[1];
    } else if (nameParts.length >= 3) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      firstName = nameParts[0];
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      middleName = nameParts[1];
      lastName = nameParts.slice(2).join(' ').trim();
    }
    return { firstName, middleName, lastName };
  }

  sendSubscriptionAnalytics(
    subscriptionFlags: SubscriptionFlag[],
    source: string,
  ) {
    const sendAnalytics = ({
      name,
      push = false,
      email = '',
    }: SubscriptionFlag) => {
      mainStore.sendAnalytics(['analytics', 'firebase'], {
        name: 'Subscribe: change settings',
        params: {
          type: 'push',
          kind: name,
          toggle: push ? 'on' : 'off',
          source,
        },
      });
      mainStore.sendAnalytics(['analytics', 'firebase'], {
        name: 'Subscribe: change settings',
        params: {
          type: 'email',
          kind: name,
          toggle: email ? 'on' : 'off',
          source,
        },
      });
    };
    const tagData: {
      subscribed_personal_offers?: string;
      subscribed_discounts?: string;
      subscribed_thirdparty?: string;
    } = {};
    for (let i = 0; i < subscriptionFlags.length; i++) {
      let flag = '';
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      const { name, push = false, email = false } = subscriptionFlags[i];
      if (push && email) {
        flag = 'all';
      } else if (!push && !email) {
        flag = '0';
      } else {
        flag = push ? 'push' : 'email';
      }
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      sendAnalytics(subscriptionFlags[i]);
      if (name === 'personalised') {
        tagData['subscribed_personal_offers'] = flag;
        mainStore.sendToRN('setUserProperties', {
          'Subscription: personalized email': email,
        });
        mainStore.sendToRN('setUserProperties', {
          'Subscription: personalized push': push,
        });
      }
      if (name === 'discounts') {
        tagData['subscribed_discounts'] = flag;
        mainStore.sendToRN('setUserProperties', {
          'Subscription: discounts email': email,
        });
        mainStore.sendToRN('setUserProperties', {
          'Subscription: discounts push': push,
        });
      }
      if (name === 'third_party') {
        tagData['subscribed_thirdparty'] = flag;
        mainStore.sendToRN('setUserProperties', {
          'Subscription: 3d party email': email,
        });
        mainStore.sendToRN('setUserProperties', {
          'Subscription: 3d party push': push,
        });
      }
    }
    if (Object.keys(tagData).length) {
      mainStore.sendToRN('sendTags', tagData);
    }
  }

  // Requests API
  requestAuthCode(
    phone: string,
    recaptchaToken: string,
  ): Promise<RequestCodeResponse | AxiosError> {
    return CustomerRequests.requestCode({
      phone_number: phone.replace('+', ''),
      recaptcha_token: recaptchaToken,
      device_id: this.deviceId,
    }).catch((e) => this.errorHandler(e, 'requestAuthCode'));
  }

  verifyAuthCode(
    phone: string,
    code: string,
    restore: boolean,
  ): Promise<
    | (VerifyCodeResponse & {
        previousEmail: string | null;
      })
    | AxiosError
  > {
    const isAdult = this.personalData.isAdult;
    if (mainStore.isRN && !this.deviceId) {
      Sentry.captureMessage('[DeviceID] Empty deviceID used', 'warning');
    }

    return CustomerRequests.verifyCode({
      phone_number: phone.replace('+', ''),
      otp: code,
      device_id: this.deviceId,
      ...(restore ? { restore } : {}),
    })
      .then((e) => {
        const previousEmail = this.personalData.email;
        runInAction(() => {
          if (e.verified && e.access_token && e.refresh_token) {
            this.personalData.id = e.customer.id;
            this.personalData.firstName =
              e.customer.first_name || this.draftUserData?.firstName || '';
            this.personalData.middleName =
              e.customer.middle_name || this.draftUserData?.middleName || '';
            this.personalData.lastName =
              e.customer.last_name || this.draftUserData?.lastName || '';
            this.personalData.phone = phone;
            this.personalData.dateOfBirth = e.customer.date_of_birth;
            this.personalData.email = e.customer.email;
            this.personalData.lastLogin = e.customer.last_login;
            this.personalData.isAdult = isAdult;
            this.personalData.isRateApp = e.customer.app_rating || false;
            this.personalData.type = e.customer.type?.code || 'User';
            this.personalData.freeDeliveryDaysLeft =
              e.customer.freeDeliveryDaysLeft || 0;
            this.personalData.kycStatus = e.customer.kyc_verification_status;
            this.token = e.access_token;
            this.refreshToken = e.refresh_token;
            this.isAuthorized = true;
            this.sessionList.push(new Date().toISOString());
            if (
              catalogStore.cart.length &&
              e.customer.freeDeliveryDaysLeft > 0
            ) {
              mainStore.setIsFreeDeliveryPopover(true);
            }
            if (catalogStore.favoritesList.length) {
              catalogStore.addFavorite(
                catalogStore.favoritesList.map((item) => item.id),
              );
            }

            if (
              !e.customer.first_name &&
              !e.customer.email &&
              this.draftUserData?.email
            ) {
              this.updatePersonalData({
                first_name: this.draftUserData.firstName,
                last_name: this.draftUserData.lastName,
                middle_name: this.draftUserData.middleName,
                email: this.draftUserData.email,
              });

              this.setDraftUserData(null);
            }

            if (isAdult && !e.customer.age_restriction) {
              this.updatePersonalData({
                age_restriction: true,
                email: this.personalData.email || '',
              }).catch((error) => error && console.error(error));
            }
            this.setAddressList(e.addresses || []);
            if (this.deliveryAddress?.address1) {
              for (let i = 0; i < e.addresses.length; i++) {
                if (
                  // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                  e.addresses[i].street_address_1 ===
                  this.deliveryAddress.address1
                ) {
                  // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                  this.deliveryAddress.addressId = e.addresses[i].id;
                  // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                  this.deliveryAddress.comment = e.addresses[i].comment || '';
                  this.deliveryAddress.instructions =
                    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                    e.addresses[i].instructions || [];
                  this.deliveryAddress.address2 =
                    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                    e.addresses[i].street_address_2 || '';
                  break;
                }
              }
            }
            if (
              this.deliveryAddress &&
              this.deliveryAddress.address1 &&
              !this.deliveryAddress.addressId
            ) {
              this.addAddress({
                street_address_1: this.deliveryAddress.address1,
                street_address_2: this.deliveryAddress.address2,
                building: this.deliveryAddress.addressBld,
                floor: this.deliveryAddress.addressFloor,
                apartment: this.deliveryAddress.addressApt,
                city: this.deliveryAddress.city,
                postcode: this.deliveryAddress.zip,
                latitude: this.deliveryAddress.coordinates.lat,
                longitude: this.deliveryAddress.coordinates.lng,
                country: this.deliveryAddress.country,
                comment: this.deliveryAddress.comment,
                instructions: this.deliveryAddress.instructions,
                type: this.deliveryAddress.type,
              })
                .then((e) => {
                  runInAction(() => {
                    if (!this.deliveryAddress || !e) {
                      return;
                    }
                    this.deliveryAddress.addressId = e?.id;
                    this.setDeliveryAddress2(e.street_address_2 ?? '');
                  });
                })
                .catch((error) => error && console.error(error));
            }
            const fullName = `${e.customer.first_name || ''} ${
              e.customer.middle_name || ''
            } ${e.customer.last_name || ''}`
              .trim()
              .replace('  ', ' ');
            mainStore.sendToRN('setUserName', {
              welcomeText: '', //i18n.t('hello'),
              name: fullName,
              customerId: e.customer.id,
            });
            mainStore.sendToRN('setToken', e.access_token);
            mainStore.setSentryUser({
              id: e.customer.id,
              name: fullName,
              email: e.customer.email || '',
              phone,
            });
            mainStore.sendToRN('analytics', {
              name: 'Login: code entered',
              params: {
                success: true,
              },
            });
            mainStore.sendToRN('firebaseAnalytics', {
              name: 'login_code_entered',
              params: {
                success: true,
              },
            });
            mainStore.sendToRN('setOneSignalUserID', {
              id: e.customer.id,
            });

            // when user signed up, check for the first order
            orderStore.checkIsFirstOrder(true).then((isFirst) => {
              if (isFirst) {
                catalogStore.calculateCart();
              }
            });

            mainStore.sendToRN('setUserProperties', {
              'General: logged in': true,
            });
            mainStore.sendToRN('setUserProperties', {
              'General: login date': new Date().toISOString(),
            });
            mainStore.sendToRN('setUserProperties', {
              'General: last session date':
                this.sessionList[this.sessionList.length - 1],
            });
            mainStore.sendToRN('setUserProperties', {
              'General: total sessions': this.sessionList.length,
            });
            mainStore.sendToRN('setUserProperties', {
              'General: user type': 'normal',
            });
            mainStore.sendToRN('setUserProperties', {
              customerId: e.customer.id,
            });
            mainStore.sendToRN('firebaseAnalytics', {
              name: 'login',
              params: {
                method: 'SMS',
              },
            });
          } else {
            //mainStore.pushAlert('error', i18n.t('errors:otpCodeError'));
            mainStore.sendToRN('analytics', {
              name: 'Login: code entered',
              params: {
                success: false,
              },
            });
            mainStore.sendToRN('firebaseAnalytics', {
              name: 'login_code_entered',
              params: {
                success: false,
              },
            });
            mainStore.sendToRN('setUserProperties', {
              'General: logged in': false,
            });
            mainStore.sendToRN('setUserProperties', {
              customerId: null,
            });
          }
        });
        return Promise.resolve({ ...e, previousEmail });
      })
      .catch((e) => {
        mainStore.sendToRN('analytics', {
          name: 'Login: code entered',
          params: {
            success: false,
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'login_code_entered',
          params: {
            success: false,
          },
        });
        return this.errorHandler(e, 'verifyAuthCode');
      });
  }

  async requestPersonalData(avoidCartCalculation = false) {
    if (!this.personalData.id) {
      return Promise.reject();
    }
    try {
      const [data] = await CustomerRequests.getPersonalData(
        this.personalData.id,
      );
      if (!data) {
        return;
      }
      this.setPersonalData(data);

      // when user signed up, check for the first order
      if (avoidCartCalculation) {
        return;
      }

      orderStore.checkIsFirstOrder().then((isFirst) => {
        if (isFirst) {
          catalogStore.calculateCart();
        }
      });
    } catch (error) {
      this.errorHandler(
        error as AxiosError<ApiErrorResponse>,
        'requestPersonalData',
      );
      return Promise.reject();
    }
  }

  async updatePersonalData(data: UpdatePersonalFields) {
    if (!this.personalData.id) {
      return Promise.reject();
    }
    try {
      const { customer: [customerData] = [] } =
        await CustomerRequests.updatePersonalData(this.personalData.id, data);
      if (!customerData) {
        //mainStore.pushAlert('error', i18n.t('errors:failedUpdatePersonal'));
        await this.requestPersonalData();
        return Promise.reject();
      }
      this.setPersonalData(customerData);
    } catch (error) {
      //mainStore.pushAlert('error', i18n.t('errors:failedUpdatePersonal'));
      if (
        error instanceof AxiosError &&
        error.response?.data.error &&
        error.response.data.message === 'Email in use.'
      ) {
        mainStore.pushAlert('error', i18n.t('errors:emailAlreadyExists'));
      }
      await this.requestPersonalData();
      return Promise.reject();
    }
  }

  async updatePersonalEmail(email: string): Promise<boolean> {
    if (!this.personalData.id) {
      return false;
    }
    const emailCached = this.personalData.email || '';
    this.emailChangeMeta.setLoading(true);
    try {
      const { customer: [customerData] = [] } =
        await CustomerRequests.updatePersonalData(this.personalData.id, {
          email,
        });

      if (!customerData) {
        await CustomerRequests.updatePersonalData(this.personalData.id, {
          email: emailCached,
        }).catch((error) => error && console.error(error));
        return false;
      }
      this.setPersonalData(customerData);
      return true;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.data.error &&
        error.response.data.message === 'Email in use.'
      ) {
        mainStore.pushAlert('error', i18n.t('errors:emailAlreadyExists'));
        checkoutStore.setEmailVal(emailCached);
      }
      return false;
    } finally {
      this.emailChangeMeta.setLoading(false);
    }
  }

  async updateDeviceData(
    name: string,
    email: string,
    flags: SubscriptionFlag[],
  ): Promise<boolean> {
    if (mainStore.isRN && !this.deviceId) {
      Sentry.captureMessage('[DeviceID] Empty deviceID used', 'warning');
    }
    try {
      const { device } = await CustomerRequests.updateDeviceData(
        this.deviceId,
        {
          name,
          email: email.trim(),
          subscription_flags: flags,
        },
      );
      runInAction(() => {
        const { firstName, middleName, lastName } = this.splitFullName(
          device.name || '',
        );
        this.personalData.firstName = firstName;
        this.personalData.middleName = middleName;
        this.personalData.lastName = lastName;
        this.personalData.email = device.email;
        if (
          device.subscriptions?.subscription_flags &&
          device.subscriptions?.subscription_flags?.length
        ) {
          for (
            let i = 0;
            i < device.subscriptions.subscription_flags.length;
            i++
          ) {
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            this.personalData.subscriptionFlags[
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              device.subscriptions.subscription_flags[i].name
            ] = device.subscriptions.subscription_flags[i];
          }
        }
        mainStore.sendToRN('setUserName', {
          welcomeText: '', //i18n.t('hello'),
          name: device.name || '',
        });
        mainStore.sendToRN('sendTags', {
          first_name: firstName || '',
        });
      });
      return true;
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'updateDeviceData',
      );
    }
    return false;
  }

  async updateSubscription(
    flags: SubscriptionFlag[],
    source: string,
  ): Promise<boolean> {
    if (mainStore.isRN && !this.deviceId) {
      Sentry.captureMessage('[DeviceID] Empty deviceID used', 'warning');
    }
    try {
      const data = await CustomerRequests.updateSubscription(this.deviceId, {
        subscription_flags: flags,
      });
      runInAction(() => {
        if (
          data.subscription.subscription_flags &&
          data.subscription.subscription_flags.length
        ) {
          for (
            let i = 0;
            i < data.subscription.subscription_flags.length;
            i++
          ) {
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            this.personalData.subscriptionFlags[
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              data.subscription.subscription_flags[i].name
            ] = data.subscription.subscription_flags[i];
          }
          this.sendSubscriptionAnalytics(
            data.subscription.subscription_flags,
            source,
          );
        } else {
          this.personalData.subscriptionFlags = {};
        }
        this.personalData.subscriptionId = data.subscription.id;
      });
      return true;
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'updateSubscription',
      );
    }
    return false;
  }

  requestNewToken() {
    if (!this.isAuthorized) {
      return Promise.reject();
    }

    return CustomerRequests.refreshToken({
      refresh_token: this.refreshToken,
      customer_id: this.personalData.id || '',
    }).then(({ access_token, refresh_token }) => {
      this.setToken(access_token);
      mainStore.sendToRN('setToken', access_token);
      this.setRefreshToken(refresh_token);
    });
  }

  async logout() {
    if (!this.isAuthorized) {
      return Promise.reject();
    }

    try {
      await CustomerRequests.logout({
        refresh_token: this.refreshToken,
        customer_id: this.personalData.id || '',
      });
    } catch (error) {
      console.error(error);
    }
    runInAction(() => {
      this.isAuthorized = false;
      this.token = '';
      this.refreshToken = '';
      this.personalData.id = null;
      this.personalData.firstName = null;
      this.personalData.middleName = null;
      this.personalData.lastName = null;
      this.personalData.phone = null;
      this.personalData.email = null;
      this.personalData.flat = '';
      this.personalData.dateOfBirth = null;
      this.personalData.lastLogin = null;
      this.personalData.isRateApp = false;
      this.personalData.type = 'User';
      this.personalData.freeDeliveryDaysLeft = 0;
      this.personalData.kycStatus = KYCStatus.NotVerified;
      this.addressList = [];
      if (this.deliveryAddress) {
        this.deliveryAddress.comment = '';
        this.deliveryAddress.instructions = [];
      }
      this.setBonuses(0);
      Object.keys(this.personalData.subscriptionFlags).forEach((flagName) => {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        this.personalData.subscriptionFlags[flagName].email = false;
      });
      orderStore.setIsFirstOrder(true);
      orderStore.setOrderStatus('none');
      orderStore.setActiveOrderID('');
      orderStore.setPaymentCards([]);
      orderStore.setActiveGift(null);
      orderStore.setGift(null);
      catalogStore.promotionStore.resetPromocode();
      catalogStore.promotionStore.proposedPromocodes.clear();
      catalogStore.favorites = {};
      catalogStore.cart = [];
      mainStore.resetAnalytics();
      checkoutStore.setPayments([]);
    });
    refreshTokens.reset();
    Sentry.setUser({ ip_address: '{{auto}}' });
    mainStore.sendToRN('logout');
    mainStore.sendToRN('setUserProperties', {
      customerId: null,
    });
    clearStoredPromocode();
  }

  async deleteProfile() {
    if (!this.isAuthorized) {
      return Promise.reject();
    }

    try {
      await CustomerRequests.deleteProfile();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  geocodingByCoordinates({
    lat,
    lng,
  }: GeoCoordinates): Promise<DeliveryAddress | null> {
    return company.config.useGoogleApiForGeocoding
      ? this.geocodingByCoordinatesWithGoogleApi({ lat, lng })
      : this.geocodingByCoordinatesWithBackendApi({ lat, lng });
  }

  async geocodingByCoordinatesWithBackendApi({
    lat,
    lng,
  }: GeoCoordinates): Promise<DeliveryAddress | null> {
    let geocodingResponse: ReverseGeocodeResponse | null = null;

    try {
      ({ data: geocodingResponse } = await GeocoderRequests.reverseGeocode({
        lat,
        lng,
      }));
    } catch (e) {
      mainStore.pushAlert('error', t('phrases:determineAddress'));

      return null;
    }

    if (!geocodingResponse) {
      return null;
    }

    const {
      PostalExt,
      Postal,
      City,
      LongLabel,
      ShortLabel,
      Region,
      CountryCode,
    } = geocodingResponse;

    return {
      zip: PostalExt || Postal || '000000',
      country: CountryCode,
      city: City,
      region: Region ?? CountryCode,
      address1: LongLabel,
      address2: '',
      addressFloor: '',
      addressBld: '',
      addressApt: '',
      shortAddress: ShortLabel,
      coordinates: { lat, lng },
      // FIXME: find out why do we use placeId
      placeId: mainStore.getPseudoId(),
      addressId: null,
      comment: '',
      instructions: [],
      // FIXME home/custom
      type: 'home',
    };
  }

  geocodingByCoordinatesWithGoogleApi(
    mapCenter: GeoCoordinates,
  ): Promise<DeliveryAddress | null> {
    return GoogleMapGeocode.geocodingByCoordinates({
      latlng: `${mapCenter.lat},${mapCenter.lng}`,
    })
      .then((e) => {
        let deliveryAddress: DeliveryAddress | null = null;
        if (e.status === 'OK') {
          if (e.results.length) {
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            const country = e.results[0].address_components.reduce(
              (str, item) =>
                str
                  ? str
                  : item.types.includes('country')
                  ? item.short_name
                  : '',
              '',
            );
            const addressComponentType: AddressComponentType =
              country === 'GB' ? 'postal_code' : 'plus_code';
            e.results = e.results
              .slice(0, 6)
              .filter((item) =>
                item.address_components.some((el) =>
                  el.types.includes(addressComponentType),
                ),
              );
            if (!e.results.length) {
              return Promise.resolve(deliveryAddress);
            }
            let index = 0;
            if (e.results.length > 1) {
              const pointsStringify: string[] = [];
              const points = e.results.map((item) => {
                const point = {
                  latitude: item.geometry.location.lat,
                  longitude: item.geometry.location.lng,
                };
                pointsStringify.push(JSON.stringify(point));
                return point;
              });
              index = pointsStringify.indexOf(
                JSON.stringify(
                  findNearest(
                    {
                      latitude: mapCenter.lat,
                      longitude: mapCenter.lng,
                    },
                    points,
                  ),
                ),
              );
            }
            const addressComponents: AddressComponents = {};
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            e.results[index].address_components.forEach((item) => {
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              addressComponents[item.types[0]] = {
                long_name: item.long_name,
                short_name: item.short_name,
              };
            });
            const address1 = this.getShortAddress(addressComponents);
            const shortAddress =
              (addressComponents.postal_code?.long_name ||
                addressComponents.plus_code?.long_name) &&
              address1
                ? `${address1}, ${
                    addressComponents.postal_code?.long_name ||
                    addressComponents.plus_code?.long_name
                  }`
                : address1
                ? address1
                : addressComponents.postal_code?.long_name ||
                  addressComponents.plus_code?.long_name ||
                  '';
            deliveryAddress = {
              zip:
                addressComponents.postal_code?.long_name ||
                addressComponents.plus_code?.long_name ||
                '000000',
              country: addressComponents.country?.short_name || '',
              city:
                addressComponents.postal_town?.long_name ||
                addressComponents.locality?.long_name ||
                addressComponents.administrative_area_level_1?.long_name ||
                addressComponents.country?.long_name ||
                '',
              region:
                addressComponents.administrative_area_level_2?.long_name ||
                addressComponents.country?.long_name ||
                '',
              address1: shortAddress,
              address2: '',
              addressFloor: '',
              addressBld: '',
              addressApt: '',
              shortAddress,
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              coordinates: e.results[index].geometry.location,
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              placeId: e.results[index].place_id,
              addressId: null,
              comment: '',
              instructions: [],
              // FIXME home/custom
              type: 'home',
            };
          }
        }
        return Promise.resolve(deliveryAddress);
      })
      .catch((error) => {
        error && console.error(error);
        return null;
      });
  }

  async getAutocomplete(
    query: AutocompleteRequest,
  ): Promise<AutocompleteResponse> {
    try {
      return await DeliveryRequests.autocomplete(query);
    } catch (e) {
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'getAutocomplete',
      );
    }
    return {
      data: {
        result: null,
        predictions: [],
      },
    };
  }

  async getDeliveryZones(): Promise<DeliveryZoneCoverage[]> {
    try {
      const {
        data: { coverages = [] },
      } = await DeliveryRequests.fetchDeliveryZones();
      return coverages;
    } catch (e) {
      console.error(e);
    }
    return [];
  }

  async fetchAddresses(): Promise<void> {
    try {
      const { data } = await DeliveryRequests.getAddresses();
      this.setAddressList(
        data.map((address) => ({
          ...address,
          building: address.building ?? address.street_address_2,
        })) || [],
      );
    } catch (e) {
      this.setAddressList([]);
      await this.errorHandler(
        e as AxiosError<ApiErrorResponse>,
        'fetchAddresses',
      );
    }
  }

  async addAddress(address: AddAddressRequest): Promise<Address | null> {
    try {
      const { data } = await DeliveryRequests.addAddress(address);
      return data;
    } catch (e) {
      return null;
    }
  }

  async updateAddress(
    id: string,
    data: UpdateAddressRequest,
  ): Promise<AddressResponse | null> {
    try {
      return await DeliveryRequests.updateAddress(id, data);
    } catch (e) {
      return null;
    }
  }

  async deleteAddress(id: string): Promise<boolean> {
    try {
      await DeliveryRequests.deleteAddress(id);
      await this.fetchAddresses();
      return true;
    } catch (e) {
      return false;
    }
  }

  async requestBonuses() {
    if (!this.isAuthorized || company.isDisableLoyaltyProgram) {
      this.setBonuses(null);

      return;
    }

    try {
      const bonuses = await CustomerRequests.requestBonuses();
      if (bonuses || bonuses === 0) {
        this.setBonuses(bonuses);
      }
    } catch (e) {
      this.setBonuses(null);
      console.error(e);
    }
  }

  requestKYCSession() {
    if (!this.isAuthorized) {
      return Promise.reject();
    }

    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_KYC_VERIFICATION_STATUS,
      {
        status:
          this.personalData.kycStatus === KYCStatus.ResubmissionRequested
            ? KYCStatus.ResubmissionRequested
            : KYCStatus.Started,
      },
    );

    return CustomerRequests.requestKYCSession();
  }

  /**
   * @param {number} page - page number
   * @param {boolean} reset - needs to reset history for requesting full list on page open
   */
  async requestBonusesTransactions(page = 1, reset?: boolean): Promise<number> {
    const size = 10;

    if (reset) {
      this.setBonusesTransactionsPagination({
        total: 0,
        page: 1,
      });
      this.setBonusesTransactionsList([]);
      page = 1;
    }

    if (page < this.bonusesTransactionsPagination.page) {
      // does not call "requestBonusesTransactions", if items for page were fetched
      return (
        this.bonusesTransactionsPagination.total -
        this.bonusesTransactionsList.length
      );
    }

    try {
      const list = await CustomerRequests.requestBonusesTransactions({
        'pagination[page]': page,
        'pagination[size]': size,
      });
      this.setBonusesTransactionsList(list.items || []);

      this.setBonusesTransactionsPagination({
        page,
        total: list.pagination.total,
      });

      return list.pagination.total;
    } catch (e) {
      this.bonusesTransactionsList = [];
      return 0;
    }
  }

  // TODO: 17.10.2023 Move all referral program handlers to promotion store
  async fetchReferralProgramInfo() {
    if (!this.personalData.id) {
      return;
    }

    try {
      this.refereeInfo = await ReferralProgramRequests.getCustomerRefereeCode(
        this.personalData.id,
      );
      this.referralInfo = await ReferralProgramRequests.getReferralInfo();
    } catch (error: unknown) {
      await this.errorHandler(error as AxiosError<ApiErrorResponse>, '');
    }
  }

  clearReferralProgramInfo() {
    this.refereeInfo = null;
    this.referralInfo = null;
  }

  resetStore() {
    this.isFirstLaunch = true;
    this.isAuthorized = false;
    this.deliveryAddress = null;
    this.token = '';
    this.refreshToken = '';
    this.lang = getCookieLang();
    this.dir = 'ltr';

    // A unique token to manage address search sessions.
    // Reset the token after an address has been found.
    this.addressSearchSessionToken = undefined;

    this.draftUserData = null;

    this.personalData = {
      id: null,
      firstName: null,
      middleName: null,
      lastName: null,
      phone: null,
      flat: '',
      dateOfBirth: null,
      email: null,
      lastLogin: null,
      subscriptionFlags: {},
      subscriptionId: '',
      isAdult: false,
      isRateApp: false,
      type: 'User',
      freeDeliveryDaysLeft: 0,
      kycStatus: KYCStatus.NotVerified,
    };
    this.playerId = '';
    this.sessionList = [];
    this.addressList = [];
    this.bonuses = null;
    this.bonusesTransactionsList = [];
    this.bonusesTransactionsPagination = {
      total: 0,
      page: 1,
    };
    this.addressToDelete = null;
    this.refereeInfo = null;
    this.referralInfo = null;
    this.deliveryZonesCoverages = [];
    this.deliveryZoneCacheTime = 0;
  }

  // Errors
  errorHandler = (
    error: AxiosError<ApiErrorResponse>,
    context: string,
  ): Promise<AxiosError> => mainStore.errorHandler(error, context);
}

export const userStore = new UserStore();
