import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';
import { orderStore } from '~/stores/OrderStore';
import copyToClipboard from '~/utils/copyToClipboard';

import styles from '../../Order.module.scss';

interface CopyOrderNumberProps {
  orderNumber: string;
  timeout?: number;
}

const CopyOrderNumber = ({
  orderNumber,
  timeout = 1800,
}: CopyOrderNumberProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [isCopied, setIsCoped] = useState<boolean>();

  const { t } = useTranslation();

  const clearTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleCopyClick = async () => {
    await copyToClipboard({
      text: orderNumber,
      successMessage: t('deliveryMethods:addressCopied'),
    });
    setIsCoped(true);
    clearTimeoutRef();

    timeoutRef.current = setTimeout(() => {
      setIsCoped(false);
    }, timeout);
  };

  useEffect(
    () => () => {
      clearTimeoutRef();
    },
    [],
  );

  return (
    <div
      role="button"
      className={styles.pickUpOrderMessage__caption}
      onClick={handleCopyClick}
    >
      <p>
        {t('deliveryMethods:pickUpFromAddress', {
          address: orderStore.etaCalculation?.warehouse.address,
        })}
      </p>
      <span className="copy">
        <Icon type={isCopied ? 'check' : 'copy'} />
      </span>
    </div>
  );
};

export default CopyOrderNumber;
