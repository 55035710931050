import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import Icon from '~/components/Icon/Icon';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './CheckoutSkeleton.module.scss';

export const DeliveryItem = () => {
  return (
    <div className={styles.panel}>
      <div className={styles.deliveryItem}>
        <div className={styles.header}>
          <Skeleton className={styles.title} />
          <Skeleton className={styles.fee} />
        </div>
        <Skeleton className={styles.selector} />
        <div className={styles.photos}>
          <SkeletonListRows rows={6}>
            <Skeleton className={styles.photo} />
          </SkeletonListRows>
        </div>
      </div>
    </div>
  );
};

const CheckoutSkeleton = () => {
  const { t } = useTranslation();

  return (
    <div className={classes(['checkout desktop', styles.page])}>
      <MobileHeaderLayout
        content={<MobileHeaderTitle text="Checkout" />}
        className={styles.mobileHeader}
      />
      <div className={classes(['content-layout', styles.layout])}>
        <Link to="/cart" className={styles.backButton}>
          <Icon type="chevron" size={24} className={styles.backButtonIcon} />
          {t('backToCart')}
        </Link>
        <div className={classes([styles.root, styles.skeleton])}>
          <div className={styles.content}>
            <div className={styles.personalInfo}>
              <div className={classes(styles.panel)}>
                <Skeleton
                  className={styles.title}
                  containerClassName={styles.title}
                />
                <div className={styles.personalInfoList}>
                  <SkeletonListRows rows={2}>
                    <Skeleton className={styles.personalInfoItem} />
                  </SkeletonListRows>
                </div>
              </div>
            </div>
            <div className={styles.deliveryInfo}>
              <div className={classes([styles.panel, styles.panelHeader])}>
                <Skeleton className={styles.title} />
                <Skeleton className={styles.switch} />
              </div>
              <SkeletonListRows rows={6}>
                <DeliveryItem />
              </SkeletonListRows>
            </div>
          </div>
          <div className={styles.summary}>
            <div className={styles.cartInfo}>
              <div className={styles.cartInfoPanel}>
                <div className={styles.panelHeader}>
                  <Skeleton className={styles.title} />
                  <Skeleton className={styles.sum} />
                </div>
              </div>
              <div className={styles.cartInfoPanel}>
                <div className={styles.fee}>
                  <Skeleton className={styles.title} />
                  <Skeleton className={styles.sum} />
                </div>
              </div>
              <div className={styles.cartInfoPanel}>
                <div className={styles.vat}>
                  <Skeleton className={styles.title} />
                  <Skeleton className={styles.sum} />
                </div>

                <Skeleton className={styles.promo} />

                <div className={styles.total}>
                  <Skeleton className={styles.title} />
                  <Skeleton className={styles.sum} />
                </div>
              </div>
            </div>
            <div className={styles.paymentInfo}>
              <Skeleton className={styles.headerTitle} />
              <div className={styles.paymentMethods}>
                <SkeletonListRows rows={4}>
                  <Skeleton className={styles.method} />
                </SkeletonListRows>
              </div>
              <div className={styles.paymentDescription}>
                <Skeleton className={styles.main} />
                <Skeleton className={styles.additional} />
              </div>
              <div className={styles.cardInfo}>
                <div className={styles.header}>
                  <Skeleton
                    className={styles.title}
                    containerClassName={styles.title}
                  />
                  <Skeleton
                    className={styles.button}
                    containerClassName={styles.button}
                  />
                </div>
                <Skeleton className={styles.card} />
                <div className={styles.description}>
                  <Skeleton
                    className={styles.line}
                    containerClassName={styles.line}
                  />
                  <Skeleton
                    className={styles.line}
                    containerClassName={styles.line}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CheckoutSkeleton);
