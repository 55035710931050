import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { company } from '~/company/Company';
import { createProductUrl } from '~/components/CategoryBlocks/utils';
import { ON_PRODUCT_CLICK_EVENT } from '~/components/SommelierButton/SommelierWindow/constants';
import {
  getProductSlugFromSommelierEvent,
  isScriptExists,
  renderScriptElement,
} from '~/components/SommelierButton/SommelierWindow/utils';

export interface UseDesktopSommelierScriptProps {
  isMobile?: boolean;
  isTablet?: boolean;
}

const useSommelierButtonScript = ({
  isMobile,
  isTablet,
}: UseDesktopSommelierScriptProps = {}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const listener = (e: Event) => {
      const { detail } = e as CustomEvent;
      const slug = getProductSlugFromSommelierEvent(detail);

      if (!slug) {
        return;
      }

      navigate(createProductUrl({ id: slug }));
    };

    const cleanUp = () => {
      window.removeEventListener(ON_PRODUCT_CLICK_EVENT, listener);
    };

    if (
      isMobile ||
      isTablet ||
      !company.isSommelierEnabled ||
      isScriptExists(document.body)
    ) {
      return cleanUp;
    }

    renderScriptElement(document.body, { popup: 'button' }).then(() => {
      window.addEventListener(ON_PRODUCT_CLICK_EVENT, listener);
    });

    return cleanUp;
  }, [isMobile, isTablet]);
};

export default useSommelierButtonScript;
