import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import NoteImage from '~/assets/img/cd_note.png';
import RefundPolicy from '~/assets/img/refund_policy.png';
import { company } from '~/company/Company';
import { ContactInformation } from '~/components/ContactInformation/ContactInformation';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Refund.module.scss';

export default observer(() => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const { state } = useLocation();
  const source = state?.source || 'home';

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      {isTablet && (
        <MobileHeaderLayout
          content={<MobileHeaderTitle text={t('refundPolicy')} />}
        />
      )}
      <div className={classes(['content-layout', styles.root])}>
        {!isTablet && (
          <Breadcrumbs
            items={
              [
                {
                  text: t('home'),
                  link: '/',
                },
                source === 'cabinet' && {
                  text: t('cabinet'),
                  link: '/cabinet',
                },
                {
                  text: t('refundPolicy'),
                  link: '/refund-policy',
                },
              ].filter(Boolean) as BreadcrumbsItem[]
            }
            className={styles.breadcrumbs}
          />
        )}
        <section className={styles.header}>
          <h1 className={styles.header__welcome}>Refund Policy</h1>
          <img src={RefundPolicy} className={styles.image} />
          <h3 className={styles.content__article_first}>
            Satisfaction Guarantee:
          </h3>
          <p className={styles.content__article_text}>
            At CityDrinks, we are committed to providing you with the finest
            selection of premium beverages. That’s why we offer a satisfaction
            guarantee for eligible orders. If you are not satisfied with your
            purchase, please refer to the following information.
          </p>
          <h3 className={styles.content__article_first}>
            Eligibility for Refund:
          </h3>
          <p className={styles.content__article_text}>
            To be eligible for a refund, please ensure that your request meets
            the following conditions:
          </p>
          <div className={styles.returnPolicy}>
            <div className={styles.policyBlock}>
              <Icon type="envelope" size={24} />
              <div>
                <p>You must contact us at</p>
                <a href={`mailto:${company.config.email}`}>
                  {company.config.email}
                </a>
                <p>within 24h of receiving your order.</p>
              </div>
            </div>
            <div className={styles.policyBlock}>
              <Icon type="box" size={24} />
              <div>
                <p>
                  The product must be unopened, unused, and in its original
                  packaging.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.refundProcess}>
            <h3>Refund Process:</h3>
            <p>To initiate a refund, please email us at</p>
            <a href={`mailto:${company.config.email}`}>
              <Icon type="envelope" size={24} />
              {company.config.email}
            </a>
            <p className={styles.shortParagraph}>
              with your order number, the reason for the refund,
              and&nbsp;any&nbsp;relevant supporting&nbsp;information.
              Our&nbsp;customer&nbsp;service&nbsp;team will guide you through
              the process.
            </p>
          </div>
          <h3 className={styles.content__article_first}>Quality Concerns:</h3>
          <p className={styles.content__article_text}>
            If you believe your product is defective, damaged, or of substandard
            quality, please provide details and, if possible, attach photographs
            illustrating the issue. We will review your case promptly.
          </p>
          <h3 className={styles.content__article_first}>Refund Time Frame:</h3>
          <p className={styles.content__article_text}>
            Once your request is approved, we will process the refund within
            5-10 business days. The refund will be issued to the original
            payment method.
          </p>
          <div className={styles.nonRefundableItems}>
            <h3>Non-Refundable Items:</h3>
            <p>
              Certain items are non-refundable for health and safety reasons.
              These include opened or partially consumed products unless there
              is a quality issue.
            </p>
          </div>
          <h3 className={styles.content__article_first}>Return Shipping:</h3>
          <p className={styles.content__article_text}>
            Return shipping costs may be applicable, and we will provide you
            with details based on the circumstances of your return.
          </p>

          <ContactInformation
            content={`For any refund-related inquiries or assistance,<br/> please contact our customer service team&nbsp;at`}
            email={company.config.email}
            className={styles.contactInfo}
          />

          <div className={styles.note}>
            <img src={NoteImage} className={styles.noteImage} />
            <div className={styles.noteText}>
              <h3>Note:</h3>
              <p>
                This Refund Policy is subject to change without prior notice. It
                is advisable to review this policy periodically for any updates.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
});
