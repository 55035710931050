import { classes } from 'html-classes';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';
import { IconTypes } from '../Icon/IconTypes';

import styles from './StoreButton.module.scss';

type StoreButtonProps = {
  iconType: IconTypes;
  link: string;
  containerClass?: string;
  iconSize?: number;
  img?: string;
};

export const StoreButton = ({
  containerClass,
  iconType,
  iconSize = 23,
  link,
  img,
}: StoreButtonProps) => {
  return (
    <a
      href={link}
      target="_blank"
      className={classes([styles.container, containerClass])}
      rel="noreferrer"
      data-company={company.name}
    >
      <Icon type={iconType} size={iconSize} className={styles.icon} />
      <img src={img} />
      {/* <div className={styles.textBody}>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subtitle}</div>
      </div> */}
    </a>
  );
};
