import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';

const devConfig = {
  apiKey: 'AIzaSyDoif0NFmGqbbS-hBEGY-dt_EAwHB7OwJQ',
  authDomain: 'jiffy-ca09a.firebaseapp.com',
  projectId: 'jiffy-ca09a',
  storageBucket: 'jiffy-ca09a.firebasestorage.app',
  messagingSenderId: '17467506934',
  appId: '1:17467506934:web:d73ef6c594901f5e92ecd7',
  measurementId: 'G-9R0G2ZCQS5',
};

const cityDrinksProdConfig = {
  apiKey: 'AIzaSyCSnQTtW6MGE-_ad71dNaHeELCIZiAv-vU',
  authDomain: 'citydrinks-8316f.firebaseapp.com',
  projectId: 'citydrinks-8316f',
  storageBucket: 'citydrinks-8316f.appspot.com',
  messagingSenderId: '114787590870',
  appId: '1:114787590870:web:46fdad0e1cbbac81db1f77',
  measurementId: 'G-478QEXEMPJ',
};

const viloProdConfig = {
  apiKey: 'AIzaSyCedYovCeC8DlhOlYzR7HX9pprYiptUNMs',
  authDomain: 'vilo-e6f40.firebaseapp.com',
  projectId: 'vilo-e6f40',
  storageBucket: 'vilo-e6f40.firebasestorage.app',
  messagingSenderId: '459830559584',
  appId: '1:459830559584:web:57e683be5e28d94ad7d405',
  measurementId: 'G-D6FBLN9930',
};

const cityDrinksConfig =
  import.meta.env.REACT_APP_ENV === 'production'
    ? cityDrinksProdConfig
    : devConfig;

const viloConfig =
  import.meta.env.REACT_APP_ENV === 'production' ? viloProdConfig : devConfig;

const configs = {
  [CompanyName.Localee]: null,
  [CompanyName.CircleK]: null,
  [CompanyName.CityDrinks]: cityDrinksConfig,
  [CompanyName.Vilo]: viloConfig,
} satisfies Partial<Record<CompanyName, unknown>>;

export const getConfig = () => configs[company.name];
