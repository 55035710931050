import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { company } from '~/company/Company';
import { swiperProps } from '~/components/CategoryBlocks/ProductsCarouselSliderOneBlock/config';
import { ModalType } from '~/components/Modal/interface';
import ProductCard from '~/components/ProductCard/ProductCard';
import Section from '~/components/Section';
import Swiper from '~/components/Swiper/Swiper';
import { useModal } from '~/hooks/useModal';
import { CategorySkeleton } from '~/pages/Catalog/CatalogSkeleton';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';

import { RECOMMENDATION_TITLES, CAROUSEL_MAX_ITEMS } from './constants';
import { CarouselContainerProps } from './interfaces';
import styles from './Recommendations.module.scss';

// TODO: use later on next iteration
export const LinkToComponent = ({
  source,
}: Pick<CarouselContainerProps, 'source'>) => {
  const { openModal } = useModal();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const handleOpenAll = () => {
    const searchParams = openModal(ModalType.Recommendations);
    searchParams.set('source', source.toString());
    setSearchParams(searchParams);
  };

  return (
    <span
      role="button"
      aria-label={t('viewAll')}
      className={styles.linkToButton}
      onClick={handleOpenAll}
    >
      {t('viewAll')}
    </span>
  );
};

const Recommendations = ({
  className,
  source,
  max = CAROUSEL_MAX_ITEMS,
}: CarouselContainerProps) => {
  const { t } = useTranslation();

  if (catalogStore.productRecommendationsMeta.isLoading) {
    return (
      <div className={styles.skeletonContainer}>
        <CategorySkeleton />
      </div>
    );
  }

  const products = catalogStore.productRecommendations[source];

  if (!products || !products.data.length) {
    return <></>;
  }

  const sectionTitle = RECOMMENDATION_TITLES[source] || 'recommended';
  return (
    <Section
      className={classes([styles.root, className])}
      title={t(sectionTitle)}
    >
      <Swiper
        {...swiperProps[company.name]}
        slideClassName={styles.slide}
        sliderClassName={styles.swiper}
        slideVariant="white"
        isPagination={false}
        dir={userStore.dir}
        key={userStore.dir}
      >
        {products.data.slice(0, max).map((product) => (
          <ProductCard
            product={product}
            className={styles.slideProduct}
            key={product.id}
          />
        ))}
      </Swiper>
    </Section>
  );
};

export default observer(Recommendations);
