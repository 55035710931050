import { motion } from 'framer-motion';
import { classes } from 'html-classes';
import { forwardRef } from 'react';

import styles from './bottomSheetDrawer.module.scss';
import { SheetBackdropProps } from './types';

const isClickable = (props: any) => !!props.onClick || !!props.onTap;

const SheetBackdrop = forwardRef<any, SheetBackdropProps>(
  ({ style = {}, className = '', visible, ...rest }, ref) => {
    const Comp = isClickable(rest) ? motion.button : motion.div;
    const pointerEvents = isClickable(rest) ? 'auto' : 'none';

    return (
      <Comp
        {...rest}
        ref={ref}
        className={classes([
          'react-modal-sheet-backdrop',
          className,
          styles.backdrop,
        ])}
        style={{
          ...style,
          pointerEvents,
          display: visible ? 'block' : 'none',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />
    );
  },
);

SheetBackdrop.displayName = 'SheetBackdrop';

export default SheetBackdrop;
