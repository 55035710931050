import { isAxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';

import { ProductAPI } from '~/api/Catalog';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { Offer } from '~/stores/CategoriesStore/Offer';
import {
  Product,
  LoadProductParams,
  getProductById,
} from '~/stores/CategoriesStore/Product';
import { ReviewsStore } from '~/stores/ReviewsStore';
import { Task } from '~/stores/shared/Task';

export class ProductState {
  private readonly id: string | number;
  public readonly reviews: ReviewsStore;

  private product: Nullable<Product> = null;
  private offer: Nullable<Offer> = null;

  private static preloadedData = new Map<string, ProductAPI>();

  constructor(id: string | number) {
    this.id = id;
    this.reviews = new ReviewsStore(id);

    this.init();
    makeAutoObservable(this);
  }

  public static async preload(params: LoadProductParams, serverLang?: string) {
    const { data } = await getProductById(params, serverLang);

    ProductState.preloadedData.set(String(params.productId), data);

    return {
      product: data,
      reviews: await ReviewsStore.preload(data.id),
    };
  }

  public static removePreloadedData(productId: string | number) {
    this.preloadedData.delete(String(productId));
  }

  public get data() {
    return this.product;
  }

  public get selectedOffer() {
    return this.offer;
  }

  public get notFound() {
    if (typeof window === 'undefined') {
      return !this.product;
    }

    return (
      isAxiosError(this.request.lastError) &&
      this.request.lastError.response?.status === 404 &&
      !this.request.isLoading
    );
  }

  public readonly request = new Task(async (params: LoadProductParams) => {
    const { data } = await getProductById(params);

    this.applyProduct(data);
  });

  public selectOffer(code: string) {
    const product = this.product;

    if (!product) {
      throw new Error('Product not found');
    }

    const offer = product.offers.find(({ sku }) => sku === code);

    if (!offer) {
      throw new Error('Offer not found');
    }

    this.offer = offer;
    analyticsEventsEmitter.emit(EventsName.SELECT_ITEM, offer);

    return this.offer;
  }

  private init() {
    const product =
      typeof window === 'undefined'
        ? ProductState.preloadedData.get(String(this.id))
        : window.__INITIAL_STATE__?.product;

    if (product) {
      if (Product.compareId(this.id, product)) {
        this.applyProduct(product);
      }

      this.reviews.setId(product.id);
    }
  }

  private applyProduct(data: ProductAPI) {
    this.product = new Product(data);
    const offer = this.product.offers[0];

    this.reviews.setId(data.id);

    if (offer) {
      this.selectOffer(offer.sku);
    }
  }
}
