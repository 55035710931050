import { classes } from 'html-classes';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';

import styles from './DeliverAllGoodsCheckbox.module.scss';

interface DeliverAllGoodsCheckboxProps {
  checked: boolean;
  onChange: () => void;
  className?: string;
}

const DeliverAllGoodsCheckbox = ({
  checked,
  onChange,
  className,
}: DeliverAllGoodsCheckboxProps) => {
  const { t } = useTranslation();

  return (
    <label
      className={classes([
        styles.switch,
        styles.checkboxWrapper,
        styles.container,
        className,
      ])}
    >
      <input
        onChange={onChange}
        checked={checked}
        type="checkbox"
        className={styles.controlSwitchInput}
        name="express"
      />
      <div className={styles.controlSwitchMark} />
      <div className={classes([styles.controlSwitchText, styles.textWrapper])}>
        <div className={styles.description}>
          {company.combineItemsByDefaut
            ? t('splitAllGoods')
            : t('deliverAllGoods')}
        </div>
        <div className={styles.descriptionAdditional}>
          {company.combineItemsByDefaut
            ? t('splitAllGoodsAdditional')
            : t('deliverAllGoodsAdditional')}
        </div>
      </div>
    </label>
  );
};

export default DeliverAllGoodsCheckbox;
