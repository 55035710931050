import { DEFAULT_COMPANIES, company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import Rating from '~/components/Rating';
import { ProductReview } from '~/types/Product/interface';

import styles from '../Product.module.scss';

interface Props extends ProductReview {
  isMobile?: boolean;
}

export const ReviewItem = ({ mark, customer, content, isMobile }: Props) => {
  if (DEFAULT_COMPANIES.includes(company.name)) {
    return (
      <div className={styles.review} data-company={company.name}>
        <div className={styles.reviewHeader}>
          <div className={styles.reviewHeaderInfo}>
            <div className={styles.reviewHeaderInfo__name}>
              <p>
                {[
                  customer.firstName,
                  customer.middleName,
                  customer.lastName,
                ].join(' ')}
              </p>
            </div>
          </div>
          <Rating rate={mark} starSize={16} showStars={true} hideText={true} />
        </div>
        <p className={styles.reviewContent}>{content}</p>
      </div>
    );
  }

  return isMobile ? (
    <div className={styles.review} data-company={company.name}>
      <div className={styles.reviewHeader}>
        <div className={styles.reviewHeaderInfo}>
          <div className={styles.reviewHeaderInfo__name}>
            <Icon type="badge" size={24} className={styles.icon} />
            <p>
              {[
                customer.firstName,
                customer.middleName,
                customer.lastName,
              ].join(' ')}
            </p>
          </div>
        </div>
        <Rating rate={mark} starSize={16} showStars={true} hideText={true} />
      </div>
      <p className={styles.reviewContent}>{content}</p>
    </div>
  ) : (
    <div className={styles.review} data-company={company.name}>
      <div className={styles.reviewHeader}>
        <div className={styles.reviewHeaderInfo}>
          <div className={styles.reviewHeaderInfo__name}>
            <Icon type="badge" size={24} className={styles.icon} />
            <p>
              {[
                customer.firstName,
                customer.middleName,
                customer.lastName,
              ].join(' ')}
            </p>
          </div>
        </div>
      </div>
      <div className={styles.reviewMain}>
        <Rating rate={mark} starSize={20} showStars hideText />
        <p className={styles.reviewContent}>{content}</p>
      </div>
    </div>
  );
};
