import { classes } from 'html-classes';
import { fill } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useGlobal } from '~/hooks/useGlobal';

import Icon from '../Icon/Icon';

import styles from './styles.module.scss';

interface Props {
  loading?: boolean;
  showButton?: boolean;
  showPaginator?: boolean;
  className?: string;
  pageCurrent: number;
  pageTotal: number;
  requestPage: (num: number) => void;
  loadMore: (num: number, upd: boolean) => void;
}

const Pagination = ({
  loading,
  showButton,
  showPaginator,
  className,
  pageCurrent,
  pageTotal,
  requestPage,
  loadMore,
}: Props) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const limit = isMobile ? 5 : 10;

  if (!showButton && !showPaginator) {
    return null;
  }

  const paginationToHide = pageTotal === 1;

  let startNumber = 0;
  let endNumber = 0;
  if (pageTotal > limit) {
    const half = ~~(limit / 2);
    if (pageCurrent > half && pageCurrent + half > pageTotal) {
      endNumber = pageTotal;
      startNumber = pageTotal - limit + 1;
    } else if (pageCurrent - half > 0) {
      startNumber = pageCurrent - half;
      endNumber = startNumber + limit - 1;
    } else {
      startNumber = 1;
      endNumber = startNumber + limit - 1;
    }
  } else {
    startNumber = 1;
    endNumber = pageTotal;
  }

  const paginator =
    !showPaginator || paginationToHide ? null : (
      <div className={styles.paginator}>
        <button
          className={classes(['button _no-color', styles.paginator_button])}
          onClick={() =>
            requestPage(pageCurrent - 10 >= 1 ? pageCurrent - 10 : 1)
          }
          disabled={pageCurrent === 1}
        >
          <Icon type="double-back" className="" />
        </button>
        <button
          className={classes([
            'button _no-color',
            styles.paginator_button,
            styles.paginator_button_close,
          ])}
          disabled={pageCurrent === 1}
          onClick={() => requestPage(pageCurrent - 1)}
        >
          <Icon type="back" className="" />
          <span className={styles.description}>Prev</span>
        </button>
        {fill(Array(endNumber - startNumber + 1), 0).map((_, idx) => (
          <button
            key={idx}
            className={classes([
              'button _no-color',
              styles.paginator_button,
              styles.paginator_button_page,
              styles.numButton,
              pageCurrent === idx + startNumber && styles.active,
            ])}
            onClick={() => {
              if (pageCurrent !== idx + startNumber) {
                requestPage(idx + startNumber);
              }
            }}
          >
            {idx + startNumber}
          </button>
        ))}
        <button
          className={classes([
            'button _no-color',
            styles.paginator_button,
            styles.paginator_button_close,
          ])}
          disabled={pageCurrent === pageTotal}
          onClick={() => requestPage(pageCurrent + 1)}
        >
          <span className={styles.description}>Next</span>
          <Icon type="back" className="icon__rotate-180" />
        </button>
        <button
          className={classes(['button _no-color', styles.paginator_button])}
          onClick={() =>
            requestPage(
              pageCurrent + 10 <= pageTotal ? pageCurrent + 10 : pageTotal,
            )
          }
          disabled={pageCurrent === pageTotal}
        >
          <Icon type="double-back" className="icon__rotate-180" />
        </button>
      </div>
    );

  return (
    <div className={classes([className, styles.root])}>
      {pageCurrent < pageTotal && (
        <button
          className={classes(['button _bordered _med', styles.button])}
          onClick={() => loadMore(pageCurrent + 1, true)}
          disabled={loading || pageCurrent >= pageTotal}
        >
          {t('showMore')}
        </button>
      )}
      {paginator}
    </div>
  );
};

export default observer(Pagination);
