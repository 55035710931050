import axios from 'axios';

import { company } from '~/company/Company';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

import { REQUEST_TIMEOUT } from './constants';

const axiosInstance = axios.create({
  baseURL: 'https://bi.tools.jiffygrocery.co.uk/',
  timeout: REQUEST_TIMEOUT,
});

interface BIRequestsInterface {
  sendAnalytics(
    eventName: string,
    data: Record<string, any> | Record<string, any>[],
  ): Promise<unknown>;
}

export const BIRequests: BIRequestsInterface = {
  sendAnalytics: (eventName, data) => {
    if (import.meta.env.DEV) {
      return Promise.resolve();
    }

    return axiosInstance.post(
      'bi/event/',
      {
        eventName,
        properties: data,
        os: {
          platform: mainStore.appPlatform,
          version: mainStore.appVersion,
        },
        userId: userStore.personalData.id,
        clientSource: 'App',
        deviceId: userStore.deviceId,
        whCode: orderStore.etaCalculation?.warehouse?.code || '',
      },
      {
        headers: {
          'api-key': import.meta.env.REACT_APP_BI_API_KEY,
          'x-company-id': company.config.id,
        },
      },
    );
  },
};
