import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { useModal } from '~/hooks/useModal';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { PICKUP_ALCOHOL_COUPON_CODE } from '~/stores/constants';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { ApplyPromocodeCallerSource } from '~/stores/PromotionsStore/interfaces';

import Icon from '../Icon/Icon';

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [promocodeVal, setPromocodeVal] = useState(
    catalogStore.promocode.value || '',
  );
  const [warningMsg, setWarningMsg] = useState('');

  const isLoading = catalogStore.promotionStore.applyPromocodeMeta.isLoading;

  const backHandler = () => closeModal();

  const handlePromocodeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromocodeVal(e.currentTarget.value.trim());
  };

  const handleDelPromocode = () => {
    setPromocodeVal('');
    catalogStore.promotionStore.resetPromocode();
  };

  const handleSubmit = () => {
    checkPromocode(promocodeVal);
  };

  const checkPromocode = (val: string) => {
    if (!val.length) {
      return;
    }

    catalogStore.promotionStore
      .applyPromocode(val, ApplyPromocodeCallerSource.CHECKOUT)
      .then(() => {
        if (catalogStore.promocode.deliveryMethod) {
          checkoutStore.setDeliveryMethod(
            catalogStore.promocode.deliveryMethod,
          );
        }
        if (catalogStore.promocode.success) {
          backHandler();
        }
      });
  };

  useEffect(() => {
    if (
      !catalogStore.totalCartPrice.amountLeftToDiscount ||
      mainStore.isZero(catalogStore.totalCartPrice.amountLeftToDiscount)
    ) {
      setWarningMsg('');
      return;
    }

    if (
      !catalogStore.isPromoCodeApplied &&
      catalogStore.promocode.coupon?.type
    ) {
      let msg =
        catalogStore.promocode.coupon?.code === PICKUP_ALCOHOL_COUPON_CODE
          ? 'errors:promocodePriceLessNoAlcohol'
          : 'errors:promocodePriceLess';

      if (!company.isExceptionAlcohol) {
        msg = 'errors:promocodePriceLessNoAlcohol';
      }

      setWarningMsg(
        t(msg, {
          amountLeft: mainStore.addCurrencySymbol(
            catalogStore.totalCartPrice.amountLeftToDiscount,
          ),
          discount: catalogStore.formatPromocodeDiscountAmount,
        }),
      );
    } else {
      setWarningMsg('');
    }
    //eslint-disable-next-line
  }, [catalogStore.totalCartPrice]);

  useEffect(() => {
    if (!catalogStore.promocode.value || !orderStore.etaCalculation) {
      return;
    }
    checkPromocode(catalogStore.promocode.value);
  }, [JSON.stringify(orderStore.etaCalculation?.cost)]);

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={backHandler}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <button
          className="button _no-padding _no-color input-form__back"
          onClick={backHandler}
        >
          <Icon type="arrow" className="icon__rotate-180" size={24} />
        </button>
        <div
          className={classes([
            'input-form__form',
            promocodeVal.length &&
              catalogStore.promocode.success === null &&
              !isLoading &&
              '_filled',
            catalogStore.promocode.errorType === 'error' &&
              !isLoading &&
              '_error',
            (catalogStore.promocode.errorType === 'warning' || warningMsg) &&
              !isLoading &&
              '_warning',
            catalogStore.promocode.success === true &&
              !warningMsg &&
              !isLoading &&
              '_success',
          ])}
        >
          <div className="input-form__form-header">
            <p className="input-form__form-header-title">{t('addPromocode')}</p>
            <div className="input-form__input-container">
              <input
                placeholder={t('phrases:enterPromocode')}
                value={promocodeVal}
                onChange={handlePromocodeChange}
                className="input-form__input-visible"
                disabled={isLoading}
              />

              {catalogStore.promocode.value.length > 0 && (
                <button
                  className="button _no-color _no-padding"
                  onClick={handleDelPromocode}
                  disabled={isLoading}
                >
                  <Icon type="plus" size={36} className="icon__rotate-45" />
                </button>
              )}
            </div>

            {!isLoading && catalogStore.promocode.message && (
              <span className="helper-text">
                {catalogStore.promocode.message}
              </span>
            )}
          </div>
          <div className="input-form__form-footer">
            <div className="input-form__form-footer-buttons">
              <button
                type="button"
                className="button _primary _med"
                onClick={handleSubmit}
                disabled={isLoading || promocodeVal.length === 0}
              >
                {t('submitCode')}
                {isLoading && <span className="spinner _small _white" />}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
