import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SwiperSlide, Swiper } from 'swiper/react';

import Icon from '~/components/Icon/Icon';
import Modal from '~/components/Modal/Modal';
import { useGlobal } from '~/hooks/useGlobal';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';

import styles from './ModalStockChanged.module.scss';
import StockProductCard from './StockProductCard';

const ModalStockChanged = () => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const { pathname } = useLocation();
  const { cartStockChangesStore } = catalogStore;

  const renderProductCards = () => {
    if (!isMobile) {
      return cartStockChangesStore.unavailableCartItems.map((item) => (
        <StockProductCard
          key={item.cartItem.id}
          product={item.cartItem}
          stockInfo={item.error}
          bundleStockInfo={item.bundleError}
        />
      ));
    }

    return (
      <Swiper
        spaceBetween={20}
        slidesPerView="auto"
        slidesPerGroupAuto
        className={styles.swiper}
        dir={userStore.dir}
        key={userStore.dir}
      >
        {cartStockChangesStore.unavailableCartItems.map((item) => (
          <SwiperSlide key={item.cartItem.id} className={styles.slide}>
            <StockProductCard
              product={item.cartItem}
              stockInfo={item.error}
              bundleStockInfo={item.bundleError}
              className={styles.slideProduct}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  };

  return (
    <Modal
      isShow={
        cartStockChangesStore.isOpen &&
        ['/cart', '/checkout'].includes(pathname)
      }
      className={styles.modalStockChanged}
      onBackdropDismiss={() => {}}
    >
      <header className={styles.header}>
        <span>{t('phrases:availableStockChanged')}</span>
        {isMobile && (
          <span
            className={styles.close}
            role="button"
            onClick={() =>
              cartStockChangesStore.updateCartAndCloseNotifierModal()
            }
          >
            <Icon type="close" size={32} />
          </span>
        )}
      </header>
      <div className={styles.root}>{renderProductCards()}</div>
      <footer className={styles.footer}>
        <button
          className={classes(['button _primary', styles.okButton])}
          onClick={() =>
            cartStockChangesStore.updateCartAndCloseNotifierModal()
          }
        >
          {t('ok')}
        </button>
      </footer>
    </Modal>
  );
};

export default observer(ModalStockChanged);
