import ImgAmEx from '~/assets/img/logo_amex.png';
import ImgDC from '~/assets/img/logo_dc.png';
import ImgDiscover from '~/assets/img/logo_discover.png';
import ImgMasterCard from '~/assets/img/logo_mastercard.png';
import ImgUber from '~/assets/img/logo_uber.png';
import ImgUnionpay from '~/assets/img/logo_unionpay.png';
import ImgVisa from '~/assets/img/logo_visa.png';

const CARD_BRANDS: Record<string, { title: string; icon: string }> = {
  visa: {
    title: 'Visa',
    icon: ImgVisa,
  },
  mastercard: {
    title: 'MasterCard',
    icon: ImgMasterCard,
  },
  amex: {
    title: 'American Express',
    icon: ImgAmEx,
  },
  uber: {
    title: 'Uber',
    icon: ImgUber,
  },
  discover: {
    title: 'Discover',
    icon: ImgDiscover,
  },
  diners: {
    title: 'Diners club',
    icon: ImgDC,
  },
  unionpay: {
    title: 'UnionPay',
    icon: ImgUnionpay,
  },
};

export default CARD_BRANDS;
