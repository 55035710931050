import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { getCloudflareUrl } from '~/components/CloudflareResponsiveImage/utils';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useOnWarehouseChange } from '~/hooks/useOnWarehouseChange';
import { catalogStore } from '~/stores/CatalogStore';
import { Category } from '~/stores/CategoriesStore';

import CategoryBlockTitle from '../CategoryBlockTitle';

import styles from './CategoriesGridThreeMBlock.module.scss';

interface CategoriesGridThreeMBlockProps {
  category: Category;
}

const gradient =
  'linear-gradient(to top, rgba(27, 26, 26, 1), rgba(27, 26, 26, 0))';

const CategoriesGridThreeMBlockProps = ({
  category,
}: CategoriesGridThreeMBlockProps) => {
  const { handleClickCategory } = useCategoryAnalytics();

  useOnWarehouseChange((_, isChanged) => {
    if (isChanged) {
      category.productsRequest.run();
    }
  });

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(
      src,
      company.envVariables.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
      {
        width: 208,
        height: 182,
      },
    );

  return (
    <div
      key={category.id}
      className={styles.container}
      data-company={company.name}
    >
      <CategoryBlockTitle text={category.name} />
      {category.subcategory.length > 0 ? (
        <div className={styles.tiles}>
          {category.subcategory.map(({ name, previewImage, id, parentId }) => {
            if (!parentId) {
              return null;
            }

            return (
              <div
                key={name}
                className={styles.tile}
                style={{
                  backgroundImage: `${
                    company.hasGradientTile ? gradient + ',' : ''
                  } url('${cfSrcForImage(previewImage)}')`,
                }}
              >
                <Link
                  state={{ clean_filters: true }}
                  to={`/c/${id}`}
                  className={styles.link}
                  onClick={() => {
                    handleClickCategory(parentId)();
                    catalogStore.resetCatalogFilters();
                  }}
                >
                  <div className={styles.title}>{name}</div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <Skeleton
          count={3}
          className={styles.tile}
          containerClassName={styles.tiles}
          inline={true}
        />
      )}
    </div>
  );
};

export default observer(CategoriesGridThreeMBlockProps);
