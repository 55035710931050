import { motion, useScroll, useTransform } from 'framer-motion';
import { classes } from 'html-classes';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { company } from '~/company/Company';
import Welcome from '~/components/Welcome/Welcome';

import Icon from '../../../components/Icon/Icon';
import { catalogStore } from '../../../stores/CatalogStore';

import { useOffsetTop } from './hooks';
import styles from './MobileHeaderLayout.module.scss';

interface MobileHeaderLayoutProps {
  content?: React.ReactElement;
  showSearch?: boolean;
  className?: string;
  showPersonalData?: boolean;
  isSticky?: boolean;
  toolbarContentClassName?: string;
  toolbarClassName?: string;
}

const SEARCH_PAGE_LIST = ['/shop', '/explore', '/c/'];
const MAIN = '/';

const MobileHeaderLayoutLocalee = ({
  content,
  showSearch,
  className,
  showPersonalData,
  isSticky,
  toolbarContentClassName,
  toolbarClassName,
}: MobileHeaderLayoutProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { offset, offsetRef } = useOffsetTop();

  const handleNavigatePersonalData = () => {
    navigate('/cabinet/personal-data');
  };

  const { scrollY } = useScroll();

  const stickyPosition = useTransform(scrollY, () =>
    offset <= 0 ? 'fixed' : 'static',
  );
  const stickyBg = useTransform(scrollY, () =>
    offset <= 0 ? 'white' : 'transparent',
  );
  const stickyTop = useTransform(scrollY, () => (offset <= 0 ? '0' : 'none'));

  return (
    <div
      className={classes([
        styles.container,
        isSticky && styles.sticky,
        className,
      ])}
      data-company={company.name}
    >
      {content && (
        <div
          className={classes([
            styles.toolbar,
            'content-layout',
            toolbarClassName,
          ])}
        >
          <div
            className={classes([
              styles.toolbarContent,
              toolbarContentClassName,
            ])}
          >
            {content}
          </div>
          {showPersonalData && (
            <div
              className={classes([
                styles.cart,
                catalogStore.totalCartCount && styles.cartFull,
              ])}
              onClick={handleNavigatePersonalData}
            >
              <Icon type="filter" size={24} />
              <div className={styles.totalCartCount}>
                {catalogStore.totalCartCount}
              </div>
            </div>
          )}
        </div>
      )}
      {company.hasWelcomeBlock && pathname === MAIN && <Welcome />}
      {(pathname === MAIN ||
        SEARCH_PAGE_LIST.some((i) => pathname.includes(i)) ||
        showSearch) && (
        <div ref={offsetRef} className={styles.mainInfoContainerContainer}>
          <motion.div
            className={styles.mainInfoContainer}
            style={{
              background: stickyBg,
              position: stickyPosition,
              top: stickyTop,
            }}
          >
            <div
              className={classes([
                'content-layout header-search-container',
                styles.searchBar,
              ])}
            >
              <Link
                className={'header-search'}
                to="/search"
                replace={true}
                data-company={company.name}
              >
                <Icon className="header-search__icon" type="search" size={24} />

                <div className="header-search__text">
                  {t('phrases:searchFor')}
                </div>
              </Link>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default observer(MobileHeaderLayoutLocalee);
