import { observer } from 'mobx-react-lite';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionFlagsNames } from '~/api/Customer';
import useDebounce from '~/components/DebounceHook';
import { usePushNotifications } from '~/hooks/usePushNotifications';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { userStore } from '~/stores/UserStore';

import { SwitchItem } from './components/switchItem';

const NotificationSettings = () => {
  const { t } = useTranslation();
  const { updateDeviceAndSendTags } = usePushNotifications();
  const nameVal = useDebounce<string>(checkoutStore.nameVal, 600);

  const [newsPush, setNewsPush] = useState(
    userStore.personalData.subscriptionFlags[SubscriptionFlagsNames.NEWS]
      ?.enabled || false,
  );
  const [specialDealsPush, setSpecialDealsPush] = useState(
    userStore.personalData.subscriptionFlags[
      SubscriptionFlagsNames.SPECIAL_DEALS
    ]?.enabled || false,
  );
  const [recommendationsPush, setRecommendationsPush] = useState(
    userStore.personalData.subscriptionFlags[
      SubscriptionFlagsNames.RECOMMENDATIONS
    ]?.enabled || false,
  );
  const [remindersPush, setRemindersPush] = useState(
    userStore.personalData.subscriptionFlags[SubscriptionFlagsNames.REMINDERS]
      ?.enabled || false,
  );

  const [newsEmail, setNewsEmail] = useState(
    userStore.personalData.subscriptionFlags[SubscriptionFlagsNames.NEWS_EMAIL]
      ?.enabled || false,
  );
  const [specialDealsEmail, setSpecialDealsEmail] = useState(
    userStore.personalData.subscriptionFlags[
      SubscriptionFlagsNames.SPECIAL_DEALS_EMAIL
    ]?.enabled || false,
  );
  const [recommendationsEmail, setRecommendationsEmail] = useState(
    userStore.personalData.subscriptionFlags[
      SubscriptionFlagsNames.RECOMMENDATIONS_EMAIL
    ]?.enabled || false,
  );
  const [remindersEmail, setRemindersEmail] = useState(
    userStore.personalData.subscriptionFlags[
      SubscriptionFlagsNames.REMINDERS_EMAIL
    ]?.enabled || false,
  );

  const subscribtionFlags = useMemo(
    () => [
      {
        name: SubscriptionFlagsNames.NEWS,
        enabled: newsPush,
      },
      {
        name: SubscriptionFlagsNames.REMINDERS,
        enabled: remindersPush,
      },
      {
        name: SubscriptionFlagsNames.RECOMMENDATIONS,
        enabled: recommendationsPush,
      },
      {
        name: SubscriptionFlagsNames.SPECIAL_DEALS,
        enabled: specialDealsPush,
      },
      {
        name: SubscriptionFlagsNames.NEWS_EMAIL,
        enabled: newsEmail,
      },
      {
        name: SubscriptionFlagsNames.SPECIAL_DEALS_EMAIL,
        enabled: specialDealsEmail,
      },
      {
        name: SubscriptionFlagsNames.RECOMMENDATIONS_EMAIL,
        enabled: recommendationsEmail,
      },
      {
        name: SubscriptionFlagsNames.REMINDERS_EMAIL,
        enabled: remindersEmail,
      },
    ],
    [
      newsPush,
      specialDealsPush,
      recommendationsPush,
      remindersPush,
      remindersEmail,
      recommendationsEmail,
      newsEmail,
      specialDealsEmail,
    ],
  );

  const updateFlags = (value: boolean, key: SubscriptionFlagsNames) => {
    const updated = subscribtionFlags.map((item) => {
      if (item.name === key) {
        return {
          ...item,
          enabled: value,
        };
      }
      return item;
    });

    updateDeviceAndSendTags(nameVal, userStore.email, updated);
  };

  const handleNotificationsSwitchChange =
    (flagName: SubscriptionFlagsNames) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;

      updateFlags(value, flagName);

      switch (flagName) {
        case SubscriptionFlagsNames.NEWS: {
          setNewsPush(value);
          break;
        }
        case SubscriptionFlagsNames.RECOMMENDATIONS: {
          setRecommendationsPush(value);
          break;
        }
        case SubscriptionFlagsNames.SPECIAL_DEALS: {
          setSpecialDealsPush(value);
          break;
        }
        case SubscriptionFlagsNames.REMINDERS: {
          setRemindersPush(value);
          break;
        }
        case SubscriptionFlagsNames.NEWS_EMAIL: {
          setNewsEmail(value);
          break;
        }
        case SubscriptionFlagsNames.RECOMMENDATIONS_EMAIL: {
          setRecommendationsEmail(value);
          break;
        }
        case SubscriptionFlagsNames.SPECIAL_DEALS_EMAIL: {
          setSpecialDealsEmail(value);
          break;
        }
        case SubscriptionFlagsNames.REMINDERS_EMAIL: {
          setRemindersEmail(value);
        }
      }
    };

  return (
    <>
      <SwitchItem
        title={t('personalisedOffers')}
        description={t('personalisedOffersDescription')}
        isEnabledEmail={newsEmail}
        inEnabledPush={newsPush}
        flagNameEmail={SubscriptionFlagsNames.NEWS_EMAIL}
        flagNamePush={SubscriptionFlagsNames.NEWS}
        onPressSwitch={handleNotificationsSwitchChange}
      />
      <SwitchItem
        title={t('disountAndSpecialOffers')}
        description={t('disountAndSpecialOffersDescription')}
        isEnabledEmail={specialDealsEmail}
        inEnabledPush={specialDealsPush}
        flagNameEmail={SubscriptionFlagsNames.SPECIAL_DEALS_EMAIL}
        flagNamePush={SubscriptionFlagsNames.SPECIAL_DEALS}
        onPressSwitch={handleNotificationsSwitchChange}
      />
      <SwitchItem
        title={t('thirdPartyPromotions')}
        description={t('thirdPartyPromotionsDescription')}
        isEnabledEmail={recommendationsEmail}
        inEnabledPush={recommendationsPush}
        flagNameEmail={SubscriptionFlagsNames.RECOMMENDATIONS_EMAIL}
        flagNamePush={SubscriptionFlagsNames.RECOMMENDATIONS}
        onPressSwitch={handleNotificationsSwitchChange}
      />
      <SwitchItem
        title={t('Transactional messages')}
        description={t('transactionalMessagesDescription')}
        isEnabledEmail={remindersEmail}
        inEnabledPush={remindersPush}
        flagNameEmail={SubscriptionFlagsNames.REMINDERS_EMAIL}
        flagNamePush={SubscriptionFlagsNames.REMINDERS}
        onPressSwitch={handleNotificationsSwitchChange}
      />
    </>
  );
};

export default observer(NotificationSettings);
