import { KYCStatus } from '~/api/KYCStatus';

type VerificationStatusData = {
  title: string;
  description?: string;
  style: string;
  icon?: string;
};

export const VERIFICATION: Record<KYCStatus, VerificationStatusData> = {
  [KYCStatus.NotVerified]: {
    title: 'Not verified',
    description:
      'Your personal details are not verified, please go through the verification process to complete your purchase.',
    style: '_fail',
    icon: 'warning',
  },
  [KYCStatus.InProgress]: {
    title: 'Verification is in progress',
    description: 'The verification process usually takes up to 1 minute.',
    style: '_waiting',
    icon: 'spinner',
  },
  [KYCStatus.Verified]: {
    title: 'You are verified ',
    style: '_success',
    icon: 'verified',
  },
  [KYCStatus.Started]: {
    title: 'The verification was initiated',
    style: '_fail',
  },
  [KYCStatus.ResubmissionRequested]: {
    title: 'Resubmit',
    description: 'Please resubmit your ID',
    style: '_waiting',
  },
};
