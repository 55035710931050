import { makeObservable, observable, runInAction, action } from 'mobx';

import { DifferenceDeliverySlot } from '~/components/ModalSlotsChangedEvent/interfaces';
import Delivery from '~/stores/CheckoutStore/Delivery';
import { CheckoutStorePartial } from '~/stores/CheckoutStore/interfaces';
import { BaseStore } from '~/stores/interfaces/BaseStore';

class SlotsChangedEventStore implements BaseStore {
  private readonly _checkoutStore: CheckoutStorePartial;

  isOpen: boolean = false;
  difference: DifferenceDeliverySlot[] | null = null;

  private _previousDeliveries: Delivery[] | null = null;

  previousExpressTime: string | undefined;

  constructor(orderStore: CheckoutStorePartial) {
    this._checkoutStore = orderStore;

    makeObservable(this, {
      previousExpressTime: observable,
      isOpen: observable,
      difference: observable,
      openNotificationModal: action.bound,
      reset: action.bound,
      checkDifferenceWithPreviousDeliveries: action.bound,
      preserveDeliveryInfo: action.bound,
    });
  }

  preserveDeliveryInfo(previousExpressTime?: string) {
    this.previousExpressTime = previousExpressTime;
    this._previousDeliveries = this._checkoutStore.deliveries;
  }

  checkDifferenceWithPreviousDeliveries(): void {
    if (!this._checkoutStore.deliveries || !this._previousDeliveries) {
      return;
    }

    const differences: DifferenceDeliverySlot[] = [];

    for (
      let deliveryIndex = 0;
      deliveryIndex < this._checkoutStore.deliveries.length;
      ++deliveryIndex
    ) {
      const newDelivery = this._checkoutStore.deliveries[deliveryIndex]!;
      const previousDelivery = this._previousDeliveries.find((delivery) =>
        delivery.itemsIds.some((id) => newDelivery.itemsIds.includes(id)),
      );

      if (!previousDelivery) {
        continue;
      }

      if (
        (!newDelivery.slotDeliveryDetails &&
          previousDelivery.slotDeliveryDetails) ||
        newDelivery.slotDeliveryDetails?.scheduleSlotId !==
          previousDelivery.slotDeliveryDetails?.scheduleSlotId ||
        newDelivery.slotDeliveryDetails?.currentDate !==
          previousDelivery.slotDeliveryDetails?.currentDate
      ) {
        differences.push({
          ...newDelivery,
          previousDeliverySlot: previousDelivery.slot,
        });
      }
    }

    if (!differences.length) {
      return;
    }

    this.difference = differences;
    this.openNotificationModal();
  }

  openNotificationModal() {
    runInAction(() => {
      this.isOpen = true;
    });
  }

  closeModal() {
    this.reset();
  }

  reset() {
    runInAction(() => {
      this.isOpen = false;
    });

    // Wait until modal is closed
    setTimeout(() => {
      runInAction(() => {
        this.difference = null;
        this._previousDeliveries = null;
        this.previousExpressTime = undefined;
      });
    }, 1000);
  }

  destroy() {
    this.reset();
  }
}

export default SlotsChangedEventStore;
