/* eslint-disable import/order */
import { JSX, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import { useGlobal } from '~/hooks/useGlobal';
import useMediaQuery from '~/hooks/useMediaQuery';

import { BREAKPOINTS } from '../../breakpoints';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Legals.module.scss';
import LegalsLocalee from './LegalsLocalee';
import LegalsCD from './LegalsCD';

import './styles.scss';
import LegalsVilo from './LegalsVilo';
import LegalsCirclek from './LegalsCirclek/LegalsCircle';
import LegalsKids from './LegalsKids/LegalsKids';

const LEGALS_NAV = [
  {
    link: 'cookie-policy',
    title: 'Cookie policy',
  },
  {
    link: 'payment-and-delivery',
    title: 'Payment and delivery T&C',
  },
  {
    link: 'privacy-policy',
    title: 'Privacy Policy',
  },
  {
    link: 'sales-and-returns-policies-and-consumer',
    title: 'Sales and Returns Policies and Consumer - Protection Procedures',
  },
  {
    link: 'terms-of-service',
    title: 'Terms Of Service',
  },
];

const Legals = (): JSX.Element => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.phone}px)`);

  useEffect(() => {
    setTimeout(() => {
      const anchor = window.location.hash.slice(1);
      handleLink(anchor);
    }, 0);
  }, []);

  const handleLink = (anchor: string) => {
    if (anchor) {
      const anchorEl = document.getElementById(anchor);
      if (anchorEl) {
        if (isMobile) {
          anchorEl.scrollIntoView();
        } else {
          const offset = anchorEl.offsetTop;
          const additionalOffset = isMobile
            ? 0
            : company.match({ vilo: 80, default: 200 });

          if (!isMobile) {
            window.scroll(0, offset - additionalOffset);
          }
        }
      }
    }

    if (location.hash) {
      history.replaceState(null, document.title, ' ');
    }
  };

  if (company.name === CompanyName.CityDrinks) {
    return <LegalsCD handleLink={handleLink} />;
  } else if (company.name === CompanyName.Localee) {
    return <LegalsLocalee handleLink={handleLink} />;
  } else if (company.name === CompanyName.Vilo) {
    return <LegalsVilo handleLink={handleLink} />;
  } else if (company.name === CompanyName.CircleK) {
    return <LegalsCirclek handleLink={handleLink} />;
  } else if (company.name === CompanyName.Kids) {
    return <LegalsKids handleLink={handleLink} />;
  }

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      <MobileHeaderLayout
        content={<MobileHeaderTitle text={t('termsAndConditions')} />}
      />
      <div className="content-layout legals">
        <div className={styles.root}>
          {!isTablet && (
            <>
              <Breadcrumbs
                items={
                  [
                    {
                      text: t('home'),
                      link: '/',
                    },
                    {
                      text: t('termsAndConditions'),
                      link: '/legals',
                    },
                  ].filter(Boolean) as BreadcrumbsItem[]
                }
              />

              <h2 className={styles.title}>{t('termsAndConditions')}</h2>
            </>
          )}

          <div className={styles.page}>
            <div className={styles.page__links_container}>
              <ul className={styles.page__links}>
                {LEGALS_NAV.map((i) => (
                  <li className={styles.page__links_item} key={i.link}>
                    <a
                      href={`#${i.link}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLink(i.link);
                      }}
                    >
                      {i.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.page__content}>
              <section>
                <h2 id="cookie-policy">Cookie policy</h2>
                <h3>Will be added soon</h3>
              </section>

              <section>
                <h2 id="payment-and-delivery">
                  Payment and delivery Terms and Conditions
                </h2>
                <h3>Will be added soon</h3>
              </section>

              <section>
                <h2 id="privacy-policy">Privacy Policy</h2>
                <h3>Will be added soon</h3>
              </section>

              <section>
                <h2 id="sales-and-returns-policies-and-consumer">
                  Sales and Returns Policies and Consumer - Protection
                  Procedures
                </h2>
                <h3>Will be added soon</h3>
              </section>

              <section>
                <h2 id="terms-of-service">Terms Of Service</h2>
                <h3>Will be added soon</h3>
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Legals;
