import i18n from 'i18next';
import { makeAutoObservable, runInAction } from 'mobx';

import {
  CustomerRequests,
  RatingDetails,
  ReviewsResponse,
} from '~/api/Customer';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { mainStore } from '~/stores/MainStore';
import { Pagination } from '~/stores/shared/Pagination';
import { Task } from '~/stores/shared/Task';

import type { CreateReviewData } from '~/stores/ReviewsStore/interfaces';
import type { ProductReview } from '~/types/Product/interface';

type ReviewsMetadata = Omit<
  Awaited<ReturnType<typeof CustomerRequests.requestReviewsByProductId>>,
  'data' | 'pagination'
>;

const PAGE_SIZE = 10;
const defaultDetails: RatingDetails = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

export class ReviewsStore {
  public id: number | string;

  private data: Nullable<ProductReview[]> = null;
  private meta: Nullable<ReviewsMetadata> = null;

  constructor(productId: number | string) {
    this.id = productId;

    makeAutoObservable(this);

    ReviewsStore.currentInstance = this;
  }

  private static readonly preloadedData = new Map<number, ReviewsResponse>();

  public static async preload(id: number) {
    const data = await CustomerRequests.requestReviewsByProductId({
      productId: id,
      page: { current: 1, size: PAGE_SIZE },
    });

    this.preloadedData.set(id, data);

    return data;
  }

  public static removePreloadedData(id: number) {
    this.preloadedData.delete(id);
  }

  public static currentInstance: Nullable<ReviewsStore> = null;

  public get list(): ProductReview[] {
    return this.request.result || [];
  }

  public get details(): RatingDetails {
    return this.meta?.details || defaultDetails;
  }

  public get canCreateReview(): boolean {
    return this.meta?.canCreateReview || false;
  }

  public setId(id: number) {
    this.id = id;
    const isSSR = typeof window === 'undefined';
    const initialState = isSSR
      ? ReviewsStore.preloadedData.get(id)
      : window.__INITIAL_STATE__?.reviews;

    if (initialState) {
      const { data, pagination, canCreateReview, details } = initialState;

      this.meta = { details, canCreateReview };
      this.request.setState({ data, pagination });
    } else if (!isSSR) {
      this.request.loadPage(1);
    }
  }

  public readonly request = new Pagination(async (page) => {
    const { data, details, pagination, canCreateReview } =
      await CustomerRequests.requestReviewsByProductId({
        productId: this.id,
        page: { current: page, size: PAGE_SIZE },
      });

    runInAction(() => {
      this.meta = { details, canCreateReview };
    });

    return { data, pagination };
  });

  public readonly createReview = new Task(async (params: CreateReviewData) => {
    try {
      const { data: newReview } = await CustomerRequests.addNewReview(params);

      runInAction(() => {
        if (this.data) {
          this.data = [newReview, ...this.data];
        }

        if (this.meta) {
          this.meta.canCreateReview = false;
        }
      });

      analyticsEventsEmitter.emit(
        EventsName.COMMON_ANALYTICS_RATE_PRODUCT_DETAILED,
        params,
      );

      return newReview;
    } catch (error) {
      mainStore.pushAlert('error', i18n.t('errors:unknown'));
      throw error;
    }
  });
}
