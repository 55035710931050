import { classes } from 'html-classes';
import { useEffect, PropsWithChildren } from 'react';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';

import { FullscreenWrapperProps } from './interfaces';
import styles from './ProductImageSlider.module.scss';

const FullscreenWrapper = (
  props: PropsWithChildren<FullscreenWrapperProps>,
) => {
  const { onClose, children, productTitle, wrapperClassName } = props;
  const { isMobile } = useGlobal();

  useEffect(() => {
    if (
      typeof document !== 'undefined' &&
      !document.body.classList.contains(`${styles.noScroll}`)
    ) {
      document.body.classList.add(`${styles.noScroll}`);
    }

    return () => {
      if (
        typeof document !== 'undefined' &&
        document.body.classList.contains(`${styles.noScroll}`)
      ) {
        document.body.classList.remove(`${styles.noScroll}`);
      }
    };
  }, []);

  return (
    <span data-company={company.name}>
      <div className={styles.fullScreenWrapper}>
        {productTitle && !isMobile && (
          <div className={styles.fullScreenWrapper__title}>{productTitle}</div>
        )}
        <div className={styles.fullScreenWrapper__inner}>
          <div className={classes([styles.sliderWr, wrapperClassName])}>
            {children}
          </div>
        </div>

        <span
          role="button"
          className={styles.closeFullScreenWrapper}
          onClick={onClose}
        >
          <Icon type="close" size={32} />
        </span>
      </div>
    </span>
  );
};

export default FullscreenWrapper;
