import './styles.scss';

import { classes } from 'html-classes';
import { compact, debounce } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import SwiperIcon from '~/components/Swiper/SwiperIcon';
import { useGlobal } from '~/hooks/useGlobal';
import { useOnWarehouseChange } from '~/hooks/useOnWarehouseChange';
import { Category } from '~/stores/CategoriesStore';
import { userStore } from '~/stores/UserStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import { createProductUrl } from '../utils';

import styles from './BannerProductBlock.module.scss';
import { SwiperWithSlidesPerView } from './interfaces';

interface BannerProductBlockProps {
  category: Category;
}

const BannerProductBlock = ({ category }: BannerProductBlockProps) => {
  const { isMobile } = useGlobal();
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [activeSwiperIndex, setActiveSwiperIndex] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const swiperRef = useRef<SwiperRef>(null);
  const initializeSwiperArrowsDebouncedRef = useRef(
    debounce((swiper: SwiperWithSlidesPerView) => {
      if (!swiper.slides.length) {
        setSlidesPerView(1);
        setIsBeginning(false);
        setIsEnd(false);
        return;
      }

      const slidesPerView = swiper.slidesPerViewDynamic?.() ?? 1;

      setSlidesPerView(slidesPerView);
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }, 100),
  );

  // TODO: figure out why products initially contains null values (that's why compact is used)
  const products = compact(
    category.subcategory.map(({ products }) => products.at(0)),
  );

  const isArrowsAllowed = () => {
    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return false;
    }

    return !isMobile && swiper.slides.length > slidesPerView;
  };

  const isPrevArrowVisible = () => isArrowsAllowed() && !isBeginning;

  const isNextArrowVisible = () => isArrowsAllowed() && !isEnd;

  const swiperPaginationClickHandler = (idx: number) => () => {
    if (activeSwiperIndex === idx) {
      return;
    }

    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return;
    }

    swiper.slideTo(idx);
    setIsEnd(swiper.isEnd);
    setIsBeginning(swiper.isBeginning);
  };

  useOnWarehouseChange(() => category.productsRequest.run());

  if (!products?.length && !category.productsRequest.isLoading) {
    return null;
  }

  return (
    <div className={classes([styles.root, 'banner-product-block'])}>
      <div className={classes([styles.container, 'banner-carousel carousel'])}>
        {isPrevArrowVisible() && (
          <SwiperIcon
            position="left"
            variant="fill"
            className={styles.leftControl}
            onClick={() => {
              swiperRef.current?.swiper.slidePrev();
              setIsEnd(false);
              if (swiperRef.current?.swiper.isBeginning) {
                setIsBeginning(true);
              }
            }}
          />
        )}
        <Swiper
          spaceBetween={20}
          slidesPerView="auto"
          slidesPerGroupAuto
          ref={swiperRef}
          onSlideChange={(swiper) => setActiveSwiperIndex(swiper.activeIndex)}
          onSlidesLengthChange={initializeSwiperArrowsDebouncedRef.current}
          className={styles.swiper}
          dir={userStore.dir}
          key={userStore.dir}
        >
          {products.map(
            ({
              id,
              name,
              categoryName,
              priceFormatted,
              previewImageThumb,
              promoQuantityDiscountPrice,
              promoQuantityDiscountPriceFormatted,
              slug,
            }) => (
              <SwiperSlide className={styles.slide} key={id}>
                <Link
                  to={createProductUrl({ id: slug })}
                  className={styles.link}
                >
                  <div className={styles.product}>
                    <CloudflareResponseImage
                      src={previewImageThumb}
                      srcParams={{ width: 96 }}
                      srcSetParams={defaultSrcSetParams}
                      alt={name}
                      loading="lazy"
                    />
                  </div>
                  <div className={styles.description}>
                    <div className={styles.title}>{categoryName}</div>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.price}>
                      <div className={styles.totalPrice}>
                        {formatPriceWithCurrency(priceFormatted)}
                      </div>
                      {!!promoQuantityDiscountPrice && (
                        <div className={styles.discount}>
                          <del>
                            {formatPriceWithCurrency(
                              promoQuantityDiscountPriceFormatted,
                            )}
                          </del>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ),
          )}
        </Swiper>
        {isNextArrowVisible() && (
          <SwiperIcon
            position="right"
            variant="fill"
            className={styles.rightControl}
            onClick={() => {
              swiperRef.current?.swiper.slideNext();
              setIsBeginning(false);
              if (swiperRef.current?.swiper.isEnd) {
                setIsEnd(true);
              }
            }}
          />
        )}
      </div>

      {products.length > 1 && isMobile && (
        <div className={styles.swiperPagination}>
          <ul>
            {products.map((product, idx) => {
              return (
                <li key={product.id}>
                  <span
                    role="button"
                    className={classes([
                      styles.swiperPagination__dot,
                      activeSwiperIndex === idx && styles.active,
                    ])}
                    onClick={swiperPaginationClickHandler(idx)}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default observer(BannerProductBlock);
