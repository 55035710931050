import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { useModal } from '~/hooks/useModal';

const EmptyAddresses = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const handleAddCard = () => openModal(ModalType.DeliveryAddress);

  return (
    <div className="empty-cart">
      <img
        className="empty-cart__img"
        src={company.images.address.empty}
        alt="Empty list"
      />
      <p className="empty-cart__title">{t('phrases:emptyAddresses')}</p>
      <div className="empty-cart__content">
        <button className="button _primary btn-wrapper" onClick={handleAddCard}>
          <Icon type="plus" size={24} />
          {t('addAddress')}
        </button>
      </div>
    </div>
  );
};

export default observer(EmptyAddresses);
