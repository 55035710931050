export const ICON_TYPES = [
  'note',
  'home-trend-up',
  'box',
  'burger',
  'burger-close',
  'search',
  'nav-bar-search',
  'express',
  'express-fill',
  'chevron',
  'chevron-fill',
  'chevron-2-fill',
  'nav-bar-fav',
  'nav-bar-fav-fill',
  'fav',
  'fav-fill',
  'profile',
  'bag',
  'insta',
  'fb',
  'apple',
  'gplay',
  'arrow',
  'star',
  'star-fill',
  'percent',
  'trash',
  'minus',
  'plus',
  'edit',
  'badge',
  'like',
  'like-fill',
  'share',
  'info',
  'check',
  'mc',
  'gpay',
  'apay',
  'apay2',
  'cash',
  'add-card',
  'shield',
  'card',
  'menu-personal',
  'logout',
  'subscription',
  'calendar',
  'notification',
  'notification-not',
  'cards',
  'orders',
  'location',
  'document',
  'message',
  'link',
  'friends',
  'refferal',
  'filter',
  'whatsapp',
  'phone',
  'bonus',
  'copy',
  'difficulty',
  'time',
  'play',
  'home',
  'home-edit',
  'pin',
  'geolocation',
  'check-round',
  'marker',
  'spinner',
  'verified',
  'warning',
  'refresh',
  'person',
  'express-round',
  'card-add',
  'check-circled',
  'close',
  'home-fill',
  'explore',
  'explore-fill',
  'search-fill',
  'cabinet',
  'cabinet-fill',
  'filters',
  'sort',
  'promocode',
  'people',
  'shop',
  'calendar-2',
  'envelope',
  'tiktok',
  'youtube',
  'back',
  'double-back',
  'close-round',
  'map',
  'receipt',
  'express-disabled',
  'person-other',
  'status-delivered',
  'status-packaging',
  'status-delivering',
  'delivery-pin-mid',
  'delivery-pin-max',
  'warehouse-pin',
  'house-pin',
  'location-pin',
  'location-fill-pin',
  'ellipses',
  'ellipses-vertical',
  'visible',
  'visible-not',
  'time-fill',
  'whatsapp-fill',
  'nav-bar-search-fill',
  'bag-fill',
  'chevron-double',
  'fresh-chat',
  'insta',
  'facebook',
  'twitter',
  'flash-fill',
  'rate-stars-circle',
  'standard-delivery',
  'empty-wallet',
  'shop-building',
  'expand',
  'shopping-bag',
  'shopping-bag-check',
  'done-all',
  'reorder',
  'linkedin',
  'pay_gpay',
  'pay_apple',
  'pay_visa',
  'pay_mc',
  'pay_jcb',
] as const;

export const ICON_TYPES_NOT_TRL = [
  'insta',
  'fb',
  'apple',
  'gplay',
  'gpay',
  'apay',
  'whatsapp',
  'tiktok',
  'youtube',
  'fresh-chat',
  'insta',
  'facebook',
  'twitter',
  'reorder',
  'linkedin',
  'pay_gpay',
  'pay_apple',
  'pay_visa',
  'pay_mc',
  'pay_jcb',
] as const;

export type IconTypes = (typeof ICON_TYPES)[number];
