import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';

import { SubtotalInfoProps } from '../../types';

import SubtotalInfoVariant1 from './SubtotalInfoVariant1/Subtotalnfo';
import SubtotalInfoVariant2 from './SubtotalInfoVariant2/SubtotalInfo';

const SubtotalInfo = (props: SubtotalInfoProps) => {
  switch (company.name) {
    case CompanyName.CityDrinks:
      return <SubtotalInfoVariant2 {...props} />;
    default:
      return <SubtotalInfoVariant1 {...props} />;
  }
};

export default SubtotalInfo;
