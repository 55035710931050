import { differenceInMinutes, set, format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import BannerImg2 from '~/assets/img/banner_2.jpg';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { useForceUpdate } from '~/hooks/useForceUpdate';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { orderStore } from '~/stores/OrderStore';

import styles from './DeliveryBanner.module.scss';

export const DeliveryBanner = observer(() => {
  const { t } = useTranslation();
  const [forceUpdate] = useForceUpdate();
  const { isMobile } = useGlobal();
  const slot = orderStore.closestSlot;
  const express = orderStore.expressDelivery;
  const expressDuration = orderStore.etaCalculation?.duration.range;
  const { openModal } = useModal();
  const open = () => {
    openModal(ModalType.DeliveryInfo, 'home');
  };
  const timeLeft = differenceInMinutes(
    slot?.cut_off_time ? slot.cut_off_time * 1000 : 0,
    Date.now(),
  );

  useEffect(() => {
    if (!slot) {
      return;
    }

    let timeout: NodeJS.Timeout;

    const nextTick = () => {
      const diff = slot.cut_off_time * 1000 - Date.now();

      if (diff < 0) {
        return;
      }

      const minutes = diff / 1000 / 60;
      const nextUpdate = Math.round(
        (minutes - Math.floor(minutes)) * 1000 * 60,
      );

      timeout = setTimeout(() => {
        forceUpdate();
        nextTick();
      }, nextUpdate);
    };

    nextTick();

    return () => clearTimeout(timeout);
  }, [JSON.stringify(slot)]);

  const getDateByTime = (time: string) => {
    const [h, m, s] = time.split(':');

    return set(Date.now(), {
      hours: parseInt(h || '0'),
      minutes: parseInt(m || '0'),
      milliseconds: parseInt(s || '0'),
    });
  };
  const deliveryStart = slot
    ? formatInTimeZone(slot.delivery_min_time * 1000, slot.time_zone, 'HH:mm')
    : null;
  const deliveryEnd = slot
    ? formatInTimeZone(slot.delivery_max_time * 1000, slot.time_zone, 'HH:mm')
    : null;
  if (!slot && !express) {
    return null;
  }
  if (isMobile) {
    return (
      <>
        {express && (
          <div className={styles.banner} onClick={open}>
            <div className={styles.info}>
              <p className={styles.express}>
                {express.availableNow
                  ? t('phrases:expressDeliveryBannerTitle', {
                      time: expressDuration,
                    })
                  : t('expressAvailableFrom', {
                      time: format(getDateByTime(express.opening), 'hh:mm aaaa')
                        .replaceAll('.', '')
                        .replaceAll(' ', ''),
                    })}
              </p>
              <div className={styles.learnMore}>
                <Icon type="info" size={16} /> Learn more
              </div>
            </div>
          </div>
        )}
        {slot && (
          <div className={styles.banner} onClick={open}>
            <div className={styles.info}>
              <>
                <p className={styles.standard}>
                  Standard delivery: {deliveryStart} to {deliveryEnd}
                </p>
                {timeLeft >= 0 && timeLeft < 60 && (
                  <p className={styles.timeLeft}>
                    Time left to order:{' '}
                    <span className={styles.time}>{timeLeft} min</span>
                  </p>
                )}
              </>
              <div className={styles.learnMore}>
                <Icon type="info" size={16} /> Learn more
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className={styles.banner} onClick={open}>
        <div className={styles.info}>
          {express && expressDuration && (
            <p className={styles.express}>
              {express.availableNow
                ? t('phrases:expressDeliveryBannerTitle', {
                    time: expressDuration,
                  })
                : t('expressAvailableFrom', {
                    time: format(getDateByTime(express.opening), 'hh:mm aaaa')
                      .replaceAll('.', '')
                      .replaceAll(' ', ''),
                  })}
            </p>
          )}
          {slot && (
            <>
              <p className={styles.standard}>
                Standard delivery: {deliveryStart} to {deliveryEnd}
              </p>
              {timeLeft >= 0 && timeLeft < 60 && (
                <p className={styles.timeLeft}>
                  Time left to order:{' '}
                  <span className={styles.time}>{timeLeft} min</span>
                </p>
              )}
            </>
          )}
          <div className={styles.learnMore}>
            <Icon type="info" size={24} /> Learn more
          </div>
        </div>
        <div>
          <img src={BannerImg2} alt="" className={styles.image} height={120} />
        </div>
      </div>
    </>
  );
});
