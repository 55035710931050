import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Price from '~/components/Price';
import { catalogStore } from '~/stores/CatalogStore';

import styles from './CartCostItemsVariant1.module.scss';

const CartCostItemsVariant1 = () => {
  const { t } = useTranslation();

  return (
    <>
      <ul className={styles.content}>
        <li className={styles.content__item}>
          <div className={styles.content__item__desc}>
            <p>
              {t('itemsN')}{' '}
              <span className={styles.content__item__desc__count}>
                ({catalogStore.selectedItemsTotalCount})
              </span>
            </p>
          </div>
          <Price price={catalogStore.totalCartPrice.base} />
        </li>
        {Number(catalogStore.totalCartPrice.discount) > 0 && (
          <li className={styles.content__item}>
            <div className={styles.content__item__desc}>
              <p>{t('discount')}</p>
            </div>
            <Price
              price={`-${catalogStore.totalCartPrice.discount}`}
              className={styles.discountPrice}
              classNameCurrentPrice={styles.content__item__price}
            />
          </li>
        )}
        {Number(catalogStore.totalCartPrice.promocodeDiscount) > 0 && (
          <li className={styles.content__item}>
            <div className={styles.content__item__desc}>
              <p>{t('promocode')}</p>
            </div>
            <Price
              price={`-${catalogStore.totalCartPrice.promocodeDiscount}`}
              className={styles.discountPrice}
              classNameCurrentPrice={styles.content__item__price}
            />
          </li>
        )}
      </ul>
      <div className={classes([styles.price, styles.price__current])}>
        <p>{t('subtotal')}</p>
        <Price
          price={catalogStore.totalCartPrice.paidWithDiscount}
          classNameCurrentPrice={styles.price_current}
        />
      </div>
    </>
  );
};

export default observer(CartCostItemsVariant1);
