import { makeAutoObservable } from 'mobx';

import {
  BackorderLogic,
  ISettingsRequestDTO,
  SettingsRequests,
} from '~/api/Settings';

class SettingsStore {
  settings: ISettingsRequestDTO['data'] = {
    backorderLogic: BackorderLogic.ORIGINAL,
  };

  constructor() {
    makeAutoObservable(this);
  }

  async requestSettings() {
    const res = await SettingsRequests.getSettings();
    this.settings = res.data;
  }
}

export const settingStore = new SettingsStore();
