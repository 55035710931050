import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { StartVerificationLocation, mainStore } from '~/stores/MainStore';

import { KYCStatus } from '../../api/KYCStatus';
import { userStore } from '../../stores/UserStore';

import Icon from '../Icon/Icon';

const KYCCheck = () => {
  const handleVerify = () => {
    mainStore.setStartVerificationLocation(StartVerificationLocation.CABINET);
    userStore
      .requestKYCSession()
      .then(({ data }) => {
        const shouldOpenInAppBrowser =
          mainStore.isRN &&
          Number(mainStore.appVersion.replaceAll('.', '')) >= 1029;

        if (shouldOpenInAppBrowser) {
          mainStore.sendToRN('openInAppBrowser', { url: data.url });
        } else {
          window.location.href = data.url;
        }
      })
      .catch(() => void 0);
  };

  const btnText = useMemo(() => {
    switch (userStore.personalData.kycStatus) {
      case KYCStatus.NotVerified:
        return (
          <>
            Start verification{' '}
            <Icon type="chevron" size={24} className="icon__rotate-270" />
          </>
        );
      case KYCStatus.Started:
        return (
          <>
            Continue verification{' '}
            <Icon type="chevron" size={24} className="icon__rotate-270" />
          </>
        );
      case KYCStatus.ResubmissionRequested:
        return (
          <>
            Resubmit <Icon type="refresh" size={24} />
          </>
        );
    }
  }, [userStore.personalData.kycStatus]);

  useEffect(() => {
    if (
      ![KYCStatus.Started, KYCStatus.ResubmissionRequested].includes(
        userStore.personalData.kycStatus,
      )
    ) {
      return;
    }

    const interval = setInterval(() => {
      userStore.requestPersonalData().catch(() => void 0);
    }, 5000);

    return () => clearInterval(interval);
  }, [userStore.personalData.kycStatus]);

  if (
    !userStore.isAuthorized ||
    userStore.personalData.kycStatus === KYCStatus.Verified
  ) {
    return <></>;
  }

  return btnText ? (
    <div
      className={classes([
        'button _bordered _block kyc-verify',
        // { '_disabled': userStore.personalData.kycStatus === KYCStatus.Started },
      ])}
      onClick={handleVerify}
    >
      {btnText}
    </div>
  ) : null;
};

export default observer(KYCCheck);
