import 'swiper/css';
import { classes } from 'html-classes';
import { forwardRef } from 'react';

import { CategorySkeleton } from '~/pages/Catalog/CatalogSkeleton';

import CategoryBlockTitle from '../CategoryBlockTitle';

import styles from './ProductsCarouselSliderOneBlock.module.scss';

import './styles.scss';

const ProductsCarouselSliderOneBlockSkeleton = forwardRef<HTMLDivElement>(
  (_, ref) => {
    return (
      <div className="content-layout" ref={ref}>
        <div
          className={classes([
            styles.container,
            'product-carousel-slider-one-block',
          ])}
        >
          <CategoryBlockTitle />
          <div className="skeleton-container">
            <CategorySkeleton />
          </div>
        </div>
      </div>
    );
  },
);

ProductsCarouselSliderOneBlockSkeleton.displayName =
  'ProductsCarouselSliderOneBlockSkeleton';

export default ProductsCarouselSliderOneBlockSkeleton;
