import { classes } from 'html-classes';
import { ReactNode } from 'react';

type Props = {
  type?: 'div' | 'p' | 'span' | 'h1' | 'h2' | 'h3' | 'h4';
  children: ReactNode;
  size?:
    | '8'
    | '9'
    | '10'
    | '11'
    | '12'
    | '13'
    | '14'
    | '15'
    | '16'
    | '17'
    | '18'
    | '19'
    | '20'
    | '21'
    | '22'
    | '24'
    | '28'
    | '32'
    | '35'
    | '36'
    | '40';
  weight?: '400' | '500' | '600' | '700';
  align?: 'left' | 'center' | 'right';
  className?: string;
  isTitle?: boolean;
};

const Text = ({
  type: Tag = 'p',
  size = '12',
  weight = '400',
  align = 'left',
  className,
  children,
  isTitle,
}: Props) => {
  return (
    <Tag
      className={classes([
        `size-${size}`,
        `weight-${weight}`,
        `align-${align}`,
        isTitle && 'title',
        className,
      ])}
    >
      {children}
    </Tag>
  );
};

export default Text;
