import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import NotificationSettings from '~/components/NotificatioinSettings/NotificationSettings';

import { useModal } from '../../hooks/useModal';

import Icon from '../Icon/Icon';

const ModalChangeNotifications = () => {
  const { t } = useTranslation();
  const { closeModal } = useModal();

  const backHandler = () => closeModal();

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <header className="popover__header">
          <button
            className="button _no-padding _no-color back-button"
            onClick={backHandler}
          >
            <Icon type="arrow" className="icon__rotate-180" size={24} />
          </button>
          <div className="title">{t('notificationSettings')}</div>
        </header>
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={backHandler}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <NotificationSettings />
      </div>
    </div>
  );
};

export default observer(ModalChangeNotifications);
