import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { RecommendationSource } from '~/api/Catalog';
import { KYCStatus } from '~/api/KYCStatus';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import Price from '~/components/Price';
import ProductCard from '~/components/ProductCard/ProductCard';
import { RECOMMENDATION_TITLES } from '~/components/Recommendations/constants';
import { VERIFICATION } from '~/constants/verification';
import { useModal } from '~/hooks/useModal';
import { catalogStore } from '~/stores/CatalogStore';
import { mainStore, StartVerificationLocation } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

import styles from './ModalRecommendations.module.scss';

const ModalRecommendations = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const [footerHeight, setFooterHeight] = useState<number>(16);

  const source = searchParams.get('source');

  if (!source) {
    return null;
  }

  const title =
    RECOMMENDATION_TITLES[source as RecommendationSource] || 'matchingProducts';

  const products =
    catalogStore.productRecommendations[source as RecommendationSource];

  const handleNavigateToCheckout = async () => {
    const cartCountChanged = await catalogStore.calculateCart();

    if (cartCountChanged) {
      return;
    }

    navigate('/checkout');
  };

  const isLoading =
    orderStore.isLoading ||
    orderStore.isETALoading ||
    catalogStore.calculationProcess.isLoading;

  const isWarehouseClosed =
    orderStore.etaCalculation?.warehouse.availability.availableNow === false;

  const handleCheckout = () => {
    if (!userStore.isAuthorized) {
      openModal(ModalType.Auth);
      return;
    }

    if (
      userStore.isAuthorized &&
      userStore.personalData.kycStatus !== KYCStatus.Verified
    ) {
      mainStore.setStartVerificationLocation(StartVerificationLocation.CART);
      userStore
        .requestKYCSession()
        .then(({ data }) => {
          const shouldOpenInAppBrowser =
            mainStore.isRN &&
            Number(mainStore.appVersion.replaceAll('.', '')) >= 1029;

          if (shouldOpenInAppBrowser) {
            mainStore.sendToRN('openInAppBrowser', { url: data.url });
          } else {
            window.location.href = data.url;
          }
        })
        .catch(() => void 0);
      return;
    }
  };

  const renderMobileGoToCheckoutButton = () => {
    switch (userStore.personalData.kycStatus) {
      case KYCStatus.Verified:
        return (
          <button
            type="button"
            onClick={handleNavigateToCheckout}
            className={classes([
              'button _primary _med',
              (catalogStore.selectedCartItems.length === 0 ||
                isLoading ||
                isWarehouseClosed) &&
                '_disabled',
            ])}
          >
            {t('goToCheckout')}
            {isLoading ? (
              <span className="spinner _white" />
            ) : (
              catalogStore.selectedCartItems.length > 0 && (
                <Price
                  price={catalogStore.totalCartPrice.paidWithoutDiscount}
                />
              )
            )}
          </button>
        );
      case KYCStatus.InProgress:
      case KYCStatus.ResubmissionRequested:
      case KYCStatus.Started: {
        const statusConfig = VERIFICATION[userStore.personalData.kycStatus];
        const isDisabled =
          (userStore.personalData.kycStatus === KYCStatus.InProgress ||
            userStore.personalData.kycStatus === KYCStatus.Started) &&
          '_disabled';

        return (
          <div
            onClick={handleCheckout}
            className={`button _primary subtotal-info__button _med _center ${isDisabled}`}
          >
            {statusConfig.title}
          </div>
        );
      }
      default:
        return (
          <div
            onClick={handleCheckout}
            className="button _primary subtotal-info__button _med _center"
          >
            {userStore.isAuthorized ? t('verifyId') : t('logInToCheckout')}
          </div>
        );
    }
  };

  return (
    <div
      className={classes(['popover-container input-form', styles.fixedContent])}
    >
      <div className={styles.header}>
        <h2 className={styles.title}>{t(title)}</h2>
        <span className={styles.close} role="button" onClick={closeModal}>
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </span>
      </div>
      <div
        style={{ '--bottom-space': `${footerHeight}px` } as CSSProperties}
        className={styles.content}
      >
        {products && products.data.length > 0 && (
          <>
            {products.data.map((product) => (
              <div className={styles.slide} key={product.id}>
                <ProductCard
                  product={product}
                  className={styles.slideProduct}
                />
              </div>
            ))}
          </>
        )}
      </div>
      <div
        className={styles.footer}
        ref={(node) => {
          if (!node) {
            return;
          }
          const { height } = node.getBoundingClientRect();
          setFooterHeight(height + 8);
        }}
      >
        {renderMobileGoToCheckoutButton()}
      </div>
    </div>
  );
};

export default observer(ModalRecommendations);
