import { t } from 'i18next';

import { mainStore } from '~/stores/MainStore';

export interface CopyToClipboardOptions {
  text: string;
  successMessage?: string;
  failureMessage?: string;
}

const copyToClipboard = async (options?: CopyToClipboardOptions) => {
  const { text, failureMessage, successMessage = t('copied') } = options || {};

  if (!text) {
    return;
  }

  if (mainStore.isRN) {
    mainStore.sendToRN(
      'copyToClipboard',
      text,
      () => {
        mainStore.pushAlert('success', successMessage);
      },
      () => {
        if (failureMessage) {
          mainStore.pushAlert('error', failureMessage);
        }
      },
    );
    return;
  }
  await navigator.clipboard.writeText(text);
  mainStore.pushAlert('success', successMessage);
};

export default copyToClipboard;
