import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { company } from '~/company/Company';
import styles from '~/pages/Checkout/CheckoutContent.module.scss';
import { DeliveryItem } from '~/pages/Checkout/CheckoutSkeleton';
import { PickUpProductList } from '~/pages/Checkout/components/PickUpProductList';
import Textarea from '~/pages/Checkout/components/Textarea/Textarea';
import DeliverAllGoodsCheckbox from '~/pages/Checkout/DeliverAllGoodsCheckbox';
import DeliveryInstructions from '~/pages/Checkout/DeliveryInstructions';
import DeliveryMobile from '~/pages/Checkout/DeliveryMobile';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';

import { MobileActiveDeliveryMethodProductListProps } from './interfaces';

const MobileActiveDeliveryMethodProductList = ({
  isDeliveryMethodSelectionAvailable,
  hasExpressDelivery,
  handleSelectDateModal,
  handleCommentChange,
}: MobileActiveDeliveryMethodProductListProps) => {
  const { t } = useTranslation();

  const getCommentsSection = (placeholder: string) => (
    <div
      className={classes([
        'content-layout delivery-payment__comment',
        styles.mobileCommentWr,
      ])}
    >
      <Textarea
        placeholder={placeholder}
        onChange={handleCommentChange}
        value={checkoutStore.commentVal}
      />
      <DeliveryInstructions />
    </div>
  );

  if (checkoutStore.deliveryMethodChangeMeta.isLoading) {
    return (
      <div className={classes(['delivery-block', styles.productListLoader])}>
        <div className={styles.productListLoading}>
          <DeliveryItem />
        </div>
      </div>
    );
  }

  switch (checkoutStore.deliveryMethod) {
    case ETADeliveryMethodType.JiffyDelivery:
      return (
        <div className="delivery-block">
          <p className="title">
            {t(
              isDeliveryMethodSelectionAvailable
                ? 'deliverySlots'
                : 'deliverySchedule',
            )}
          </p>
          {checkoutStore.isMultipleDeliveriesAvailable && (
            <div className="checkout-control content-layout">
              <DeliverAllGoodsCheckbox
                checked={
                  company.combineItemsByDefaut
                    ? !checkoutStore.isDeliverTogether
                    : checkoutStore.isDeliverTogether
                }
                onChange={() => checkoutStore.switchDeliverTogether()}
              />
            </div>
          )}
          <ul className="slot-list">
            {checkoutStore.deliveries?.map((delivery, index) => {
              const key = delivery.slotDeliveryDetails
                ? delivery.slotDeliveryDetails?.scheduleSlotId +
                  delivery.slotDeliveryDetails?.currentDate
                : 'express';

              const order = catalogStore.deliveriesOrders[index];

              if (!order) {
                return <Fragment key={key} />;
              }

              return (
                <DeliveryMobile
                  slotNumber={hasExpressDelivery ? index : index + 1}
                  delivery={delivery}
                  order={order}
                  key={
                    delivery.slotDeliveryDetails
                      ? delivery.slotDeliveryDetails.scheduleSlotId +
                        delivery.slotDeliveryDetails.currentDate
                      : 'express'
                  }
                  handleSelectDateModal={() =>
                    handleSelectDateModal(index, order)
                  }
                />
              );
            })}
          </ul>
          {getCommentsSection(t('comments'))}
        </div>
      );
    case ETADeliveryMethodType.ClickAndCollect:
      return (
        <div className="delivery-block">
          <p className="title">{t('deliveryMethods:pickup')}</p>
          <PickUpProductList className={styles.mobile} />
          {getCommentsSection(t('clickCollectComment'))}
        </div>
      );
  }
};

export default observer(MobileActiveDeliveryMethodProductList);
