import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { searchStore } from '~/stores/SearchStore';
import { checkAndSaveReferralPromocode } from '~/utils/referralLink';

import { mainStore } from '../stores/MainStore';
import { storiesStore } from '../stores/StoriesStore';
import { userStore } from '../stores/UserStore';

export const useRN = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!mainStore.isRN) {
      return;
    }
    const logSentry = (msg: Record<string, any>) => {
      if (msg.targetFunc !== 'getDeviceInfo') {
        return;
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          response: msg,
        });
        Sentry.captureMessage(
          `[Receive RN message] ${msg.targetFunc || msg.event || ''}`,
          'debug',
        );
      });
    };
    const handlerEvent = async (e: Event) => {
      if ((e as MessageEvent).origin === 'https://js.stripe.com') {
        return;
      }
      if ((e as MessageEvent).origin === 'https://jiffygrocery.co.uk') {
        return;
      }
      if (!(e as MessageEvent).data) {
        return;
      }
      if ((e as MessageEvent).data[0] !== '{') {
        return;
      }
      try {
        const message: Record<string, any> = JSON.parse(
          (e as MessageEvent).data || '{}',
        );
        logSentry(message);
        if (message.event === 'showed') {
          mainStore.setIsRNReady(true);
        }
        if (message.event === 'appStateBackground') {
          mainStore.setInBackground(true);
        }
        if (message.event === 'appStateActive') {
          mainStore.setInBackground(false);
        }
        if (message.event === 'AppStateBackground') {
          mainStore.clearAllCache();
        }
        if (message.event === 'deeplinkGetted') {
          if (
            message.universalLinkData?.key &&
            message.universalLinkData?.value
          ) {
            const url = new URL(window.location.href);

            url.searchParams.set(
              message.universalLinkData?.key,
              message.universalLinkData?.value,
            );

            window.history.pushState({ path: url.href }, '', url.href);

            await checkAndSaveReferralPromocode();
          }

          if (message.data?.deep_link_value) {
            const [type, value] = (
              message.data.deep_link_value as string
            ).split('_');
            let pathname = '';
            let state: Record<string, string | number> = {};
            if (type === 'product') {
              pathname = `/p/${value}`;
            }
            if (type === 'category') {
              pathname = `/c/${value}`;
            }
            if (type === 'promo') {
              pathname = `/banner/${value}`;
            }
            if (type === 'cart') {
              pathname = '/cart';
            }
            if (type === 'favourites') {
              pathname = '/favorites';
            }
            if (type === 'purchased') {
              pathname = '/favorites';
              state = { activeTabNum: 1 };
            }
            if (type === 'search') {
              pathname = '/search';
              searchStore.paginationRequest.loadPage(
                1,
                // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                value.search(/%\D|%$/g) === -1
                  ? // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                    decodeURIComponent(value)
                  : value,
              );
            }
            if (type === 'order-rate' && userStore.isAuthorized) {
              pathname = '/';
              mainStore.setDeferedRateOrder(true);
            }
            if (type === 'story') {
              storiesStore
                .requestStories()
                .catch((error) => error && console.error(error));
              const storyByDeepLink = storiesStore.stories.find(
                (story) => story.deepLink === message.data.deep_link_value,
              );
              if (storyByDeepLink) {
                pathname = `/stories/${storyByDeepLink.id}`;
              }
            }
            if (pathname) {
              if (userStore.deliveryAddress) {
                navigate(pathname, {
                  state: { isDeeplink: true, ...state },
                });
              } else {
                mainStore.setDeferedDeeplink(pathname);
              }
            }
          }
          if (message.navigationLink) {
            navigate(message.navigationLink);
          }
        }
        if (mainStore.rnBridgeCallbacks[message.msgId]) {
          const callback = mainStore.rnBridgeCallbacks[message.msgId];
          if (message.isSuccessfull) {
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            if (callback.onSuccess) {
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              callback.onSuccess(message.args || {});
            }
          } else {
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            if (callback.onError) {
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              callback.onError(message.args || {});
            }
          }
          delete mainStore.rnBridgeCallbacks[message.msgId];
        }
      } catch (error) {
        error && console.error(error);
      }
    };
    document.addEventListener('message', handlerEvent);
    window.addEventListener('message', handlerEvent);
    mainStore.sendToRN('mount', {
      customerId: userStore.personalData.id || undefined,
    });
  }, []);

  useEffect(() => {
    if (!mainStore.isRN || !mainStore.isRNReady) {
      return;
    }
    mainStore.sendToRN('getDeviceInfo', null, (data) => mainStore.initRN(data));
  }, [mainStore.isRNReady]);
};
