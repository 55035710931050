import ProductCardSkeleton from '~/components/ProductCard/ProductCardSkeleton';

type Props = {
  num?: number;
};

export const CategorySkeleton = ({ num = 8 }: Props) => {
  const arr = new Array(num).fill(0);

  return (
    <>
      {arr.map((_, i) => (
        <ProductCardSkeleton key={i} />
      ))}
    </>
  );
};
