import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import { pluralizePcLabel } from '~/components/ProductCard/utils';
import { Offer } from '~/stores/CategoriesStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from '../Product.module.scss';

type Props = {
  offer?: Offer;
  isMultiBuy: boolean;
  oldPrice: string | null;
  currentPrice?: string;
};

const ProductPriceLocalee = ({
  offer,
  isMultiBuy,
  oldPrice,
  currentPrice,
}: Props) => {
  const { t } = useTranslation();

  const componentBody = () => {
    if (DEFAULT_COMPANIES.includes(company.name)) {
      return (
        <div
          className="price-info-container__price"
          data-company={company.name}
        >
          <div className="price-info-container__price-with-discount">
            <div className="price-info-container__price-row">
              <p
                className={classes([
                  'price-info__current',
                  styles.priceInfoCurrent,
                  (oldPrice || isMultiBuy) && '_with-discount',
                ])}
              >
                {isMultiBuy
                  ? formatPriceWithCurrency(
                      offer?.promoQuantityDiscountPriceFormatted || '',
                    )
                  : formatPriceWithCurrency(currentPrice ?? 0)}{' '}
              </p>
              {isMultiBuy && (
                <div className={styles.multiBuyFromRule}>
                  {t('phrases:fromPc', {
                    length: pluralizePcLabel(offer?.promoRequiredQuantity),
                  })}
                </div>
              )}
            </div>
            {isMultiBuy && (
              <p className={styles.multiBuyActualPrice}>
                {formatPriceWithCurrency(currentPrice)} / 1x
              </p>
            )}
          </div>
          {oldPrice && (
            <p className="price-info__old">
              {formatPriceWithCurrency(oldPrice)}
            </p>
          )}
        </div>
      );
    } else {
      return (
        <div
          className="price-info-container__price"
          data-company={company.name}
        >
          <div>
            {isMultiBuy && (
              <div className={styles.multiBuyFromRule}>
                {t('phrases:fromPc', {
                  length: pluralizePcLabel(offer?.promoRequiredQuantity),
                })}
              </div>
            )}
            <p
              className={classes([
                'price-info__current',
                (oldPrice || isMultiBuy) && '_with-discount',
              ])}
            >
              {isMultiBuy
                ? formatPriceWithCurrency(
                    offer?.promoQuantityDiscountPriceFormatted || '',
                  )
                : formatPriceWithCurrency(currentPrice ?? 0)}{' '}
            </p>
            {isMultiBuy && (
              <p className={styles.multiBuyActualPrice}>
                {formatPriceWithCurrency(currentPrice)} / 1x
              </p>
            )}
          </div>
          {oldPrice && (
            <p className="price-info__old">
              {formatPriceWithCurrency(oldPrice)}
            </p>
          )}
        </div>
      );
    }
  };

  return componentBody();
};

export default observer(ProductPriceLocalee);
