import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import Icon from '~/components/Icon/Icon';

import styles from './Textarea.module.scss';

type Props = {
  placeholder?: string;
  onChange: (val: string) => void;
  value: string;
};

const Textarea = ({ placeholder, onChange, value }: Props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && value) {
      ref.current.textContent = value;
    }
  }, [ref.current]);

  const handleClearButton = () => {
    if (ref.current) {
      onChange('');
      ref.current.focus();
      ref.current.textContent = '';
    }
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.editable}
        contentEditable={true}
        suppressContentEditableWarning={true}
        onInput={(e) => {
          onChange(e.currentTarget.textContent ?? '');
        }}
        ref={ref}
        onFocus={() => setShowPlaceholder(false)}
        onBlur={() => !value && setShowPlaceholder(true)}
        aria-readonly="false"
        role="textbox"
      >
        {showPlaceholder && !value && <span>{placeholder}</span>}
      </div>
      {value && (
        <button className={styles.close} onClick={handleClearButton}>
          <Icon type="burger-close" size={24} />
        </button>
      )}
    </div>
  );
};

export default observer(Textarea);
