import { classes } from 'html-classes';
import { JSX } from 'react';
import { useTranslation } from 'react-i18next';

import { MobileActionDialog } from '~/components/MobileActionDialog';

import { MobileFiltersProps } from './interfaces';
import styles from './MobileFilters.module.scss';

const MobileFilters = (props: MobileFiltersProps): JSX.Element => {
  const { open, onClose, onReset, onApply, className, children, results } =
    props;

  const { t } = useTranslation();

  return (
    <MobileActionDialog
      open={open}
      onClose={onClose}
      className={className}
      title={
        <>
          <h2 className={styles.headerTitle}>{t('filter')}</h2>
          <span className={styles.headerCaption}>
            {results} {t('results')}
          </span>
        </>
      }
      footer={
        <>
          <button type="button" className="button _bordered" onClick={onReset}>
            {t('resetAll')}
          </button>
          <button
            type="button"
            className={classes(['button', '_primary', styles.applyButton])}
            onClick={onApply}
          >
            {t('applyFilters')}
          </button>
        </>
      }
    >
      {children}
    </MobileActionDialog>
  );
};

export default MobileFilters;
