import { CompanyConfig } from '../interface';

// TODO: need update!!!
export const KidsConfig: CompanyConfig = {
  id: 'fca60c82-0022-49e4-aee6-5ec196acd111',
  lang: 'ar',
  langs: ['en', 'ar'],
  name: 'Our Kids',
  currency: 'EGP',
  officialName: 'OurKids',
  email: '',
  feedbackEmail: '',
  phonePlaceholder: '+20',
  defaultPhoneCode: '+20',
  phoneNumber: '16074',
  ageRestricted: 21,
  timeZone: 'Africa/Cairo',
  minimalOrderFeeThreshold: 700,
  address:
    '25 Saint Bride Street, Farringdon Within, Blackfriars, City of London, London, England, GBR',
  canonical: 'https://ourkids.eg',
  warehouse: {
    default: 'OCTOB',
    minimalOrderFeeWarehouseCodes: [],
    location: { lat: 29.991935444877424, lng: 30.966115882359333 },
  },
  map: {
    center: { lat: 29.991935444877424, lng: 30.966115882359333 },
    zoom: 12,
  },
  paymentMethods: [],
  links: {
    cookie: '',
    deliveryZones: '',
    forRiders: '',
    // whatsapp: 'https://wa.me/971501515042',
    facebook: 'https://www.facebook.com/ourkids.eg/',
    instagram: 'https://www.instagram.com/ourkidseg/',
    // tiktok: 'https://www.tiktok.com/@citydrinksuae/',
    // youtube: 'https://www.youtube.com/channel/UCzwC746u4WxzAs0fPOMfSMA/',
    affiliateProgram: import.meta.env.REACT_APP_AFFILIATE_PROGRAM_LINK,
    legals: {
      cookiePolicy: {
        label: 'cookiePolicy',
        link: '/legals#cookie-policy',
      },
      paymentAndDeliveryPolicy: {
        label: 'paymentAndDeliveryPolicy',
        link: '/legals#payment-and-delivery',
      },
      privacyPolicy: {
        label: 'privacyPolicy',
        link: '/legals#privacy-policy',
      },
      salesAndReturnPolicy: {
        label: 'salesAndReturnPolicy',
        link: '/legals#sales-and-returns-policies-and-consumer',
      },
      generalTradingPolicy: {
        label: 'generalTradingPolicy',
        link: '/legals#terms-of-service',
      },
    },
  },
  referral: {
    code: 'SWIFFTUCDAVIS',
    discount: '15%',
    minBasket: '15',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  points: {
    value: '1=1c.',
    pointsPerUnit: 15,
    icon: 'coins',
  },
  appStoreUrl: {
    ios: '',
    android: '',
  },
  apis: {},
  countryCode: 'EG',
  license: {
    name: 'OurKids',
    numberPrinary: '',
  },
};
