import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import { ApplySMSButtonProps } from '../interface';

export default observer((props: PropsWithChildren<ApplySMSButtonProps>) => {
  const { isLoading, isDisabled, children } = props;

  return (
    <button
      className="button _primary _block _med"
      disabled={isDisabled || isLoading}
      type="submit"
    >
      {isLoading && <span className="spinner _white" />}

      {!isLoading && children}
    </button>
  );
});
