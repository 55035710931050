import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { useModal } from '~/hooks/useModal';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import styles from './RateAppAlert.module.scss';

const RateAppAlert = () => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  const noHandler = () => {
    openModal(ModalType.RateApp);
  };

  const yesHandler = () => {
    mainStore.sendToRN('showRatePopup', null, (e) => {
      if (e.answer === 'NotNow') {
        return;
      }
      userStore.setIsRateApp(true);
      mainStore.pushAlert('success', t('rateAppForm:thanksFeedback'));
    });
  };

  return (
    <>
      <div className={styles.root__wr}>
        <div className={styles.root__label}>
          <Icon type="rate-stars-circle" size={40} />
          <div className={styles.root__label__text}>
            <p>{t('phrases:likeCDApp')}</p>
            <span>{t('phrases:likeCDAppCaption')}</span>
          </div>
        </div>
        <div className={styles.root__actions}>
          <button
            type="button"
            className={classes(['button _bordered', styles.root__noButton])}
            onClick={noHandler}
          >
            {t('no')}
          </button>
          <button
            type="button"
            className="button _bordered"
            onClick={yesHandler}
          >
            {t('phrases:yesILike')}
          </button>
        </div>
      </div>
    </>
  );
};

export default observer(RateAppAlert);
