import { isArray } from 'lodash-es';
import { useMemo, useRef } from 'react';

import { BackorderLogic } from '~/api/Settings';
import { company } from '~/company/Company';
import { MAX_COUNT_TO_SHOW, OFFERS_LIMIT } from '~/constants/variables';
import { orderStore } from '~/stores/OrderStore';
import { settingStore } from '~/stores/Settings';
import { userStore } from '~/stores/UserStore';

import type { Offer, Product } from '~/stores/CategoriesStore';

export const useProductLimit = ({
  offer,
}: {
  offer?: Offer | Product | null;
}) => {
  const sellableWhPrev = useRef({ current: 0, parent: 0 });
  const whCode =
    (userStore.deliveryAddress && orderStore.etaCalculation?.warehouse?.code) ||
    company.config.warehouse.default;
  const sellableInWh =
    (offer &&
      offer?.sellableWarehouses &&
      Object.keys(offer.sellableWarehouses)) ??
    [];

  const sellableItemsByWh = useMemo(() => {
    if (sellableInWh.includes(whCode)) {
      const newSellableItems = isArray(sellableInWh)
        ? sellableInWh.reduce(
            (acc, key) => ({
              ...acc,
              [key === whCode ? 'current' : 'parent']:
                (Number(offer?.sellableWarehouses[key]) || 0) <= 0
                  ? 0
                  : offer?.sellableWarehouses[key],
            }),
            { current: 0, parent: 0 },
          )
        : { current: sellableInWh, parent: 0 };
      sellableWhPrev.current = newSellableItems;
      return newSellableItems;
    } else {
      return sellableWhPrev.current;
    }
  }, [sellableInWh, whCode]);

  // posibillity to order more than stock
  const isOriginalLogic =
    settingStore.settings.backorderLogic === BackorderLogic.ORIGINAL;

  if (!offer) {
    return {
      currentWhSellable: 0,
      parentWhSellable: 0,
      limit: 0,
    };
  }

  const isAvailableExpress = orderStore.isExpressAvailableNow;
  const parentExist = isArray(sellableInWh) && sellableInWh.length > 1;

  const getLimitStockOnly = (): number => {
    if (!offer) {
      return 0;
    }

    if (isAvailableExpress) {
      return sellableItemsByWh.current > sellableItemsByWh.parent
        ? sellableItemsByWh.current
        : sellableItemsByWh.parent;
    }

    if (!parentExist) {
      return sellableItemsByWh.current;
    }

    return sellableItemsByWh.parent;
  };

  const getLimitOriginal = (): number => {
    if (!offer) {
      return 0;
    }

    if (offer.isBackorderAvailable) {
      return OFFERS_LIMIT;
    }

    if (offer.promoRequiredQuantity) {
      return Math.min(OFFERS_LIMIT, sellableItemsByWh.current || 0);
    }

    return sellableItemsByWh.current > OFFERS_LIMIT
      ? OFFERS_LIMIT
      : sellableItemsByWh.current;
  };

  const limit = isOriginalLogic
    ? getLimitOriginal()
    : getLimitStockOnly() || offer.sellable;

  const currentWhSellable = sellableItemsByWh.current ?? 0;

  const parrentSellable = parentExist
    ? sellableItemsByWh.parent ?? 0
    : sellableItemsByWh.current ?? 0;

  const parentWhSellable = isOriginalLogic
    ? getLimitOriginal()
    : parrentSellable;

  const laterPrecount = isOriginalLogic
    ? MAX_COUNT_TO_SHOW >= sellableItemsByWh.current
      ? MAX_COUNT_TO_SHOW + 1
      : sellableItemsByWh.current
    : parrentSellable;

  const laterCount =
    MAX_COUNT_TO_SHOW >= laterPrecount
      ? laterPrecount
      : `${MAX_COUNT_TO_SHOW}+`;

  return {
    currentWhSellable,
    parentWhSellable,
    limit,
    laterCount,
  };
};

export const getProductLimit = (offer?: Offer | Product | null) => {
  // posibillity to order more than stock
  const isOriginalLogic =
    settingStore.settings.backorderLogic === BackorderLogic.ORIGINAL;

  if (!offer) {
    return {
      currentWhSellable: 0,
      parentWhSellable: 0,
      limit: 0,
    };
  }

  const isAvailableExpress = orderStore.isExpressAvailableNow;
  const whCode =
    orderStore.etaCalculation?.warehouse?.code ||
    company.config.warehouse.default;
  const sellableInWh =
    offer && offer?.sellableWarehouses && Object.keys(offer.sellableWarehouses);

  const parentExist = isArray(sellableInWh) && sellableInWh.length > 1;

  const sellableItemsByWh = isArray(sellableInWh)
    ? sellableInWh.reduce(
        (acc, key) => ({
          ...acc,
          [key === whCode ? 'current' : 'parent']:
            (Number(offer?.sellableWarehouses[key]) || 0) <= 0
              ? 0
              : offer.sellableWarehouses[key],
        }),
        { current: 0, parent: 0 },
      )
    : { current: sellableInWh, parent: 0 };

  const getLimitStockOnly = (): number => {
    if (!offer) {
      return 0;
    }

    if (isAvailableExpress) {
      return sellableItemsByWh.current > sellableItemsByWh.parent
        ? sellableItemsByWh.current
        : sellableItemsByWh.parent;
    }

    if (!parentExist) {
      return sellableItemsByWh.current;
    }

    return sellableItemsByWh.parent;
  };

  const getLimitOriginal = (): number => {
    if (!offer) {
      return 0;
    }

    if (offer.isBackorderAvailable) {
      return OFFERS_LIMIT;
    }

    if (offer.promoRequiredQuantity) {
      return Math.min(OFFERS_LIMIT, sellableItemsByWh.current || 0);
    }

    return sellableItemsByWh.current > OFFERS_LIMIT
      ? OFFERS_LIMIT
      : sellableItemsByWh.current;
  };

  const limit = isOriginalLogic
    ? getLimitOriginal()
    : getLimitStockOnly() || offer.sellable;

  const currentWhSellable = sellableItemsByWh.current ?? 0;

  const parrentSellable = parentExist
    ? sellableItemsByWh.parent ?? 0
    : sellableItemsByWh.current ?? 0;

  const parentWhSellable = isOriginalLogic
    ? getLimitOriginal()
    : parrentSellable;

  const laterPrecount = isOriginalLogic
    ? MAX_COUNT_TO_SHOW >= sellableItemsByWh.current
      ? MAX_COUNT_TO_SHOW + 1
      : sellableItemsByWh.current
    : parrentSellable;

  const laterCount =
    MAX_COUNT_TO_SHOW >= laterPrecount
      ? laterPrecount
      : `${MAX_COUNT_TO_SHOW}+`;

  return {
    currentWhSellable,
    parentWhSellable,
    limit,
    laterCount,
  };
};
