// immutable splice for arrays
const toSpliced = <T>(
  arr: T[],
  index: number,
  deleteCount: number,
  newItems: T[],
): T[] => {
  const newArr = [...arr];
  newArr.splice(index, deleteCount, ...newItems);
  return newArr;
};

export default toSpliced;
