import { makeObservable, observable, computed, action } from 'mobx';

import { PaginationResponse } from '~/api/Catalog';
import { Task, TaskOptions } from '~/stores/shared/Task';

export interface PaginationResult<T> {
  data: T[];
  pagination?: Nullable<PaginationResponse>;
}

export class Pagination<Data, Args extends unknown[] = []> extends Task<
  Promise<PaginationResult<Data> | undefined>,
  [page: number, loadMore: boolean, ...args: Args]
> {
  private pagination: Nullable<PaginationResponse> = null;
  private data: Data[] = [];

  constructor(
    fn: (
      page: number,
      ...args: Args
    ) => Promise<PaginationResult<Data> | undefined>,
    options: TaskOptions = {},
  ) {
    super(async (page, loadMore, ...args) => {
      const response = await fn(page, ...args);

      if (response) {
        this.setState(
          loadMore
            ? {
                data: this.data.concat(response.data),
                pagination: response.pagination,
              }
            : response,
        );
      }

      return response;
    }, options);

    makeObservable(this, {
      pagination: observable,
      data: observable,
      result: computed,
      currentPage: computed,
      nextPage: computed,
      prevPage: computed,
      total: computed,
      pageTotal: computed,
      canLoadMore: computed,
      loadPage: action,
      debounceLoadPage: action,
      loadMore: action,
      debounceLoadMore: action,
      setState: action,
      reset: action,
    } as any);
  }

  public get result() {
    return this.data;
  }

  public get currentPage() {
    return this.pagination?.current || 0;
  }

  public get nextPage() {
    return this.currentPage + 1;
  }

  public get prevPage() {
    return this.currentPage - 1;
  }

  public get isFirstPage() {
    return this.currentPage < 2;
  }

  public get isLastPage() {
    return this.currentPage >= this.pageTotal;
  }

  public get total() {
    return this.pagination?.total || 0;
  }

  public get pageTotal() {
    return this.pagination ? Math.ceil(this.total / this.pagination.size) : 1;
  }

  public get canLoadMore() {
    if (!this.pagination) {
      return false;
    }

    return this.currentPage * this.pagination.size < this.total;
  }

  public readonly loadPage = (page: number, ...args: Args) => {
    return this.run(page, false, ...args);
  };

  public readonly debounceLoadPage = (page: number, ...args: Args) => {
    this.runDebounce(page, false, ...args);
  };

  public readonly loadMore = (...args: Args) => {
    if (!this.canLoadMore || this.isRunning) {
      return Promise.resolve();
    }

    return this.run(this.currentPage + 1, true, ...args);
  };

  public readonly debounceLoadMore = (...args: Args) => {
    if (!this.canLoadMore || this.isRunning) {
      return;
    }

    this.runDebounce(this.currentPage + 1, true, ...args);
  };

  public readonly setState = ({ data, pagination }: PaginationResult<Data>) => {
    this.data = data;
    this.pagination = pagination || null;
  };

  public readonly reset = () => {
    this.data = [];
    this.pagination = null;
  };
}
