/* eslint-disable react/prop-types */
import { classes } from 'html-classes';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';

import styles from './ContactInformation.module.scss';

interface ContactInformationProps {
  title?: string;
  content?: string;
  email?: string;
  className?: string;
}

const ContactInformation: React.FC<ContactInformationProps> = ({
  title,
  content,
  email,
  className,
}) => {
  return (
    <div className={classes([styles.contactInformation, className])}>
      <h2>{title ?? 'Contact Information'}</h2>
      <p
        dangerouslySetInnerHTML={{
          __html:
            content ??
            `If you have any further questions or concerns, <br /> please don't hesitate to contact us at,`,
        }}
      />
      <a
        href={`mailto:${email ?? company.config.email}`}
        className={styles.contactEmail}
      >
        <Icon type="envelope" size={24} /> {email ?? company.config.email}
      </a>
    </div>
  );
};

export default ContactInformation;
