export const storage = import.meta.env.SSR
  ? (() => {
      const inMemoryStorage = new Map();

      return {
        setItem(key: string, value: string): void {
          inMemoryStorage.set(key, value);
        },
        getItem(key: string): string | null {
          return inMemoryStorage.get(key) ?? null;
        },
        removeItem(key: string): void {
          inMemoryStorage.delete(key);
        },
        get length(): number {
          return inMemoryStorage.size;
        },
        clear(): void {
          inMemoryStorage.clear();
        },
        key: (index: number): string | null =>
          [...inMemoryStorage.keys()][index] || null,
      };
    })()
  : window.localStorage;

const LocalStorage = {
  set: (key: string, value: any) => storage.setItem(key, JSON.stringify(value)),

  get: (key: string) => {
    try {
      const value = storage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch {
      return null;
    }
  },
};

export default LocalStorage;
