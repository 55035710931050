import { classes } from 'html-classes';

import Icon from '../Icon/Icon';

export interface SwiperIconProps {
  onClick: () => void;
  position: 'right' | 'left';
  variant: 'fill' | 'white' | 'transparent';
  className?: string;
  hide?: boolean;
  iconClassName?: string;
}

const SwiperIcon = ({
  onClick,
  position,
  variant,
  className,
  hide,
  iconClassName,
}: SwiperIconProps) => {
  if (hide) {
    return null;
  }

  return (
    <div
      className={classes([
        `button-circle-arrow`,
        `_${position}`,
        `variant-${variant}`,
        className,
      ])}
      onClick={onClick}
    >
      <Icon type="chevron" size={24} className={iconClassName} />
    </div>
  );
};

export default SwiperIcon;
