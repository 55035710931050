import { ENDPOINT } from '~/api/constants';
import { CheckPromocodeResponse } from '~/api/Order';
import { RequestAPI } from '~/api/Requests';

export interface RewardStatusResponse {
  new: number;
  total: number;
}

export interface RewarderPromoCode extends CheckPromocodeResponse {
  is_new: boolean;
}

export const ReferralProgramRequests = {
  getCustomerRefereeCode: (
    customerId: string,
  ): Promise<CheckPromocodeResponse> =>
    RequestAPI.get(ENDPOINT.promocodes.getRefereeCode + customerId),
  getAffiliateCode: (promocode: string): Promise<string> =>
    RequestAPI.get(ENDPOINT.promocodes.getAffiliateCode + promocode),
  getReferralInfo: (): Promise<CheckPromocodeResponse> =>
    RequestAPI.get(ENDPOINT.promocodes.getReferralInfo),
  getCustomerRewards: (customerId: string): Promise<RewarderPromoCode[]> =>
    RequestAPI.get(ENDPOINT.promocodes.getCustomerRewards + customerId),
  getRewardsNotifications: (customerId: string): Promise<number> =>
    RequestAPI.get(ENDPOINT.promocodes.getRewardsNotifications + customerId),
  getRewardStatus: (customerId: string): Promise<RewardStatusResponse> =>
    RequestAPI.get(ENDPOINT.promocodes.getRewardStatus + customerId),
};
