import { classes } from 'html-classes';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon/Icon';
import Text from '../Text';

import styles from './Section.module.scss';

type Props = {
  title?: string | ReactNode | (string | ReactNode)[];
  subtitle?: string | ReactNode | (string | ReactNode)[];
  linkTo?: string;
  linkToElement?: ReactNode;
  linkText?: string;
  className?: string;
  headerClassName?: string;
  children?: ReactNode;
  horizontalHeader?: boolean;
};

const Section = ({
  title,
  subtitle,
  linkTo,
  linkText,
  linkToElement,
  className,
  headerClassName,
  horizontalHeader,
  children,
}: Props) => {
  return (
    <section className={classes(['section', className])}>
      {title && (
        <div className={classes(['section-header', headerClassName])}>
          <div
            className={classes([
              'section-header__text',
              horizontalHeader && 'horizontal-header',
            ])}
          >
            {/* <Text type="p" size="24" className="section-title" isTitle>
              {title}
            </Text> */}
            {title && <h2 className={styles.title}>{title}</h2>}
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            {/* {subtitle && (
              <Text type="div" size="14" className="section-subtitle">
                {subtitle}
              </Text>
            )} */}
          </div>
          {linkToElement
            ? linkToElement
            : linkTo &&
              linkText && (
                <Link className="section-link" to={linkTo}>
                  <Text type="p" size="14" className="section-link">
                    {linkText}
                  </Text>
                  <Icon type="arrow" size={16} />
                </Link>
              )}
        </div>
      )}
      {children}
    </section>
  );
};

export default Section;
