import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { CompaniesMap } from '~/company/companiesMap';
import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';
import { deleteCookie, getCookie, setCookie } from '~/utils/cookies';

import { deliveryAddressStore } from '../ModalDeliveryAddress/DeliveryAddressStore';

import styles from './styles.module.scss';

type CompanyRadioItemData = {
  name: CompanyName;
  original: boolean;
};

const RadioList = ({
  companies,
}: {
  companies: Array<CompanyRadioItemData>;
}) => {
  const [selectedCompany, setSelectedCompany] = useState<{
    original: boolean;
    name: CompanyName;
  } | null>(null);

  useEffect(() => {
    setSelectedCompany({
      original: true,
      name: company.name,
    });
  }, []);

  useEffect(() => {
    if (getCookie('selectedCompany')) {
      setSelectedCompany(getCookie('selectedCompany'));
    }
  }, []);

  const handleSelect = (data: CompanyRadioItemData) => {
    setCookie('selectedCompany', JSON.stringify(data));

    if (
      data.original !== selectedCompany?.original ||
      (data.original && data.name !== selectedCompany.name)
    ) {
      deliveryAddressStore.resetStore();
      checkoutStore.resetStore();
      userStore.resetStore();
      localStorage.clear();
      sessionStorage.clear();
      deleteCookie('warehouse');
    }

    mainStore.sendToRN('changeCompany', data.name);

    setTimeout(() => {
      location.reload();
    }, 1000);
  };

  return (
    <div className={styles.radioList}>
      {companies.map((company, index) => {
        const displayName = `${
          company.name.charAt(0).toUpperCase() + company.name.slice(1)
        } ${company.original ? '' : 'Universal '}`;

        return (
          <label key={index} className={styles.radioLabel}>
            <input
              type="radio"
              name="company"
              value={displayName}
              className={styles.radioInput}
              checked={
                selectedCompany?.name === company.name &&
                selectedCompany.original === company.original
              }
              onChange={() =>
                handleSelect({ name: company.name, original: company.original })
              }
            />
            <span className={styles.displayName}>{displayName}</span>
          </label>
        );
      })}
    </div>
  );
};

const SideBarChooseCompanyCmp = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`${styles.sideMenuContainer} ${isOpen ? styles.open : ''}`}>
      <div className={styles.sideMenu}>
        <button className={styles.menuButton} onClick={toggleMenu}>
          {isOpen ? 'Close' : 'Open'}
        </button>
        <div className={styles.menuContent}>
          <input
            type="checkbox"
            onChange={() =>
              company.setIsActiveRecaptchaDevMode(
                !company.isActiveRecaptchaDevMode,
              )
            }
            id="recaptcha"
            checked={company.isActiveRecaptchaDevMode}
          />
          <label htmlFor="recaptcha">Enabled reCaptcha</label>

          <RadioList companies={CompaniesMap} />
        </div>
      </div>
    </div>
  );
};

export default observer(SideBarChooseCompanyCmp);
