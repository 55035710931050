import { OrderPublicStatus } from '../../api/Order';

export const MAX_LAST_ORDER_LENGTH = 10;

export const ACTIVE_ORDER_STATUS_CRITERIA: OrderPublicStatus[] = [
  'accepted',
  'picking',
  'ready_to_ship',
  'in_delivery',
];
