import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import useDebounce from '~/components/DebounceHook';
import Icon from '~/components/Icon/Icon';
import KYCCheck from '~/components/KYCCheck/KYCCheck';
import MainSidebar from '~/components/MainSidebar/MainSidebar';
import { ModalType } from '~/components/Modal/interface';
import { VERIFICATION } from '~/constants/verification';
import { useModal } from '~/hooks/useModal';
import { useRewardedPromocodesStatus } from '~/hooks/useRewardedPromocodesStatus';
import { PersonalDataVariant1 } from '~/pages/PersonalData/Variant1/PersonalData';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './PersonalData.module.scss';

import './styles.scss';

const PersonalData = observer(() => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const nameVal = useDebounce<string>(checkoutStore.nameVal, 600);

  useEffect(() => {
    updatePersonalData();
  }, [nameVal]);

  const updatePersonalData = async () => {
    const { firstName, middleName, lastName } =
      userStore.splitFullName(nameVal);

    if (firstName) {
      await userStore
        .updatePersonalData({
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          email: checkoutStore.isEmailValid
            ? checkoutStore.emailVal.trim()
            : userStore.personalData.email || '',
        })
        .catch((error) => error && console.error(error));
    }
    checkoutStore.setIsPersonalDataSync(true);
  };

  useEffect(() => {
    checkoutStore.setPhoneVal(userStore.personalData.phone || '');
    checkoutStore.setNameVal(userStore.fullName);
    checkoutStore.setEmailVal(
      userStore.personalData.email || '',
      'PersonalData.useEffect',
    );
    checkoutStore.initStateData();
  }, []);

  const handleChangeEmailModal = () => openModal(ModalType.ChangeEmail);

  const handleChangeNameModal = () => openModal(ModalType.ChangeName);

  const handleChangeNotificationsModal = () =>
    openModal(ModalType.ChangeNotifications);

  const handleDeleteAccountModal = () => openModal(ModalType.DeleteAccount);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setIsPersonalDataChanged(true);
    checkoutStore.setIsPersonalDataSync(false);
    checkoutStore.setNameVal(e.currentTarget.value);
    userStore.setPersonalDataName(e.currentTarget.value);
  };

  const currentVerificationStatus =
    VERIFICATION[userStore.personalData.kycStatus];

  useRewardedPromocodesStatus();

  return (
    <>
      <MobileHeaderLayout
        content={<MobileHeaderTitle text={t('personalData')} />}
      />
      <div className={classes(['content personal-data', styles.content])}>
        <aside className="sidebar">
          <MainSidebar />
        </aside>
        <main className="container">
          <section className={classes(['header', styles.header])}>
            <Icon type="arrow" size={24} className="icon__rotate-180" />
            <div className="title">{t('personalData')}</div>
          </section>

          {company.needVerification && (
            <div
              className={classes([
                'verification',
                currentVerificationStatus?.style,
              ])}
            >
              <div className="verification__header">
                <div className="verification__header-status">
                  {currentVerificationStatus?.icon && (
                    <Icon
                      type={currentVerificationStatus.icon as never}
                      size={24}
                    />
                  )}
                  {currentVerificationStatus?.title}
                </div>
                <div className="verification__header-control">
                  <KYCCheck />
                </div>
              </div>
              {currentVerificationStatus?.description && (
                <div className="verification__body">
                  <p className="verification__body-message">
                    {currentVerificationStatus.description}
                  </p>
                </div>
              )}
            </div>
          )}

          <section className="login-credentials">
            <div className="title">{t('loginCredentials')}</div>
            <div className="subtitle">{t('phrases:editYourDetails')}</div>
            <div className="personal-data-wrapper">
              <div className="block-1">
                <div className="name">
                  <div className="label">{t('name')}</div>
                  <input
                    className="input"
                    value={checkoutStore.nameVal}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="name-mobile" onClick={handleChangeNameModal}>
                  <div className="label-wrapper">
                    <div className="label">{t('name')}</div>
                    <div className="text">{userStore.fullName}</div>
                  </div>
                  <div className="arrow-right">
                    <Icon
                      type="chevron"
                      size={24}
                      className="icon__rotate-270"
                    />
                  </div>
                </div>
              </div>
              <div className="block-2">
                <div className="phone">
                  <div className="label">{t('phoneNumber')}</div>
                  <div className="value">
                    {mainStore.prettyPhoneNumber(checkoutStore.phoneVal || '')}
                  </div>
                </div>
                <div className="phone-mobile">
                  <div className="label-wrapper">
                    <div className="label">{t('phoneNumber')}</div>
                    <div className="value">
                      {mainStore.prettyPhoneNumber(checkoutStore.phoneVal) ||
                        ''}
                    </div>
                  </div>
                </div>
                <div className="email">
                  <div className="label">{t('email')}</div>
                  <div className="content-container">
                    {userStore.email.length > 0 && (
                      <div className="value">{userStore.email}</div>
                    )}
                    <button
                      className="button _bordered _xs"
                      onClick={handleChangeEmailModal}
                    >
                      {t('change')}
                    </button>
                  </div>
                </div>
                <div className="email-mobile" onClick={handleChangeEmailModal}>
                  <div className="label-wrapper">
                    <div className="label">{t('email')}</div>
                    <div className="value">{userStore.email}</div>
                  </div>
                  <div className="arrow-right">
                    <Icon
                      type="chevron"
                      size={24}
                      className="icon__rotate-270"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="notifications-mobile"
            onClick={handleChangeNotificationsModal}
          >
            <div className="label-wrapper">
              <div className="title">{t('notifications')}</div>
              <div className="subtitle">{t('newslettersSettings')}</div>
            </div>
            <div className="arrow-right">
              <Icon type="chevron" size={24} className="icon__rotate-270" />
            </div>
          </section>
          <section className="delete-accaunt">
            <button
              className="button _bordered"
              onClick={handleDeleteAccountModal}
            >
              <Icon type="trash" size={24} />
              <div className="btn">{t('deleteAccount')}</div>
            </button>
          </section>
        </main>
      </div>
    </>
  );
});

export default company.match({
  citydrinks: PersonalData,
  default: PersonalDataVariant1,
});
