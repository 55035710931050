import { classes } from 'html-classes';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, LinkProps } from 'react-router-dom';

import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';

import Icon from '../Icon/Icon';

import styles from './CategoryBlockTitle.module.scss';

interface BlockTitleProps {
  text?: string;
  description?: Nullable<string>;
  backgroundColor?: Nullable<string>;
  className?: string;
  linkTo?: LinkProps['to'];
  categoryId?: number | string;
  hasLink?: boolean;
  hasArrow?: boolean;
}

const CategoryBlockTitle = ({
  text,
  description,
  backgroundColor,
  className,
  linkTo,
  categoryId,
  hasLink,
  hasArrow,
}: BlockTitleProps) => {
  const { handleClickCategory } = useCategoryAnalytics();
  const { t } = useTranslation();

  const showLink = linkTo && (linkTo === '/shop' || hasLink);

  return (
    <div className={classes([styles.container, className])}>
      {!text ? (
        <Skeleton
          className={classes([styles.title])}
          containerClassName={classes([styles.title_container])}
        />
      ) : (
        <>
          {backgroundColor && (
            <div
              className={styles.background}
              style={{ background: backgroundColor }}
            />
          )}
          <h2 className={styles.title}>{text}</h2>
          <p className={styles.description}>{description}</p>
          {showLink && (
            <Link
              onClick={categoryId ? handleClickCategory(categoryId) : undefined}
              to={linkTo}
              className={styles.link}
              preventScrollReset={true}
            >
              {t('viewAll')} {hasArrow && <Icon type="arrow" size={24} />}
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryBlockTitle;
