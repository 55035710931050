import { AxiosError } from 'axios';
import { makeAutoObservable, reaction } from 'mobx';

import { CatalogRequests } from '~/api/Catalog';
import { ApiErrorResponse } from '~/api/Requests';
import { Product } from '~/stores/CategoriesStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { Pagination } from '~/stores/shared/Pagination';
import { Task } from '~/stores/shared/Task';
import { userStore } from '~/stores/UserStore';

const PAGE_SIZE = 48;

class SearchStore {
  private query = '';
  private searchHistory: string[] = [];

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => userStore.deviceId,
      (deviceId) => deviceId && this.updateHistory.run(),
    );
  }

  public get result() {
    return this.paginationRequest.result;
  }

  public get history() {
    return this.searchHistory || [];
  }

  public get queryString() {
    return this.query;
  }

  public readonly paginationRequest = new Pagination(
    async (page: number, query: string) => {
      try {
        this.query = query;

        if (query.length < 3) {
          return;
        }

        const response = await CatalogRequests.getSearch({
          query,
          warehouseCode: orderStore.etaWarehouseCode,
          deviceId: userStore.deviceId,
          page: { size: PAGE_SIZE, current: page },
        });

        this.updateHistory.run();

        mainStore.sendAnalytics(['BI', 'analytics'], {
          name: 'Catalog: searched',
          params: {
            query,
            results_num:
              response.pagination.total || response.data.products.length,
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'search',
          params: {
            search_term: query,
          },
        });

        if (!response.data.products.length) {
          response.pagination = {
            current: this.paginationRequest.pageTotal,
            size: PAGE_SIZE,
            total: this.paginationRequest.total,
          };
        }

        return this.convert(response);
      } catch (error) {
        return mainStore.errorHandler(
          error as AxiosError<ApiErrorResponse>,
          'requestSearch',
        );
      }
    },
    {
      debounce: 300,
    },
  );

  public readonly updateHistory = new Task(async () => {
    if (typeof window === 'undefined') {
      return;
    }

    const { data } = await CatalogRequests.getSearchHistory({
      device_id: userStore.deviceId,
    });

    this.searchHistory = (data || [])
      .filter(({ search_phrase }) => Boolean(search_phrase))
      .map(({ search_phrase }) => search_phrase as string);
  });

  public readonly clearHistory = new Task(async () => {
    this.searchHistory = [];

    await CatalogRequests.deleteSearchHistory({
      device_id: userStore.deviceId,
    });
  });

  public setQuery(query: string) {
    if (!query) {
      return this.reset();
    }

    this.query = query;
  }

  public reset() {
    this.query = '';
    this.paginationRequest.reset();
  }

  private convert({
    data,
    pagination,
  }: Awaited<ReturnType<typeof CatalogRequests.getSearch>>) {
    return {
      pagination,
      data: data.products
        .filter(Product.validate)
        .map((data) => new Product(data)),
    };
  }
}

export const searchStore = new SearchStore();
