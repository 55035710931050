import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import { useGlobal } from '~/hooks/useGlobal';
import { CURRENCY_SYMBOL } from '~/stores/constants';
import { userStore } from '~/stores/UserStore';

export const formatPrice = (
  price: number | string,
  minimumFractionDigits = 2,
): string => {
  const number = +price;

  if (!number) {
    return '0';
  }

  return new Intl.NumberFormat('en', { minimumFractionDigits }).format(number);
};

export const notSSRFormatPriceWithCurrency = (
  price: string | number = 0,
  lang?: string,
  currency?: string,
) => {
  const formattedPrice = formatPrice(price);

  const languageToUse = lang || userStore.lang;

  switch (company.name) {
    case CompanyName.Localee: {
      return new Intl.NumberFormat(languageToUse, {
        style: 'currency',
        currency: currency ?? 'USD',
      }).format(Number(price) ?? 0);
    }
    case CompanyName.CircleK: {
      return new Intl.NumberFormat(languageToUse, {
        style: 'currency',
        currency: currency ?? CURRENCY_SYMBOL.egp,
      }).format(Number(price) ?? 0);
    }
    case CompanyName.Kids: {
      return new Intl.NumberFormat(languageToUse, {
        style: 'currency',
        currency: currency ?? CURRENCY_SYMBOL.egp,
      }).format(Number(price) ?? 0);
    }
    case CompanyName.Vilo: {
      return new Intl.NumberFormat(languageToUse, {
        style: 'currency',
        currency: currency ?? CURRENCY_SYMBOL.idr,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
        .format(Number(price) ?? 0)
        .replaceAll('IDR', 'Rp.');
    }
    case CompanyName.Jiffy: {
      return new Intl.NumberFormat(languageToUse, {
        style: 'currency',
        currency: currency ?? 'GBP',
      }).format(Number(price) ?? 0);
    }
    default:
      return `${formattedPrice} ${CURRENCY_SYMBOL.aed}`;
  }
};

export const formatPriceWithCurrency = (
  price: string | number = 0,
  locale?: string,
  currency?: string,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { serverLang } = useGlobal();
  const languageToUse = import.meta.env.SSR
    ? serverLang
    : locale ?? userStore.lang;

  return notSSRFormatPriceWithCurrency(price, languageToUse, currency);
};

export const toFloat = (number: number | string): number => {
  return parseFloat(number?.toString().replace(',', '.')) || 0;
};

export const isZero = (number: number | string): boolean => {
  return !toFloat(number);
};

const digitsAfterComma = 2;

export const addZeroFraction = (
  number: number | string,
  isTrimTrailingZeros = true,
): string => {
  number = number.toString().replace(',', '.');
  const splitNumber = number.split('.');
  if (!splitNumber[1]) {
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    return isTrimTrailingZeros
      ? splitNumber[0]
      : `${splitNumber[0]}.${''.padEnd(digitsAfterComma, '0')}`;
  }
  if (isTrimTrailingZeros) {
    return parseFloat(number).toString();
  } else {
    return `${splitNumber[0]}.${splitNumber[1].padEnd(digitsAfterComma, '0')}`;
  }
};

export const convertPoundsToPence = (number: number | string): NumberInt => {
  if (number === undefined) {
    return number;
  }
  if (isZero(number)) {
    return 0;
  }
  return parseInt(addZeroFraction(number, false).replace('.', ''), 10);
};

export const convertPenceToPounds = (number: NumberInt | string): string => {
  if (number === undefined) {
    return number;
  }
  if (isZero(number)) {
    return addZeroFraction('0', company.isTrimTrailingZeros);
  }
  number = number.toString();
  const isNegative = number[0] === '-';
  if (isNegative) {
    number = number.slice(1);
  }
  number = number.padStart(digitsAfterComma + 1, '0');
  const letters = number.split('');
  letters.splice(digitsAfterComma * -1, 0, '.');
  return isNegative
    ? '-' + addZeroFraction(letters.join(''), company.isTrimTrailingZeros)
    : addZeroFraction(letters.join(''), company.isTrimTrailingZeros);
};

export const calcDiscountPercentage = (
  price: NumberInt,
  discountPrice: NumberInt,
): NumberInt => {
  if (isZero(price) || isZero(discountPrice)) {
    return 0;
  }
  const discount = 100 - Math.ceil((discountPrice * 100) / price);
  return discount > 0 ? discount : 0;
};
