import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { userStore } from '~/stores/UserStore';
import { getCookieValue, setCookie } from '~/utils/cookies';

const rtlLangs = ['ar', 'he', 'fa', 'ur', 'sd', 'hw', 'ps', 'ug'];

export const useLang = () => {
  const { i18n } = useTranslation();

  const changeLang = (lang: string) => {
    userStore.setLang(lang);
    i18n.changeLanguage(lang);
    userStore.setDir(rtlLangs.includes(lang) ? 'rtl' : 'ltr');
  };

  const setNavigatorLang = () => {
    const browserLang = navigator.language;
    const langGroup = browserLang.split('-')[0] ?? company.config.lang;
    const checkedLang = company.config.langs.includes(langGroup)
      ? langGroup
      : company.config.lang;
    changeLang(checkedLang);
    setCookie('lang', checkedLang, { 'max-age': 2678400 });
  };

  useEffect(() => {
    const cookieLang = getCookieValue('lang');

    if (cookieLang && company.config.langs?.includes(cookieLang)) {
      if (company.config.langs.includes(cookieLang)) {
        changeLang(cookieLang);
      } else {
        setNavigatorLang();
      }
    } else {
      setNavigatorLang();
    }
  }, []);

  const setLanguage = (lang: string) => {
    // changeLang(lang);
    setCookie('lang', lang, { 'max-age': 2678400 });
    window.location.reload();
  };

  return { setLanguage };
};
