import { addHours, isToday } from 'date-fns';
import { format } from 'date-fns-tz';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import Checkbox from '~/components/CheckboxCustom/Checkbox';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import DeliveryDateLabel from '~/components/DeliveryDateLabel/DeliveryDateLabel';
import ExpressDeliveryBadge from '~/components/ExpressDeliveryBadge/ExpressDeliveryBadge';
import Icon from '~/components/Icon/Icon';
import { formatVolume, pluralizePcLabel } from '~/components/ProductCard/utils';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useDateLang } from '~/hooks/useDateLang';
import { useProductLimit } from '~/hooks/useProductLimit';
import { CartItem, CartItemOffer, catalogStore } from '~/stores/CatalogStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { isProductBundle } from '~/types/Product';
import { formatPriceWithCurrency } from '~/utils/formaters';

import Counter from '../../Counter';
import Price from '../../Price';
import Tile from '../../Tile';

import { RestrictionBlock } from '../components/RestrictionBlock/RestrictionBlock';

import styles from './CartCard.module.scss';

interface CartCardProps {
  item: CartItem;
  size?: 's' | 'm' | 'l';
  location?: 'modalCart' | 'productPreview';
  className?: string;
  showCheckbox?: boolean;
}

const CartCard = ({
  item,
  size = 's',
  className,
  showCheckbox,
  location,
}: CartCardProps) => {
  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const isBundle = isProductBundle(item);
  const { locale } = useDateLang();

  const { limit, currentWhSellable, parentWhSellable, laterCount } =
    useProductLimit({
      offer: item,
    });

  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    if (item) {
      catalogStore.setCartItemCountByProduct(
        item,
        count,
        action,
        'product_main',
      );
    }
  };

  const handleResetCount = () => {
    if (item) {
      catalogStore.setCartItemCountByProduct(item, 0, 'remove', 'product_main');
    }
  };

  const isMultiBuy = !isBundle && Boolean(item.promoRequiredQuantity);
  const isMultibuyActivated =
    isMultiBuy && item.count >= item.promoRequiredQuantity;
  const isExpress =
    item &&
    currentWhSellable &&
    currentWhSellable >= item.count &&
    orderStore.isExpressAvailableNow;
  const isBackorderAvailable =
    item.isBackorderAvailable && item.backorderLeadTime && parentWhSellable > 0;

  const volume = isBundle
    ? `${item.bundleQuantity ?? 1}x`
    : formatVolume(item.properties, { defaultVolume: '1x' });

  const leadTime = item.backorderLeadTime ? item.backorderLeadTime : 0;

  const price =
    !isMultiBuy && item.discountPrice ? item.discountPrice : item.price;

  const renderPriceComponent = () => {
    if (isMultiBuy && item.count >= item.promoRequiredQuantity) {
      return (
        <Price
          price={mainStore.convertPenceToPounds(
            item.promoQuantityDiscountPrice * item.count,
          )}
          discount={mainStore.convertPenceToPounds(price * item.count)}
          classNameCurrentPrice={styles['cart-card__info-footer-price']}
          className={styles.priceContainer}
        />
      );
    }

    if (item.discountPrice) {
      return (
        <Price
          price={mainStore.convertPenceToPounds(price * item.count)}
          discount={mainStore.convertPenceToPounds(item.price * item.count)}
          classNameCurrentPrice={styles['cart-card__info-footer-price']}
          className={styles.priceContainer}
        />
      );
    }

    return (
      <Price
        price={mainStore.convertPenceToPounds(price * item.count)}
        classNameCurrentPrice={styles['cart-card__info-footer-price']}
      />
    );
  };

  let selectablePropsList = null;
  if (!isBundle && Object.keys(item.selectableProperties ?? {}).length > 0) {
    selectablePropsList = (
      <ul className={styles.propsList}>
        {Object.values((item as CartItemOffer).selectableProperties).map(
          (item, id) => (
            <li key={id} className={styles.propsListItem}>
              <span className={styles.propsListItemName}>{item.name}:</span>{' '}
              <span className={styles.propsListItemValue}>{item.value}</span>
            </li>
          ),
        )}
      </ul>
    );
  }

  return (
    <div className={styles.container} data-company={company.name}>
      <div
        className={classes([styles['cart-card'], `_${size}`, className])}
        data-company={company.name}
      >
        {showCheckbox && (
          <Checkbox
            onChange={() =>
              item.sku && catalogStore.handleSelectedCartItem(item.sku)
            }
            checked={item.selected}
          />
        )}
        <Link
          onClick={handleClickProduct(item)}
          to={`/p/${item.slug}` + (isBundle ? '' : `?sku=${item.sku}`)}
          state={{ ...(isProductBundle(item) ? {} : { offerSku: item.sku }) }}
        >
          <Tile className={styles.cartCardTile}>
            {/* TODO: express badges */}
            {isExpress ? (
              <ExpressDeliveryBadge
                className={styles.deliveryDateLabel}
                size={location === 'modalCart' ? 'xs' : undefined}
              />
            ) : isBackorderAvailable ? (
              <DeliveryDateLabel
                size={location === 'modalCart' ? 'xs' : undefined}
                className={styles.deliveryDateLabel}
                deliveryDate={
                  isToday(addHours(new Date(), leadTime))
                    ? null
                    : addHours(new Date(), leadTime)
                }
              />
            ) : null}
            <CloudflareResponseImage
              src={item.previewImageThumb || ''}
              srcParams={{ width: 100, height: 140 }}
              srcSetParams={defaultSrcSetParams}
              alt=""
              className={styles.cartCardTileImage}
              loading="lazy"
            />
          </Tile>
        </Link>
        <div
          className={classes([
            styles['cart-card__info'],
            showCheckbox && styles['_row'],
          ])}
        >
          <div className={styles.cartCardInfoHeader}>
            <div className={styles.cartCardLeftBlock}>
              <Link
                onClick={handleClickProduct(item)}
                to={`/p/${item.slug}` + (isBundle ? '' : `?sku=${item.sku}`)}
                state={{
                  ...(isProductBundle(item) ? {} : { offerSku: item.sku }),
                }}
              >
                <p className={styles.itemName}>{item.name}</p>
              </Link>
              {!selectablePropsList && (
                <p
                  className={classes([
                    styles.cartCardInfoUnit,
                    !isMultiBuy && item.discountPrice > 0 && styles.hasDiscount,
                    isMultibuyActivated && styles.hasDiscount,
                  ])}
                >
                  <span
                    className={classes([
                      styles.volume,
                      !isMultiBuy &&
                        item.discountPrice > 0 &&
                        styles.discountedValue,
                      isMultibuyActivated && styles.discountedValue,
                    ])}
                  >
                    {volume}
                  </span>
                  {isBundle && (
                    <div className={styles.specialOffer}>
                      {t('specialOffer')} -{' '}
                      <span className={styles.type}>{t('bundle')}</span>
                    </div>
                  )}
                </p>
              )}
              {selectablePropsList}
              {isMultiBuy && (
                <div className={styles.multiBuySpecialOfferWr}>
                  <span className={styles.multiBuySpecialOffer}>
                    {`${t('phrases:specialOffer')}: `}
                    <span className={styles.multiBuySpecialOffer__bold}>
                      {t('phrases:multiBuySpecialOffer', {
                        cost: formatPriceWithCurrency(
                          item.promoQuantityDiscountPriceFormatted,
                        ),
                        length: pluralizePcLabel(item.promoRequiredQuantity),
                      })}
                    </span>
                  </span>
                </div>
              )}
            </div>
            {!showCheckbox && (
              <button
                className={styles['reset-button']}
                onClick={handleResetCount}
              >
                <Icon type="close" size={24} />
              </button>
            )}
          </div>
          <div
            className={classes([
              styles['cart-card__info-footer'],
              showCheckbox && styles['_column'],
            ])}
          >
            {renderPriceComponent()}
            <div className={styles.counter}>
              <Counter
                count={item.count}
                onClick={handleChangeCount}
                size={size}
                limit={limit}
                customSize={20}
                containerClassName={styles.containerClassName}
                buttonClassName={styles.buttonClassName}
                countClassName={styles.countClassName}
              />
            </div>
          </div>
        </div>
      </div>
      {currentWhSellable > 0 && item.count > currentWhSellable ? (
        <RestrictionBlock
          date={format(addHours(new Date(), leadTime), 'd MMMM', {
            timeZone: company.config.timeZone,
            locale,
          })}
          currentCount={currentWhSellable}
          laterCount={laterCount}
        />
      ) : null}
    </div>
  );
};

export default observer(CartCard);
