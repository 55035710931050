import { v4 as uuid } from 'uuid';

import { DeliverySlot } from '~/api/ETA';

import Delivery from './Delivery';

export type NormalizedDeliveryItems = {
  sku: string;
  slotDeliveryDetails?: {
    scheduleSlotId: string;
    currentDate: number;
  };
  slot?: DeliverySlot;
};

export const groupByDeliverySlots = (
  normalizedDeliveryItems: NormalizedDeliveryItems[],
) =>
  normalizedDeliveryItems
    .reduce((acc, { sku, slotDeliveryDetails, slot }) => {
      if (!slotDeliveryDetails) {
        const expressDelivery = acc.find(
          (delivery) => !delivery.slotDeliveryDetails,
        );
        if (expressDelivery) {
          expressDelivery.itemsIds.push(sku);
          return acc;
        }

        return [{ itemsIds: [sku], id: uuid() }, ...acc];
      }

      const delivery = acc.find(
        (delivery) =>
          delivery.slotDeliveryDetails?.scheduleSlotId ===
            slotDeliveryDetails.scheduleSlotId &&
          delivery.slotDeliveryDetails?.currentDate ===
            slotDeliveryDetails.currentDate,
      );

      if (delivery) {
        delivery.itemsIds.push(sku);
        return acc;
      }

      acc.push({
        id: uuid(),
        itemsIds: [sku],
        slotDeliveryDetails,
        slot,
      });
      return acc;
    }, [] as Delivery[])
    .sort((a, b) => {
      const dateA = a.slotDeliveryDetails?.currentDate;
      const dateB = b.slotDeliveryDetails?.currentDate;

      if (!dateA && !dateB) {
        return 0;
      }

      if (!dateA) {
        return -1;
      }

      if (!dateB) {
        return 1;
      }

      return dateA - dateB;
    });
