import { differenceInDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { JSX, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// import Icon from '../Icon/Icon';
import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';

import { normalizeOrderId } from '../../utils/normalizeOrderId';

import Icon from '../Icon/Icon';

import styles from './ActiveOrderCard.module.scss';
import { STATUS_INDICATORS, DATE_FORMAT, TIME_FORMAT } from './constants';
import { IActiveOrderCardProps } from './interfaces';

const ActiveOrderCard = (props: IActiveOrderCardProps): JSX.Element => {
  const { order, className } = props;
  const { t } = useTranslation();
  const { locale } = useDateLang();

  const normalizedOrderId = normalizeOrderId(order.order_id);

  const estimatedTime = useMemo(() => {
    const estimationDate = new Date(order.sla.deliver_by_max);
    const difference = differenceInDays(new Date(), estimationDate);

    if (!difference) {
      return t('phrases:todayAt', {
        time: format(estimationDate, TIME_FORMAT, {
          timeZone: company.config.timeZone,
          locale,
        }),
      });
    }

    return format(estimationDate, `${DATE_FORMAT} 'at' ${TIME_FORMAT}`, {
      timeZone: company.config.timeZone,
      locale,
    });
  }, [order, t, locale]);

  const progressPercent = useMemo(() => {
    const max = STATUS_INDICATORS.length + 1;
    const activeStatusIndex =
      STATUS_INDICATORS.indexOf(order.public_status) + 1;

    return Math.ceil((activeStatusIndex / max) * 100);
  }, [order]);

  return (
    <Link
      to={`/order/${order.id}`}
      className={classes([styles.deliveringWrapper, className])}
    >
      <div className={styles.blockOne}>
        <div className={styles.leftBlock}>
          <div className={styles.orderTitleWrapper}>
            <div className={styles.title}>
              {t('phrases:orderNum', { number: `#${normalizedOrderId}` })}
            </div>
            <div className={styles.track}>
              {STATUS_INDICATORS.map((status, idx) => {
                const isLast = idx >= STATUS_INDICATORS.length - 1;
                const orderStatusIndex = STATUS_INDICATORS.indexOf(
                  order.public_status,
                );

                return (
                  <div
                    key={status}
                    className={classes([
                      styles.point,
                      {
                        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                        [styles.passed]: idx > orderStatusIndex,
                        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
                        [styles.active]: status === order.public_status,
                      },
                    ])}
                  >
                    <div className={styles.icon} />
                    {!isLast && <div className={styles.line} />}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.delivering}>
            {t(`orderStatus:${order.public_status}.value`)}
          </div>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.estimateWrapper}>
            <div className={styles.text}>
              <span>{t('phrases:estTimeOfDelivery')}</span>
              <span className={styles.short}>
                {t('phrases:estTimeOfDeliveryShort')}
              </span>
            </div>
            <div className={styles.time}>{estimatedTime}</div>
          </div>
        </div>
      </div>

      <div className={styles.blockTwo}>
        <div
          className={styles.progress}
          style={{ width: `${progressPercent}%` }}
        />
      </div>

      <Link to="/cabinet/referral" className={styles.inviteWrapper}>
        <div className={styles.referWrapper}>
          <Icon type="refferal" size={16} />
          <div className={styles.text}>{t('phrases:referFriend')}</div>
        </div>
      </Link>
    </Link>
  );
};

export default observer(ActiveOrderCard);
