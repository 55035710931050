export enum ROUTES {
  index = '/',
  catalog = '/c/:subCat2Id',
  cart = '/cart',
  favs = '/favs',
  checkout = '/checkout',
  shop = '/shop',
  product = '/p/:productId',
  order = '/order/:orderId',
  cabinet = '/cabinet',
  cabinet_orders = 'orders',
  cabinet_orders_tab = ':tab',
  cabinet_personalData = 'personal-data',
  cabinet_address = 'address',
  cabinet_events = 'events',
  cabinet_subscriptions = 'subscriptions',
  cabinet_paymentMethod = 'payment-method',
  cabinet_referral = 'referral',
  cabinet_referral_rewards = 'referral/rewards',
  ourStory = '/our-story',
  search = '/search',
  legals = '/legals',
  contactUs = '/contact-us',
  icons = '/icons',
  faq = '/faq',
  careers = '/careers',
  brand = '/brand',
  influencers = '/influencers',
  refund = '/refund',
  shippingReturn = '/shipping-return',
  any = '*',
}
