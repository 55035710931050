import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';

import styles from './CashPayment.module.scss';

const banknotes = [100, 200, 500, 1000];

export const CashPayment: FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => checkoutStore.setChangeFromAmount(null);
  }, []);

  if (DEFAULT_COMPANIES.includes(company.name)) {
    return null;
  }

  return (
    <div className={styles.cashPayment}>
      <h3 className={styles.title}>{t('cashOrderTitle')}</h3>
      <p className={styles.subtitle}>{t('cashOrderDescription')}</p>
      <div className={styles.checkbox}>
        <label className={classes(['control__checkbox', styles.checkbox])}>
          <input
            type="checkbox"
            className="control__checkbox-input"
            checked={checkoutStore.hasExactAmount}
            onChange={() =>
              checkoutStore.setHasExactAmount(!checkoutStore.hasExactAmount)
            }
          />
          <span className="control__checkbox-mark" />
          {t('cashOrderExactAmount')}
        </label>
      </div>
      {!checkoutStore.hasExactAmount && (
        <>
          <p className={styles.subtitle}>{t('cashOrderSelectBanknote')}</p>
          <div className={styles.buttons}>
            {banknotes.map((value) => (
              <button
                key={value}
                className={classes([
                  'button',
                  styles.button,
                  checkoutStore.changeFromAmount === value && styles.selected,
                ])}
                onClick={() => checkoutStore.setChangeFromAmount(value)}
              >
                {value} {company.currency}
              </button>
            ))}
          </div>
          <p className={styles.subtitle}>{t('cashOrderEnterAmount')}</p>
          <label className={styles.inputField}>
            <input
              type="number"
              placeholder={t('enterYourAmount')}
              className={styles.input}
              value={checkoutStore.changeFromAmount || undefined}
              onChange={(event) =>
                checkoutStore.setChangeFromAmount(event.target.valueAsNumber)
              }
            />
            {company.currency}
          </label>
        </>
      )}
    </div>
  );
});
