import { useState } from 'react';

interface Props {
  id: string;
  label: string;
  isChecked?: boolean;
  onChange: (isChecked: boolean) => void;
}

const ProductBadge = ({ label, id, isChecked, onChange }: Props) => {
  const [checked, setIsChecked] = useState(isChecked);

  return (
    <div className="badge__container">
      <input
        type="checkbox"
        onChange={() => {
          setIsChecked(!checked);
          onChange(!checked);
        }}
        id={id}
        checked={checked}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default ProductBadge;
