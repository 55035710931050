import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { company } from '~/company/Company';
import styles from '~/pages/Checkout/CheckoutContent.module.scss';
import { DeliveryItem } from '~/pages/Checkout/CheckoutSkeleton';
import { PickUpProductList } from '~/pages/Checkout/components/PickUpProductList';
import Textarea from '~/pages/Checkout/components/Textarea/Textarea';
import DeliverAllGoodsCheckbox from '~/pages/Checkout/DeliverAllGoodsCheckbox';
import DeliveryDesktop from '~/pages/Checkout/DeliveryDesktop';
import DeliveryInstructions from '~/pages/Checkout/DeliveryInstructions';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';

import { ActiveDeliveryMethodProductListProps } from './interfaces';

const ActiveDeliveryMethodProductList = ({
  isDeliveryMethodSelectionAvailable,
  hasExpressDelivery,
  handleSelectDateModal,
  handleCommentChange,
}: ActiveDeliveryMethodProductListProps) => {
  const { t } = useTranslation();

  if (checkoutStore.deliveryMethodChangeMeta.isLoading) {
    return (
      <div className={styles.panel} data-company={company.name}>
        <div className={styles.panelSection}>
          <DeliveryItem />
        </div>
      </div>
    );
  }

  switch (checkoutStore.deliveryMethod) {
    default:
    case ETADeliveryMethodType.JiffyDelivery:
      return (
        <div className={styles.panel}>
          <div
            className={classes([
              styles.panelSection,
              styles.deliverySlotsTitle,
            ])}
          >
            <p
              className={classes([
                styles.panelTitle,
                styles.h2,
                isDeliveryMethodSelectionAvailable && styles.productListTitle,
              ])}
            >
              {t('deliverySlots')}
            </p>
            {checkoutStore.isMultipleDeliveriesAvailable && (
              <DeliverAllGoodsCheckbox
                checked={
                  company.combineItemsByDefaut
                    ? !checkoutStore.isDeliverTogether
                    : checkoutStore.isDeliverTogether
                }
                onChange={() => checkoutStore.switchDeliverTogether()}
                className={styles.deliverySlotsTitleCheckbox}
              />
            )}
          </div>
          <div
            className={classes([
              'panel-section',
              styles.deliverySlotsContainer,
            ])}
          >
            <ul className="slot-list">
              {checkoutStore.deliveries?.map((delivery, index) => {
                const key = delivery.slotDeliveryDetails
                  ? delivery.slotDeliveryDetails?.scheduleSlotId +
                    delivery.slotDeliveryDetails?.currentDate
                  : 'express';

                const order = catalogStore.deliveriesOrders[index];

                if (!order) {
                  return <Fragment key={key} />;
                }

                return (
                  <DeliveryDesktop
                    slotNumber={hasExpressDelivery ? index : index + 1}
                    delivery={delivery}
                    key={key}
                    order={order}
                    handleSelectDateModal={() =>
                      handleSelectDateModal?.(index, order)
                    }
                  />
                );
              })}
            </ul>
          </div>
          <div className="panel-section comment">
            <div className="panel-section__content-comment">
              <div className="panel-section__content-comment-input">
                <Textarea
                  placeholder={t('comments')}
                  onChange={handleCommentChange}
                  value={checkoutStore.commentVal}
                />
              </div>
              <DeliveryInstructions />
            </div>
          </div>
        </div>
      );
    case ETADeliveryMethodType.ClickAndCollect: {
      return (
        <div className={styles.panel}>
          <div
            className={classes([
              styles.panelSection,
              styles.deliverySlotsTitle,
            ])}
          >
            <p
              className={classes([
                styles.panelTitle,
                styles.h2,
                styles.productListTitle,
              ])}
            >
              {t('deliveryMethods:pickup')}
            </p>
          </div>
          <div className="panel-section">
            <PickUpProductList />
          </div>
          <div className="panel-section comment">
            <div className="panel-section__content-comment">
              <div className="panel-section__content-comment-input">
                <Textarea
                  placeholder={t('clickCollectComment')}
                  onChange={handleCommentChange}
                  value={checkoutStore.commentVal}
                />
              </div>
              <DeliveryInstructions />
            </div>
          </div>
        </div>
      );
    }
  }
};

export default observer(ActiveDeliveryMethodProductList);
