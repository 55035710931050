import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';

import styles from './BannerProductBlock.module.scss';

import './styles.scss';

const BannerProductBlockSkeleton = () => {
  return (
    <div className="content-layout">
      <div
        className={classes([
          styles.container,
          'banner-product-block banner-carousel carousel',
        ])}
      >
        <Skeleton
          className={classes([styles.slide1, styles.skeleton])}
          containerClassName={classes([styles.slide1, styles.skeleton])}
        />
      </div>
    </div>
  );
};

export default BannerProductBlockSkeleton;
