export const DELIVERY_ZONES_STYLE: Record<
  number,
  Record<'fillOpacity' | 'strokeOpacity', number>
> = {
  1: {
    fillOpacity: 0.1,
    strokeOpacity: 0.45,
  },
  2: {
    fillOpacity: 0.15,
    strokeOpacity: 0.72,
  },
  3: {
    fillOpacity: 0.1,
    strokeOpacity: 0.38,
  },
  100: {
    fillOpacity: 0.1,
    strokeOpacity: 0.38,
  },
};
