import { format } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';

interface Props {
  createdAt: string;
}

const OrderDateText = ({ createdAt }: Props) => {
  const { locale } = useDateLang();
  const { t } = useTranslation();

  return (
    <>
      {t('dayAtTime', {
        day: format(new Date(createdAt), 'MMMM d', {
          timeZone: company.config.timeZone,
          locale,
        }),
        time: format(new Date(createdAt), 'h:mm aaa', {
          timeZone: company.config.timeZone,
          locale,
        }),
      })}
    </>
  );
};

export default OrderDateText;
