import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import React from 'react';

import { IStory, IStorySlideButton, StoriesRequest } from '../api/Stories';

import { storage as localStorage } from './LocalStorage';
import { orderStore } from './OrderStore';

type StoryState = {
  isViewed: boolean;
  isOpened: boolean;
  timestamp: number;
};

class StoriesStore {
  stories: IStory[] = [];
  storiesData: Record<string, StoryState> = {};

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'StoriesStore',
      properties: ['stories', 'storiesData'],
      storage: localStorage,
    }).catch((error) => error && console.error(error));
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  getStoryById(storyId: string): IStory | null {
    return this.stories.find((story) => story.id === storyId) || null;
  }

  isStoryViewed(storyId: string): boolean {
    return this.storiesData[storyId]?.isViewed || false;
  }

  isStoryOpened(storyId: string): boolean {
    return this.storiesData[storyId]?.isOpened || false;
  }

  getStylesForButton(btn: IStorySlideButton): React.CSSProperties {
    return {
      backgroundColor: btn.backgroundColor,
      color: btn.color,
    };
  }

  getNextStoryId(storyId: string): string | null {
    if (this.stories.length <= 1) {
      return null;
    }
    for (let i = 0; i < this.stories.length - 1; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (this.stories[i].id === storyId) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        return this.stories[i + 1].id;
      }
    }
    return null;
  }

  getPreviousStoryId(storyId: string): string | null {
    if (this.stories.length <= 1) {
      return null;
    }
    for (let i = 1; i < this.stories.length; i++) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      if (this.stories[i].id === storyId) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        return this.stories[i - 1].id;
      }
    }
    return null;
  }

  // Setters
  setStoryViewed(storyId: string) {
    if (!this.storiesData[storyId]) {
      this.storiesData[storyId] = {
        isViewed: true,
        isOpened: false,
        timestamp: Date.now(),
      };
    } else {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      this.storiesData[storyId].isViewed = true;
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      this.storiesData[storyId].timestamp = Date.now();
    }
  }

  setStoryOpened(storyId: string) {
    if (!this.storiesData[storyId]) {
      this.storiesData[storyId] = {
        isViewed: false,
        isOpened: true,
        timestamp: Date.now(),
      };
    } else {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      this.storiesData[storyId].isOpened = true;
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      this.storiesData[storyId].timestamp = Date.now();
    }
  }

  // Actions
  async requestStories(): Promise<void> {
    if (!orderStore.etaCalculation?.warehouse.code) {
      return Promise.reject();
    }
    try {
      const stories = await StoriesRequest.getAllStories(
        orderStore.etaCalculation?.warehouse.code,
      );
      const notViewedStories: IStory[] = [];
      const viewedStories: IStory[] = [];
      for (let i = 0; i < stories.length; i++) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        if (this.storiesData[stories[i].id]?.isViewed) {
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          viewedStories.push(stories[i]);
        } else {
          // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
          notViewedStories.push(stories[i]);
        }
      }
      runInAction(() => {
        this.stories = notViewedStories.concat(viewedStories);
      });
    } catch (error) {
      error && console.error(error);
    }
  }
}

export const storiesStore = new StoriesStore();
