import { classes } from 'html-classes';
import { ChangeEvent } from 'react';

import { ProductPropertyFilterChoice } from '../../types/Product/interface';

import Collapsible from '../Collapsible/Collapsible';

interface Props {
  label: string;
  values?: ProductPropertyFilterChoice[];
  currentValues?: string[];
  onChange: (values: string[]) => void;
  className?: string;
  isCollapsed: boolean;
  type?: string;
}

const ProductMultiselectFilter = ({
  label,
  values = [],
  currentValues = [],
  onChange,
  className,
  isCollapsed,
  type,
}: Props) => {
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const isChecked = event.target.checked;

    const newSelected = isChecked
      ? [...currentValues, value]
      : currentValues.filter((s) => s !== value);

    onChange(newSelected);
  };

  return (
    <Collapsible
      isCollapsed={!currentValues.length && isCollapsed}
      title={label}
      className={classes(['filter__item', className])}
    >
      {values.map((value, idx) => (
        <label key={`${value.value}_${idx}`} className="control__checkbox">
          <input
            type="checkbox"
            checked={currentValues.includes(value.value)}
            value={value.value}
            className="control__checkbox-input"
            onChange={(e) => onChangeValue(e)}
          />
          <span className="control__checkbox-mark" />
          <div className="control__label_ontainer">
            {type === 'colorList' && (
              <div
                style={{ backgroundColor: value.value }}
                className="control__color"
              />
            )}
            {value.label}
          </div>
        </label>
      ))}
    </Collapsible>
  );
};

export default ProductMultiselectFilter;
