import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import Counter from '~/components/Counter';
import Price from '~/components/Price';
import { pluralizePcLabel } from '~/components/ProductCard/utils';
import { Offer } from '~/stores/CategoriesStore';
import { formatPrice } from '~/utils/formaters';

import '../styles.scss';
import styles from '../Product.module.scss';

type Props = {
  offer?: Offer;
  isMultiBuy?: boolean;
  currentPrice?: string;
  oldPrice: string | null;
  count: number;
  limit: number;

  handleChangeCount: (count: number, action: 'add' | 'remove') => void;
};

const MobileBottomContainerLocalee = forwardRef<HTMLDivElement, Props>(
  (
    {
      offer,
      isMultiBuy,
      currentPrice,
      oldPrice,
      count,
      limit,
      handleChangeCount,
    },
    ref,
  ) => {
    const { t } = useTranslation();

    if (!offer) {
      return null;
    }

    const componentBody = () => {
      if (DEFAULT_COMPANIES.includes(company.name)) {
        return (
          <div
            className="mobile-bottom-button-container _h"
            data-company={company.name}
          >
            {count > 0 ? (
              <Counter
                size="m"
                count={count}
                onClick={handleChangeCount}
                limit={limit}
              />
            ) : (
              <button
                type="button"
                className={classes([
                  'button _primary _med',
                  limit === 0 && '_disabled',
                ])}
                onClick={() => handleChangeCount(count + 1, 'add')}
              >
                {limit > 0 ? t('addToCart') : t('soldOut')}
              </button>
            )}
          </div>
        );
      } else {
        return (
          <div
            ref={ref}
            className="mobile-bottom-button-container _h"
            data-company={company.name}
          >
            <div>
              {isMultiBuy && (
                <div className={styles.multiBuyFromRule}>
                  {t('phrases:fromPc', {
                    length: pluralizePcLabel(offer.promoRequiredQuantity),
                  })}
                </div>
              )}
              <Price
                price={
                  isMultiBuy
                    ? offer.promoQuantityDiscountPriceFormatted
                    : currentPrice ?? ''
                }
                discount={isMultiBuy ? '' : oldPrice ?? ''}
                classNameCurrentPrice={
                  isMultiBuy ? styles.multiBuyMobileCurrentPrice : undefined
                }
              />
              {isMultiBuy && (
                <div className={styles.multiBuyActualPrice}>
                  {formatPrice(currentPrice ?? 0)} AED&nbsp;/&nbsp;1x
                </div>
              )}
            </div>
            {count > 0 ? (
              <Counter
                size="m"
                count={count}
                onClick={handleChangeCount}
                limit={limit}
              />
            ) : (
              <button
                type="button"
                className={classes([
                  'button _primary _med',
                  limit === 0 && '_disabled',
                ])}
                onClick={() => handleChangeCount(count + 1, 'add')}
              >
                {limit > 0 ? t('addToCart') : t('soldOut')}
              </button>
            )}
          </div>
        );
      }
    };

    return componentBody();
  },
);

MobileBottomContainerLocalee.displayName = 'MobileBottomContainerLocalee';

export default observer(MobileBottomContainerLocalee);
