export const copyWith = <
  T extends object,
  D extends Record<PropertyKey, unknown>,
>(
  target: T,
  data: D,
): T & D => {
  return Object.assign(
    Object.create(Object.getPrototypeOf(target)),
    target,
    data,
  );
};
