import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';

import { OrderQrCodeProps } from './interfaces';
import styles from './OrderQrCode.module.scss';

const SMALL_SIZE = 146;
const FULLSCREEN_SIZE = 268.8;

const OrderQrCode = ({ className, orderNumber }: OrderQrCodeProps) => {
  const { t } = useTranslation();

  const { isSSR } = useGlobal();
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const toggleFullscreen = () => setIsFullscreen((prev) => !prev);

  useEffect(() => {
    const removeClass = () => {
      document.body.classList.remove(`${styles.noScroll}`);
    };

    removeClass();
    if (isFullscreen) {
      document.body.classList.add(`${styles.noScroll}`);
    }

    return () => {
      removeClass();
    };
  }, [isFullscreen]);

  const component = (
    <div
      className={classes([
        styles.root,
        className,
        isFullscreen && styles.fullscreen,
      ])}
    >
      {isFullscreen && (
        <div className={styles.closeWr}>
          <span
            role="button"
            className={styles.close}
            onClick={toggleFullscreen}
          >
            <Icon type="plus" className="icon__rotate-45" size={24} />
          </span>
        </div>
      )}
      <div className={styles.qrWr}>
        <div className={classes([styles.qr, isFullscreen && styles.qr__fs])}>
          <QRCode
            role="img"
            value={orderNumber}
            size={isFullscreen ? FULLSCREEN_SIZE : SMALL_SIZE}
          />
        </div>
        {isFullscreen && <p>{t('deliveryMethods:showInTheStore')}</p>}
      </div>
      <div className={styles.action}>
        {!isFullscreen && (
          <div className={styles.label}>
            <p>{t('deliveryMethods:showInTheStore')}</p>
          </div>
        )}
        <div className={styles.buttonWr}>
          <button
            type="button"
            className={classes([
              'button _bordered',
              isFullscreen && styles.closeExpandBtn,
              !isFullscreen && styles.expandBtn,
            ])}
            onClick={toggleFullscreen}
          >
            {isFullscreen ? t('close') : <Icon type="expand" size={24} />}
          </button>
        </div>
      </div>
    </div>
  );

  if (isFullscreen && !isSSR) {
    return createPortal(
      <div className={styles.orderQrCodePortal} data-company={company.name}>
        {component}
      </div>,
      document.body,
    );
  }
  return component;
};

export default observer(OrderQrCode);
