import Skeleton from 'react-loading-skeleton';

import styles from './CategorySkeleton.module.scss';

const CategorySkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton className={styles.image} />
      <div className={styles.content}>
        <Skeleton className={styles.title} />
        <Skeleton className={styles.subtitle} />
      </div>
    </div>
  );
};

export default CategorySkeleton;
