import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';

import SkeletonListRows from '../Skeleton/SkeletonList';

const CollapsibleTextSkeleton = () => {
  return (
    <div className={classes(['collapsible-text', 'clamp'])}>
      <div className="collapsible-text__container">
        <SkeletonListRows rows={12}>
          <Skeleton />
        </SkeletonListRows>
      </div>
      <div className="collapsible-text__controls">
        <Skeleton
          className="button _bordered _s collapsible-text__button"
          width="75px"
        />
      </div>
    </div>
  );
};

export default CollapsibleTextSkeleton;
