import { ProductPropertiesKeyAPI } from '~/api/Catalog';
import { ProductState } from '~/stores/CategoriesStore';
import { ProductPropertiesValue } from '~/types/Product/interface';

import { getProductLimit } from './useProductLimit';

export const useProductChoices = ({ product }: { product?: ProductState }) => {
  if (!product) {
    return {};
  }

  const productFilters = product?.selectedOffer?.selectableProperties;
  const selectedProperties = Object.values(
    product?.selectedOffer?.selectableProperties ?? {},
  ).reduce(
    (
      acc: Record<string, string | number | boolean | string[]>,
      item: ProductPropertiesValue,
    ) => {
      acc[item.code] = item.value;
      return acc;
    },
    {},
  );

  const currentProductFilters = Object.fromEntries(
    Object.entries(productFilters ?? {}).map(([key, value]) => {
      const choices = value.choices.map((choice) => {
        let sku = '',
          sellable = false,
          selected = false;

        if (
          choice.label ===
          product?.selectedOffer?.selectableProperties[
            key as ProductPropertiesKeyAPI
          ]?.value
        ) {
          selected = true;
        }

        for (const offer of product?.data?.offers ?? []) {
          if (
            choice.label ===
            offer.selectableProperties[key as ProductPropertiesKeyAPI]?.value
          ) {
            let ok = true;
            Object.entries(selectedProperties).forEach(
              ([selectedKey, selectedValue]) => {
                if (selectedKey !== key) {
                  if (
                    selectedValue !==
                    offer.selectableProperties[
                      selectedKey as ProductPropertiesKeyAPI
                    ]?.value
                  ) {
                    ok = false;
                  }
                }
              },
            );

            if (ok) {
              sku = offer.sku;
              const { limit } = getProductLimit(offer);

              if (limit > 0) {
                sellable = true;
              }
            }
          }
        }

        return { ...choice, sku, sellable, selected };
      });

      return [key, { ...value, choices }];
    }),
  );

  return currentProductFilters;
};
