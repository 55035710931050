import { classes } from 'html-classes';
import { debounce } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { company } from '~/company/Company';
import { getCloudflareUrl } from '~/components/CloudflareResponsiveImage/utils';
import SwiperIcon from '~/components/Swiper/SwiperIcon';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { Category } from '~/stores/CategoriesStore';
import { userStore } from '~/stores/UserStore';

import { BREAKPOINTS } from '../../../breakpoints';

import { SwiperWithSlidesPerView } from '../BannerProductBlock/interfaces';
import CategoryBlockTitle from '../CategoryBlockTitle';
import { createCategoryUrl } from '../utils';

import styles from './CategoriesCarouselSliderThreeWBlock.module.scss';

interface CategoriesCarouselSliderThreeWBlockProps {
  category: Category;
}

const CategoriesCarouselSliderThreeWBlock = ({
  category,
}: CategoriesCarouselSliderThreeWBlockProps) => {
  const { handleClickCategory } = useCategoryAnalytics();
  const { isMobile } = useGlobal();
  const [slidesPerView, setSlidesPerView] = useState(1);
  const [, setActiveSwiperIndex] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const swiperRef = useRef<SwiperRef>(null);

  const initializeSwiperArrowsDebouncedRef = useRef(
    debounce((swiper: SwiperWithSlidesPerView) => {
      if (!swiper.slides.length) {
        setSlidesPerView(1);
        setIsBeginning(false);
        setIsEnd(false);
        return;
      }

      const slidesPerView = swiper.slidesPerViewDynamic?.() ?? 1;

      setSlidesPerView(slidesPerView);
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    }, 100),
  );

  const isArrowsAllowed = () => {
    const swiper = swiperRef.current?.swiper;

    if (!swiper) {
      return false;
    }

    return !isMobile && swiper.slides.length > slidesPerView;
  };

  const isPrevArrowVisible = () => isArrowsAllowed() && !isBeginning;

  const isNextArrowVisible = () => isArrowsAllowed() && !isEnd;

  const cfSrcForImage = (src: string) =>
    getCloudflareUrl(
      src,
      company.envVariables.REACT_APP_CLOUDFLARE_IMAGE_RESIZE_DOMAIN,
      {
        width: 285,
        height: 380,
      },
    );

  return (
    <div className={classes([styles.container])}>
      <CategoryBlockTitle
        text={category.name}
        className={styles.title}
        categoryId={category.id}
        linkTo={createCategoryUrl({ id: category.id })}
      />
      <div
        className={classes([
          styles.desktopContainer,
          'banner-carousel carousel',
        ])}
      >
        {isPrevArrowVisible() && (
          <SwiperIcon
            position="left"
            variant="transparent"
            onClick={() => {
              swiperRef.current?.swiper.slidePrev();
              setIsEnd(false);
              if (swiperRef.current?.swiper.isBeginning) {
                setIsBeginning(true);
              }
            }}
          />
        )}
        <Swiper
          className={styles.items}
          spaceBetween={8}
          slidesPerView="auto"
          slidesPerGroupAuto
          ref={swiperRef}
          breakpoints={{
            [BREAKPOINTS.phone]: {
              spaceBetween: 20,
            },
          }}
          onSlideChange={(swiper) => setActiveSwiperIndex(swiper.activeIndex)}
          onSlidesLengthChange={initializeSwiperArrowsDebouncedRef.current}
          dir={userStore.dir}
          key={userStore.dir}
        >
          {category.subcategory.map(
            ({ name, previewImage, id, previewText }) => (
              <SwiperSlide
                key={name}
                className={styles.item}
                style={{
                  backgroundImage: `url('${encodeURI(
                    cfSrcForImage(previewImage || ''),
                  )}')`,
                }}
              >
                <Link
                  onClick={handleClickCategory(id)}
                  to={createCategoryUrl({ id })}
                  className={styles.link}
                >
                  <div className={styles.itemTitle}>{name}</div>
                  <div
                    className={styles.itemText}
                    dangerouslySetInnerHTML={{
                      __html: previewText,
                    }}
                  />
                </Link>
              </SwiperSlide>
            ),
          )}
        </Swiper>
        {isNextArrowVisible() && (
          <SwiperIcon
            position="right"
            variant="transparent"
            onClick={() => {
              swiperRef.current?.swiper.slideNext();
              setIsBeginning(false);
              if (swiperRef.current?.swiper.isEnd) {
                setIsEnd(true);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default observer(CategoriesCarouselSliderThreeWBlock);
