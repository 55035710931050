import { PropsWithChildren, useState } from 'react';
import { Thumbs, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import { company } from '~/company/Company';
import { SwiperWithSlidesPerView } from '~/components/CategoryBlocks/BannerProductBlock/interfaces';
import SwiperIcon from '~/components/Swiper/SwiperIcon';
import { userStore } from '~/stores/UserStore';

import { SliderThumbsProps } from './interfaces';
import styles from './ProductImageSlider.module.scss';

const SLIDES_PER_VIEW = 5;

const SliderThumbs = (
  props: PropsWithChildren<SliderThumbsProps> & { isImageFullscreen: boolean },
) => {
  const { swiper, onSwiper, children, isImageFullscreen } = props;
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(true);

  const updateArrowVisibleStatus = (swiper: SwiperWithSlidesPerView) => {
    setIsEnd(swiper.isEnd);
    setIsBeginning(swiper.isBeginning);
  };

  const getIsArrowsVisible = () => {
    if (!swiper) {
      return false;
    }

    return swiper.slides.length > SLIDES_PER_VIEW;
  };

  const isPrevArrowVisible = () => getIsArrowsVisible() && !isBeginning;

  const isNextArrowVisible = () => getIsArrowsVisible() && !isEnd;

  return (
    <div className={styles.thumbsWrapper} data-company={company.name}>
      {isPrevArrowVisible() && (
        <SwiperIcon
          position="left"
          variant="white"
          className={styles.leftControl}
          onClick={() => {
            if (!swiper) {
              return;
            }

            swiper.slidePrev();
          }}
        />
      )}
      <Swiper
        modules={[Thumbs, Navigation]}
        watchSlidesProgress
        slidesPerView={SLIDES_PER_VIEW}
        spaceBetween={8}
        className={styles.sliderThumbs}
        onSlidesLengthChange={updateArrowVisibleStatus}
        onClick={updateArrowVisibleStatus}
        onSlideChangeTransitionEnd={updateArrowVisibleStatus}
        onSwiper={onSwiper}
        direction={
          company.isVerticalSwiperPageDesktop && !isImageFullscreen
            ? 'vertical'
            : 'horizontal'
        }
        dir={userStore.dir}
        key={userStore.dir}
      >
        {children}
      </Swiper>
      {isNextArrowVisible() && (
        <SwiperIcon
          position="right"
          variant="white"
          className={styles.rightControl}
          onClick={() => {
            if (!swiper) {
              return;
            }

            swiper.slideNext();
          }}
        />
      )}
    </div>
  );
};

export default SliderThumbs;
