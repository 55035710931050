const sec = 1_000; // ms
const min = 60 * sec;
const hour = 60 * min;

export const PICKUP_ALCOHOL_COUPON_CODE = 'COLLECT';

export const CURRENCY_SYMBOL: Record<string, string> = {
  gbp: '£',
  usd: '$',
  kwd: 'KD ',
  aed: 'AED',
  egp: 'EGP',
  idr: 'IDR',
};

export const PURCHASED_ITEMS_COUNT = 20;
export const REQUEST_STRIPE_CONFIRM_TIMEOUT = 10 * sec; // ms
export const PAYMENT_TIMEOUT = 10 * min; // ms
export const PAYMENT_WAITING_TIME = 120; // sec

export const ANALYTICS_PRODUCTS_SHOWN_INTERVAL = min; // ms
export const REQUEST_ETA_INTERVAL = min; // ms
export const REQUEST_ORDER_STATUS_INTERVAL = 30 * sec; // ms
export const CATEGORIES_CACHE_DURATION = 3 * min; // ms
export const BANNERS_SLIDER_DURATION = 5 * sec; // ms
export const CATEGORY_CACHE_DURATION = 3 * min; // ms
export const PRODUCT_CACHE_DURATION = 2 * min; // ms
export const ALERT_DURATION = 5 * sec; // ms
export const OUTDATED_APP_POPUP_INTERVAL = 24 * hour; // ms

export const PRODUCT_DESCRIPTION_LENGTH = 135;
export const PRODUCT_SELLABLE_LIMIT = 10;
export const BUNDLE_SELLABLE_LIMIT = 5;
export const PRESENT_PROMOCODES: string[] = [];
export const PRESENT_PROMOCODES_DATA: Record<
  string,
  { storeAllowed: string[] }
> = {};
export const PRESENT_PROMOCODES_ORDERS_LIMIT = 1;
export const SEARCH_CATEGORY_STANDARD_LENGTH = 5;
export const SEARCH_PRODUCTS_STANDARD_LENGTH = 30;
export const REMAIN_MINUTES_BEFORE_CLOSE_POPOVER = 10;
export const SESSION_STORAGE = {
  BLINK_ACTION_CALLED: 'BLINK_ACTION_CALLED',
};

export const GOOGLE_API_KEY = import.meta.env.REACT_APP_GOOGLE_API_KEY;
