import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { StartVerificationLocation, mainStore } from '~/stores/MainStore';

export const useNavigationAfterVerification = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const modalType = searchParams.get('verification');

  const handleNavigate = (location: string) => {
    navigate(location);
    mainStore.setStartVerificationLocation(StartVerificationLocation.DEFAULT);
    searchParams.delete('verification');
  };

  useEffect(() => {
    if (modalType && mainStore.startVerificationLocation) {
      switch (mainStore.startVerificationLocation) {
        case StartVerificationLocation.CABINET: {
          handleNavigate('/cabinet/personal-data');
          break;
        }
        case StartVerificationLocation.CART: {
          handleNavigate('/cart');
          break;
        }
        default: {
          handleNavigate(StartVerificationLocation.DEFAULT);
        }
      }
    }
  }, [modalType, mainStore.isAllStoresSynchronized]);
};
