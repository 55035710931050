export const isAndroid = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  return (
    /android/i.test(navigator.userAgent) || /android/i.test(navigator.platform)
  );
};

export const isIOS = () => {
  if (typeof navigator === 'undefined') {
    return false;
  }
  return (
    /iphone|ipad/i.test(navigator.userAgent) ||
    /iphone|ipad/i.test(navigator.platform)
  );
};
