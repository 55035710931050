import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { SubscriptionFlagsNames } from '~/api/Customer';

import styles from './styles.module.scss';

type SwitchItemProps = {
  isEnabledEmail: boolean;
  inEnabledPush: boolean;
  title: string;
  description: string;
  flagNamePush: SubscriptionFlagsNames;
  flagNameEmail: SubscriptionFlagsNames;
  onPressSwitch: (
    name: SubscriptionFlagsNames,
  ) => (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SwitchItem = ({
  inEnabledPush,
  isEnabledEmail,
  title,
  description,
  flagNamePush,
  flagNameEmail,
  onPressSwitch,
}: SwitchItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.switch_item__container}>
      <div>
        <div className={styles.switch_item__title}>{title}</div>
        <div className={styles.switch_item__description}>{description}</div>
      </div>
      <label className="control__switch checkbox-wrapper">
        <div className={styles.switch_item__switch_container}>
          <input
            checked={inEnabledPush}
            onChange={onPressSwitch(flagNamePush)}
            type="checkbox"
            className="control__switch-input"
            name="lang"
          />
          <span className="control__switch-mark" />
          <div className={styles.switch_item__switch_container__label}>
            {t('pushNotifications')}
          </div>
        </div>
      </label>
      <label className="control__switch checkbox-wrapper">
        <div className={styles.switch_item__switch_container}>
          <input
            checked={isEnabledEmail}
            onChange={onPressSwitch(flagNameEmail)}
            type="checkbox"
            className="control__switch-input"
            name="lang"
          />
          <span className="control__switch-mark" />
          <div className={styles.switch_item__switch_container__label}>
            {t('emailNotifications')}
          </div>
        </div>
      </label>
    </div>
  );
};
