import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { ModalType } from '~/components/Modal/interface';
import Rating from '~/components/Rating';
import Section from '~/components/Section';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { catalogStore } from '~/stores/CatalogStore';
import { Product } from '~/stores/CategoriesStore';
import { ReviewsStore } from '~/stores/ReviewsStore';
import { userStore } from '~/stores/UserStore';
import { ProductReview } from '~/types/Product/interface';

import styles from '../Product.module.scss';

import { ReviewItem } from './ReviewItem';

type Props = {
  productState?: Product | null;
  reviews: ReviewsStore;
};

const ProductReviewMobileLocalee = ({ productState, reviews }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { isMobile } = useGlobal();

  return (
    <div
      className={classes([styles.productContent, styles.reviewContainer])}
      data-company={company.name}
    >
      <Section
        title={t('reviews')}
        subtitle={
          <Rating
            rate={productState?.ratingAverage ?? 0}
            starSize={16}
            showStars={true}
            classNameText={styles.reviewRatingText}
            className={styles.reviewRating}
          />
        }
        className={styles.reviewList}
        headerClassName={styles.reviewSectionHeader}
        horizontalHeader
      >
        <>
          {userStore.isAuthorized && reviews.canCreateReview && (
            <button
              className={classes([
                'button _primary',
                styles.productInfoReviewsShortAddReviewButton,
              ])}
              onClick={() => {
                catalogStore.feedbackProduct = productState ?? null;
                openModal(ModalType.Feedback);
              }}
            >
              {t('writeReview')}
            </button>
          )}
          {reviews.list.map((review: ProductReview) => (
            <ReviewItem key={review.id} {...review} isMobile={isMobile} />
          ))}
        </>
      </Section>
    </div>
  );
};

export default observer(ProductReviewMobileLocalee);
