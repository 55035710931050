import Skeleton from 'react-loading-skeleton';

import SkeletonListRows from '~/components/Skeleton/SkeletonList';

const SKELETONS_WIDTHS = [60, 40, 70, 20, 40];

const FilterSkeleton = () => {
  return (
    <>
      <Skeleton className="filter-skeleton_express" />
      <SkeletonListRows rows={3}>
        <div className="filter-skeleton">
          <Skeleton className="filter-skeleton_section-name" width="100%" />
          <ul className="filter-skeleton_section-list">
            {SKELETONS_WIDTHS.map((width, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <li className="filter-skeleton_section-list-item" key={i}>
                <Skeleton className="filter-skeleton_section-list-item-control" />
                <Skeleton
                  className="filter-skeleton_section-list-item-content"
                  containerClassName="filter-skeleton_section-list-item-content-container"
                  width={`${width}%`}
                />
              </li>
            ))}
          </ul>
        </div>
      </SkeletonListRows>
    </>
  );
};

export default FilterSkeleton;
