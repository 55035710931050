import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';

const EmptyFavs = () => {
  const { t } = useTranslation();

  return (
    <div className="page-empty">
      <img
        src={company.images.favs.empty}
        className="page-empty__image"
        alt=""
      />
      <p className="page-empty__title">{t('phrases:emptyFavorites')}</p>
      <p className="page-empty__subtitle">
        {t('phrases:addProducts')} <Icon type="fav-fill" size={24} />
      </p>
    </div>
  );
};

export default EmptyFavs;
