export const SECOND = 1_000;

export const ORDER_WATCH_INTERVAL = 30 * SECOND;

export interface WatchOrderOptions<T> {
  promiseBuilder: () => Promise<T>;
  conditionFn: (data: T) => boolean;
  timeout?: number;
}

export const pollingBuilder = <T>(options: WatchOrderOptions<T>) => {
  const {
    promiseBuilder,
    conditionFn,
    timeout = ORDER_WATCH_INTERVAL,
  } = options;

  let timer: ReturnType<typeof setTimeout>;

  const handler = async () => {
    const response = await promiseBuilder();

    const result = conditionFn(response);

    if (!result) {
      return;
    }

    timer = setTimeout(handler, timeout);
  };

  timer = setTimeout(handler, timeout);

  return () => {
    if (!timer) {
      return;
    }

    clearTimeout(timer);
  };
};
