import { classes } from 'html-classes';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './EmptyState.module.scss';

const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p className={styles.noProductsTitle}>{t('phrases:noProducts')}</p>
      <div className={styles.img} />
      <p className={styles.noProductsText}>
        <Trans i18nKey="phrases:noProductsContinue" />
      </p>
      <div className={styles.buttonContainer}>
        <Link to="/" className={classes(['button', '_primary', '_med'])}>
          {t('phrases:backToHome')}
        </Link>
      </div>
    </div>
  );
};

export default EmptyState;
