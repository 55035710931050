import { CompanyName } from '~/company/interface';

export const swiperProps: Record<CompanyName, any> = {
  [CompanyName.CityDrinks]: {},
  [CompanyName.Localee]: {
    isPagination: true,
  },
  [CompanyName.CircleK]: {
    isPagination: true,
  },
  [CompanyName.Vilo]: {
    isPagination: true,
  },
  [CompanyName.Jiffy]: {
    isPagination: true,
  },
  [CompanyName.Kids]: {
    isPagination: true,
  },
};
