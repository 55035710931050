import scssBreakpoints from './breakpoints.module.scss';

const tailwindBreakpoints = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export type TailwindBreakpoints = (typeof tailwindBreakpoints)[number];

export type BreakpointKeys =
  | 'tablet'
  | 'phone'
  | 'maxWidth'
  | TailwindBreakpoints;

export const TAILWIND_BREAKPOINTS = tailwindBreakpoints.reduce(
  (acc, key) => ({
    ...acc,
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    [key]: parseInt(scssBreakpoints[key], 10),
  }),
  {} as Record<TailwindBreakpoints, number>,
);

export const BREAKPOINTS = Object.entries(scssBreakpoints).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: parseInt(value, 10),
  }),
  {} as Record<BreakpointKeys, number>,
);
