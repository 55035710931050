import { classes } from 'html-classes';
import { isEqual } from 'lodash-es';
import { useEffect, forwardRef, useRef } from 'react';

import { company } from '~/company/Company';
import { useGlobal } from '~/hooks/useGlobal';

import {
  SOMMELIER_BOT_ELEMENT_IDENTIFIER,
  ON_PRODUCT_CLICK_EVENT,
} from './constants';
import { SommelierWindowProps } from './interfaces';
import styles from './SommelierWindow.module.scss';
import {
  renderScriptElement,
  adjustSommelierBotElement,
  isScriptExists,
} from './utils';

const SommelierWindow = forwardRef<HTMLDivElement, SommelierWindowProps>(
  ({ open = false, onProductClick, className, style }, ref) => {
    const { isSSR } = useGlobal();

    const innerRef = useRef<HTMLDivElement | null>(null);

    const handleMountElement = async (node: HTMLDivElement | null) => {
      if (
        !node ||
        isSSR ||
        (isEqual(node, innerRef.current) && isScriptExists(node))
      ) {
        return;
      }

      innerRef.current = node;

      const mutationObserver = new MutationObserver((mutations) => {
        const sommelierElementAdded = mutations.some((item) =>
          Array.from(item.addedNodes).some((node) => {
            const element = node as Nullable<HTMLElement | undefined>;

            if (typeof element?.getAttribute !== 'function') {
              return false;
            }

            return (
              (element as HTMLElement).getAttribute('id') ===
              SOMMELIER_BOT_ELEMENT_IDENTIFIER
            );
          }),
        );

        if (sommelierElementAdded) {
          adjustSommelierBotElement();
        }
      });
      mutationObserver.observe(node, { childList: true, subtree: true });
      await renderScriptElement(node);
    };

    useEffect(() => {
      const listener = (e: Event) => {
        const { detail } = e as CustomEvent;

        onProductClick?.(detail, e);
      };

      window.addEventListener(ON_PRODUCT_CLICK_EVENT, listener);

      return () => {
        window.removeEventListener(ON_PRODUCT_CLICK_EVENT, listener);
      };
    }, []);

    return (
      <div
        ref={ref}
        data-company={company.name}
        className={classes([styles.root, open && styles.open, className])}
        style={style}
      >
        <div className={styles.root__inner} ref={handleMountElement} />
      </div>
    );
  },
);

SommelierWindow.displayName = 'SommelierWindow';

export default SommelierWindow;
