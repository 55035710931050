import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';

type Props = {
  className?: string;
};

const ProductCardSkeleton = ({ className }: Props) => {
  return (
    <div className={classes(['product-card skeleton', className])}>
      <div className="product-card__img-wrap">
        <Skeleton className="product-card__img" />
      </div>
      <div className="product-card__body">
        <div className="product-card__info">
          <div className="product-card__rated" />
          <Skeleton className="product-card__info-volume" />
        </div>
        <div className="product-card__name">
          <Skeleton />
        </div>
        <div className="product-card__footer">
          <div className="product-card__price-wrap">
            <div className="product-card__price">
              <Skeleton />
            </div>
          </div>
          <div className="skeleton-btn-container">
            <Skeleton className="skeleton" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ProductCardSkeleton);
