import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import Counter from '~/components/Counter';
import Icon from '~/components/Icon/Icon';
import { catalogStore } from '~/stores/CatalogStore';
import { Offer, Product } from '~/stores/CategoriesStore';

import styles from '../Product.module.scss';

import ProductPrice from './ProductPrice';

type Props = {
  offer?: Offer;
  productState?: Product | null;

  count: number;
  isMultiBuy: boolean;
  oldPrice: string | null;
  currentPrice?: string;
  isExpress?: boolean | 0;
  showFlashIcon: boolean;
  expressDeliveryLabel: string;
  expressDeliveryLabelArgs: Record<string, string>;
  isBackorderAvailable?: boolean;
  deliveryToday: boolean | '';
  backorderDate: string | null;
  limit: number;

  handleChangeCount: (count: number, action: 'add' | 'remove') => void;
  handleFavorite: () => void;
  handleCopyCode: () => Promise<void>;
};

const ProductPriceInfoLocalee = ({
  offer,
  productState,

  count,
  isMultiBuy,
  oldPrice,
  currentPrice,
  isExpress,
  expressDeliveryLabel,
  expressDeliveryLabelArgs,
  isBackorderAvailable,
  deliveryToday,
  backorderDate,
  limit,

  handleChangeCount,
  handleFavorite,
  handleCopyCode,
}: Props) => {
  const { t } = useTranslation();

  const elementView = () => {
    if (DEFAULT_COMPANIES.includes(company.name)) {
      return (
        <div className="price-info" data-company={company.name}>
          <div className="price-info-buttons">
            {count > 0 ? (
              <div className="counter-container">
                <Icon
                  type="check-round"
                  size={36}
                  className="counter-container__icon"
                />
                <p className="counter-container__text">{t('addedToCart')}</p>
                <Counter
                  size="m"
                  count={count}
                  onClick={handleChangeCount}
                  limit={limit}
                />
              </div>
            ) : (
              <button
                className={classes([
                  'button _primary _med price-info-buttons__cta',
                  limit === 0 && '_disabled',
                ])}
                onClick={() => handleChangeCount(count + 1, 'add')}
              >
                {limit > 0 ? t('addToCart') : t('soldOut')}
              </button>
            )}
            <button
              className="button _med price-info-buttons__actions-btn"
              onClick={handleFavorite}
            >
              <Icon
                size={24}
                type={
                  catalogStore.favorites[productState?.id || '']
                    ? 'fav-fill'
                    : 'fav'
                }
                className={classes(
                  catalogStore.favorites[productState?.id || ''] && '_fill',
                )}
              />
            </button>
            <button
              className="button _med price-info-buttons__actions-btn"
              onClick={handleCopyCode}
            >
              <Icon size={24} type="share" />
            </button>
          </div>
          <div className="price-info__bages">
            {isExpress ? (
              <div className="badge__container">
                {t(expressDeliveryLabel, expressDeliveryLabelArgs)}
              </div>
            ) : isBackorderAvailable ? (
              <div className={styles.backorder_date}>
                <Icon
                  type="calendar-2"
                  size={20}
                  className={styles.backorder_date__icon}
                />
                {deliveryToday
                  ? t('deliveryToday')
                  : t('slotDeliveryFrom', { backorderDate })}
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      return (
        <div className="price-info" data-company={company.name}>
          <div className="price-info-container">
            <ProductPrice
              offer={offer}
              isMultiBuy={isMultiBuy}
              oldPrice={oldPrice}
              currentPrice={currentPrice}
            />
            {limit > 0 && !!isExpress && (
              <p className="price-info__status-in-stock">{t('inStock')}</p>
            )}
          </div>
          <div className="price-info-buttons">
            {count > 0 ? (
              <div className="counter-container">
                <Icon
                  type="check-round"
                  size={36}
                  className="counter-container__icon"
                />
                <p className="counter-container__text">{t('addedToCart')}</p>
                <Counter
                  size="m"
                  count={count}
                  onClick={handleChangeCount}
                  limit={limit}
                />
              </div>
            ) : (
              <button
                className={classes([
                  'button _primary _med price-info-buttons__cta',
                  limit === 0 && '_disabled',
                ])}
                onClick={() => handleChangeCount(count + 1, 'add')}
              >
                {limit > 0 ? t('addToCart') : t('soldOut')}
              </button>
            )}
            <button
              className="button _med price-info-buttons__actions-btn"
              onClick={handleFavorite}
            >
              <Icon
                size={24}
                type={
                  catalogStore.favorites[productState?.id || '']
                    ? 'fav-fill'
                    : 'fav'
                }
                className={classes(
                  catalogStore.favorites[productState?.id || ''] && '_fill',
                )}
              />
            </button>
            <button
              className="button _med price-info-buttons__actions-btn"
              onClick={handleCopyCode}
            >
              <Icon size={24} type="share" />
            </button>
          </div>
          <div className="price-info__bages">
            {isExpress ? (
              <div className="badge__container">
                {t(expressDeliveryLabel, expressDeliveryLabelArgs)}
              </div>
            ) : isBackorderAvailable ? (
              <div className={styles.backorder_date}>
                <Icon
                  type="calendar-2"
                  size={20}
                  className={styles.backorder_date__icon}
                />
                {deliveryToday
                  ? t('deliveryToday')
                  : t('slotDeliveryFrom', { backorderDate })}
              </div>
            ) : null}
          </div>
        </div>
      );
    }
  };

  return offer ? (
    elementView()
  ) : (
    <div className="price-info _skeleton">
      <Skeleton className="skeleton" containerClassName="skeleton-container" />
    </div>
  );
};

export default observer(ProductPriceInfoLocalee);
