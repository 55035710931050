import { classes } from 'html-classes';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import Modal from '~/components/Modal/Modal';
import { useGlobal } from '~/hooks/useGlobal';
import styles from '~/pages/Catalog/Catalog.module.scss';

interface DescriptionBlockProps {
  title: string;
  text: string;
  results?: string;
}

export const DescriptionBlock: FC<DescriptionBlockProps> = ({
  title,
  text,
  results,
}) => {
  const { t } = useTranslation();
  const textRef = useRef<HTMLDivElement>(null);

  const [openModal, setOpenModal] = useState(false);
  const [isCropped, setIsCropped] = useState(false);

  useEffect(() => {
    const handler = () => {
      const element = textRef.current;

      if (element) {
        const diff = element.scrollHeight - element.clientHeight;
        setIsCropped(diff > 14);
      }
    };

    handler();

    window.addEventListener('resize', handler);

    return () => window.removeEventListener('resize', handler);
  }, [title, text]);

  return (
    <>
      <div className={styles.descriptionBlock} data-company={company.name}>
        <h1 className={styles.title}>
          {title} <span className={styles.results}>{results}</span>
        </h1>
        <div className={styles.separator} />
        <div className={styles.text} ref={textRef}>
          {text}
        </div>
        {isCropped && (
          <button
            className={classes(['button', styles.showMore])}
            onClick={() => setOpenModal(true)}
          >
            {t('showMore')}
          </button>
        )}
      </div>
      <DescriptionModal
        open={openModal}
        title={title}
        text={text}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

interface DescriptionModalProps {
  title: string;
  text: string;
  open: boolean;

  onClose(): void;
}

const DescriptionModal: FC<DescriptionModalProps> = ({
  title,
  text,
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();

  return (
    <Modal isShow={open} className={styles.descriptionModal}>
      <header className={styles.header}>
        {title}
        <button
          className={classes([styles.close, 'button _no-padding'])}
          onClick={onClose}
        >
          <Icon size={isMobile ? 24 : 40} type="close" />
        </button>
      </header>
      <div className={styles.body}>{text}</div>
      <footer className={styles.footer}>
        <button className="button _primary" onClick={onClose}>
          {t('showLess')}
        </button>
      </footer>
    </Modal>
  );
};
