import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import { CartCalculationOrderResponse } from '~/api/Catalog';
import { company } from '~/company/Company';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import FreeDeliveryProgressBar from '~/components/FreeDeliveryProgressBar/FreeDeliveryProgressBar';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useDateLang } from '~/hooks/useDateLang';
import { useModal } from '~/hooks/useModal';
import DeliveryDesktopOfferSkeleton from '~/pages/Checkout/DeliveryDesktopOfferSkeleton';
import { catalogStore } from '~/stores/CatalogStore';
import Delivery from '~/stores/CheckoutStore/Delivery';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';
import { isProductBundle } from '~/types/Product';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from './DeliveryDesktop.module.scss';
import { useSlots } from './useSlots';
import { getSelectedDay } from './utils';

interface DeliveryDesktopProps {
  handleSelectDateModal?: () => void;
  delivery: Delivery;
  slotNumber?: number;
  order: CartCalculationOrderResponse;
}

const DeliveryDesktop = ({
  handleSelectDateModal,
  delivery,
  slotNumber,
  order,
}: DeliveryDesktopProps) => {
  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const { locale } = useDateLang();

  const selectedDay = delivery.slot
    ? getSelectedDay(delivery.slot, locale)
    : t('phrases:deliveryInMin', {
        time: orderStore.etaCalculation?.duration.range,
      });

  const swiperRef = useRef<SwiperRef>(null);

  const deliveryItems = catalogStore.selectedCartItems.filter(({ sku }) =>
    delivery.itemsIds.includes(sku),
  );

  const { showSelectTimeButton } = useSlots({ deliveryItems });

  const deliveryPrice = formatPriceWithCurrency(
    mainStore.convertPenceToPounds(order.delivery_info.paid_price),
  );

  const isDeliveryFree = order.is_delivery_free;

  const freeDeliveryThreshold = order.delivery_info.threshold;

  const leftUntilFreeDelivery = mainStore.convertPenceToPounds(
    freeDeliveryThreshold - order.paid_without_delivery,
  );

  const { isLoading } = catalogStore.calculationProcess;
  const { openModal } = useModal();
  const openDeliveryModal = () => {
    openModal(ModalType.DeliveryInfo, 'checkout');
  };
  const deliveryTooltip = company.isDeliveryInfoModalEnabled && (
    <div onClick={openDeliveryModal} className={styles.deliveryTooltip}>
      <Icon type="info" size={18} />
    </div>
  );

  return (
    <li className={classes(['slot-list__slot', styles.container])}>
      {isLoading ? (
        <DeliveryDesktopOfferSkeleton />
      ) : (
        <>
          <div className="slot-list__slot-info">
            <div className="slot-list__slot-header">
              <div className="slot-list__slot-header-group">
                {!slotNumber ? (
                  <p className="slot-list__slot-type">
                    {t('expressDelivery')} {selectedDay}
                    {deliveryTooltip}
                  </p>
                ) : (
                  <p className="slot-list__slot-type">
                    {selectedDay}
                    {deliveryTooltip}
                  </p>
                )}
              </div>
              {isDeliveryFree ? (
                <p className={styles.deliveryFree}>{t('deliveryFree')}</p>
              ) : (
                <p className="slot-list__slot-fee">
                  {t('deliveryFee')}:&nbsp;
                  {deliveryPrice}
                </p>
              )}
            </div>
            {showSelectTimeButton && (
              <button
                className={styles.selectTimeBtn}
                onClick={handleSelectDateModal}
              >
                {t('selectTime')}
              </button>
            )}
          </div>
          <ul className="slot-list__slot-images">
            <Swiper
              ref={swiperRef}
              spaceBetween={8}
              slidesPerView="auto"
              modules={[Navigation]}
              dir={userStore.dir}
              key={userStore.dir}
            >
              {deliveryItems.map((item) => (
                <SwiperSlide
                  className="banner-carousel__item1"
                  key={item.previewImageThumb}
                >
                  <Link
                    onClick={handleClickProduct(item)}
                    to={
                      `/p/${item.slug}` +
                      (isProductBundle(item) ? '' : `?sku=${item.sku}`)
                    }
                    state={{
                      ...(isProductBundle(item) ? {} : { offerSku: item.sku }),
                    }}
                  >
                    <div
                      key={item.previewImageThumb}
                      className="slot-list__slot-images-item"
                    >
                      <CloudflareResponseImage
                        src={item.previewImageThumb || ''}
                        srcParams={{ width: 80, height: 110 }}
                        srcSetParams={defaultSrcSetParams}
                        alt={item.name}
                        loading="lazy"
                      />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </ul>
          {!isDeliveryFree && (
            <FreeDeliveryProgressBar
              leftUntilFreeDelivery={`${formatPriceWithCurrency(
                leftUntilFreeDelivery,
              )}`}
              cssWidth={`${
                (order.paid_without_delivery / freeDeliveryThreshold) * 100
              }%`}
            />
          )}
        </>
      )}
    </li>
  );
};

export default observer(DeliveryDesktop);
