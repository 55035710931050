import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import ProductCardSkeleton from '~/components/ProductCard/ProductCardSkeleton';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';
import { useGlobal } from '~/hooks/useGlobal';

import ProductCard from '../../components/ProductCard/ProductCard';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { catalogStore } from '../../stores/CatalogStore';

import MobileHeaderAddress from '../Main/MobileHeader/MobileHeaderAddress';
import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import EmptyFavs from './EmptyFavs';
import EmptyPurchased from './EmptyPurchased';
import styles from './Favs.module.scss';

const TABS = ['products', 'previouslyOrdered'] as const;

const Favs = () => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();

  const tabsTranslation: Record<(typeof TABS)[number], string> = useMemo(
    () => ({
      products: t('products'),
      recipes: t('recipes'),
      previouslyOrdered: t('previouslyOrdered'),
    }),
    [],
  );

  const [tab, setTab] = useState<(typeof TABS)[number]>(TABS[0]);

  const fetchMoreElementRef = useRef<HTMLDivElement>(null);
  const { runObserver, isObservableElementVisible } = useIntersectionObserver();
  const [isLoading, setIsLoading] = useState(true);
  const [isCanFetchFavouritesMore, setIsCanFetchFavouritesMore] =
    useState(true);
  const [isCanFetchPurchasesMore, setIsCanFetchPurchasesMore] = useState(true);
  const [isCanFetchRecommendsMore] = useState(true);
  const [currentItems, setcurrentItems] = useState<Array<any>>([]);

  const getItemsListByTab = (activeTab: string, isForce?: boolean) => {
    // Code below gets additional items by the tab any time when it is called
    switch (activeTab) {
      case TABS[0]:
        setIsLoading(true);
        catalogStore
          .fetchFavorites()
          .catch((error) => error && console.error(error))
          .then((result) => {
            /**
             * If last fetch returns 0 items, that means that we got all list from server and
             * fetch more flag for favorites can be disabled
             * */
            setIsCanFetchFavouritesMore(!!result);
            setcurrentItems(catalogStore.favoritesList);
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case TABS[1]:
        setIsLoading(true);
        setcurrentItems([]);
        catalogStore
          .fetchPurchasedItems(isForce)
          .catch((error) => error && console.error(error))
          .then((isCanFetchPurchasesMore) => {
            /**
             * If last fetch returns 0 items, that means that we got all list from server and
             * fetch more flag for purchases can be disabled
             * */
            setIsCanFetchPurchasesMore(isCanFetchPurchasesMore);
            setcurrentItems(catalogStore.purchasedItemsList);
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
    }
  };

  useEffect(() => {
    /**
     * Code below gets list of items for particular active tab
     *
     * Should be called only once, when user open favourites page with any tab
     * */
    setIsLoading(true);
    getItemsListByTab(tab, true);

    runObserver(fetchMoreElementRef);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    /**
     * Code below runs any time when user scroll down to the end of the list
     * and we need to upload more items
     * */
    if (!isObservableElementVisible) {
      return;
    }

    const shouldUpdatePurchases = tab === TABS[0] && isCanFetchPurchasesMore;
    const shouldUpdateFavourite = tab === TABS[1] && isCanFetchFavouritesMore;

    if (shouldUpdatePurchases || shouldUpdateFavourite) {
      // Code below will be called only when user on particular page and specific for tab fetch more item flag is enabled
      setIsLoading(true);

      getItemsListByTab(tab);
      return;
    }

    // eslint-disable-next-line
  }, [
    isCanFetchPurchasesMore,
    isObservableElementVisible,
    isCanFetchFavouritesMore,
    tab,
    isCanFetchRecommendsMore,
  ]);

  useEffect(() => {
    getItemsListByTab(tab);
  }, [tab]);

  let pageContent = null;
  if (isLoading) {
    pageContent = (
      <div
        data-company={company.name}
        className={classes(['block-products', styles.blockProducts])}
      >
        <SkeletonListRows rows={6}>
          <ProductCardSkeleton />
        </SkeletonListRows>
      </div>
    );
  } else if (currentItems.length > 0) {
    pageContent = (
      <div
        data-company={company.name}
        className={classes(['block-products', styles.blockProducts])}
      >
        {currentItems.map((product) => (
          <ProductCard
            key={product.id}
            product={product}
            className={styles.productCard}
            isRatingShown={tab !== TABS[1]}
          />
        ))}
      </div>
    );
  } else {
    if (tab === TABS[0]) {
      pageContent = <EmptyFavs />;
    } else if (tab === TABS[1]) {
      pageContent = <EmptyPurchased />;
    }
  }

  return (
    <div className={styles.pageFavs} data-company={company.name}>
      <MobileHeaderLayout
        content={
          company.isFavsInCabinet ? (
            <MobileHeaderTitle text={t('savedAndPurchased') || ''} />
          ) : (
            <MobileHeaderAddress />
          )
        }
        toolbarClassName={styles.toolbarContent}
      />
      <div className={classes(['content-layout favorites'])}>
        <div className={styles.tabFilters}>
          <div className={styles.tabFiltersWrapper}>
            {TABS.map((i) => (
              <Link
                key={i}
                to="#"
                className={classes([
                  styles.tabFiltersWrapperItem,
                  i === tab && styles.active,
                ])}
                onClick={() => setTab(i)}
                data-company={company.name}
              >
                {tabsTranslation[i]}
              </Link>
            ))}
          </div>
        </div>
        <div className="favs-content">
          {!company.isFavsInCabinet && (
            <nav className="breadcrumbs">
              <Link to="/" className="breadcrumbs__item">
                {t('home')}
              </Link>
              <div className="breadcrumbs__line">/</div>
              <Link to="/" className="breadcrumbs__item">
                {t('favs')}
              </Link>
            </nav>
          )}
          {isMobile && company.isFavsInCabinet ? null : (
            <h1 className={styles.title}>{tabsTranslation[tab]}</h1>
          )}
          {pageContent}
        </div>
      </div>
    </div>
  );
};

export default observer(Favs);
