import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterChoiceAPI } from '~/api/Catalog';
import {
  ProductFilters,
  ProductPropertyFilterCode,
} from '~/stores/CatalogStore';

import styles from './EmptyWithFilters.module.scss';
// import Icon from "../Icon/Icon";

export interface IEmptyWithFiltersProps {
  currentFilters?: ProductFilters;
  subcategories?: FilterChoiceAPI[];
  onReset: MouseEventHandler;
  onChange?: (code: ProductPropertyFilterCode, value?: any) => void;
}

const EmptyWithFilters = ({ onReset }: IEmptyWithFiltersProps) => {
  const { t } = useTranslation();

  const [canShow, setCanShow] = useState(false);

  // TODO: alert! must be removed! just need while getting products makes multiple rendering
  useEffect(() => {
    const timer = setTimeout(() => setCanShow(true), 500);
    return () => clearTimeout(timer);
  });

  return canShow ? (
    <div className={styles.root}>
      <div className={styles.contentContainer}>
        <p className={styles.title}>{t('nothingFound')}</p>
        <p className={styles.description}>{t('tryToReset')}</p>
      </div>
      {/* <div className={styles.filtersContainer}>
        {filters.map(i => <Pile {...i} onChange={onChange} />)}
      </div> */}
      <div className={styles.buttonContainer}>
        <button
          className={classes(['button _primary _big', styles.button])}
          onClick={onReset}
        >
          {t('resetAll')}
        </button>
      </div>
    </div>
  ) : null;
};

// interface IPileProps {
//   code: ProductPropertyFilterCode;
//   value: string | number;
//   label: string | number;
//   onChange: (code: ProductPropertyFilterCode, value?: any) => void;
// };

// const Pile = ({ code, value, label, onChange }: IPileProps) => {
//   const { t } = useTranslation();
//
//   return (
//     <div className={styles.pile}>
//       <span className={styles.text}>
//         {text}
//       </span>
//       <span className={styles.close} onClick={() => onChange(code, value)}>
//         <Icon type="close" size={14} />
//       </span>
//     </div>
//   )
// }

export default observer(EmptyWithFilters);
