import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from '~/hooks/useModal';
import { Rate, catalogStore } from '~/stores/CatalogStore';
import { mainStore } from '~/stores/MainStore';
import { ReviewsStore } from '~/stores/ReviewsStore';

import CloudflareResponseImage from '../CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '../CloudflareResponsiveImage/constants';
import Icon from '../Icon/Icon';
import { ModalType } from '../Modal/interface';
import StarRate from '../StarRate/StarRate';

import styles from './ModalFeedback.module.scss';

const ModalFeedback = () => {
  const { t } = useTranslation();
  const { closeModal, openModal } = useModal();
  const [feedback, setFeedback] = useState('');
  const [rate, setRate] = useState<Rate>(0);

  if (!catalogStore.feedbackProduct) {
    closeModal();
  }

  const offer = catalogStore.feedbackProduct?.offers[0];

  const sumbitFeedback = async () => {
    if (rate === 0) {
      mainStore.pushAlert('error', t('phrases:rateProduct'));
      return;
    }
    const addReviewResult =
      await ReviewsStore.currentInstance?.createReview.run({
        productId: +(catalogStore.feedbackProduct?.id ?? 0),
        mark: rate,
        content: feedback || null,
      });
    if (addReviewResult) {
      openModal(ModalType.FeedbackSuccess);
    }
  };

  const isFormValid = () => {
    return rate > 0;
  };

  return (
    <div className="popover-container input-form">
      <div className={classes(['popover', styles.popover])}>
        <button
          className={classes([
            'button _no-padding _no-color',
            styles.closeCross,
          ])}
          onClick={closeModal}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <div className={styles.root}>
          <div className={styles.info}>
            <CloudflareResponseImage
              src={offer?.previewImageThumb || ''}
              srcParams={{ width: 106 }}
              srcSetParams={defaultSrcSetParams}
              alt=""
              className={styles.infoImage}
              loading="lazy"
            />
            <div className={styles.infoDetails}>
              <div className={styles.infoDetailsHeader}>
                <p className={styles.infoDetailsTitle}>{offer?.name}</p>
              </div>
              <div className={styles.infoDetailsProps}>
                {offer?.properties.producer && (
                  <>
                    <p className={styles.infoDetailsPropsName}>
                      {t('producer')}
                    </p>
                    <p className={styles.infoDetailsPropsValue}>
                      {offer?.properties.producer.value}
                    </p>
                  </>
                )}
                {offer?.properties.region && (
                  <>
                    <p className={styles.infoDetailsPropsName}>{t('region')}</p>
                    <p className={styles.infoDetailsPropsValue}>
                      {offer?.properties.region.value}
                    </p>
                  </>
                )}
              </div>
              {offer?.description && (
                <p
                  className={styles.infoDescription}
                  dangerouslySetInnerHTML={{ __html: offer?.description }}
                />
              )}
            </div>
          </div>
          <div className={styles.form}>
            <p className={styles.formTitle}>{t('yourRate')}</p>
            <div className={styles.formContent}>
              <StarRate {...{ rate, setRate }} />
              <textarea
                rows={7}
                value={feedback}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setFeedback(e.target.value)
                }
                className={styles.formInput}
                placeholder={t('yourReview')}
              />
              <button
                className={classes(['button _primary'])}
                onClick={sumbitFeedback}
                disabled={!isFormValid()}
              >
                {t('submitRewiew')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(ModalFeedback);
