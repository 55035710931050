import { ProductReview } from '~/types/Product/interface';

import Icon from '../Icon/Icon';
import Rating from '../Rating';
// import { format } from "date-fns";

interface Props extends ProductReview {
  isMobile?: boolean;
}

export const ReviewItem = ({ mark, customer, content, isMobile }: Props) => {
  return isMobile ? (
    <div className="review">
      <div className="review-header">
        <div className="review-header-info">
          <div className="review-header-info__name">
            <Icon type="badge" size={24} className="icon" />
            <p>
              {[
                customer.firstName,
                customer.middleName,
                customer.lastName,
              ].join(' ')}
            </p>
          </div>
          {/* <p className="review-header-info__date">{format(new Date(createdAt), 'dd/MM/yyyy')}</p> */}
        </div>
        <Rating rate={mark} starSize={16} showStars={true} hideText={true} />
      </div>
      <p className="review-content">{content}</p>
      {/* <div className="review-footer">
        <div className="review-footer__stat-item">
          <Icon type={i.stat.vote && i.stat.vote > 0 ? 'like-fill' : 'like'} size={24} />
          {i.stat.minus > 0 && <div className="review-footer__stat-item-count">
            {i.stat.minus}
          </div>}
        </div>
        <div className="review-footer__stat-item">
          <Icon type={i.stat.vote && i.stat.vote < 0 ? 'like-fill' : 'like'} className="icon__rotate-180" size={24} />
          {i.stat.plus > 0 && <div className="review-footer__stat-item-count">
            {i.stat.plus}
          </div>}
        </div>
      </div> */}
    </div>
  ) : (
    <div className="review">
      <div className="review-header">
        <div className="review-header-info">
          <div className="review-header-info__name">
            <Icon type="badge" size={24} className="icon" />
            <p>
              {[
                customer.firstName,
                customer.middleName,
                customer.lastName,
              ].join(' ')}
            </p>
          </div>
          {/* <p className="review-header-info__date">{format(new Date(createdAt), 'dd/MM/yyyy')}</p> */}
        </div>
      </div>
      <div className="review-main">
        <Rating rate={mark} starSize={20} showStars hideText />
        <p className="review-content">{content}</p>
      </div>
      {/* <div className="review-footer">
        <div className="review-footer__stat-item">
          <Icon type={i.stat.vote && i.stat.vote > 0 ? 'like-fill' : 'like'} size={24} />
          {i.stat.minus > 0 && <div className="review-footer__stat-item-count">
            {i.stat.minus}
          </div>}
        </div>
        <div className="review-footer__stat-item">
          <Icon type={i.stat.vote && i.stat.vote < 0 ? 'like-fill' : 'like'} className="icon__rotate-180" size={24} />
          {i.stat.plus > 0 && <div className="review-footer__stat-item-count">
            {i.stat.plus}
          </div>}
        </div>
      </div> */}
    </div>
  );
};
