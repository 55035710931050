import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { company } from '~/company/Company';
import { useLang } from '~/hooks/useLang';
import { userStore } from '~/stores/UserStore';

import { LOCALE } from './constants';
import styles from './LanguageSelector.module.scss';

const LanguageSelector = () => {
  const { setLanguage } = useLang();
  const [curLangNum, setCurLangNum] = useState(0);

  useEffect(() => {
    const idx = company.config.langs.indexOf(
      userStore.lang || company.config.lang,
    );
    setCurLangNum(idx);
  }, []);

  if (!company.config.langs || company.config.langs.length < 2) {
    return null;
  }

  const nextLangNum =
    curLangNum + 1 < company.config.langs.length ? curLangNum + 1 : 0;

  const handleChangeLang = (nextNum: number) => {
    setCurLangNum(nextNum);
    const nextLang = company.config.langs[nextNum];
    if (nextLang) {
      setLanguage(nextLang);
    }
  };

  return (
    <button
      className={styles.root}
      onClick={() => handleChangeLang(nextLangNum)}
    >
      <img
        src={
          LOCALE[company.config.langs[nextLangNum] as keyof typeof LOCALE].img
        }
      />
      <span>
        {LOCALE[company.config.langs[nextLangNum] as keyof typeof LOCALE].title}
      </span>
    </button>
  );
};

export default observer(LanguageSelector);
