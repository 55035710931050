import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ReactNode, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { BreadcrumbsItem } from '../../stores/CatalogStore';

interface Props {
  className?: string;
  items: BreadcrumbsItem[];
  delimiterContent?: string | ReactNode;
}

const Breadcrumbs = ({ items, className, delimiterContent = '/' }: Props) => {
  return (
    <nav className={classes(['breadcrumbs', className])}>
      {items.map((item, i) => (
        <Fragment key={item.link}>
          {i + 1 === items.length ? (
            <span className="breadcrumbs__item">{item.text}</span>
          ) : (
            <>
              <Link to={item.link} className="breadcrumbs__item">
                {item.text}
              </Link>
              {i < items.length - 1 && (
                <div className="breadcrumbs__line">{delimiterContent}</div>
              )}
            </>
          )}
        </Fragment>
      ))}
    </nav>
  );
};

export default observer(Breadcrumbs);
