import { classes } from 'html-classes';
import { debounce } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';

import CollapsibleTextSkeleton from './CollapsibleTextSkeleton';

import './styles.scss';

const CollapsibleText = ({
  text,
  className,
  isLoading,
}: {
  text?: string;
  className?: string;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    window.scrollBy(0, -1);
    setClamped(!clamped);
  };

  useEffect(() => {
    const hasClamping = (el: HTMLDivElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        const hadClampClass = containerRef.current.classList.contains('clamp');
        if (!hadClampClass) {
          containerRef.current.classList.add('clamp');
        }
        setShowButton(hasClamping(containerRef.current));
        if (!hadClampClass) {
          containerRef.current.classList.remove('clamp');
        }
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => window.removeEventListener('resize', debouncedCheck);
  }, [containerRef]);

  if (isLoading) {
    return <CollapsibleTextSkeleton />;
  } else if (!text) {
    return null;
  }

  return (
    <div className={classes(['collapsible-text', clamped && 'clamp'])}>
      <div ref={containerRef} className="collapsible-text__container">
        <div
          dangerouslySetInnerHTML={{ __html: text }}
          className={classes([
            'collapsible-text__container-content',
            className,
          ])}
          data-company={company.name}
        />
      </div>
      {showButton && (
        <div className="collapsible-text__controls">
          <button
            onClick={handleClick}
            className="button _bordered collapsible-text__button"
          >
            {clamped ? t('showMore') : t('showLess')}{' '}
            <Icon
              type="chevron-2-fill"
              className={classes(!clamped && 'opened')}
              size={16}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default CollapsibleText;
