import axios from 'axios';
import { join } from 'urlcat';

import { API_URL } from '~/api/constants';

const geocoderRequest = axios.create({
  baseURL: join(API_URL, '/', 'geocoder/v1/api'),
});

export default geocoderRequest;
