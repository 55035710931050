import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';

import { CartCostItemsVariant1 } from './CartCostItemsVariant1';
import { CartCostItemsVariant2 } from './CartCostItemsVariant2';

const CartCostItems = () => {
  switch (company.name) {
    case CompanyName.CityDrinks:
      return <CartCostItemsVariant2 />;
    default:
      return <CartCostItemsVariant1 />;
  }
};

export default CartCostItems;
