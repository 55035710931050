import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, ReactNode, InputHTMLAttributes } from 'react';
import { RefCallBack } from 'react-hook-form';

interface InputTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  children?: ReactNode;
  classNameWrapper?: string;
  className?: string;
  classNameInput?: string;
  classNameLabel?: string;
  externalLabel?: string;
  isValid?: boolean;
  isError?: boolean;
  error?: string;
  refObj?: React.RefObject<HTMLTextAreaElement> | RefCallBack;
  required?: boolean;
}

export default observer(
  ({
    label,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    externalLabel,
    classNameWrapper,
    children,
    className,
    classNameInput,
    // classNameLabel,
    isValid,
    error,
    isError,
    maxLength,
    refObj,
    required,
    ...props
  }: InputTextAreaProps) => {
    return (
      <div className={classNameWrapper}>
        {externalLabel && (
          <div className="input-text__externalLabel">
            {externalLabel}
            {required && '*'}
          </div>
        )}
        <div
          className={classes([
            'input-text__wrap',
            className,
            { active: props.value, _error: isError },
          ])}
        >
          {children ? (
            children
          ) : (
            <>
              {isValid && (
                <div className="icon icon-check input-text__icon _check" />
              )}
              <textarea
                className={classes([
                  'input-text',
                  classNameInput,
                  { noLabel: !label },
                ])}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                maxLength={maxLength}
                ref={refObj}
                {...props}
              />
            </>
          )}
          {/* {label && (
            <div
              className={classes([
                'input-text__label text-truncate',
                classNameLabel,
                {
                  _active: placeholder,
                },
              ])}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )} */}
          {!!error && <p className="input-text_errorText error">{error}</p>}
          {/* <div className="input-text__border" /> */}
        </div>
      </div>
    );
  },
);
