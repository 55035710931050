import { hoursToSeconds, millisecondsToSeconds } from 'date-fns';
import { mapValues } from 'lodash-es';

import { truncateSlots } from '~/pages/Checkout/truncateSlots';
import { CartItem, catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { orderStore } from '~/stores/OrderStore';

export const useSlots = ({ deliveryItems }: { deliveryItems: CartItem[] }) => {
  const isExpressDeliveryAvailable = deliveryItems.every(
    ({ count, sellableWarehouses }) => {
      const currentWHStock = Number(
        sellableWarehouses[`${orderStore.etaCalculation?.warehouse.code}`] || 0,
      );

      return count <= currentWHStock;
    },
  );

  const latestBackorderLeadTime =
    deliveryItems
      .sort((a, b) => a.backorderLeadTime - b.backorderLeadTime)
      .at(0)?.backorderLeadTime ?? 0;

  const now = millisecondsToSeconds(Date.now());
  const latestBackorderTime = now + hoursToSeconds(latestBackorderLeadTime);

  const deliverySlots = truncateSlots(
    mapValues(checkoutStore.deliverySlots, (slots) =>
      slots.filter(
        ({ delivery_min_time }) => delivery_min_time >= latestBackorderTime,
      ),
    ),
  );

  const lastBackorder =
    checkoutStore.isDeliverTogether && !isExpressDeliveryAvailable
      ? catalogStore.selectedCartItems.reduce((acc, { backorderLeadTime }) => {
          const backorderTime = now + hoursToSeconds(backorderLeadTime);

          return Math.max(acc, backorderTime);
        }, 0)
      : 0;

  const showSelectTimeButton =
    Object.keys(deliverySlots).filter((i) => {
      return deliverySlots[i]?.some((j) => {
        return j.cut_off_time >= lastBackorder;
      });
    }).length > 0;

  return { showSelectTimeButton };
};
