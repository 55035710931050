import { MAP_GEOLOCATION_ZOOM } from '~/components/ModalDeliveryAddress/constants';
import { deliveryAddressStore } from '~/components/ModalDeliveryAddress/DeliveryAddressStore';
import { mainStore } from '~/stores/MainStore';

export const useGeolocation = () => {
  const requestLocation = () => {
    if (deliveryAddressStore.isActiveGeolocation) {
      deliveryAddressStore.setIsActiveGeolocation(false);
    } else {
      deliveryAddressStore.setIsAwaitGeolocation(true);
      if (mainStore.isRN) {
        mainStore.sendToRN(
          'getGeolocation',
          {
            target: 'findme_icon',
          },
          (e) => {
            if (
              !e ||
              !e.location?.coords?.latitude ||
              !e.location?.coords?.longitude
            ) {
              deliveryAddressStore.setIsMapBusy(false);
              deliveryAddressStore.setIsAwaitGeolocation(false);
              return;
            }
            deliveryAddressStore.setGeolocationCoordinates({
              lat: e.location.coords.latitude,
              lng: e.location.coords.longitude,
            });
            deliveryAddressStore.setMapZoom(MAP_GEOLOCATION_ZOOM);
            deliveryAddressStore.setIsActiveGeolocation(true);
            deliveryAddressStore.setIsMapBusy(false);
            deliveryAddressStore.setIsSmallSizeCover(true);
            deliveryAddressStore.setIsAwaitGeolocation(false);
          },
          () => {
            deliveryAddressStore.setIsActiveGeolocation(false);
            deliveryAddressStore.setGeolocationCoordinates(null);
            deliveryAddressStore.setIsAwaitGeolocation(false);
            deliveryAddressStore.setIsMapBusy(false);
          },
        );
      } else {
        navigator.geolocation.getCurrentPosition(
          (e) => {
            if (!e || !e.coords?.latitude || !e.coords?.longitude) {
              deliveryAddressStore.setIsMapBusy(false);
              deliveryAddressStore.setIsAwaitGeolocation(false);
              return;
            }
            deliveryAddressStore.setGeolocationCoordinates({
              lat: e.coords.latitude,
              lng: e.coords.longitude,
            });
            deliveryAddressStore.setMapZoom(MAP_GEOLOCATION_ZOOM);
            deliveryAddressStore.setIsActiveGeolocation(true);
            deliveryAddressStore.setIsMapBusy(false);
            deliveryAddressStore.setIsSmallSizeCover(true);
            deliveryAddressStore.setIsAwaitGeolocation(false);
          },
          () => {
            deliveryAddressStore.setIsActiveGeolocation(false);
            deliveryAddressStore.setGeolocationCoordinates(null);
            deliveryAddressStore.setIsAwaitGeolocation(false);
            deliveryAddressStore.setIsMapBusy(false);
          },
        );
      }
    }
    deliveryAddressStore.setIsManuallyInput(false);
    deliveryAddressStore.setInputAddressFocused(false);

    /**
     * When user clicks on geolocation button, we need to block map actions,
     * so the user will not to see wrong address from MAP_CENTER
     * */
    deliveryAddressStore.setIsMapBusy(true);
  };
  return { requestLocation };
};
