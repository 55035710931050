import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';

const EmptyPurchased = () => {
  const { t } = useTranslation();

  return (
    <div className="page-empty">
      <img
        src={
          company.match({
            citydrinks: company.images.order?.empty,
            default: company.images.favs?.empty,
          }) ?? ''
        }
        className="page-empty__image"
        alt=""
      />
      <p className="page-empty__title">{t('phrases:emptyPurchased')}</p>
    </div>
  );
};

export default EmptyPurchased;
