import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';

import styles from './FreeDeliveryProgressBar.module.scss';

interface FreeDeliveryProgressBarProps {
  leftUntilFreeDelivery: string;
  cssWidth: string;
}

const FreeDeliveryProgressBar = ({
  leftUntilFreeDelivery,
  cssWidth,
}: FreeDeliveryProgressBarProps) => {
  const { t } = useTranslation();

  if (company.hideFreeDeliveryProgressBar) {
    return null;
  }

  return (
    <div className={styles.container} data-company={company.name}>
      <div className="status-description">
        <div className="status-description-header">
          <div className="status-description-header-text">
            <p>{t('add')}</p>
            <div className="status-description-header-text-marked">
              {leftUntilFreeDelivery}
            </div>
            <p>{t('toGetFreeDelivery')}</p>
          </div>
        </div>
      </div>
      <div className="status-line">
        <div
          className="status-line-fill"
          style={{ width: cssWidth, transition: 'all 0.3s' }}
        />
      </div>
    </div>
  );
};

export default FreeDeliveryProgressBar;
