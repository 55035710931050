import { classes } from 'html-classes';

import Icon from '~/components/Icon/Icon';

import styles from './Rating.module.scss';

type RatingProps = {
  rating: number;
  noRatingText: string;
  ratedClass?: string;
  noRatingClass?: string;
  ratedStarClassName?: string;
  noRatedStarClassName?: string;
};

const Rating = ({
  rating,
  noRatingText,
  ratedClass,
  noRatingClass,
  ratedStarClassName,
  noRatedStarClassName,
}: RatingProps) =>
  rating ? (
    <div className={classes([styles.rated, ratedClass])}>
      <Icon
        type="star-fill"
        size={12}
        className={classes([styles.ratedIcon, ratedStarClassName])}
      />
      {rating}
    </div>
  ) : (
    <div className={classes([styles.no_rating, noRatingClass])}>
      <Icon
        type="star-fill"
        size={12}
        className={classes([styles.no_rating__star, noRatedStarClassName])}
      />
      {noRatingText}
    </div>
  );

export default Rating;
