import { format } from 'date-fns-tz';

import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';

import Badge from '../Badge/Badge';

interface DeliveryDateLabelProps {
  deliveryDate: Date | null;
  className?: string;
  dateFormat?: string;
  text?: string;
  size?: 'xs' | 's' | 'm';
}

const DeliveryDateLabel = ({
  deliveryDate,
  className,
  dateFormat = 'dd.MM',
  text,
  size = 's',
}: DeliveryDateLabelProps) => {
  const { locale } = useDateLang();

  if (!deliveryDate && !text) {
    return null;
  }

  return (
    <Badge size={size} theme="secondary" className={className}>
      {text && text}
      {deliveryDate &&
        format(deliveryDate, dateFormat, {
          timeZone: company.config.timeZone,
          locale,
        })}
    </Badge>
  );
};

export default DeliveryDateLabel;
