import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';

import { KYCStatus } from '~/api/KYCStatus';
import { company } from '~/company/Company';

import { userStore } from '../../stores/UserStore';

import { AuthorizationGuardProps } from './interface';

export default observer(
  ({ children, requireVerified = false }: AuthorizationGuardProps) => {
    if (
      !userStore.isAuthorized ||
      (company.needKycCheck &&
        requireVerified &&
        userStore.personalData?.kycStatus !== KYCStatus.Verified)
    ) {
      return (
        <Navigate
          to="/"
          replace
          state={{ isShowAuthModal: !userStore.isAuthorized }}
        />
      );
    }

    return <>{children}</>;
  },
);
