import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductPropertyFilterCode } from '../../stores/CatalogStore';

import ProductBadge from './ProductBadge';

export const BADGES_FILTERS: Array<{
  id: string;
  filterCode: ProductPropertyFilterCode;
  filterValue: any;
}> = [];

interface Props {
  badgesViewedCount: number;
  onSelect: (id: string, code: ProductPropertyFilterCode, value: any) => void;
  onUnselect: (id: string, code: ProductPropertyFilterCode) => void;
  currentBadges: string[];
}

const ProductBadgesList = ({
  badgesViewedCount = 5,
  currentBadges,
  onSelect,
  onUnselect,
}: Props) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const badges = BADGES_FILTERS.map((badgeFilter) => ({
    ...badgeFilter,
    label: t(`badges:${badgeFilter.id}`),
  }));

  return (
    <div className="badges-wrapper">
      <div className="badges">
        {(showMore ? badges : badges.slice(0, badgesViewedCount)).map(
          (badge, i) => (
            <ProductBadge
              isChecked={currentBadges.includes(badge.id)}
              key={i}
              onChange={(isChecked: boolean) => {
                isChecked
                  ? onSelect(badge.id, badge.filterCode, badge.filterValue)
                  : onUnselect(badge.id, badge.filterCode);
              }}
              {...badge}
            />
          ),
        )}
        {!showMore && badges.length > badgesViewedCount && (
          <div
            className="badges__item _show-more"
            onClick={() => setShowMore(!showMore)}
          >
            {t('badges:showMore', {
              count: badges.length - badgesViewedCount ?? 0,
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductBadgesList;
