import { useEffect, useState } from 'react';

import { company } from '~/company/Company';
import CityDrinksStepData from '~/company/configs/citydrinks/onboarding';
import DefaultStepData from '~/company/configs/default/onboarding';
import { userStore } from '~/stores/UserStore';

let timeout: ReturnType<typeof setTimeout>;

export const useStepData = () => {
  const [selectedScreenIndex, setSelectedScreenIndex] = useState(0);
  const [isTimerAlive, setIsTimerAlive] = useState(
    company.hasOnboardingAutoplay,
  );

  const STEPS_DATA = company.variant({
    '1': CityDrinksStepData(),
    '2': DefaultStepData(),
  });

  useEffect(() => {
    if (
      selectedScreenIndex < STEPS_DATA.length - 1 &&
      isTimerAlive &&
      userStore.personalData.isAdult
    ) {
      timeout = setTimeout(() => {
        setSelectedScreenIndex((current) => current + 1);
      }, 5000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [selectedScreenIndex, userStore.personalData.isAdult]);

  return {
    handleChangeScreenIndex: setSelectedScreenIndex,
    handleTimerAlive: setIsTimerAlive,
    currentStep: selectedScreenIndex,
    stepsNumber: STEPS_DATA.length,
    currentStepData: STEPS_DATA[selectedScreenIndex],
  };
};
