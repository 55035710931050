import { classes } from 'html-classes';
import { useState, useEffect, useRef, MouseEvent } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { createProductUrl } from '~/components/CategoryBlocks/utils';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';

import { SommelierButtonProps } from './interfaces';
import styles from './SommelierButton.module.scss';
import { SommelierWindow } from './SommelierWindow';
import { ProductClickEventDetail } from './SommelierWindow/interfaces';
import { getProductSlugFromSommelierEvent } from './SommelierWindow/utils';

const SommelierButton = ({ className }: SommelierButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isSSR } = useGlobal();
  const navigate = useNavigate();
  const buttonRef = useRef<Nullable<HTMLButtonElement>>(null);
  const modalWrapperRef = useRef<Nullable<HTMLDivElement>>(null);

  const handleToggleSommelierWindow = (e: MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen((prev) => !prev);
  };

  const handleCloseSommelierWindow = () => {
    setIsModalOpen(false);
  };

  const handleSommelierProductClick = (
    detail: ProductClickEventDetail,
    e: Event,
  ) => {
    e.stopPropagation();
    const slug = getProductSlugFromSommelierEvent(detail);

    if (!slug) {
      return;
    }

    // TODO: Handle analytics event
    handleCloseSommelierWindow();
    navigate(createProductUrl({ id: slug }));
  };

  const renderModal = () => {
    if (isSSR) {
      return null;
    }

    return createPortal(
      <SommelierWindow
        open={isModalOpen}
        onProductClick={handleSommelierProductClick}
        ref={modalWrapperRef}
      />,
      document.body,
    );
  };

  useEffect(() => {
    const removeClassName = () => {
      document.body.classList.remove(`${styles.noScroll}`);
    };
    const outsideClickListener = (e: Event) => {
      const targetNode = e.target as HTMLElement;

      // Buttons in the suggestions block have unpredictable behaviour
      // .contains() method return false even for document.body element.
      // Added extra check for these buttons
      if (
        targetNode.tagName === 'BUTTON' &&
        targetNode.parentElement?.classList.contains('sb-footer-suggestions')
      ) {
        return;
      }

      if (
        buttonRef.current?.contains(targetNode) ||
        modalWrapperRef.current?.contains(targetNode)
      ) {
        return;
      }
      handleCloseSommelierWindow();
    };
    const cleanUp = () => {
      window.removeEventListener('click', outsideClickListener);
    };

    removeClassName();
    cleanUp();
    if (isModalOpen) {
      document.body.classList.add(`${styles.noScroll}`);
    }
    window.addEventListener('click', outsideClickListener);

    return () => {
      removeClassName();
      cleanUp();
    };
  }, [isModalOpen]);

  return (
    <>
      <button
        ref={buttonRef}
        className={classes([
          styles.root,
          isModalOpen && styles.opened,
          className,
        ])}
        type="button"
        onClick={handleToggleSommelierWindow}
      >
        {isModalOpen ? <Icon type="close" size={32} /> : 'AI'}
      </button>

      {renderModal()}
    </>
  );
};

export default SommelierButton;
