import { useEffect, useRef } from 'react';

import { orderStore } from '~/stores/OrderStore';

export const useOnWarehouseChange = (
  callback: (code: string, isChanged: boolean) => void,
  deps: unknown[] = [],
) => {
  const ref = useRef<string>();

  useEffect(() => {
    const code = orderStore.etaWarehouseCode;

    callback(code, Boolean(ref.current) && ref.current !== code);

    ref.current = code;
  }, [orderStore.etaWarehouseCode, ...deps]);
};
