import { CompanyName } from './interface';

export const CompaniesMap = [
  {
    name: CompanyName.CityDrinks,
    original: true,
  },
  {
    name: CompanyName.CityDrinks,
    original: false,
  },
  {
    name: CompanyName.Localee,
    original: false,
  },
  {
    name: CompanyName.Kids,
    original: true,
  },
  {
    name: CompanyName.Kids,
    original: false,
  },
  {
    name: CompanyName.Vilo,
    original: true,
  },
  {
    name: CompanyName.Vilo,
    original: false,
  },
  {
    name: CompanyName.CircleK,
    original: true,
  },
  {
    name: CompanyName.CircleK,
    original: false,
  },
  {
    name: CompanyName.Jiffy,
    original: true,
  },
  {
    name: CompanyName.Jiffy,
    original: false,
  },
];
