import Skeleton from 'react-loading-skeleton';

import styles from './DeliveryDesktopOfferSkeleton.module.scss';

const DeliveryDesktopOfferSkeleton = () => (
  <div className={styles.container}>
    <Skeleton className={styles.skeleton} />
    <Skeleton className={styles.skeleton} />
  </div>
);

export default DeliveryDesktopOfferSkeleton;
