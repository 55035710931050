import { ReactNode } from 'react';

import { IconTypes } from '~/components/Icon/IconTypes';

export enum BottomNavigationType {
  LINK = 'link',
  NODE = 'node',
}

export interface LinkNavItem {
  iconType: IconTypes;
  title: string;
  link: string;
}

export interface NodeNavItem {
  node: ReactNode;
}

export type BottomNavItem =
  | ({ type: BottomNavigationType.LINK } & LinkNavItem)
  | ({ type: BottomNavigationType.NODE } & NodeNavItem);

export type BottomNavigationItem = BottomNavItem & { key: string };
