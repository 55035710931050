import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import Icon from '~/components/Icon/Icon';
import { IconTypes } from '~/components/Icon/IconTypes';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';

import styles from './DeliveryMethodsTab.module.scss';
import { DeliveryMethodsTabProps } from './interfaces';

const DELIVERY_METHOD_ICONS: Record<ETADeliveryMethodType, IconTypes> = {
  JIFFY_DELIVERY: 'delivery-pin-mid',
  CLICK_AND_COLLECT: 'warehouse-pin',
};

const DeliveryMethodsTab = (props: DeliveryMethodsTabProps) => {
  const {
    onTabClick,
    activeTab = ETADeliveryMethodType.JiffyDelivery,
    className,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={classes([styles.root, className])}>
      {checkoutStore.availableDeliveryMethods.map((deliveryMethod) => {
        const isActive = activeTab === deliveryMethod;

        return (
          <div
            key={deliveryMethod}
            role="tab"
            tabIndex={-1}
            className={classes([styles.tab, isActive && styles.active])}
            onClick={() => onTabClick?.(deliveryMethod)}
          >
            <Icon
              className={styles.tab__icon}
              type={DELIVERY_METHOD_ICONS[deliveryMethod]}
              size={20}
            />
            <p className={styles.tab__label}>
              {t(`deliveryMethods:labels.${deliveryMethod}`)}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default observer(DeliveryMethodsTab);
