import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';

import { company } from '~/company/Company';

import LOGO_IMAGE from '../../../assets/img/logo_cd_color.svg';

import styles from './MobileLogo.module.scss';

const MobileLogo = () => {
  if (!company.showCompanyLogoOnMainMobile) {
    return null;
  }

  return (
    <div className={classes([styles.logoContainer, 'content-layout'])}>
      <img src={LOGO_IMAGE} alt="logo" width="140" />
    </div>
  );
};

export default observer(MobileLogo);
