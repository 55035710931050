/* eslint-disable import/order */
import emptyCartVariant2 from '~/assets/img/empty_cart.png';
import emptyCartVariant1 from '~/assets/img/variant1/empty-cart.png';
import { CompanyName } from '~/company/interface';

//ONDOARDING IAMGES
import NotFoundPage from '../assets/img/404_page.png';
import arEg from '../assets/img/circlek/ar-EG.png';
import enGb from '../assets/img/circlek/en-GB.png';

import { OboardingImages as OboardingImagesCd } from '../assets/img/citydrinks';

import EmptyAddressesCD from '../assets/img/empty_address.png';
import EmptyPaymentsCD from '../assets/img/empty_payments.png';
import NotFoundPageLocalee from '../assets/img/localee/404.png';
import EmptyAddressesLocalee from '../assets/img/localee/geolocation.png';
import EmptyPaymentsLocalee from '../assets/img/localee/wallet.png';
import logoDarkCD from '../assets/img/logo_cd_color.svg';
import LogoColorCD from '../assets/img/logo_cd_color_vertical.svg';
import LogoWhiteCD from '../assets/img/logo_cd_white.svg';
import logoDarkLocalee from '../assets/img/logo_localee.svg';
import logoLightLocalee from '../assets/img/logo_localee_orange.svg';
import joinLoyality from '../assets/img/onboarding/join_loyality.png';
import referFriend from '../assets/img/onboarding/refer_friend.png';
import shareThoughts from '../assets/img/onboarding/share_thoughts.png';
import signUp from '../assets/img/onboarding/sign_up.png';
import spotTheLight from '../assets/img/onboarding/spot_the_lighting.png';
import swiftDelivery from '../assets/img/onboarding/swift_delivery.png';
import trackDelivery from '../assets/img/onboarding/track_delivery.png';
import unlockComplementary from '../assets/img/onboarding/unlock_complementary.png';
import QRMaxiLocalee from '../assets/img/localee/qr_maxi.png';
import QRMiniLocalee from '../assets/img/localee/qr_mini.png';
import QRMaxiCD from '../assets/img/qr_footer.png';
import QRMiniCD from '../assets/img/qr_header.png';
import ReferralCD from '../assets/img/refer_friend.png';
import ReferralLocalee from '~/assets/img/localee/referral.png';
import EmptyFavsImageCD from '../assets/img/empty_fav.png';
import EmptyFavsImageLocalee from '../assets/img/localee/favs.png';
import EmptyOrdersLocalee from '../assets/img/localee/empty_orders.png';
import EmptyOrdersCD from '../assets/img/empty_orders.png';
import QRMaxiDefault from '../assets/img/qr_default_maxi.png';
import QRMiniDefault from '../assets/img/qr_default_mini.png';
import TimeCD from '../assets/img/closed_clock.png';
import TimeLocalee from '../assets/img/localee/time.png';
import SearchCD from '~/assets/img/illustration-empty-search.png';
import SearchLocalee from '../assets/img/localee/search.png';

import FavsVilo from '../assets/img/vilo/favs.png';
import NotFoundPageVilo from '../assets/img/vilo/404.png';
import LogoDarkVilo from '../assets/img/vilo/logo_dark.svg';
import LogoLightVilo from '../assets/img/vilo/logo_light.svg';
import EmptyCartVilo from '../assets/img/vilo/empty_cart.png';
import EmptyAddressVilo from '../assets/img/vilo/geolocation.png';
import EmptyWalletVilo from '../assets/img/vilo/wallet.png';
import EmptyOrdersVilo from '../assets/img/vilo/empty_orders.png';
import ReferralVilo from '../assets/img/vilo/referral.png';
import SearchVilo from '../assets/img/vilo/search.png';
import TimeVilo from '../assets/img/vilo/time.png';
import QRMaxiVilo from '../assets/img/vilo/qr_footer.png';
import QRMiniVilo from '../assets/img/vilo/qr_primary.png';

import { OboardingImages as OboardingImagesVilo } from '../assets/img/vilo';

import NotFoundPageCirclek from '../assets/img/circlek/404.png';
import EmptyCartCirclek from '../assets/img/circlek/empty_cart.png';
import EmptyAddressCirclek from '../assets/img/circlek/geolocation.png';
import EmptyWalletCirclek from '../assets/img/circlek/wallet.png';
import EmptyOrdersCirclek from '../assets/img/circlek/empty_orders.png';
import ReferralCirclek from '../assets/img/circlek/referral.png';
import FavsCirclek from '../assets/img/circlek/favs.png';
import logoDarkCirclek from '../assets/img/circlek/logo_circlek_red.png';
import logoLightCirclek from '../assets/img/circlek/logo_circlek_white.png';
import SearchCirclek from '../assets/img/circlek/search.png';
import TimeCirclek from '../assets/img/circlek/time.png';

import NotFoundPageJiffy from '../assets/img/jiffy/404.png';
import ReferralJiffy from '~/assets/img/jiffy/referral.png';
import logoDarkJiffy from '../assets/img/jiffy/logo_dark.png';
import logoLightJiffy from '../assets/img/jiffy/logo_light.png';

import logoKids from '../assets/img/kids/logo.png';
import NotFoundKids from '../assets/img/kids/404.png';
// import BellKids from '../assets/img/kids/bell.png';
// import CartKids from '../assets/img/kids/cart.png';
import EmptyCartKids from '../assets/img/kids/empty_cart.png';
import EmptyOrdersKids from '../assets/img/kids/empty_orders.png';
// import EmptySubsKids from '../assets/img/kids/empty_subs.png';
import FavsKids from '../assets/img/kids/favs.png';
import GeoKids from '../assets/img/kids/geolocation.png';
import ReferralKids from '../assets/img/kids/referral.png';
// import ReviewsKids from '../assets/img/kids/reviews.png';
import SearchKids from '../assets/img/kids/search.png';
// import SmtwrongKids from '../assets/img/kids/smtwrong.png';
import TimeKids from '../assets/img/kids/time.png';
// import TorchKids from '../assets/img/kids/torch.png';
import WalletKids from '../assets/img/kids/wallet.png';

import EmptyCartJiffy from '~/assets/img/jiffy/empty_cart.png';
import EmptyWalletJiffy from '~/assets/img/jiffy/wallet.png';
import EmptyAddressJiffy from '~/assets/img/jiffy/geolocation.png';
import EmptyOrdersJiffy from '~/assets/img/jiffy/empty_orders.png';
import FavsJiffy from '~/assets/img/jiffy/favs.png';
import SearchJiffy from '~/assets/img/jiffy/search.png';
import { OboardingImagesKids } from '~/assets/img/kids/onboarding';
// import InternetJiffy from '~/assets/img/jiffy/smtwrong.png';

/* eslint-enable import/order */

export const IMAGES_BY_COMPANY: any = {
  [CompanyName.CityDrinks]: {
    onboarding: OboardingImagesCd,
    404: {
      notFound: NotFoundPage,
    },
    cart: {
      empty: emptyCartVariant2,
    },
    address: {
      empty: EmptyAddressesCD,
    },
    locale: {
      en: enGb,
      ar: arEg,
    },
    logo: {
      dark: logoDarkCD,
      light: '',
    },
    ageRestriction: {
      white: LogoWhiteCD,
      color: LogoColorCD,
    },
    qr: {
      mini: QRMiniCD,
      maxi: QRMaxiCD,
    },
    payments: {
      empty: EmptyPaymentsCD,
    },
    order: {
      empty: EmptyOrdersCD,
    },
    referral: {
      hero: ReferralCD,
    },
    favs: {
      empty: EmptyFavsImageCD,
    },
    search: {
      empty: SearchCD,
    },
    time: TimeCD,
  },
  [CompanyName.Localee]: {
    onboarding: {
      swiftDelivery,
      spotTheLight,
      unlockComplementary,
      trackDelivery,
      shareThoughts,
      joinLoyality,
      referFriend,
      signUp,
    },
    404: {
      notFound: NotFoundPageLocalee,
    },
    cart: {
      empty: emptyCartVariant1,
    },
    address: {
      empty: EmptyAddressesLocalee,
    },
    locale: {
      en: enGb,
      ar: arEg,
    },
    logo: {
      dark: logoDarkLocalee,
      light: logoLightLocalee,
    },
    ageRestriction: {
      white: logoLightLocalee,
      color: logoDarkLocalee,
    },
    qr: {
      mini: QRMiniLocalee,
      maxi: QRMaxiLocalee,
    },
    payments: {
      empty: EmptyPaymentsLocalee,
    },
    order: {
      empty: EmptyOrdersLocalee,
    },
    referral: {
      hero: ReferralLocalee,
    },
    favs: {
      empty: EmptyFavsImageLocalee,
    },
    search: {
      empty: SearchLocalee,
    },
    time: TimeLocalee,
  },
  [CompanyName.CircleK]: {
    onboarding: {
      swiftDelivery,
      spotTheLight,
      unlockComplementary,
      trackDelivery,
      shareThoughts,
      joinLoyality,
      referFriend,
      signUp,
    },
    404: {
      notFound: NotFoundPageCirclek,
    },
    cart: {
      empty: EmptyCartCirclek,
    },
    address: {
      empty: EmptyAddressCirclek,
    },
    locale: {
      en: enGb,
      ar: arEg,
    },
    logo: {
      dark: logoDarkCirclek,
      light: logoLightCirclek,
    },
    ageRestriction: {
      white: logoLightCirclek,
      color: logoDarkCirclek,
    },
    qr: {
      mini: QRMiniDefault,
      maxi: QRMaxiDefault,
    },
    payments: {
      empty: EmptyWalletCirclek,
    },
    order: {
      empty: EmptyOrdersCirclek,
    },
    referral: {
      hero: ReferralCirclek,
    },
    favs: {
      empty: FavsCirclek,
    },
    search: {
      empty: SearchCirclek,
    },
    time: TimeCirclek,
  },
  [CompanyName.Vilo]: {
    onboarding: OboardingImagesVilo,
    404: {
      notFound: NotFoundPageVilo,
    },
    cart: {
      empty: EmptyCartVilo,
    },
    address: {
      empty: EmptyAddressVilo,
    },
    locale: {
      en: enGb,
      ar: arEg,
    },
    logo: {
      dark: LogoDarkVilo,
      light: LogoLightVilo,
    },
    ageRestriction: {
      white: LogoLightVilo,
      color: LogoDarkVilo,
    },
    qr: {
      mini: QRMiniVilo,
      maxi: QRMaxiVilo,
    },
    payments: {
      empty: EmptyWalletVilo,
    },
    order: {
      empty: EmptyOrdersVilo,
    },
    referral: {
      hero: ReferralVilo,
    },
    favs: {
      empty: FavsVilo,
    },
    search: {
      empty: SearchVilo,
    },
    time: TimeVilo,
  },
  [CompanyName.Jiffy]: {
    onboarding: {
      swiftDelivery,
      spotTheLight,
      unlockComplementary,
      trackDelivery,
      shareThoughts,
      joinLoyality,
      referFriend,
      signUp,
    },
    404: {
      notFound: NotFoundPageJiffy,
    },
    cart: {
      empty: EmptyCartJiffy,
    },
    address: {
      empty: EmptyAddressJiffy,
    },
    locale: {
      en: enGb,
      ar: arEg,
    },
    logo: {
      dark: logoDarkJiffy,
      light: logoLightJiffy,
    },
    ageRestriction: {
      white: logoLightJiffy,
      color: logoDarkJiffy,
    },
    qr: {
      mini: QRMiniLocalee,
      maxi: QRMaxiLocalee,
    },
    payments: {
      empty: EmptyWalletJiffy,
    },
    order: {
      empty: EmptyOrdersJiffy,
    },
    referral: {
      hero: ReferralJiffy,
    },
    favs: {
      empty: FavsJiffy,
    },
    search: {
      empty: SearchJiffy,
    },
    time: TimeLocalee,
  },
  [CompanyName.Kids]: {
    onboarding: OboardingImagesKids,
    404: {
      notFound: NotFoundKids,
    },
    cart: {
      empty: EmptyCartKids,
    },
    address: {
      empty: GeoKids,
    },
    locale: {
      en: enGb,
      ar: arEg,
    },
    logo: {
      dark: logoKids,
      light: logoKids,
    },
    ageRestriction: {
      white: logoKids,
      color: logoKids,
    },
    qr: {
      mini: QRMiniLocalee,
      maxi: QRMaxiLocalee,
    },
    payments: {
      empty: WalletKids,
    },
    order: {
      empty: EmptyOrdersKids,
    },
    referral: {
      hero: ReferralKids,
    },
    favs: {
      empty: FavsKids,
    },
    search: {
      empty: SearchKids,
    },
    time: TimeKids,
  },
};
