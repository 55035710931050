import mitt from 'mitt';
import { makeObservable, observable, computed, action } from 'mobx';

type TaskEvents<Result> = {
  success: Awaited<Result>;
  error: Error;
};

enum TaskStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

export interface TaskOptions {
  debounce?: number;
}

export class Task<Result, Args extends unknown[] = []> {
  private readonly task: (...args: Args) => Result;
  public readonly options: TaskOptions;

  protected isRunning = false;
  private readonly eventEmitter = mitt<TaskEvents<Result>>();
  private error: Nullable<Error> = null;
  private status = TaskStatus.IDLE;
  private timeout?: NodeJS.Timeout;

  constructor(task: (...args: Args) => Result, options?: TaskOptions) {
    makeObservable(this, {
      isRunning: observable,
      error: observable,
      status: observable,
      isIdle: computed,
      isLoading: computed,
      isFulfilled: computed,
      isRejected: computed,
      lastError: computed,
      setStatus: action,
      run: action,
      runDebounce: action,
      handleSuccess: action,
      handleError: action,
    } as any);

    this.task = task;
    this.options = options || {};
  }

  get isIdle() {
    return this.status === TaskStatus.IDLE;
  }

  public get isLoading() {
    return this.status === TaskStatus.LOADING;
  }

  public get isFulfilled() {
    return this.status === TaskStatus.FULFILLED;
  }

  public get isRejected() {
    return this.status === TaskStatus.REJECTED;
  }

  public get lastError() {
    return this.error;
  }

  public get on() {
    return this.eventEmitter.on;
  }

  public get off() {
    return this.eventEmitter.off;
  }

  public readonly setStatus = (status: EnumValues<TaskStatus>) => {
    this.status = status as TaskStatus;
  };

  public readonly run = (...args: Args): Result => {
    try {
      this.isRunning = true;
      this.status = TaskStatus.LOADING;
      const result = this.task(...args);

      if (result instanceof Promise) {
        return result
          .then(this.handleSuccess)
          .catch(this.handleError) as Result;
      } else {
        return this.handleSuccess(result as Awaited<Result>);
      }
    } catch (error) {
      return this.handleError(error);
    }
  };

  public readonly runDebounce = (...args: Args): void => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(
      () => this.run(...args),
      this.options.debounce || 200,
    );
  };

  private readonly handleSuccess = (data: Awaited<Result>) => {
    this.isRunning = false;
    this.status = TaskStatus.FULFILLED;
    this.error = null;
    this.eventEmitter.emit('success', data);
    return data;
  };

  private readonly handleError = (error: unknown): never => {
    this.isRunning = false;
    this.status = TaskStatus.REJECTED;
    this.error = error instanceof Error ? error : new Error(String(error));
    this.eventEmitter.emit('error', this.error);
    throw error;
  };
}
