import { classes } from 'html-classes';
import { useTranslation } from 'react-i18next';

import { CheckPromocodeResponse } from '~/api/Order';
import Icon from '~/components/Icon/Icon';
import styles from '~/pages/ReferralProgram/ReferralProgram.module.scss';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';
import { generateLinkByCode } from '~/utils/referralLink';

interface ReferralCodeProps {
  isLoading: boolean;
}

export const ReferralCode = ({ isLoading }: ReferralCodeProps) => {
  const { t } = useTranslation();

  const getDiscount = (instance?: Nullable<CheckPromocodeResponse>): string => {
    if (!instance) {
      return '';
    }

    const { type, value } = instance;

    if (type === 'PERCENTAGE') {
      return `${value}%`;
    }

    return mainStore.addCurrencySymbol(mainStore.convertPenceToPounds(value));
  };

  const getShareMessage = () => {
    if (!userStore.refereeInfo || !userStore.personalData?.id) {
      return '';
    }

    const link = generateLinkByCode(
      userStore.refereeInfo.code,
      userStore.personalData.id,
    );

    const amount =
      userStore.referralInfo && userStore.referralInfo.minimumPurchase > 0
        ? t('referralProgram:shareAmount', {
            amount: userStore.referralInfo.minimumPurchase,
          })
        : '';

    return t('referralProgram:shareText', {
      discount: getDiscount(userStore.referralInfo),
      code: userStore.refereeInfo.code,
      amount,
      link,
    });
  };

  const copyCode = async (message: string): Promise<void> => {
    try {
      await navigator.clipboard.writeText(message);
      mainStore.pushAlert('success', t('codeCopied'));
    } catch (error) {
      mainStore.pushAlert('error', t('codeCopyError'));
    }
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Referral program: Copy code',
      params: {},
    });
  };

  const handleCopyCode = async () => {
    if (!userStore.refereeInfo) {
      return;
    }

    await copyCode(userStore.refereeInfo.code);
  };

  const handleShare = async () => {
    if (!userStore.refereeInfo) {
      return;
    }

    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_SHARE,
    );

    const message = getShareMessage();

    if (typeof navigator === 'undefined' || !navigator.share) {
      await copyCode(message);
      return;
    }

    try {
      await navigator.share({
        title: t('referralProgram:shareTitle'),
        text: message,
      });
    } catch (e) {
      const error = e as DOMException;
      if (error.name !== 'AbortError') {
        await copyCode(message);
      }
    }
  };

  return (
    <div className={styles.code__wrapper}>
      <div className={styles.code} role="button" onClick={handleCopyCode}>
        <span className={styles.code__value}>
          {isLoading ? (
            <span className="spinner _primary" />
          ) : (
            userStore.refereeInfo?.code
          )}
        </span>
        <span className={styles.code__copy}>
          <Icon type="copy" />
        </span>
      </div>
      <button
        type="button"
        className={classes(['button _primary _big', styles.code__share])}
        onClick={handleShare}
      >
        <Icon type="share" /> {t('share')}
      </button>
    </div>
  );
};
