import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { CollapsibleList } from '~/components/CollapsibleList/CollapsibleList';
import { ModalType } from '~/components/Modal/interface';
import Rating from '~/components/Rating';
import Section from '~/components/Section';
import { useModal } from '~/hooks/useModal';
import { catalogStore } from '~/stores/CatalogStore';
import { Product } from '~/stores/CategoriesStore';
import { ReviewsStore } from '~/stores/ReviewsStore';
import { userStore } from '~/stores/UserStore';
import { ProductReview } from '~/types/Product/interface';

import styles from '../Product.module.scss';

import { ReviewItem } from './ReviewItem';

type Props = {
  productState?: Product | null;
  reviews: ReviewsStore;
};

const ProductInfoReviews = ({ productState, reviews }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <div
      className={classes([
        'content-layout',
        styles.shortListContainer,
        styles.desktop,
        styles.reviewContainer,
      ])}
    >
      <Section
        title={t('reviews')}
        className={classes([styles.reviewList, styles.shortList])}
        headerClassName={styles.reviewSectionHeader}
        horizontalHeader
      >
        {reviews.list.length > 0 ? (
          <div className={styles.productInfoReviewsContainer}>
            <div className={styles.productInfoReviewsShort}>
              <Rating
                rate={productState?.ratingAverage ?? 0}
                starSize={20}
                showStars={true}
                classNameText={styles.reviewRatingText}
                className={styles.reviewRating}
              />
              {userStore.isAuthorized && reviews.canCreateReview && (
                <div className={styles.productInfoReviewsShortAddReview}>
                  <p className={styles.productInfoReviewsShortAddReviewText}>
                    {t('phrases:forReview')}
                  </p>
                  <button
                    className={classes([
                      'button _primary',
                      styles.productInfoReviewsShortAddReviewButton,
                    ])}
                    onClick={() => {
                      catalogStore.feedbackProduct = productState ?? null;
                      openModal(ModalType.Feedback);
                    }}
                  >
                    {t('writeReview')}
                  </button>
                </div>
              )}
            </div>
            <div className={styles.productInfoReviewsContent}>
              {reviews.list.length > 0 && (
                <div
                  className={styles.reviewListContent}
                  data-company={company.name}
                >
                  <CollapsibleList
                    sublistClassName={styles.reviewListContent}
                    sublistClassNameCollapsible={styles.__collapsible}
                    itemsToShow={reviews.list
                      .slice(0, 4)
                      .map((review: ProductReview) => (
                        <ReviewItem key={review.id} {...review} />
                      ))}
                    itemsToHide={reviews.list
                      .slice(4)
                      .map((review: ProductReview) => (
                        <ReviewItem key={review.id} {...review} />
                      ))}
                    showMoreText={t('readMoreReviews')}
                    showLessText={t('lessReviews')}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.noReviews}>
            <Rating
              rate={productState?.ratingAverage ?? 0}
              starSize={24}
              hideText
              showStars
              type="star-fill"
              classNameStart={styles.noReviewsStars}
            />
            {userStore.isAuthorized && reviews.canCreateReview ? (
              <>
                <div className={styles.noReviewsContent}>
                  <p className={styles.noReviewsContentTitle}>
                    {t('noReviewsYet')}
                  </p>
                  <p className={styles.noReviewsContentDescription}>
                    {t('phrases:noRatingTellUs')}
                  </p>
                </div>
                <button
                  className={classes([
                    'button _primary',
                    styles.noReviewsButton,
                  ])}
                  onClick={() => {
                    catalogStore.feedbackProduct = productState ?? null;
                    openModal(ModalType.Feedback);
                  }}
                >
                  {t('writeReview')}
                </button>
              </>
            ) : (
              <div className={styles.noReviewsContent}>
                <p className={styles.noReviewsContentTitle}>{t('noRating')}</p>
                <p className={styles.noReviewsContentDescription}>
                  {t('phrases:noRewiewsTellUs')}
                </p>
              </div>
            )}
          </div>
        )}
      </Section>
    </div>
  );
};

export default observer(ProductInfoReviews);
