import { observer } from 'mobx-react-lite';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from '~/hooks/useModal';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import Icon from '../Icon/Icon';

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [step, setStep] = useState(0);
  const [emailVal, setEmailVal] = useState<string>(
    () => userStore.personalData.email || checkoutStore.emailVal,
  );

  const backHandler = () => closeModal();

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailVal(e.currentTarget.value);
  };

  const updateUserEmail = () => {
    if (!isEmailValid) {
      return;
    }

    const value = emailVal.trim();

    if (value === userStore.personalData.email) {
      return;
    }

    checkoutStore.setIsEmailSync(false);
    checkoutStore.setIsPersonalDataChanged(true);
    checkoutStore.setIsPersonalDataSync(false);

    userStore
      .updatePersonalEmail(value)
      .then((flag) => {
        checkoutStore.setIsEmailSync(flag);
        checkoutStore.setIsPersonalDataSync(flag);

        if (flag) {
          setStep(1);
        }
      })
      .catch((error) => error && console.error(error));
  };

  useEffect(() => {
    checkoutStore.setEmailVal(userStore.personalData.email || '');
  }, []);

  const isEmailValid = mainStore.validateEmail(emailVal);

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__back"
          onClick={backHandler}
        >
          <Icon type="arrow" className="icon__rotate-180" size={24} />
        </button>
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={backHandler}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        {step === 1 && (
          <div className="input-form__form">
            <div className="input-form__form-header">
              <div className="input-form__form-header-image">
                <Icon type="check-round" size={100} />
              </div>
              <p className="input-form__form-header-title">
                {t('phrases:emailChanged')}
              </p>
            </div>
            <div className="input-form__form-content" />
            <div className="input-form__form-footer">
              <div className="input-form__form-footer-buttons">
                <button className="button _bordered _med" onClick={backHandler}>
                  {t('goBack')}
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 0 && (
          <div className="input-form__form">
            <div className="input-form__form-header">
              <p className="input-form__form-header-title">
                {t('changeEmail')}
              </p>
              <p className="input-form__form-header-subtitle">
                {t('phrases:enterNewEmail')}
              </p>
              <div className="input-form__input-container">
                <input
                  placeholder=""
                  value={emailVal}
                  // isValid={checkoutStore.isEmailValid && checkoutStore.isEmailSync}
                  // isError={!!(checkoutStore.emailVal.length && !checkoutStore.isEmailValid)}
                  onChange={handleEmailChange}
                  disabled={userStore.emailChangeMeta.isLoading}
                  className="input-form__input-visible"
                  autoFocus
                />
                {!!(emailVal.length && !isEmailValid) && (
                  <p className="error">{t('phrases:invalidEmail')}</p>
                )}
              </div>
            </div>
            <div className="input-form__form-footer">
              <div className="input-form__form-footer-buttons">
                <button
                  type="button"
                  className="button _primary _med"
                  onClick={updateUserEmail}
                  disabled={
                    !isEmailValid || userStore.emailChangeMeta.isLoading
                  }
                >
                  {userStore.emailChangeMeta.isLoading && (
                    <span className="spinner _white" />
                  )}
                  {t('change')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
