import { isEmpty } from 'lodash-es';

import { DeliverySlotsResponse } from '~/api/ETA';

export const truncateSlots = (
  slots: DeliverySlotsResponse,
): DeliverySlotsResponse => {
  const slotsEntries = Object.entries(slots).sort(([a], [b]) => +a - +b);

  const start = slotsEntries.findIndex(([, value]) => !isEmpty(value));

  const end = slotsEntries
    .slice(start)
    .reverse()
    .findIndex(([, value]) => !isEmpty(value));

  return Object.fromEntries(
    slotsEntries.slice(start, slotsEntries.length - end),
  );
};
