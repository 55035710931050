import { CSSProperties, RefObject, useEffect, useState, useRef } from 'react';

import { KYCStatus } from '~/api/KYCStatus';
import { DESKTOP_HEADER_ID } from '~/components/Header/constants';
import { SECOND } from '~/constants/variables';
import { useIsTablet } from '~/pages/Order/hooks';
import { userStore } from '~/stores/UserStore';

interface UseStickyWrapperOptions {
  elementRef: RefObject<HTMLDivElement | null>;
  containerRef: RefObject<HTMLDivElement | null>;
  containerPaddingTop?: number;
  containerPaddingBottom?: number;
  headerGap?: number;
  headerId?: string;
}

export const useStickyWrapper = (props: UseStickyWrapperOptions) => {
  const {
    elementRef,
    containerRef,
    containerPaddingTop = 0,
    containerPaddingBottom = 0,
    headerGap = 0,
    headerId = DESKTOP_HEADER_ID,
  } = props;

  const [style, setStyle] = useState<CSSProperties>({});

  const isFixedRef = useRef<boolean>(false);
  const isFinishedRef = useRef<boolean>(false);

  const isTablet = useIsTablet();

  useEffect(() => {
    const element = elementRef.current;
    const container = containerRef.current;

    const listener = () => {
      if (!element || !container) {
        return;
      }

      const headerElement = document.getElementById(headerId);
      const { height: headerHeight = 0 } =
        headerElement?.getBoundingClientRect() || {};

      const elementRect = element.getBoundingClientRect();
      const containerRect = container.getBoundingClientRect();

      const scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.scrollY;

      const scrollTopWithOffset = scrollTop + headerHeight + headerGap;
      const scrollBottom = scrollTopWithOffset + elementRect.height;

      const startPosition = container.offsetTop + containerPaddingTop;
      const endPosition =
        startPosition +
        (containerRect.height - containerPaddingTop - containerPaddingBottom);

      if (
        !isFixedRef.current &&
        !isFinishedRef.current &&
        scrollTopWithOffset >= startPosition
      ) {
        isFixedRef.current = true;
        setStyle({
          position: 'fixed',
          top: headerHeight + headerGap,
          width: elementRect.width,
        });
      }

      if (
        isFixedRef.current &&
        !isFinishedRef.current &&
        scrollTopWithOffset < startPosition
      ) {
        isFixedRef.current = false;
        setStyle({
          position: 'static',
        });
      }

      if (
        isFixedRef.current &&
        !isFinishedRef.current &&
        scrollBottom >= endPosition
      ) {
        isFinishedRef.current = true;
        setStyle({
          position: 'absolute',
          bottom: containerPaddingBottom,
          width: elementRect.width,
        });
      }

      if (
        isFixedRef.current &&
        isFinishedRef.current &&
        scrollBottom < endPosition
      ) {
        isFinishedRef.current = false;
        setStyle({
          position: 'fixed',
          top: headerHeight + headerGap,
          width: elementRect.width,
        });
      }
    };

    listener();

    if (isTablet) {
      window.removeEventListener('scroll', listener);
      return;
    }

    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [isTablet]);

  return { style };
};

export const useUserVerificationStatusChecker = () => {
  const verificationStatusInterval = useRef<ReturnType<
    typeof setInterval
  > | null>(null);
  const canCheckStatus =
    userStore.personalData.kycStatus !== KYCStatus.Verified &&
    userStore.isAuthorized;

  useEffect(() => {
    if (canCheckStatus) {
      userStore.requestPersonalData(true);
    }
  }, []);

  useEffect(() => {
    if (canCheckStatus) {
      verificationStatusInterval.current = setInterval(() => {
        userStore.requestPersonalData(true);
      }, 15 * SECOND);
    }
    return () => {
      if (verificationStatusInterval.current) {
        return clearInterval(verificationStatusInterval.current);
      }
    };
  }, [userStore.isAuthorized, userStore.personalData.kycStatus]);
};
