import { makeAutoObservable } from 'mobx';
import { v4 as uuid } from 'uuid';

import { CartCalculationOrderResponse } from '~/api/Catalog';
import { DeliverySlot } from '~/api/ETA';
import Delivery from '~/stores/CheckoutStore/Delivery';
import { CheckoutStorePartial } from '~/stores/CheckoutStore/interfaces';
import { BaseStore } from '~/stores/interfaces/BaseStore';

class SlotSelectModalStore implements BaseStore {
  private readonly _checkoutStore: CheckoutStorePartial;

  private _selectedSlot: Delivery | null = null;
  private _selectedOrder: CartCalculationOrderResponse | null = null;

  constructor(checkoutStore: CheckoutStorePartial) {
    this._checkoutStore = checkoutStore;

    makeAutoObservable(this);
  }

  get selectedSlot(): Delivery | null {
    return this._selectedSlot;
  }

  get selectedOrder() {
    return this._selectedOrder;
  }

  setSelectedSlot(
    slot: Delivery | null,
    order: CartCalculationOrderResponse | null,
  ): void {
    this._selectedSlot = slot;
    this._selectedOrder = order;
  }

  updateDelivery(selectedSlotId?: string, selectedDate?: string) {
    const { deliveries } = this._checkoutStore;
    const currentDelivery = this.selectedSlot;

    if (!deliveries || !currentDelivery) {
      throw new Error('Something went wrong');
    }

    const currentIndex = deliveries.findIndex(
      (item) => item.id === currentDelivery.id,
    );

    const deliveryIndexToUpdate = deliveries.findIndex(
      ({ slotDeliveryDetails }) =>
        slotDeliveryDetails &&
        selectedDate &&
        slotDeliveryDetails.scheduleSlotId === selectedSlotId &&
        slotDeliveryDetails.currentDate === +selectedDate / 1000,
    );

    const newDeliveries = this._getDeliveries(
      deliveryIndexToUpdate,
      selectedSlotId,
      selectedDate,
    );

    newDeliveries.splice(currentIndex, 1);
    newDeliveries.sort((a, b) => {
      const dateA = a.slotDeliveryDetails?.currentDate;
      const dateB = b.slotDeliveryDetails?.currentDate;

      if (!dateA && !dateB) {
        return 0;
      }

      if (!dateA) {
        return -1;
      }

      if (!dateB) {
        return 1;
      }

      return dateA - dateB;
    });

    this._checkoutStore.setDeliveries(newDeliveries);
  }

  private _getDeliveries(
    indexToUpdate: number,
    selectedSlotId?: string,
    selectedDate?: string,
  ): Delivery[] {
    const { deliveries } = this._checkoutStore;
    const currentDelivery = this.selectedSlot;

    if (!deliveries || !currentDelivery) {
      return [];
    }

    if (indexToUpdate >= 0) {
      return deliveries.map((delivery, index) => {
        if (index === indexToUpdate) {
          return {
            ...delivery,
            itemsIds: delivery.itemsIds.concat(currentDelivery.itemsIds),
          };
        }

        return delivery;
      });
    }

    const selectedSlotDeliveryDetails =
      selectedSlotId && selectedDate
        ? {
            scheduleSlotId: selectedSlotId,
            currentDate: +selectedDate / 1000,
          }
        : undefined;
    const lastSelectedSlot = this.getSlotByIdAndDate(
      selectedSlotDeliveryDetails?.scheduleSlotId,
      selectedSlotDeliveryDetails?.currentDate
        ? `${selectedSlotDeliveryDetails?.currentDate}`
        : '',
    );

    return [
      ...deliveries,
      {
        id: uuid(),
        itemsIds: currentDelivery.itemsIds,
        slotDeliveryDetails: selectedSlotDeliveryDetails,
        slot: lastSelectedSlot,
      },
    ];
  }

  getSlotByIdAndDate(
    slotId?: string | null,
    slotDate?: string | null,
  ): DeliverySlot | undefined {
    return slotId && slotDate
      ? this._checkoutStore.sortedDeliverySlotsList?.find(
          ({ schedule_slot_id, current_date }) =>
            schedule_slot_id === slotId && `${current_date}` === slotDate,
        )
      : undefined;
  }

  destroy() {}
}

export default SlotSelectModalStore;
