import { classes } from 'html-classes';
import { useState } from 'react';

// import EVENT_IMAGE from '../../assets/img/temp/upcomming-1.png';
import Icon from '../../components/Icon/Icon';
import MainSidebar from '../../components/MainSidebar/MainSidebar';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Events.module.scss';

const Events = () => {
  const [current, setCurrent] = useState(0);

  return (
    <>
      <MobileHeaderLayout content={<MobileHeaderTitle text="My Events" />} />
      <div className="content-layout">
        <div className={classes(['content events', styles.content])}>
          <aside className="sidebar">
            <MainSidebar />
          </aside>

          <main className="container">
            <div className={classes(['title-wrapper', styles.titleWrapper])}>
              <button className="button _no-color">
                <Icon type="arrow" size={24} className="icon__rotate-180" />
              </button>
              <div className="title">My Events</div>
              <div className="badge">1</div>
            </div>

            <section className="tools-btn">
              <div className="text-wrapper">
                <button
                  className={classes(['left-btn', current === 1 && 'active'])}
                  onClick={() => setCurrent(1)}
                >
                  Upcoming Events
                </button>
                <button
                  className={classes(['right-btn', current === 2 && 'active'])}
                  onClick={() => setCurrent(2)}
                >
                  Visited Events
                </button>
              </div>
            </section>

            {current !== 2 && (
              <section className="events-upcoming">
                <div className="content-wrapper">
                  <div className="title">Upcoming</div>

                  <div className="items">
                    <div className="item">
                      <div className="item-bg">
                        {/* <img alt="" src={EVENT_IMAGE} className="image" /> */}
                      </div>
                      <div className="top-block">
                        <div className="text-wrapper">
                          <Icon type="friends" size={24} />
                          <div className="text">108</div>
                        </div>
                      </div>
                      <div className="middle-block">
                        <div className="text-wrapper">
                          <div className="text">MAR 8 | 8:30 PM</div>
                        </div>
                      </div>
                      <div className="bottom-block">
                        <div className="text-wrapper">
                          <div className="text">
                            Some event title degustation and some other text
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {current !== 1 && (
              <section className="events-visited">
                <div className="content-wrapper">
                  <div className="title">Visited</div>

                  <div className="items">
                    <div className="item item1">
                      <div className="item-bg">
                        {/* <img alt="" src={EVENT_IMAGE} className="image" /> */}
                      </div>
                      <div className="top-block">
                        <div className="text-wrapper">
                          <Icon type="friends" size={24} />
                          <div className="text">72</div>
                        </div>
                      </div>
                      <div className="middle-block">
                        <div className="text-wrapper">
                          <div className="text">MAR 8 | 8:30 PM</div>
                        </div>
                      </div>
                      <div className="bottom-block">
                        <div className="text-wrapper">
                          <div className="text">
                            Some event title degustation
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item item2">
                      <div className="item-bg">
                        {/* <img alt="" src={EVENT_IMAGE} className="image" /> */}
                      </div>
                      <div className="top-block">
                        <div className="text-wrapper">
                          <Icon type="friends" size={24} />
                          <div className="text">56</div>
                        </div>
                      </div>
                      <div className="middle-block">
                        <div className="text-wrapper">
                          <div className="text">MAR 8 | 8:30 PM</div>
                        </div>
                      </div>
                      <div className="bottom-block">
                        <div className="text-wrapper">
                          <div className="text">
                            Some event title degustation
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item item3">
                      <div className="item-bg">
                        {/* <img alt="" src={EVENT_IMAGE} className="image" /> */}
                      </div>
                      <div className="top-block">
                        <div className="text-wrapper">
                          <Icon type="friends" size={24} />
                          <div className="text">223</div>
                        </div>
                      </div>
                      <div className="middle-block">
                        <div className="text-wrapper">
                          <div className="text">MAR 8 | 8:30 PM</div>
                        </div>
                      </div>
                      <div className="bottom-block">
                        <div className="text-wrapper">
                          <div className="text">
                            Some event title degustation
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default Events;
