import { useState, useEffect } from 'react';

const useMediaQuery = (query: string, defaults: boolean = false): boolean => {
  const [matches, setMatches] = useState(defaults);

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);

    const handleMatch = (e: MediaQueryListEvent) => {
      setMatches(e.matches);
    };

    mediaQuery.addEventListener('change', handleMatch);
    setMatches(mediaQuery.matches);

    return () => {
      mediaQuery.removeEventListener('change', handleMatch);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
