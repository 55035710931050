import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';
import Modal from '~/components/Modal/Modal';
import { useGlobal } from '~/hooks/useGlobal';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { SlotDeliveryDetails } from '~/stores/CheckoutStore/Delivery';

import DeliverySlotItem from './DeliverySlotItem';
import styles from './ModalSlotsChangedEvent.module.scss';

const ModalSlotsChangedEvent = () => {
  const { slotsChangedEventStore } = checkoutStore;
  const { t } = useTranslation();
  const { isMobile } = useGlobal();

  const getKey = (delivery?: SlotDeliveryDetails): string =>
    delivery ? `${delivery.scheduleSlotId}${delivery.currentDate}` : 'express';

  const handleCloseModal = () => {
    slotsChangedEventStore.closeModal();
  };

  return (
    <Modal
      isShow={slotsChangedEventStore.isOpen}
      className={styles.root}
      onBackdropDismiss={handleCloseModal}
    >
      <header className={styles.header}>
        <aside>
          <h2>{t('deliverySlotsChangedEvent:title')}</h2>
          {t('deliverySlotsChangedEvent:caption')
            .split('\n')
            .map((line) => (
              <p key={line}>{line}</p>
            ))}
        </aside>
        <aside>
          <span
            className={styles.close}
            role="button"
            onClick={handleCloseModal}
          >
            <Icon type="close" size={isMobile ? 24 : 40} />
          </span>
        </aside>
      </header>
      <main className={styles.body}>
        {slotsChangedEventStore.difference && (
          <ul className={styles.slotList}>
            {slotsChangedEventStore.difference.map((delivery) => {
              const key = getKey(delivery.slotDeliveryDetails);

              return (
                <DeliverySlotItem key={key} deliveryDifference={delivery} />
              );
            })}
          </ul>
        )}
      </main>
      <footer className={styles.footer}>
        <button
          className="button _primary"
          type="button"
          onClick={handleCloseModal}
        >
          {t('deliverySlotsChangedEvent:buttons.ok')}
        </button>
      </footer>
    </Modal>
  );
};

export default observer(ModalSlotsChangedEvent);
