import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';

import styles from './Orders.module.scss';

type OrderItemProps = {
  isActive?: boolean;
};

export const OrderItemSkeleton = ({ isActive }: OrderItemProps) => {
  return (
    <div className={classes([styles.orderItem, 'order-item_skeleton'])}>
      <div className={classes(['order-wrapper', isActive && '_active'])}>
        <div className="header">
          <div className="title-wrapper">
            <Skeleton className="title" />
            <Skeleton className="subtitle" />
          </div>
          <Skeleton className="badge-skeleton" />
        </div>
        <div className="body">
          <div className="left-block">
            <div className="paid-wrapper">
              <Skeleton className="title" />
              <Skeleton className="value" />
            </div>
          </div>
          <div className="right-block">
            <div className="buttle-wrapper">
              {[1, 2, 3].map((item) => (
                <Skeleton key={item} className="buttle-wrapper-image" />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemSkeleton;
