import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { company, DEFAULT_COMPANIES } from '~/company/Company';

import { MODAL_EDIT_ADDRESS_PARAM, useModal } from '../../hooks/useModal';
import { orderStore } from '../../stores/OrderStore';
import { userStore } from '../../stores/UserStore';

import { HeaderSearch } from '../HeaderSearch';
import Icon from '../Icon/Icon';
import { ModalType } from '../Modal/interface';

import { DeliveryButton } from './components/DeliveryButton';
import { DownloadApp } from './components/DownloadApp';
import HeaderMenu from './components/HeaderMenu';
import { NavButtons } from './components/NavButtons';
import { DESKTOP_HEADER_ID } from './constants';
import styles from './Header.module.scss';

export default observer(() => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const [, setSearchParams] = useSearchParams();

  const handleDeliveryAddress = () => {
    const searchParams = openModal(ModalType.DeliveryAddress);
    searchParams.set(
      MODAL_EDIT_ADDRESS_PARAM,
      userStore?.deliveryAddress?.addressId || '',
    );
    setSearchParams(searchParams, { replace: true });
  };

  const {
    message: expressDeliveryMessage,
    args: expressDeliveryMessageArgs,
    showFlashIcon,
  } = orderStore.getDeliveryLabel({ minLabel: t('min') });

  if (DEFAULT_COMPANIES.includes(company.name)) {
    return (
      <header
        id={DESKTOP_HEADER_ID}
        className={classes([
          styles.header,
          styles.header_root,
          styles.compactScroll,
        ])}
        data-company={company.name}
      >
        {!company.hideDownloadAppPanel && <DownloadApp />}
        <div className={styles.headerElementWrapper}>
          <div
            className={classes(['content-layout', styles.deliveryContainer])}
          >
            <DeliveryButton
              {...{
                expressDeliveryMessage,
                expressDeliveryMessageArgs,
                showFlashIcon,
                handleDeliveryAddress,
              }}
            />
          </div>
        </div>
        <div className={styles.stickyContainer} data-company={company.name}>
          <div className={classes(['content-layout'])}>
            <div className={styles.header_top}>
              <Link to="/">
                <img
                  src={company.match({
                    circlek: company.images.logo.light,
                    default: company.images.logo.dark,
                  })}
                  alt=""
                  className={styles.header_logo}
                />
              </Link>
              <Link
                to="/shop"
                className={classes([styles.header_top__button, 'button _lg'])}
              >
                <Icon type="shop" size={24} />
                {t('catalog')}
              </Link>
              <HeaderSearch />
              <NavButtons />
            </div>
          </div>
        </div>
        <div
          className={classes([styles.headerElementWrapper, styles.bottomBlock])}
        >
          <HeaderMenu hideAllCategories />
        </div>
      </header>
    );
  }

  return (
    <header
      id={DESKTOP_HEADER_ID}
      className={classes([styles.header, styles.header_root])}
      data-company={company.name}
    >
      <DownloadApp />
      <div className={classes(['content-layout', styles.contentLayout])}>
        <div className={styles.header_top}>
          <Link to="/">
            <img
              src={company.images.logo.dark}
              alt=""
              className={styles.header_logo}
            />
          </Link>
          <HeaderSearch />
          <DeliveryButton
            {...{
              expressDeliveryMessage,
              expressDeliveryMessageArgs,
              showFlashIcon,
              handleDeliveryAddress,
            }}
          />
          <NavButtons />
        </div>
      </div>
      <HeaderMenu />
    </header>
  );
});
