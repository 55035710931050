import { classes } from 'html-classes';
import { useEffect, useRef } from 'react';

import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import ProductsCarouselSliderOneBlockSkeleton from '~/components/CategoryBlocks/ProductsCarouselSliderOneBlock/ProductsCarouselSliderOneBlockSkeleton';
import styles from '~/pages/SearchResults/SearchResults.module.scss';

interface SearchLoaderProps {
  onLoadMore?(): void;
}

export const SearchLoader = ({ onLoadMore }: SearchLoaderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !onLoadMore) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const item = entries[0];

        if (item && item.intersectionRatio > 0) {
          onLoadMore();
        }
      },
      { rootMargin: `${window.innerHeight}px` },
    );

    ref.current && observer.observe(ref.current);

    return () => observer.disconnect();
  }, [onLoadMore]);

  if (CompanyName.CityDrinks === company.name) {
    return (
      <div className="block-products" ref={ref}>
        <div className={classes(['spinner', styles.spinner])} />
      </div>
    );
  } else {
    return (
      <>
        <ProductsCarouselSliderOneBlockSkeleton ref={ref} />
        <ProductsCarouselSliderOneBlockSkeleton />
      </>
    );
  }
};
