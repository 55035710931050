import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { RewarderPromoCode } from '~/api/ReferralProgram';
import { company } from '~/company/Company';
import Breadcrumbs from '~/components/Breadcrumbs/Breadcrumbs';
import Icon from '~/components/Icon/Icon';
import useMediaQuery from '~/hooks/useMediaQuery';
import { useRewardedPromocodesStatus } from '~/hooks/useRewardedPromocodesStatus';
import { catalogStore } from '~/stores/CatalogStore';
import { mainStore } from '~/stores/MainStore';

import { BREAKPOINTS } from '~/breakpoints';

import { BREADCRUMBS } from './constants';
import styles from './RewardedPromocodes.module.scss';
import { sortByNovelty } from './utils';

const RewardedPromocodes = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width: ${BREAKPOINTS.phone}px)`);
  const promotion = catalogStore.promotionStore;

  useEffect(() => {
    promotion.getRewardedPromocodesList();
  }, []);

  useRewardedPromocodesStatus();

  const getPromoCodeAwardText = (item: RewarderPromoCode): string => {
    const value =
      item.type === 'PERCENTAGE'
        ? `${item.value}%`
        : mainStore.addCurrencySymbol(item.value);

    const context = {
      value,
      companyName: t(company.name),
    };

    return t('referralProgram:rewardedCodeText', context);
  };

  const handleCopyRewardedCode = async (item: RewarderPromoCode) => {
    try {
      await navigator.clipboard.writeText(item.code);
      mainStore.pushAlert('success', t('codeCopied'));
    } catch (error) {
      mainStore.pushAlert('error', t('codeCopyError'));
    }
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Referral program: Copy code',
      params: {},
    });
  };

  return (
    <div className={styles.root}>
      {isMobile && (
        <div className={styles.mobileHeader}>
          <div className={styles.mobileTitle}>
            <Link to="/cabinet/referral/" className={styles.mobileTitle__icon}>
              <Icon type="arrow" />
            </Link>
            <span className={styles.mobileTitle__text}>
              {t('referralProgram:rewards')}
            </span>
          </div>
        </div>
      )}
      <div className={styles.container}>
        <Breadcrumbs
          className={styles.breadcrumbs}
          items={BREADCRUMBS.map((item) => ({
            text: t(item.label),
            link: item.link,
          }))}
        />

        <h1 className={styles.title}>{t('referralProgram:rewards')}</h1>
        <span className={styles.caption}>
          {t('referralProgram:caption', {
            available: promotion.promocodeRewardStatus.total,
          })}
          {promotion.promocodeRewardStatus.new > 0 && (
            <>
              {` ${t('and')} `}
              <span className={styles.caption__new}>
                {t('referralProgram:newOne', {
                  count: promotion.promocodeRewardStatus.new,
                })}
              </span>
            </>
          )}
        </span>

        {promotion.rewardedPromocodeMeta.isLoading && (
          <div className={styles.loadingWrapper}>
            <div className="spinner _big" />
          </div>
        )}
        {!promotion.rewardedPromocodeMeta.isLoading && (
          <div className={styles.rewardedCodesWr}>
            {Array.from(promotion.rewardedPromocodeInstances)
              .sort(sortByNovelty)
              .map((item) => (
                <div className={styles.rewardedCode} key={item.id}>
                  <div>
                    {item.is_new && (
                      <span className={styles.rewardedCode__new}>
                        {t('new')}
                      </span>
                    )}
                    <h4 className={styles.rewardedCode__title}>
                      {getPromoCodeAwardText(item)}
                    </h4>
                  </div>
                  <div
                    className={styles.code}
                    role="button"
                    onClick={() => handleCopyRewardedCode(item)}
                  >
                    <span className={styles.code__value}>{item.code}</span>
                    <span className={styles.code__copy}>
                      <Icon type="copy" />
                    </span>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(RewardedPromocodes);
