import onboardingFrame1 from './frame_1.png';
import onboardingFrame2 from './frame_2.png';
import onboardingFrame3 from './frame_3.png';
import onboardingFrame4 from './frame_4.png';
import onboardingFrame5 from './frame_5.png';
import onboardingFrame6 from './frame_6.png';

export const OboardingImagesKids = [
  onboardingFrame1,
  onboardingFrame2,
  onboardingFrame3,
  onboardingFrame4,
  onboardingFrame5,
  onboardingFrame6,
];
