import { motion } from 'framer-motion';
import { classes } from 'html-classes';
import { forwardRef } from 'react';

import { company } from '~/company/Company';

import styles from './bottomSheetDrawer.module.scss';
import { MAX_HEIGHT, SAFE_AREA_TOP_OFFSET } from './constants';
import { useSheetContext } from './context';
import { useEventCallbacks } from './hooks';
import { SheetContainerProps } from './types';
import { mergeRefs } from './utils';

const SheetContainer = forwardRef<any, SheetContainerProps>(
  ({ children, style = {}, className = '', ...rest }, ref) => {
    const {
      y,
      isOpen,
      callbacks,
      snapPoints,
      initialSnap = 0,
      sheetRef,
      windowHeight,
      detent,
      animationOptions,
      reduceMotion,
    } = useSheetContext();

    const { handleAnimationComplete } = useEventCallbacks(isOpen, callbacks);
    const initialY = snapPoints
      ? snapPoints[0] - snapPoints[initialSnap] - SAFE_AREA_TOP_OFFSET
      : SAFE_AREA_TOP_OFFSET;
    const maxSnapHeight = snapPoints ? snapPoints[0] : null;

    const height =
      maxSnapHeight !== null
        ? `min(${maxSnapHeight}px, ${MAX_HEIGHT})`
        : MAX_HEIGHT;

    return (
      <span data-company={company.name}>
        <motion.div
          {...rest}
          ref={mergeRefs([sheetRef, ref])}
          className={classes([
            'react-modal-sheet-container',
            className,
            styles.container,
          ])}
          style={{
            ...style,
            ...(detent === 'full-height' && { height }),
            ...(detent === 'content-height' && { maxHeight: height }),
            y,
          }}
          initial={reduceMotion ? false : { y: windowHeight }}
          animate={{ y: initialY, transition: animationOptions }}
          exit={{ y: windowHeight, transition: animationOptions }}
          onAnimationComplete={handleAnimationComplete}
        >
          {children}
        </motion.div>
      </span>
    );
  },
);

SheetContainer.displayName = 'SheetContainer';

export default SheetContainer;
