import { observer } from 'mobx-react-lite';
import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useModal } from '../../hooks/useModal';
import { checkoutStore } from '../../stores/CheckoutStore/CheckoutStore';
import { userStore } from '../../stores/UserStore';

import useDebounce from '../DebounceHook';
import Icon from '../Icon/Icon';

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const nameVal = useDebounce<string>(checkoutStore.nameVal, 800);

  const backHandler = () => closeModal();

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    checkoutStore.setIsEmailSync(false);
    checkoutStore.setIsPersonalDataChanged(true);
    checkoutStore.setIsPersonalDataSync(false);
    checkoutStore.setNameVal(e.currentTarget.value);
  };

  const updateUserName = () => {
    const { firstName, middleName, lastName } =
      userStore.splitFullName(nameVal);
    userStore
      .updatePersonalData({
        first_name: firstName,
        middle_name: middleName,
        last_name: lastName,
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        checkoutStore.setIsPersonalDataSync(true);
      });
    backHandler();
  };

  useEffect(() => {
    checkoutStore.setNameVal(userStore.fullName);
  }, []);

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={backHandler}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <button
          className="button _no-padding _no-color input-form__back"
          onClick={backHandler}
        >
          <Icon type="arrow" className="icon__rotate-180" size={24} />
        </button>
        <div className="input-form__form">
          <div className="input-form__form-header">
            <p className="input-form__form-header-title">{t('yourName')}</p>
            <div className="input-form__input-container">
              <input
                className="input-form__input-visible"
                placeholder=""
                value={checkoutStore.nameVal}
                onChange={handleNameChange}
                autoFocus
              />
            </div>
          </div>
          <div className="input-form__form-footer">
            <div className="input-form__form-footer-buttons">
              <button className="button _primary _med" onClick={updateUserName}>
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
