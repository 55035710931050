import { observer } from 'mobx-react-lite';

import { useGeolocation } from '~/hooks/useGeolocation';

import Icon from '../../Icon/Icon';

import { deliveryAddressStore } from '../DeliveryAddressStore';

const Geolocation = () => {
  const { requestLocation } = useGeolocation();

  return (
    <div
      className="modal-delivery-address__map-geolocation"
      onClick={requestLocation}
    >
      {deliveryAddressStore.isAwaitGeolocation ? (
        <span className="spinner" />
      ) : (
        <Icon type="geolocation" size={24} />
      )}
    </div>
  );
};

export default observer(Geolocation);
