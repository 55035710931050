import sha256 from 'crypto-js/sha256';
import { makeAutoObservable } from 'mobx';

import { EnvConfig } from '~/constants/envConfig';

class AwsPaymentStore {
  private iframeHeightValue = 0;
  private isIframeActive = false;
  private postMessageReceived = false;
  private iframeContent = null;
  private iframeLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get heightIframe() {
    return this.iframeHeightValue;
  }

  get isIframeLoading() {
    return this.iframeLoading;
  }

  get isActiveAwsIframe() {
    return this.isIframeActive;
  }

  get messageReceived() {
    return this.postMessageReceived;
  }

  get iframeContentData() {
    return this, this.iframeContent;
  }

  setIsIframeLoading(flag: boolean) {
    this.iframeLoading = flag;
  }

  setPostMessageReceived(flag: boolean) {
    this.postMessageReceived = flag;
  }

  setHeight(iframeHeigh: number) {
    this.iframeHeightValue = iframeHeigh;
  }

  setIsActive(flag: boolean) {
    this.isIframeActive = flag;
  }

  private generateSHA256(str: string) {
    return sha256(str).toString();
  }

  generateString(obj: Record<string, string>) {
    const keys = Object.keys(obj).sort();
    const reqPhrase = EnvConfig.AWS_PAY_PHRASE;
    let result = EnvConfig.AWS_PAY_PHRASE;

    keys.forEach((key) => {
      result += key + '=' + obj[key];
    });

    return this.generateSHA256(result + reqPhrase);
  }
}

export const awsPaymentStore = new AwsPaymentStore();
