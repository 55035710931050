import { ENDPOINT } from './constants';
import { RequestAPI } from './Requests';

export enum BackorderLogic {
  ORIGINAL = 'ORIGINAL',
  STOCK_ONLY = 'STOCK_ONLY',
}

export interface ISettingsRequestDTO {
  data: {
    backorderLogic: BackorderLogic;
  };
}

interface SettingsRequestsInterface {
  getSettings: () => Promise<ISettingsRequestDTO>;
}

export const SettingsRequests: SettingsRequestsInterface = {
  getSettings: () => {
    return RequestAPI.get(ENDPOINT.settings.getBackorderLogic);
  },
};
