import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { PaymentMethods } from '~/types/payments';

import Icon from '../Icon/Icon';
import { IconTypes } from '../Icon/IconTypes';

import styles from './PaymentMethodsList.module.scss';

const METHODS: Record<PaymentMethods, { icon: IconTypes; text?: string }> = {
  cash: {
    icon: 'cash',
    text: 'cash',
  },
  gpay: {
    icon: 'pay_gpay',
  },
  applepay: {
    icon: 'pay_apple',
  },
  visa: {
    icon: 'pay_visa',
  },
  mc: {
    icon: 'pay_mc',
  },
  jcb: {
    icon: 'pay_jcb',
  },
};

export const PaymentMethodsList = observer(
  ({ classNames }: { classNames?: string }) => {
    const { t } = useTranslation();

    if (!company.config.paymentMethods?.length) {
      return null;
    }

    return (
      <div className={classes([styles.container, classNames])}>
        <p className={styles.header}>{t('ourPaymentMethods')}</p>
        <ul className={styles.list}>
          {company.config.paymentMethods.map((item) => {
            if (!METHODS[item]) {
              return null;
            }
            const hasText = Boolean(METHODS[item].text);

            return (
              <li
                className={classes([styles.item, hasText && styles.hor])}
                key={item}
              >
                <Icon
                  size={hasText ? 24 : 52}
                  type={METHODS[item].icon}
                  className={styles.itemIcon}
                />
                {hasText && (
                  <span className={styles.itemCaption}>
                    {t(METHODS[item].text!)}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
);
