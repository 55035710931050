import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import 'swiper/css';
import { useLocation } from 'react-router-dom';

import { company } from '~/company/Company';
import ProductCard from '~/components/ProductCard/ProductCard';
import ProductCardSkeleton from '~/components/ProductCard/ProductCardSkeleton';
import SwiperComponent from '~/components/Swiper/Swiper';
import { useGlobal } from '~/hooks/useGlobal';
import { Category } from '~/stores/CategoriesStore';

import CategoryBlockTitle from '../CategoryBlockTitle';
import { createCategoryUrl } from '../utils';

import { swiperProps } from './config';
import styles from './ProductsCarouselSliderOneBlock.module.scss';
import './styles.scss';

interface ProductsCarouselSliderOneBlockProps {
  category: Category;
}

const ProductsCarouselSliderOneBlock = ({
  category,
}: ProductsCarouselSliderOneBlockProps) => {
  const { pathname } = useLocation();
  const { isMobile } = useGlobal();

  if (!category.products.length && category.productsRequest.isFulfilled) {
    return null;
  }

  const currentProps = swiperProps[company.name];

  return (
    <div
      data-company={company.name}
      className={classes([
        styles.container,
        'product-carousel-slider-one-block',
      ])}
    >
      <CategoryBlockTitle
        hasLink={pathname !== '/search'}
        text={category.name}
        description={category.previewText}
        backgroundColor={category.backgroundColor}
        categoryId={category?.subcategory[0]?.id}
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        linkTo={createCategoryUrl({ id: category.subcategory[0].id })}
      />
      <SwiperComponent
        {...currentProps}
        skeletonComponent={
          <ProductCardSkeleton className={styles.productSkeleton} />
        }
      >
        {category.products.slice(0, isMobile ? 10 : 15).map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </SwiperComponent>
    </div>
  );
};

export default observer(ProductsCarouselSliderOneBlock);
