import { OrderPublicStatus } from '../../api/Order';

export const STATUS_INDICATORS: OrderPublicStatus[] = [
  'accepted',
  'picking',
  'ready_to_ship',
  'in_delivery',
];

export const TIME_FORMAT = 'HH:mm a';
export const DATE_FORMAT = 'MM/dd/yyyy';
