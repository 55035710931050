import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import i18n, { Resource } from 'i18next';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { company } from './company/Company';
import { CompanyName } from './company/interface';
import TranslationsCirclek from './translations-circlek.json';
import TranslationsJiffy from './translations-jiffy.json';
import TranslationsLocalee from './translations-localee.json';
import TranslationsKids from './translations-ourkids.json';
import TranslationsVilo from './translations-vilo.json';
import Translations from './translations.json';
import { getCookieValue } from './utils/cookies';

Sentry.init({
  dsn: 'https://0d0291e4f0fd4f45a654bb68b5a1f336@sentry.jiffy-team.com/25',
  integrations: [new BrowserTracing()],
  environment: import.meta.env.DEV
    ? 'development'
    : import.meta.env.REACT_APP_ENV || 'development',
  tracesSampleRate: 1.0,
  maxValueLength: 5000,
  normalizeDepth: 0,
  enabled: import.meta.env.PROD,
  release: import.meta.env.REACT_APP_RELEASE
    ? `frontend-cd@${import.meta.env.REACT_APP_RELEASE}`
    : undefined,
  beforeBreadcrumb(breadcrumb) {
    if (
      breadcrumb.category === 'xhr' &&
      breadcrumb.data &&
      breadcrumb.data.url.includes('yandex')
    ) {
      return null;
    }
    if (breadcrumb.category === 'sentry.transaction') {
      return null;
    }
    return breadcrumb;
  },
});

const getCurrentTranslation = () => {
  const companyName = company.name;
  switch (companyName) {
    case CompanyName.CircleK:
      return TranslationsCirclek;
    case CompanyName.Localee:
      return TranslationsLocalee;
    case CompanyName.Vilo:
      return TranslationsVilo;
    case CompanyName.Jiffy:
      return TranslationsJiffy;
    case CompanyName.Kids:
      return TranslationsKids;
    default:
      return Translations;
  }
};

i18n.use(initReactI18next).init({
  resources: getCurrentTranslation() as Resource,
  lng: getCookieValue('lang') ?? company.config.lang,
  fallbackLng: company.config.lang,
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      companyName: company.config.name,
      companyEmail: company.config.email,
      ageRestricted: company.config.ageRestricted,
    },
  },
});

const queryClient = new QueryClient();

const origin = window.location.origin;

const manifests = {
  [CompanyName.CityDrinks]: {
    name: 'CityDrinks',
    short_name: 'CityDrinks',
    start_url: origin,
    background_color: '#000',
    theme_color: '#000',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-citydrinks/apple-touch-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      },
      {
        src: `${origin}/favicon-citydrinks/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      },
      {
        src: `${origin}/favicon-citydrinks/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      },
      {
        src: `${origin}/favicon-citydrinks/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
  [CompanyName.Localee]: {
    name: 'Localee',
    short_name: 'Localee',
    start_url: origin,
    background_color: '#000',
    theme_color: '#000',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-localee/apple-touch-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      },
      {
        src: `${origin}/favicon-localee/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      },
      {
        src: `${origin}/favicon-localee/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      },
      {
        src: `${origin}/favicon-localee/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
  [CompanyName.CircleK]: {
    name: 'CircleK',
    short_name: 'CircleK',
    start_url: origin,
    background_color: '#000',
    theme_color: '#000',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-vilo/apple-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      },
      {
        src: `${origin}/favicon-vilo/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      },
      {
        src: `${origin}/favicon-vilo/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      },
      {
        src: `${origin}/favicon-vilo/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
  [CompanyName.Vilo]: {
    name: 'Vilo Gelato',
    short_name: 'Vilo Gelato',
    start_url: origin,
    background_color: '#000',
    theme_color: '#000',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-vilo/apple-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      },
      {
        src: `${origin}/favicon-vilo/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      },
      {
        src: `${origin}/favicon-vilo/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      },
      {
        src: `${origin}/favicon-vilo/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
  [CompanyName.Jiffy]: {
    name: 'Jiffy',
    short_name: 'Jiffy',
    start_url: origin,
    background_color: '#000',
    theme_color: '#000',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-jiffy/apple-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      },
      {
        src: `${origin}/favicon-jiffy/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      },
      {
        src: `${origin}/favicon-jiffy/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      },
      {
        src: `${origin}/favicon-jiffy/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
  [CompanyName.Kids]: {
    name: 'Our Kids',
    short_name: 'Our Kids',
    start_url: origin,
    background_color: '#000',
    theme_color: '#000',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-ourkids/apple-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      },
      {
        src: `${origin}/favicon-ourkids/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      },
      {
        src: `${origin}/favicon-ourkids/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      },
      {
        src: `${origin}/favicon-ourkids/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
};

if (manifests[company.name as keyof typeof manifests]) {
  const stringManifest = JSON.stringify(
    manifests[company.name as keyof typeof manifests],
  );
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document
    .querySelector('#manifest-placeholder')
    ?.setAttribute('href', manifestURL);

  let link = document.querySelector(
    "link[rel='shortcut icon']",
  ) as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    if (document?.getElementsByTagName('head')?.[0]) {
      document.getElementsByTagName('head')[0]!.appendChild(link);
    }
  } else if (manifests[company.name as keyof typeof manifests]) {
    link.href = `/favicon-${company.name}/favicon.ico`;
  }
}

/**
 The following code is replacing favicons for the dev env
 with the same icons but different colors.
 It's not used in production.
 */
// if (import.meta.env.DEV) {
//   const favicon = document.querySelectorAll<HTMLLinkElement>('link[rel=icon]');
//   favicon.forEach((link) => {
//     const newHref = link.href.replace(/(\/public\/)(.+)/, '$1development/$2');
//     return (link.href = newHref);
//   });
// }

ReactDOM.hydrateRoot(
  document.getElementById('app')!,
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App globalContext={window.__INITIAL_STATE__?.context} />
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>,
);
