import { parse } from 'date-fns';
import { format } from 'date-fns-tz';
import { FC, useId } from 'react';
import { useTranslation } from 'react-i18next';

import { MechanicType } from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

interface ExpressDateItemProps {
  onChange: (value: string) => void;
  isChecked: boolean;
}

const ExpressDateItem = ({ isChecked, onChange }: ExpressDateItemProps) => {
  const { t } = useTranslation();
  const inputId = useId();
  const { locale } = useDateLang();

  const expressMechanic =
    orderStore.etaCalculation?.warehouse?.availability?.deliveryMechanics?.[
      MechanicType.ON_DEMAND
    ];

  const order = checkoutStore.slotSelectionModalStore.selectedOrder;
  const expressIsAvailable = expressMechanic?.availableNow;
  const expressOpening = expressMechanic?.opening;
  const expressMinimumOrderValue =
    orderStore.etaCalculation?.warehouse.deliveryMethods[0]?.deliveryMechanics[
      ETADeliveryMethodType.JiffyDelivery
    ].ON_DEMAND.order_amount_threshold;
  const deliveryPrice = order?.delivery_info.paid_price
    ? formatPriceWithCurrency(
        mainStore.convertPenceToPounds(order.delivery_info.paid_price),
      )
    : '0';
  const minOrderVal = expressMinimumOrderValue
    ? formatPriceWithCurrency(
        mainStore.convertPenceToPounds(expressMinimumOrderValue),
      )
    : '0';

  const expressOpeningTime = expressOpening
    ? format(
        parse(expressOpening.split(':', 2).join(':'), 'HH:mm', new Date()),
        'hh:mm aaaa',
        {
          timeZone: company.config.timeZone,
          locale,
        },
      )
    : null;

  const buttonContent = expressIsAvailable ? (
    <label htmlFor={inputId}>
      <span className="text-primary">{t('expressDelivery')}</span>
      <ExpressDeliveryPrice
        minOrderVal={
          minOrderVal === '0'
            ? null
            : t('minimumOrderValue', { price: minOrderVal })
        }
        price={
          deliveryPrice === '0'
            ? t('deliveryFree')
            : t('deliveryFeeValueLong', { price: deliveryPrice })
        }
      />
    </label>
  ) : (
    <label htmlFor={inputId} className="_disabled-express">
      <span className="text-primary">{t('expressDelivery')}</span>
      <span>{t('expressIsNotAvailable', { time: expressOpeningTime })}</span>
    </label>
  );

  return (
    <li className="list-item">
      <input
        type="radio"
        id={inputId}
        value=""
        name="date"
        checked={isChecked}
        onChange={({ target }) => {
          onChange(target.value);
        }}
        disabled={!expressIsAvailable}
      />
      {buttonContent}
    </li>
  );
};

const ExpressDeliveryPrice: FC<{
  price: string;
  minOrderVal: string | null;
}> = ({ price, minOrderVal }) => {
  return (
    <>
      {minOrderVal && <span className="text-secondary">{minOrderVal}</span>}
      <span className="text-secondary">{price}</span>
    </>
  );
};

export default ExpressDateItem;
