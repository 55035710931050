import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { mainStore } from '~/stores/MainStore';
import { searchStore } from '~/stores/SearchStore';

export const useSearchRecommendations = () => {
  const { t } = useTranslation();

  const popular = useMemo(
    () =>
      company.match({
        citydrinks: [
          'Alfonso',
          'Black Label',
          'Gin',
          'Jack Daniels',
          'Kingfisher',
          'Red horse',
          'Red Wine',
          'Scotch',
          'Soju',
          'Vodka',
          'White Wine',
          'Whiskey',
        ],
        default: [],
      }) || [],
    [],
  );

  const handleSelect = useCallback(
    (value: string) => {
      searchStore.paginationRequest.loadPage(1, value);

      if (popular.includes(value)) {
        mainStore.sendAnalytics(['BI', 'analytics'], {
          name: 'Catalog: search idea clicked',
          params: { query: value },
        });
      }
    },
    [popular],
  );

  const data = useMemo(() => {
    return [
      {
        title: t('searchHistory'),
        action: (
          <button
            className="button _no-padding"
            disabled={searchStore.clearHistory.isLoading}
            onClick={searchStore.clearHistory.run}
          >
            {t('clear')}
          </button>
        ),
        items: searchStore.history,
      },
      {
        title: t('popularSearches'),
        items: popular,
      },
    ].filter(({ items }) => Boolean(items.length));
  }, [t, popular, searchStore.history]);

  return [data, handleSelect] as const;
};
