import { t } from 'i18next';

import { ReferralProgramRequests } from '~/api/ReferralProgram';
import { mainStore } from '~/stores/MainStore';

export interface PromoCodeTransferData {
  code: string;
  customerId: string;
}

export interface CheckTransferredPromocodeResult {
  exist: boolean;
  saved: boolean;
  error?: string;
}

export const TRANSFERRED_PROMO_CODE_QUERY_NAME = 'rc';
export const TAPFFILIATE_PROMOCODE_QUERY_NAME = 'ref';
export const STORED_PROMO_CODE_KEY = 'referralPromocode';
export const AFFILIATE_PROMOCODE = 'promocode';

export const generateLinkByCode = (
  code: string,
  customerId: string,
): string => {
  const { origin } = document.location;

  const data: PromoCodeTransferData = { code, customerId };
  const encoded = btoa(JSON.stringify(data));

  return `${origin}/?${TRANSFERRED_PROMO_CODE_QUERY_NAME}=${encoded}`;
};

const parseRefferalCode = (refferalCode: string): PromoCodeTransferData => {
  const base64Decoded = atob(refferalCode);

  return JSON.parse(base64Decoded);
};

export const checkAndSaveReferralPromocode = async (): Promise<
  URLSearchParams | undefined
> => {
  const searchParams = new URLSearchParams(document.location.search);
  const transferredCode = searchParams.get(TRANSFERRED_PROMO_CODE_QUERY_NAME);
  const tapAffilateCode = searchParams.get(TAPFFILIATE_PROMOCODE_QUERY_NAME);
  const affilateCode = searchParams.get(AFFILIATE_PROMOCODE);

  if (!transferredCode && !tapAffilateCode && !affilateCode) {
    return;
  }

  try {
    let codeDataToSet = null;

    if (transferredCode) {
      const { code, customerId } = parseRefferalCode(transferredCode);

      if (!code || !customerId) {
        throw new Error('Invalid code data');
      }

      codeDataToSet = { code, customerId };
    }

    if (tapAffilateCode) {
      const code = await ReferralProgramRequests.getAffiliateCode(
        tapAffilateCode ?? '',
      );
      codeDataToSet = { code };
    }

    localStorage.setItem(
      STORED_PROMO_CODE_KEY,
      JSON.stringify(affilateCode ? { code: affilateCode } : codeDataToSet),
    );

    mainStore.pushAlert('success', t('referralProgram:codeSaved'));

    searchParams.delete(TRANSFERRED_PROMO_CODE_QUERY_NAME);
    searchParams.delete(TAPFFILIATE_PROMOCODE_QUERY_NAME);
    searchParams.delete(AFFILIATE_PROMOCODE);

    return searchParams;
  } catch (e) {
    mainStore.pushAlert('error', t('referralProgram:jsonParseError'));
  }
};

export const getStoredPromocode = (): PromoCodeTransferData | undefined => {
  const stored = localStorage.getItem(STORED_PROMO_CODE_KEY);

  if (!stored) {
    return;
  }

  try {
    return JSON.parse(stored) as PromoCodeTransferData;
  } catch (e) {
    // TODO: handle parse error.
    // If we can't parse the promo code, we just won't use it
  }
};

export const clearStoredPromocode = (): void => {
  localStorage.removeItem(STORED_PROMO_CODE_KEY);
};
