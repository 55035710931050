import { classes } from 'html-classes';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import IllustrationEmptyOrderHistoryImg from '~/assets/img/illustration-empty-order-history.png';

import styles from './NoOrders.module.scss';

interface NoOrdersProps {
  className?: string;
}

const NoOrders = ({ className }: NoOrdersProps) => {
  const { t } = useTranslation();

  return (
    <div className={classes([className, styles.container])}>
      <img
        className={styles.img}
        src={IllustrationEmptyOrderHistoryImg}
        alt={t('emptyOrder')}
        width="201"
        height="200px"
      />
      <p className={styles.text}>
        <Trans i18nKey="phrases:ordersWillBeHereSoon" t={t} />
      </p>
      <Link to="/shop" className="button _primary">
        {t('goShopping')}
      </Link>
    </div>
  );
};

export default NoOrders;
