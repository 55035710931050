import { classes } from 'html-classes';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';

import styles from './SmallSelector.module.scss';

type Props = {
  options?: any[];
  id?: string;
  side: string;
  onChange: (id: string) => void;
};

const ITEMS = [
  {
    id: '500',
    value: '500ml',
  },
  {
    id: '750',
    value: '750ml',
  },
  {
    id: '1000',
    value: '1000ml',
  },
];

const TooltipSelector = ({
  options = [...ITEMS],
  id,
  onChange,
  side,
}: Props) => {
  const wrapperRef = useRef() as MutableRefObject<any>;

  const [selected, setSelected] = useState(id || options[0].id);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        open &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () =>
      document.removeEventListener('mousedown', checkIfClickedOutside);
  }, [open]);

  const changeHandler = (e: any) => {
    setIsTooltipOpen(false);
    onChange(e.target.value);
    setSelected(e.target.value);
    setOpen(false);
  };

  const selectedValue = useMemo(
    () => options.find((i) => i.id === selected)?.value,
    [selected],
  );

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const rootElementRef = useRef<HTMLDivElement | null>(null);

  const renderTooltip = () => {
    if (!isTooltipOpen) {
      return;
    }

    if (typeof document === 'undefined') {
      return (
        <div data-company={company.name} role="tooltip">
          <div className={styles.tooltipRoot} />
        </div>
      );
    }

    return createPortal(
      <div data-company={company.name} role="tooltip">
        <div className={styles.tooltipRoot} ref={setTooltipPosition}>
          <div className={classes(['small-selector__tooltip', 'open', side])}>
            {options.map((i) => (
              <div className="small-selector__tooltip-item" key={i.id}>
                <input
                  type="radio"
                  id={`${i.id}-${id}`}
                  name={`vol-${id}`}
                  value={i.id}
                  defaultChecked={selected === i.id}
                  onClick={changeHandler}
                />
                <label htmlFor={`${i.id}-${id}`}>{i.value}</label>
              </div>
            ))}
          </div>
        </div>
      </div>,
      document.body,
    );
  };

  const setTooltipPosition = (node: HTMLDivElement | null): void => {
    const rect = rootElementRef.current?.getBoundingClientRect();
    if (!node || !rect) {
      return;
    }

    const nodeRect = node.getBoundingClientRect();

    const top = rect.top + rect.height + 8;
    const left =
      side === 'left'
        ? rect.left - nodeRect.width + rect.width
        : rect.left - 12;

    node.style.top = top > 0 ? `${top}px` : '0';
    node.style.left = left > 0 ? `${left}px` : '0';
  };

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const listener = () => {
      setIsTooltipOpen(false);
    };

    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [isTooltipOpen, setIsTooltipOpen]);

  return (
    <div className={classes([styles.root])} ref={rootElementRef} role="button">
      {renderTooltip()}
      <div className="small-selector" ref={wrapperRef}>
        <div
          className="small-selector__visible"
          onClick={() => setIsTooltipOpen((prev) => !prev)}
        >
          {selectedValue}
          <Icon type="chevron-fill" size={12} />
        </div>
      </div>
    </div>
  );
};

export default TooltipSelector;
