import { SubscriptionFlag, SubscriptionFlagsNames } from '~/api/Customer';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

export const usePushNotifications = () => {
  const syncRN = (pushIsEnabled: boolean): Promise<boolean> => {
    if (!window.ReactNativeWebView) {
      return Promise.resolve(true);
    }
    return new Promise<boolean>((resolve) => {
      mainStore.sendToRN(
        'subscribeToNotifications',
        {
          isPush: pushIsEnabled,
          isEmail: false,
        },
        () => resolve(true),
        () => resolve(false),
      );
    });
  };

  const updateDeviceAndSendTags = async (
    firstName = '',
    email = '',
    flags: SubscriptionFlag[],
  ) => {
    if (!mainStore.isRN) {
      return;
    }
    const hasPushes = flags.some((item) => item.enabled);

    const subscribtionFlagsMap: Record<
      SubscriptionFlagsNames,
      SubscriptionFlag
    > = flags.reduce(
      (acc, item) => {
        return {
          ...acc,
          [item.name]: {
            enabled: item.enabled,
          },
        };
      },
      {} as Record<SubscriptionFlagsNames, SubscriptionFlag>,
    );

    const hasPermissions = await syncRN(hasPushes);

    if (!hasPermissions) {
      return;
    }

    mainStore.setIsBlockedPush(false);
    userStore
      .updateDeviceData(firstName.trim(), email.trim(), flags)
      .then((e) => {
        if (!e) {
          return;
        }
        userStore.setSubscriptionFlags(subscribtionFlagsMap);

        const tagsToSent = flags.reduce(
          (acc, item) => {
            const emailItem = flags.find(
              (flag) => flag.name === `${item.name}_email`,
            );
            const pushType =
              (emailItem?.enabled && item.enabled && 'all') ||
              (emailItem?.enabled && 'email') ||
              (item?.enabled && 'push') ||
              'off';

            if (item.name.includes('email')) {
              return acc;
            }

            return {
              ...acc,
              [item.name]: pushType,
            };
          },
          {} as Record<SubscriptionFlagsNames, SubscriptionFlag>,
        );

        (Object.keys(tagsToSent) as SubscriptionFlagsNames[]).forEach((key) => {
          mainStore.sendToRN('sendTags', {
            [key]: tagsToSent[key],
          });
        });
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        userStore.updateSubscription(flags, '');
      });
  };

  return {
    updateDeviceAndSendTags,
  };
};
