import Skeleton from 'react-loading-skeleton';

import SkeletonListRows from '~/components/Skeleton/SkeletonList';

const PaymentMethodsSkeleton = () => {
  return (
    <SkeletonListRows rows={9}>
      <label className="item skeleton">
        <Skeleton containerClassName="control" className="control" />
        <Skeleton containerClassName="content" className="content" />
      </label>
    </SkeletonListRows>
  );
};

export default PaymentMethodsSkeleton;
