import { CloudflareResizeOptions } from './CloudflareResizeOptions';
import { SrcSetAnchor } from './types';

export const defaultSrcSetParams: Record<
  SrcSetAnchor,
  CloudflareResizeOptions
> = {
  '320w': { fit: 'contain', width: 320 },
  '480w': { fit: 'contain', width: 480 },
  '640w': { fit: 'contain', width: 640 },
  '1280w': { fit: 'contain', width: 1280 },
};
