import { CardElement } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';
import Select from '~/components/Select/Select';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';

const StripeCardPayment = () => {
  const { t } = useTranslation();
  const [isCardEmpty, setIsCardEmpty] = useState('');
  const handleAgreeChange = () =>
    checkoutStore.setAgreeSaveCard(!checkoutStore.agreeSaveCard);
  const handleNewCard = (flag: boolean) => checkoutStore.setIsAddNewCard(flag);
  const paymentsChange = (val: string) => {
    checkoutStore.setPaymentsMethodId(val);
    checkoutStore.setIsDisabled(false);
    orderStore.setDefaultPayment(val);
    mainStore.sendToRN('analytics', {
      name: 'Purchase: payment method filled in',
      params: {
        cart_id: undefined,
        payment_method: 'card',
        type: 'existing',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'add_payment_info',
      params: {
        currency: orderStore.currency.toUpperCase(),
        payment_type: 'credit card',
        value: mainStore.toFloat(catalogStore.finalPrice),
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promocode.value,
      },
    });
  };

  const handleChange = (e: StripeCardElementChangeEvent) => {
    setIsCardEmpty(e.empty ? '' : 'true');
    checkoutStore.setIsDisabled(!e.complete);
    checkoutStore.setError(e.error ? e.error.message : '');

    if (e.complete) {
      mainStore.sendToRN('analytics', {
        name: 'Purchase: payment method filled in',
        params: {
          cart_id: undefined,
          payment_method: 'card',
          type: 'new',
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'add_payment_info',
        params: {
          currency: orderStore.currency.toUpperCase(),
          payment_type: 'credit card',
          value: mainStore.toFloat(catalogStore.finalPrice),
          items: catalogStore.cartForFirebase,
          coupon: catalogStore.promocode.value,
        },
      });
    }
  };

  return (
    <>
      {Array.isArray(checkoutStore.payments) &&
      checkoutStore.payments.length > 0 &&
      !checkoutStore.isAddNewCard ? (
        <>
          <div className="panel-section__content-payment-add-title-container">
            <p className="panel-section__content-payment-add-title">
              {t('paymentDetails')}
            </p>
            <button
              className="button _no-padding _no-color"
              onClick={() => handleNewCard(true)}
            >
              <Icon type="card-add" size={24} />
              {t('addNewCard')}
            </button>
          </div>
          <Select
            className="panel-section__content-payment-add-select"
            // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
            initialValue={checkoutStore.payments[0].text}
            data={checkoutStore.payments}
            onChange={paymentsChange}
          />
          {checkoutStore.error && (
            <div className="alert _error mt-10 z-auto">
              {checkoutStore.error}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="panel-section__content-payment-add-title-container">
            <p className="panel-section__content-payment-add-title">
              {t('paymentDetails')}
            </p>
            {checkoutStore.isAddNewCard &&
              Array.isArray(checkoutStore.payments) &&
              checkoutStore.payments.length && (
                <button
                  className="button _no-padding _no-color"
                  onClick={() => handleNewCard(false)}
                >
                  <Icon type="cards" size={24} />
                  {t('chooseCardInList')}
                </button>
              )}
          </div>
          <div
            className={classes([
              'panel-section__content-payment-add-card-form',
              (checkoutStore.error || !isCardEmpty) && '_error',
            ])}
          >
            <CardElement
              className="z-3 position-relative"
              options={checkoutStore.cardStyle}
              onChange={handleChange}
            />
            <input className="card-validation" value={isCardEmpty} readOnly />
          </div>
          {checkoutStore.error && (
            <div className="card-alert">{checkoutStore.error}</div>
          )}
          <label className="control__checkbox">
            <input
              type="checkbox"
              className="control__checkbox-input"
              checked={checkoutStore.agreeSaveCard}
              onChange={handleAgreeChange}
            />
            <span className="control__checkbox-mark" />
            {t('phrases:rememberMyCard')}
          </label>
        </>
      )}
    </>
  );
};

export default observer(StripeCardPayment);
