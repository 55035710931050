import { useCallback, useState } from 'react';

export const useDisclosure = (initialState: boolean = false) => {
  const [value, setValue] = useState(initialState);

  const open = useCallback(() => setValue(true), []);

  const close = useCallback(() => setValue(false), []);

  const toggle = useCallback(() => setValue((prev) => !prev), []);

  return { visible: value, open, close, toggle };
};
