import * as Sentry from '@sentry/react';
import { CardElement } from '@stripe/react-stripe-js';
import {
  type PaymentIntentResult,
  type Stripe,
  type StripeCardElement,
  type StripeCardElementOptions,
  type StripeElements,
} from '@stripe/stripe-js';
import { AxiosError } from 'axios';
import { hoursToSeconds, millisecondsToSeconds } from 'date-fns';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import isNumber from 'lodash-es/isNumber.js';
import { autorun, makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { v4 as uuid } from 'uuid';

import {
  DeliverySlot,
  DeliverySlotsResponse,
  ETAPaymentMethod,
} from '~/api/ETA';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { NewOrderResponse, PaymentSystem, Payment } from '~/api/Order';
import UnknownCard from '~/assets/img/unknown_card.png';
import { company } from '~/company/Company';
import CARD_BRANDS from '~/pages/Checkout/cardBrands';
import AdditionalPaymentMethod from '~/stores/CheckoutStore/AdditionalPaymentMethod';
import Delivery from '~/stores/CheckoutStore/Delivery';
import MobilePaymentMethod from '~/stores/CheckoutStore/MobilePaymentMethod';
import OrderData from '~/stores/CheckoutStore/OrderData';
import PaymentMethod from '~/stores/CheckoutStore/PaymentMethod';
import StateData from '~/stores/CheckoutStore/StateData';
import { MetaStore } from '~/stores/shared/MetaStore';
import { SlotsChangedEventStore } from '~/stores/shared/SlotChangedEventStore';
import { formatPrice } from '~/utils/formaters';
import { clearStoredPromocode, getStoredPromocode } from '~/utils/referralLink';

import { CartItem, catalogStore } from '../CatalogStore';
import {
  PAYMENT_TIMEOUT,
  PAYMENT_WAITING_TIME,
  PRESENT_PROMOCODES,
} from '../constants';
import { storage as localStorage } from '../LocalStorage';
import { mainStore } from '../MainStore';
import { orderStore, RequestETAResponse } from '../OrderStore';
import { userStore } from '../UserStore';

import { CheckoutStorePartial } from './interfaces';
import { SlotSelectModalStore } from './SlotSelectModalStore';
import { groupByDeliverySlots, NormalizedDeliveryItems } from './utils';

class CheckoutStore implements CheckoutStorePartial {
  addressVal = '';
  address2Val = '';
  addressFloorVal = '';
  addressBldVal = '';
  addressAptVal = '';
  phoneVal = '';
  isPhoneValid = true;
  isEmailSync = true;
  isPersonalDataSync = true;
  nameVal = '';
  emailVal = '';
  commentVal = '';
  recipientPhoneVal = '';
  isRecipientPhoneValid = false;
  recipientNameVal = '';
  recipientEmailVal = '';
  recipientTempPhoneVal = '';
  isRecipientTempPhoneValid = false;
  recipientTempNameVal = '';
  recipientTempEmailVal = '';
  agreeSaveCard = true;
  error: string | null = null;
  isLoading = false;
  isDisabled = true;
  isPersonalDataChanged = false;
  isShowRecipientPopover = false;
  isShowTimeoutPopover = false;
  isUnableConfirmCardPayment = false;
  isAddNewCard = false;
  paymentIntent: NewOrderResponse['data']['orders'][number] | null = null;
  paymentIntentMultiorder: NewOrderResponse['data']['multi_order'] | null =
    null;
  paymentMethod: { card: StripeCardElement } | string = '';
  orderId = '';
  instructionList: Record<number, string> = {};
  activePaymentMethod: PaymentMethod | null = null;
  payments: Array<{ value: string; text: string }> | null = null;
  paymentsMethodId: string | null = null;
  cardStyle: StripeCardElementOptions = {
    style: {
      base: {
        color: '#333',
        fontFamily: '"Noto Sans", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#878786',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  stateData: StateData | null = null;
  isRefreshed = false;
  deliveryMethod: ETADeliveryMethodType = ETADeliveryMethodType.JiffyDelivery;
  isUpdatedDeliveryPopover = false;
  deliveryPrices: RequestETAResponse | null = null;
  useBonuses = false;
  sessionId: string | undefined = undefined;

  deliveryAllTogether = false;

  deliverySlots: DeliverySlotsResponse = {};

  isDeliverTogether = company.combineItemsByDefaut;

  hasExactAmount = false;
  changeFromAmount: Nullable<number> = null;

  // FIXME: Why is this a string?
  selectedDeliveryDate: string | null = '0';
  selectedDeliverySlot: DeliverySlot | null = null;
  deliveries: Delivery[] | null = null;
  isMultipleDeliveriesAvailable = false;

  readonly slotsChangedEventStore: SlotsChangedEventStore;
  readonly slotSelectionModalStore: SlotSelectModalStore;
  readonly deliveryMethodChangeMeta: MetaStore = new MetaStore();

  constructor() {
    this.slotsChangedEventStore = new SlotsChangedEventStore(this);
    this.slotSelectionModalStore = new SlotSelectModalStore(this);

    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CheckoutStore',
      properties: [
        'activePaymentMethod',
        'payments',
        'deliveryMethod',
        'paymentsMethodId',
        'stateData',
        'deliveries',
        'deliveryMethod',
        'isMultipleDeliveriesAvailable',
        'isDeliverTogether',
      ],
      storage: localStorage,
    });
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get isPersonalDataValid(): boolean {
    return !!(
      this.isPhoneValid &&
      userStore.fullName.length &&
      this.isEmailValid &&
      this.isEmailSync &&
      this.isPersonalDataSync
    );
  }

  get isRecipientValid(): boolean {
    return !!(
      this.isPersonalDataValid &&
      this.isRecipientPhoneValid &&
      this.recipientNameVal.length &&
      this.isRecipientEmailValid
    );
  }

  get isRecipientTempValid(): boolean {
    return !!(
      this.isRecipientTempPhoneValid &&
      this.recipientTempNameVal.length &&
      this.isRecipientTempEmailValid
    );
  }

  get isEmailValid(): boolean {
    return mainStore.validateEmail(this.emailVal);
  }

  get isRecipientEmailValid(): boolean {
    return mainStore.validateEmail(this.recipientEmailVal);
  }

  get isRecipientTempEmailValid(): boolean {
    return mainStore.validateEmail(this.recipientTempEmailVal);
  }

  get isFormValid(): boolean {
    if (company.hasAddress2 && !this.address2Val.trim()) {
      return false;
    }

    return (
      (this.isPersonalDataValid || this.isRecipientValid) &&
      this.activePaymentMethod !== null &&
      !!this.addressVal.trim()
    );
  }

  get availableDeliveryMethods(): ETADeliveryMethodType[] {
    const methodList = Object.keys(
      orderStore.etaSlotCalculation?.cost ||
        orderStore.etaCalculation?.cost ||
        {},
    ) as ETADeliveryMethodType[];
    if (!methodList.length) {
      return [];
    }
    return [
      ETADeliveryMethodType.JiffyDelivery,
      ETADeliveryMethodType.ClickAndCollect,
    ].filter((method) => methodList.includes(method));
  }

  get availablePaymentMethods(): PaymentMethod[] {
    const availablePaymentMethods: PaymentMethod[] = [];
    let paymentMethods =
      orderStore.etaSlotCalculation?.paymentMethods[this.deliveryMethod] ||
      orderStore.etaCalculation?.paymentMethods[this.deliveryMethod] ||
      [];

    if (!paymentMethods.length) {
      paymentMethods =
        orderStore.etaSlotCalculation?.paymentMethods[
          ETADeliveryMethodType.JiffyDelivery
        ] ||
        orderStore.etaCalculation?.paymentMethods[
          ETADeliveryMethodType.JiffyDelivery
        ] ||
        [];
    }

    if (paymentMethods.length) {
      if (paymentMethods.includes(ETAPaymentMethod.Card)) {
        availablePaymentMethods.push(ETAPaymentMethod.Card);

        if (mainStore.isApplePay) {
          availablePaymentMethods.push(MobilePaymentMethod.ApplePay);
        }

        if (
          mainStore.isGooglePay &&
          orderStore.paymentSystem !== PaymentSystem.myfatoorah
        ) {
          availablePaymentMethods.push(MobilePaymentMethod.GooglePay);
        }
      }

      if (paymentMethods.includes(ETAPaymentMethod.Cash)) {
        availablePaymentMethods.push(ETAPaymentMethod.Cash);
      }
    }

    return availablePaymentMethods;
  }

  get sortedDeliverySlotsList(): DeliverySlot[] | null {
    if (isEmpty(this.deliverySlots)) {
      return null;
    }

    const slots = Object.values(this.deliverySlots).flat();

    return [...slots].sort((a, b) => a.delivery_min_time - b.delivery_min_time);
  }

  get hasBackorderItems(): boolean {
    return catalogStore.selectedCartItems.some(
      ({ count, sellable, isBackorderAvailable }) =>
        isBackorderAvailable && count > sellable,
    );
  }

  get currentSelectedMethod(): Payment | undefined {
    const currentActiveMethod = orderStore.paymentCards.find(
      (item) => item.id === this.paymentsMethodId,
    );
    return currentActiveMethod;
  }

  calculateDeliveries(slots: DeliverySlot[] | null) {
    if (!slots) {
      if (slots !== null) {
        this.deliveries = null;
      }
      return;
    }

    if (this.deliveryMethod === ETADeliveryMethodType.ClickAndCollect) {
      this.isMultipleDeliveriesAvailable = false;
      this.deliveries = [
        {
          id: uuid(),
          itemsIds: catalogStore.selectedCartItems.map((item) => item.sku),
        },
      ];
      catalogStore.debouncedCalculateCart();
      return;
    }

    // max count of slots
    const normalizedCartItems: NormalizedDeliveryItems[] =
      this._normalizeCartItemsForDeliveries(
        slots,
        catalogStore.selectedCartItems,
      );
    const groupedNotJoinedDeliveries =
      groupByDeliverySlots(normalizedCartItems);

    this.isMultipleDeliveriesAvailable = groupedNotJoinedDeliveries.length > 1;
    this.deliveries =
      this.isDeliverTogether && this.isMultipleDeliveriesAvailable
        ? this._getDeliveryItemWithAllCartItems(slots)
        : groupedNotJoinedDeliveries;
    catalogStore.debouncedCalculateCart();
  }

  private _normalizeCartItemsForDeliveries(
    slots: DeliverySlot[] | null,
    cartItems?: CartItem[],
  ): NormalizedDeliveryItems[] {
    if (!cartItems || !slots) {
      return [];
    }

    const now = millisecondsToSeconds(Date.now());

    return cartItems.map((cartItem) => {
      const {
        id,
        sku,
        count,
        backorderLeadTime = 0,
        sellableWarehouses,
      } = cartItem;
      const currentWHStock = Number(
        sellableWarehouses[`${orderStore.etaCalculation?.warehouse.code}`] ||
          '0',
      );

      if (currentWHStock >= count && orderStore.isExpressAvailableNow) {
        return { itemId: id, sku: sku || '' };
      }

      const backorderTime = now + hoursToSeconds(backorderLeadTime);

      const closestSlot = slots.find(
        ({ delivery_min_time }) => delivery_min_time >= backorderTime,
      );

      // FIXME: handle the case where there is no slot available
      if (!closestSlot) {
        return { itemId: id, sku: sku || '' };
      }

      return {
        itemId: id,
        sku: sku || '',
        slotDeliveryDetails: {
          scheduleSlotId: closestSlot.schedule_slot_id,
          currentDate: closestSlot.current_date,
        },
        slot: closestSlot,
      };
    });
  }

  private _getDeliveryItemWithAllCartItems(slots: DeliverySlot[]): Delivery[] {
    // All the slot dates use seconds for timestamps
    const now = millisecondsToSeconds(Date.now());

    const lastBackorder = catalogStore.selectedCartItems.reduce(
      (acc, { backorderLeadTime = 0 }) => {
        const backorderTime = now + hoursToSeconds(backorderLeadTime);

        return Math.max(acc, backorderTime);
      },
      0,
    );

    const closestBackorderSlot = slots.find(
      ({ delivery_min_time }) => delivery_min_time >= lastBackorder,
    );

    return groupByDeliverySlots(
      catalogStore.selectedCartItems.map(({ sku }) => {
        return {
          sku: sku || '',
          slotDeliveryDetails: {
            scheduleSlotId: closestBackorderSlot!.schedule_slot_id,
            currentDate: closestBackorderSlot!.current_date,
          },
          slot: closestBackorderSlot,
        };
      }),
    );
  }

  checkSlotExpirationOfExistingDeliveries(
    slots: DeliverySlot[] | null,
  ): boolean {
    if (!this.deliveries) {
      this.calculateDeliveries(slots);
      return false;
    }

    if (!slots) {
      return false;
    }

    const dropDeliveries: Delivery[] = [];

    for (const delivery of this.deliveries) {
      if (!delivery.slotDeliveryDetails) {
        if (!orderStore.isExpressAvailableNow) {
          dropDeliveries.push(delivery);
        }
        continue;
      }

      const existingSlot = slots.find(
        (slot) =>
          slot.schedule_slot_id ===
            delivery.slotDeliveryDetails?.scheduleSlotId &&
          slot.current_date === delivery.slotDeliveryDetails?.currentDate,
      );

      if (!existingSlot) {
        dropDeliveries.push(delivery);
      }
    }

    if (!dropDeliveries.length) {
      return false;
    }

    const expectingCartItems = catalogStore.selectedCartItems.filter((item) =>
      dropDeliveries.some((d) => d.itemsIds.includes(item.sku)),
    );

    const normalizedCartItems: NormalizedDeliveryItems[] =
      this._normalizeCartItemsForDeliveries(slots, expectingCartItems);

    const existingDeliveriesNormalizedItems: NormalizedDeliveryItems[] =
      this.deliveries
        .filter((delivery) => !dropDeliveries.includes(delivery))
        .map((delivery) =>
          delivery.itemsIds.map((sku) => ({
            itemId: sku,
            sku: sku || '',
            slotDeliveryDetails: delivery.slotDeliveryDetails,
            slot: delivery.slot,
          })),
        )
        .flat();

    const newDeliveries = groupByDeliverySlots([
      ...normalizedCartItems,
      ...existingDeliveriesNormalizedItems,
    ]);

    this.isMultipleDeliveriesAvailable = newDeliveries.length > 1;
    this.deliveries =
      this.isDeliverTogether && this.isMultipleDeliveriesAvailable
        ? this._getDeliveryItemWithAllCartItems(slots)
        : newDeliveries;
    catalogStore.debouncedCalculateCart();
    return true;
  }

  // Setters
  setDeliveries(deliveries: Delivery[] | null) {
    this.deliveries = deliveries;
    catalogStore.debouncedCalculateCart();
  }

  setHasExactAmount(value: boolean) {
    this.changeFromAmount = null;
    this.hasExactAmount = value;
  }

  setChangeFromAmount(value: Nullable<number>) {
    this.hasExactAmount = false;
    this.changeFromAmount = value;
  }

  setAddressVal(val: string) {
    this.addressVal = val;
  }

  setAddress2Val(val: string) {
    this.address2Val = val;
  }

  setAddressFloorVal(val: string) {
    this.addressFloorVal = val;
  }

  setAddressBldVal(val: string) {
    this.addressBldVal = val;
  }

  setAddressAptVal(val: string) {
    this.addressAptVal = val;
  }

  setPhoneVal(val: string) {
    this.phoneVal = val;
  }

  setIsPhoneValid(flag: boolean) {
    this.isPhoneValid = flag;
  }

  setIsEmailSync(flag: boolean) {
    if (flag) {
      this.setEmailVal(userStore.personalData.email || '');
    }
    this.isEmailSync = flag;
  }

  setIsPersonalDataSync(flag: boolean) {
    this.isPersonalDataSync = flag;
  }

  setNameVal(val: string) {
    this.nameVal = val;
  }

  setEmailVal(val: string) {
    this.emailVal = val;
  }

  setCommentVal(val: string) {
    this.commentVal = val;
  }

  setRecipientTempPhoneVal(val: string) {
    this.recipientTempPhoneVal = val;
  }

  setIsRecipientTempPhoneValid(flag: boolean) {
    this.isRecipientTempPhoneValid = flag;
  }

  setRecipientTempNameVal(val: string) {
    this.recipientTempNameVal = val;
  }

  setRecipientTempEmailVal(val: string) {
    this.recipientTempEmailVal = val;
  }

  setAgreeSaveCard(flag: boolean) {
    this.agreeSaveCard = flag;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  setPaymentIntent(newOrderResponse: NewOrderResponse | null) {
    if (!newOrderResponse) {
      this.paymentIntentMultiorder = null;
      this.paymentIntent = null;
      return;
    }

    const order = newOrderResponse.data.orders[0];

    if (!order) {
      throw new Error('No order data');
    }

    this.paymentIntentMultiorder = newOrderResponse.data.multi_order;

    this.paymentIntent = order;
  }

  setPaymentMethod(val: { card: StripeCardElement } | string) {
    this.paymentMethod = val;
  }

  setOrderId(val: string) {
    this.orderId = val;
  }

  setError(val: string | null) {
    if (val) {
      mainStore.pushAlert('error', val);
    }

    this.error = val;
  }

  setIsDisabled(flag: boolean) {
    this.isDisabled = flag;
  }

  setIsPersonalDataChanged(flag: boolean) {
    this.isPersonalDataChanged = flag;
  }

  setIsShowRecipientPopover(flag: boolean) {
    this.isShowRecipientPopover = flag;
  }

  setIsUnableConfirmCardPayment(flag: boolean) {
    this.isUnableConfirmCardPayment = flag;
  }

  setRecipient() {
    this.recipientPhoneVal = toJS(this.recipientTempPhoneVal);
    this.isRecipientPhoneValid = toJS(this.isRecipientTempPhoneValid);
    this.recipientNameVal = toJS(this.recipientTempNameVal);
    this.recipientEmailVal = toJS(this.recipientTempEmailVal);
  }

  addInstruction(val: string, index: number) {
    this.instructionList[index] = val;
  }

  deleteInstruction(index: number) {
    delete this.instructionList[index];
  }

  resetInstructionList() {
    this.instructionList = {};
  }

  setActivePaymentMethod(val: PaymentMethod | null) {
    this.activePaymentMethod = val;
    this.setError(null);
  }

  setIsAddNewCard(flag: boolean) {
    this.isAddNewCard = flag;
    this.setIsDisabled(flag);
    this.setError(null);
  }

  setPayments(val: { value: string; text: string }[] | null) {
    this.payments = val;
  }

  setPaymentsMethodId(val: string | null) {
    this.paymentsMethodId = val;
  }

  setIsShowTimeoutPopover(flag: boolean) {
    this.isShowTimeoutPopover = flag;
  }

  setDeliveryMethod(method: ETADeliveryMethodType) {
    if (this.deliveryMethod !== method) {
      // start loader, reaction will handle loader stopping
      this.deliveryMethodChangeMeta.setLoading(true);
    }
    // this assign will trigger reaction that runs calculate deliveries
    this.deliveryMethod = method;
  }

  setIsUpdatedDeliveryPopover(flag: boolean) {
    this.isUpdatedDeliveryPopover = flag;
  }

  setDeliveryPrices(val: RequestETAResponse) {
    this.deliveryPrices = val;
  }

  setUseBonuses(flag: boolean) {
    this.useBonuses = flag;
  }

  setSessionId(val: string) {
    this.sessionId = val;
  }

  setDeliverySlots(slots: DeliverySlotsResponse) {
    this.deliverySlots = slots;
  }
  setDeliveryAllTogether(val: boolean): void {
    this.deliveryAllTogether = val;
  }

  setSelectedDate(val: string) {
    this.selectedDeliveryDate = val;
    if (this.deliverySlots && val !== '0') {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      this.setSelectedSlot(this.deliverySlots[val][0]);
    } else {
      this.setSelectedSlot(null);
    }
  }

  setSeletectedDateExpress() {
    this.selectedDeliveryDate = '0';
    this.selectedDeliverySlot = null;
  }

  // TODO: is it obsolete now?
  async setSelectedSlot(val: DeliverySlot | null) {
    this.selectedDeliverySlot = val;

    await orderStore.requestETA();
    await catalogStore.calculateCart();
  }

  calculateFullDeliveryFee() {
    let fullDeliveryPrice = 0;

    this.deliveries?.forEach((_, index) => {
      const order = catalogStore.deliveriesOrders[index];
      if (!order) {
        return;
      }
      if (order.is_delivery_free) {
        return;
      }

      const deliveryPrice = formatPrice(
        mainStore.convertPenceToPounds(order.delivery_info.paid_price),
      );

      fullDeliveryPrice = +fullDeliveryPrice + +deliveryPrice;
    });

    return `${fullDeliveryPrice}`;
  }

  // Actions
  getOrderData(): OrderData {
    let instructions = Object.values(this.instructionList);

    let customerCash = 0;
    const hasExactAmount = this.hasExactAmount;
    if (hasExactAmount) {
      instructions.push(this.getExactAmountInstruction());
    } else if (isNumber(this.changeFromAmount)) {
      customerCash = Math.abs(Number(this.changeFromAmount.toFixed(2)));
    }

    const notes = [
      this.address2Val,
      instructions.join(', ').toUpperCase(),
      this.commentVal,
    ]
      .filter((i) => i)
      .join(', ');
    if (this.deliveryMethod === ETADeliveryMethodType.ClickAndCollect) {
      instructions = [];
    }
    let isSaveCard = toJS(this.agreeSaveCard);
    if (
      mainStore.isRN &&
      this.activePaymentMethod === MobilePaymentMethod.ApplePay &&
      mainStore.isApplePay
    ) {
      isSaveCard = false;
    }
    if (
      mainStore.isRN &&
      this.activePaymentMethod === MobilePaymentMethod.GooglePay &&
      mainStore.isGooglePay
    ) {
      isSaveCard = false;
    }
    return {
      address: this.addressVal,
      address2: this.address2Val,
      addressFloor: this.addressFloorVal,
      addressBld: this.addressBldVal,
      addressApt: this.addressAptVal,
      fullName: this.isRecipientValid
        ? this.recipientNameVal
        : userStore.fullName,
      email: this.isRecipientValid
        ? this.recipientEmailVal.trim()
        : this.emailVal.trim(),
      phone: this.isRecipientValid ? this.recipientPhoneVal : this.phoneVal,
      comment: this.commentVal,
      instructions,
      notes,
      saveCard: isSaveCard,
      deliveryMethod: this.deliveryMethod,
      sessionId: this.sessionId,
      customerCash,
      hasExactAmount,
      ...(checkoutStore.selectedDeliverySlot &&
      checkoutStore.selectedDeliveryDate
        ? {
            slot_delivery_details: {
              schedule_slot_id:
                checkoutStore.selectedDeliverySlot.schedule_slot_id,
              current_date: +checkoutStore.selectedDeliveryDate / 1000,
            },
          }
        : {}),
    };
  }

  switchDeliverTogether() {
    this.isDeliverTogether = !this.isDeliverTogether;
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Delivery option changed',
      params: { type: this.isDeliverTogether ? 'single' : 'split' },
    });
    catalogStore.calculateCart();
  }

  autofillRecipient() {
    this.recipientTempPhoneVal = toJS(this.phoneVal);
    this.isRecipientTempPhoneValid = toJS(this.isPhoneValid);
    this.recipientTempNameVal = userStore.fullName;
    this.recipientTempEmailVal = toJS(this.emailVal);
  }

  purchaseCompletedAnalytics(orderId: string) {
    const lastOrderETA = orderStore.etaCalculation?.duration.min || 0;
    mainStore.sendToRN('sendTags', {
      last_ordered_time: Math.floor(Date.now() / 1000),
    });

    mainStore.sendAnalytics(['BI', 'analytics', 'yaMetrika', 'firebase'], {
      name: 'Purchase: completed',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce(
          (sum, item) => sum + item.count,
          0,
        ),
        items_amount: catalogStore.totalCartCount,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: lastOrderETA,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger:
          orderStore.etaSlotCalculation?.highDemand ||
          orderStore.etaCalculation?.highDemand ||
          false,
        order_id: orderId,
        order_num: mainStore.analytics.totalSuccessfulOrders,
        promocode: catalogStore.promocode.value,
        warehouse_code:
          orderStore.etaSlotCalculation?.warehouse.code ||
          orderStore.etaCalculation?.warehouse.code ||
          '',
      },
    });
    if (mainStore.analytics.totalSuccessfulOrders === 1) {
      mainStore.sendToRN('analytics', {
        name: 'af_first_purchase',
        params: {
          revenue: catalogStore.finalPrice,
          price: catalogStore.totalCartPrice.base,
          quantity: catalogStore.totalCartCount,
          currency: orderStore.currency.toUpperCase(),
          order_id: orderId,
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'first_purchase',
        params: {
          transaction_id: orderId,
          value: mainStore.toFloat(catalogStore.finalPrice),
          currency: orderStore.currency.toUpperCase(),
          tax: 0,
          shipping: 0,
          items: catalogStore.cartForFirebase,
          coupon: catalogStore.promocode.value,
        },
      });
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'first_purchase_quantity',
        value: catalogStore.totalCartCount,
      });
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'first_purchase_amount',
        value: catalogStore.totalCartPrice.base,
      });
    } else {
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'purchase_quantity',
        value: catalogStore.totalCartCount,
      });
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'purchase_amount',
        value: catalogStore.totalCartPrice.base,
      });
    }
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last purchase date': new Date().toISOString(),
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order eta': lastOrderETA,
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'purchase',
      params: {
        transaction_id: orderId,
        value: mainStore.toFloat(catalogStore.finalPrice),
        currency: orderStore.currency.toUpperCase(),
        tax: 0,
        shipping: 0,
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promocode.value,
      },
    });
    Object.keys(checkoutStore.instructionList).forEach((v) => {
      const instruction = checkoutStore.instructionList[+v];
      mainStore.sendAnalytics(['BI', 'analytics'], {
        name: 'Delivery instructions',
        params: {
          deliveryInstruction: instruction,
        },
      });
    });
  }

  initStateData() {
    if (!this.stateData || Date.now() - this.stateData.timestamp > 6000) {
      this.stateData = null;
      this.isRefreshed = false;
      return;
    }
    const stateData = toJS(this.stateData);
    this.addressVal = stateData.addressVal;
    this.address2Val = stateData.address2Val;
    this.addressFloorVal = stateData.addressFloorVal;
    this.addressBldVal = stateData.addressBldVal;
    this.addressAptVal = stateData.addressAptVal;
    this.commentVal = stateData.commentVal;
    this.instructionList = stateData.instructionList;
    this.recipientPhoneVal = stateData.recipientPhoneVal;
    this.isRecipientPhoneValid = true;
    this.recipientNameVal = stateData.recipientNameVal;
    this.recipientEmailVal = stateData.recipientEmailVal;
    this.isAddNewCard = stateData.isAddNewCard;
    this.agreeSaveCard = stateData.agreeSaveCard;
    if (stateData.isAddNewCard) {
      this.isDisabled = true;
    }
    this.isRefreshed = true;
    this.stateData = null;
    this.deliveryMethod = stateData.deliveryMethod;
  }

  initActivePaymentMethod() {
    if (!this.availablePaymentMethods.length) {
      this.setActivePaymentMethod(null);
      return;
    }

    if (
      this.activePaymentMethod &&
      this.availablePaymentMethods.includes(this.activePaymentMethod)
    ) {
      return;
    }

    if (this.availablePaymentMethods.includes(ETAPaymentMethod.Card)) {
      this.setActivePaymentMethod(ETAPaymentMethod.Card);
      return;
    } else if (this.availablePaymentMethods.includes(ETAPaymentMethod.Cash)) {
      this.setActivePaymentMethod(ETAPaymentMethod.Cash);
      return;
    }

    const [first] = this.availablePaymentMethods;

    // Check for linter
    if (!first) {
      this.setActivePaymentMethod(null);
      return;
    }

    this.setActivePaymentMethod(first);
  }

  async requestPayments() {
    if (orderStore.paymentSystem === PaymentSystem.myfatoorah) {
      this.setPayments(null);
      this.setPaymentsMethodId(null);
      return;
    }

    try {
      await orderStore
        .requestPayments()
        .catch((error) => error && console.error(error));
      if (!orderStore.paymentCards?.length) {
        this.setPayments(null);
        this.setPaymentsMethodId(null);
        return;
      }

      const paymentList: Array<{ value: string; text: string }> =
        orderStore.paymentCards.map((card) => {
          const {
            id,
            card: { brand, last4, exp_month, exp_year },
          } = card;

          const cardIcon = CARD_BRANDS[brand]?.icon ?? UnknownCard;
          const cardTitle = CARD_BRANDS[brand]?.title ?? brand;
          const expDate =
            exp_year &&
            exp_month &&
            `${mainStore.addLeadingZero(exp_month)}/${exp_year
              .toString()
              .slice(-2)}`;

          return {
            value: id,
            text: `
             ${cardIcon ? `<img class="" src="${cardIcon}" alt="" />` : ''}
              <div class="card-main text-truncate text-capitalize">
                <p class="card-main__brand">${cardTitle}</p>
                <div class="card-main__number">${last4}</div>
              </div>
              ${expDate ? ` <div class="card-date">${expDate}</div>` : ''}
             `,
          };
        });

      const defaultIndex = orderStore.paymentCards.findIndex(
        (el) => el.metadata.is_default === 'true',
      );
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      paymentList.splice(0, 0, paymentList.splice(defaultIndex || 0, 1)[0]);
      this.setPayments(paymentList);
      if (paymentList.length) {
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        this.setPaymentsMethodId(paymentList[0].value);
        if (!this.isAddNewCard) {
          this.setIsDisabled(false);
        }
        mainStore.sendToRN('analytics', {
          name: 'Purchase: payment method filled in',
          params: {
            cart_id: undefined,
            payment_method: 'card',
            type: 'existing',
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'add_payment_info',
          params: {
            currency: orderStore.currency.toUpperCase(),
            payment_type: 'credit card',
            value: mainStore.toFloat(catalogStore.finalPrice),
            items: catalogStore.cartForFirebase,
            coupon: catalogStore.promocode.value,
          },
        });
      }
    } catch (error) {
      if (
        error instanceof AxiosError &&
        !error.response &&
        (error.code === 'ECONNABORTED' || error.message === 'Network Error')
      ) {
        return;
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: (error as AxiosError).response,
        });
        Sentry.captureMessage('[Checkout] Failed payments loading', 'warning');
      });
    }
  }

  async orderCheckout(
    stripe?: Stripe | null,
    elements?: StripeElements | null,
  ) {
    try {
      if (mainStore.convertPoundsToPence(catalogStore.finalPrice) < 50) {
        mainStore.setIsZeroCartPopover(true);
        throw new Error('price < 50');
      }

      this.setPaymentIntent(null);
      this.setIsLoading(true);
      this.setIsUnableConfirmCardPayment(false);
      this.setError(null);
      await this.syncAddress();
      const isCartChanged = await catalogStore.calculateCart();
      if (isCartChanged) {
        this.setIsLoading(false);
        throw new Error('cart is changed');
      }

      if (orderStore.gift && orderStore.activeGift) {
        orderStore.setIsAuthInCart(false);
        const isGiftsFetched = await orderStore.fetchGifts();
        if (!isGiftsFetched) {
          this.setIsLoading(false);
          throw new Error("Can't fetch gifts");
        }
      }

      if (
        this.deliveryMethod !== ETADeliveryMethodType.ClickAndCollect &&
        orderStore.freezeETAExpired - Date.now() < 10_000
      ) {
        try {
          const deliveryPrices = await orderStore.getDeliveryCost();
          if (deliveryPrices.oldPrice !== deliveryPrices.newPrice) {
            this.setIsLoading(false);
            throw new Error('oldPrice !== newPrice');
          }
        } catch (error) {
          error && console.error(error);
          const deliveryPrices = await orderStore.requestETA();
          if (
            deliveryPrices &&
            deliveryPrices.oldPrice !== deliveryPrices.newPrice
          ) {
            this.setIsLoading(false);
            throw new Error('oldPrice !== newPrice');
          }
          if (!orderStore.etaSlotCalculation || !orderStore.etaCalculation) {
            this.setIsLoading(false);
            throw new Error(
              '!orderStore.etaSlotCalculation || !orderStore.etaCalculation',
            );
          }
        }
      }
      const activePaymentMethod = this.activePaymentMethod;
      // await orderStore.requestETA();
      const isDeliverySlotsChanged = await orderStore.reCalculateDeliveries();
      if (isDeliverySlotsChanged) {
        this.setIsLoading(false);
        throw new Error('Delivery slots changed');
      }
      this.initActivePaymentMethod();
      if (activePaymentMethod !== this.activePaymentMethod) {
        this.setIsLoading(false);
        throw new Error('Payment method is changed');
      }
      let response: NewOrderResponse | null = null;
      try {
        Sentry.withScope((scope) => {
          scope.setExtras({
            context: 'newOrder',
            error: 'NO ERROR JUST TESTING IN CHECKOUT BUTTON',
            data: {
              ...this.getOrderData(),
              slots: checkoutStore.deliverySlots,
            },
          });
          Sentry.captureMessage('[Checkout] NEW ORDER REQUEST DATA', 'warning');
        });
        response = await orderStore.newOrder(this.getOrderData());
      } catch (error) {
        this.setIsLoading(false);
        throw error;
      }
      if (!response || !response.data.orders.length) {
        this.setIsLoading(false);
        throw new Error('No orders');
      }

      this.setPaymentIntent(response);

      if (this.activePaymentMethod !== ETAPaymentMethod.Cash) {
        if (orderStore.paymentSystem === PaymentSystem.myfatoorah) {
          if (
            mainStore.isRN &&
            this.activePaymentMethod === MobilePaymentMethod.ApplePay &&
            mainStore.isApplePay
          ) {
            this.applePay(response);
            return;
          }
          if (response.data.orders[0]?.payment.myFatoorahPayment.error) {
            this.setError('' /*i18n.t('errors:paymentSystemError')*/);
            this.setIsLoading(false);
            throw new Error(
              'response.data.orders[0]?.payment.myFatoorahPayment.error',
            );
          }
          if (response.data.orders[0]?.payment?.myFatoorahPayment.paymentURL) {
            if (mainStore.isRN) {
              mainStore.sendToRN('openInAppBrowser', {
                url: response.data.orders[0]?.payment?.myFatoorahPayment
                  .paymentURL,
              });
            } else {
              window
                .open(
                  response.data.orders[0]?.payment?.myFatoorahPayment
                    .paymentURL,
                  '_blank',
                )
                ?.focus();
            }
          }
        }

        if (
          orderStore.paymentSystem === PaymentSystem.midtrans &&
          response.data.orders[0]?.payment.midtransPayment
        ) {
          this.midtransPay(
            response.data.orders[0].payment.midtransPayment?.token,
          );
          return;
        }

        if (
          orderStore.paymentSystem === PaymentSystem.amazon &&
          this.activePaymentMethod === ETAPaymentMethod.Card
        ) {
          this.setIsLoading(false);
          return;
        }

        if (orderStore.paymentSystem === PaymentSystem.stripe) {
          if (!response.data.orders[0]?.payment_intent) {
            this.setIsLoading(false);
            throw new Error('!response.data.orders[0]?.payment_intent');
          }
          if (
            mainStore.isRN &&
            this.activePaymentMethod === MobilePaymentMethod.ApplePay &&
            mainStore.isApplePay
          ) {
            this.applePay(response);
            return;
          }

          if (
            mainStore.isRN &&
            this.activePaymentMethod === MobilePaymentMethod.GooglePay &&
            mainStore.isGooglePay
          ) {
            this.googlePay(response);
            return;
          }

          if (this.activePaymentMethod === ETAPaymentMethod.Card) {
            if (!stripe || !elements) {
              throw new Error('Stripe not found');
            }
            let paymentMethod: { card: StripeCardElement } | string;
            if (this.payments && this.payments.length && !this.isAddNewCard) {
              // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
              paymentMethod = this.paymentsMethodId || this.payments[0].value;
            } else {
              paymentMethod = {
                card: elements.getElement(CardElement) as StripeCardElement,
              };
            }
            this.setPaymentMethod(paymentMethod);
            this.stripeConfirmCardPayment(response, paymentMethod, stripe);
            return;
          }
        }
      }
      this.setPaymentMethod('');
      this.finalizePayment();
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          context: 'orderCheckout',
          error: e,
        });
        Sentry.captureMessage(
          '[Checkout] FAILED ON CHECKOUT METHOD',
          'warning',
        );
      });
      throw new Error('Filed create order');
    }
  }

  midtransPay(snapToken: string) {
    const setIsLoading = this.setIsLoading.bind(this);
    const finalizePayment = this.finalizePayment.bind(this);

    try {
      window?.snap.pay(snapToken, {
        onSuccess: () => {
          finalizePayment().then(() => {
            const storedPromocode = getStoredPromocode();
            if (
              catalogStore.promocode.success &&
              catalogStore.promocode.value === storedPromocode?.code
            ) {
              clearStoredPromocode();
            }
          });
        },
        onError: (result) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              result,
            });
            window?.snap.hide();
            mainStore.pushAlert('error', t('errors:unknown'));
            setIsLoading(false);
          });
        },
        onClose: () => {
          setIsLoading(false);
        },
      });
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtras({
          e,
        });
        setIsLoading(false);
      });
    }
  }

  async applePay(newOrderResponse: NewOrderResponse) {
    const deliveryFee = this.calculateFullDeliveryFee();

    if (orderStore.paymentSystem === PaymentSystem.myfatoorah) {
      mainStore.sendToRN(
        'initApplePay',
        {
          params: {
            cartItems: catalogStore.cart
              .map<Record<string, string>>((item) => {
                return {
                  label: item.name || '',
                  paymentType: 'Immediate',
                  amount: mainStore.convertPenceToPounds(
                    (item.discountPrice ? item.discountPrice : item.price) *
                      item.count,
                  ),
                };
              })
              .concat({
                label: company.name,
                amount: catalogStore.finalPrice,
                paymentType: 'Immediate',
              }),
            country: 'GB',
            currency: orderStore.currency.toUpperCase(),
            shippingMethods: [
              {
                label: 'Delivery',
                amount: deliveryFee || '0',
                identifier: 'delivery',
              },
            ],
          },
          clientSecret: undefined,
        },
        (e) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              response: e,
              paymentAmount: catalogStore.finalPrice,
            });
            Sentry.captureMessage('[ApplePay] Success', 'debug');
          });
        },
        (e) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              error: e,
              paymentAmount: catalogStore.finalPrice,
            });
            Sentry.captureMessage('[ApplePay] Failed', 'warning');
          });
        },
      );
    }

    const order = newOrderResponse.data.orders[0];

    if (!order) {
      throw new Error('No order data');
    }

    if (
      !order.payment_intent ||
      !order.payment_intent.client_secret ||
      !order.order
    ) {
      this.setError('' /*i18n.t('errors:paymentSystemError')*/);
      this.setIsLoading(false);
      throw new Error('!order.payment_intent');
    }

    try {
      const paymentResult = await new Promise<
        'success' | 'failed' | 'canceled' | 'timeout'
      >((resolve) => {
        if (!order.payment_intent) {
          resolve('failed');
          return;
        }

        const timeout = setTimeout(() => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              timeout: PAYMENT_TIMEOUT / 1000 + 'sec',
              request: {
                paymentIntentSecret: order.payment_intent?.client_secret || '',
              },
            });
            Sentry.captureMessage('[ApplePay] Timeout 10min', 'warning');
          });
          resolve('timeout');
        }, PAYMENT_TIMEOUT);

        mainStore.sendToRN(
          'initApplePay',
          {
            params: {
              cartItems: catalogStore.cart
                .map<Record<string, string>>((item) => {
                  return {
                    label: item.name || '',
                    amount: mainStore.convertPenceToPounds(
                      (item.discountPrice ? item.discountPrice : item.price) *
                        item.count,
                    ),
                    paymentType: 'Immediate',
                  };
                })
                .concat({
                  label: company.name,
                  amount: catalogStore.finalPrice,
                  paymentType: 'Immediate',
                }),
              country: 'GB',
              currency: orderStore.currency.toUpperCase(),
              shippingMethods: [
                {
                  label: 'Delivery',
                  amount: deliveryFee ?? '0',
                  identifier: 'delivery',
                },
              ],
            },
            clientSecret: order.payment_intent.client_secret,
          },
          (e) => {
            clearTimeout(timeout);
            Sentry.withScope((scope) => {
              scope.setExtras({
                response: e,
                paymentAmount: catalogStore.finalPrice,
              });
              Sentry.captureMessage('[ApplePay] Success', 'debug');
            });
            resolve('success');
          },
          (e) => {
            clearTimeout(timeout);
            Sentry.withScope((scope) => {
              scope.setExtras({
                error: e,
                paymentAmount: catalogStore.finalPrice,
              });
              Sentry.captureMessage('[ApplePay] Failed', 'warning');
            });
            resolve(e.error?.code === 'Canceled' ? 'canceled' : 'failed');
          },
        );
      });

      if (
        this.paymentIntent?.payment_intent?.client_secret !==
        order.payment_intent.client_secret
      ) {
        throw new Error(
          'this.paymentIntent?.payment_intent?.client_secret !== order.payment_intent.client_secret',
        );
      } else {
        if (paymentResult === 'success') {
          await this.finalizePayment().catch(
            (error) => error && console.error(error),
          );
        } else {
          if (paymentResult === 'canceled') {
            // await orderStore
            //   .orderCancel(order.order.id)
            //   .catch((error) => error && console.error(error));
          }
          if (paymentResult === 'failed' || paymentResult === 'timeout') {
            this.setError('' /*i18n.t('errors:paymentSystemError')*/);
            throw new Error('failed or timeout');
          }
        }
      }
      this.setIsLoading(false);
    } catch (error) {
      if (
        this.paymentIntent?.payment_intent?.client_secret ===
        order.payment_intent.client_secret
      ) {
        this.setError('' /*i18n.t('errors:paymentSystemError')*/);
        this.setIsLoading(false);
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error,
        });
        Sentry.captureMessage('[ApplePay] Error exception', 'warning');
      });
    }
  }

  async googlePay(newOrderResponse: NewOrderResponse) {
    const order = newOrderResponse.data.orders[0];

    if (!order) {
      throw new Error('No order data');
    }

    if (
      !order.payment_intent ||
      !order.payment_intent.client_secret ||
      !order.order
    ) {
      this.setError('' /*i18n.t('errors:paymentSystemError')*/);
      this.setIsLoading(false);
      throw new Error('!order.payment_intent');
    }
    try {
      const paymentResult = await new Promise<
        'success' | 'failed' | 'canceled' | 'timeout'
      >((resolve) => {
        if (!order.payment_intent) {
          resolve('failed');
          return;
        }
        const timeout = setTimeout(() => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              timeout: PAYMENT_TIMEOUT / 1000 + 'sec',
              request: {
                paymentIntentSecret: order.payment_intent?.client_secret || '',
              },
            });
            Sentry.captureMessage('[GooglePay] Timeout 10min', 'warning');
          });
          resolve('timeout');
        }, PAYMENT_TIMEOUT);
        mainStore.sendToRN(
          'requestGooglePayment',
          {
            transaction: {
              totalPrice: catalogStore.finalPrice,
              totalPriceStatus: 'FINAL',
              currencyCode: orderStore.currency.toUpperCase(),
            },
            merchantName: this.isRecipientValid
              ? this.recipientNameVal
              : userStore.fullName,
            clientSecret: order.payment_intent.client_secret,
          },
          (e) => {
            clearTimeout(timeout);
            Sentry.withScope((scope) => {
              scope.setExtras({
                response: e,
                paymentAmount: catalogStore.finalPrice,
              });
              Sentry.captureMessage('[GooglePay] Success', 'debug');
            });
            resolve('success');
          },
          (e) => {
            clearTimeout(timeout);
            Sentry.withScope((scope) => {
              scope.setExtras({
                error: e,
                paymentAmount: catalogStore.finalPrice,
              });
              Sentry.captureMessage('[GooglePay] Failed', 'warning');
            });
            resolve(
              e.confirmationError?.code === 'PAYMENT_RESULT_CANCELED'
                ? 'canceled'
                : 'failed',
            );
          },
        );
      });
      if (
        this.paymentIntent?.payment_intent?.client_secret !==
        order.payment_intent.client_secret
      ) {
        throw new Error(
          'this.paymentIntent?.payment_intent?.client_secret !== order.payment_intent.client_secret',
        );
      } else {
        if (paymentResult === 'success') {
          await this.finalizePayment().catch(
            (error) => error && console.error(error),
          );
        } else {
          if (paymentResult === 'canceled') {
            throw new Error('canceled');
            // await orderStore
            //   .orderCancel(order.order.id)
            //   .catch((error) => error && console.error(error));
          }
          if (paymentResult === 'failed' || paymentResult === 'timeout') {
            this.setError('' /*i18n.t('errors:paymentSystemError')*/);
            throw new Error('failed or timeout');
          }
        }
      }
      this.setIsLoading(false);
    } catch (error) {
      if (
        this.paymentIntent?.payment_intent?.client_secret ===
        order.payment_intent.client_secret
      ) {
        this.setError('' /*i18n.t('errors:paymentSystemError')*/);
        this.setIsLoading(false);
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error,
        });
        Sentry.captureMessage('[GooglePay] Error exception', 'warning');
      });
    }
  }

  async stripeConfirmCardPayment(
    newOrderResponse: NewOrderResponse,
    paymentMethod: { card: StripeCardElement } | string,
    stripe: Stripe,
  ) {
    const order = newOrderResponse.data.orders[0];

    if (!order) {
      throw new Error('No order data');
    }

    if (
      !order.payment_intent ||
      !order.payment_intent.client_secret ||
      !order.order
    ) {
      this.setError('' /*i18n.t('errors:paymentSystemError')*/);
      this.setIsLoading(false);
      throw new Error('!order.payment_intent');
    }
    try {
      const paymentResult = await new Promise<
        PaymentIntentResult | 'failed' | 'timeout'
      >((resolve, reject) => {
        if (!order.payment_intent) {
          resolve('failed');
          return;
        }

        const timeoutFroze = setTimeout(() => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              timeout: PAYMENT_TIMEOUT / 1000 + 'sec',
              request: {
                paymentIntentSecret: order.payment_intent?.client_secret || '',
                paymentMethod,
              },
            });
            Sentry.captureMessage(
              '[Checkout] Stripe confirmCardPayment timeout 10min',
              'warning',
            );
          });
          resolve('timeout');
        }, PAYMENT_TIMEOUT);

        stripe
          .confirmCardPayment(order.payment_intent.client_secret || '', {
            payment_method: paymentMethod,
          })
          .then((e) => {
            clearTimeout(timeoutFroze);
            resolve(e);
          })
          .catch((e) => {
            clearTimeout(timeoutFroze);
            reject(e);
          });
      });

      if (
        this.paymentIntent?.payment_intent?.client_secret !==
        order.payment_intent.client_secret
      ) {
        throw new Error(
          'paymentIntent?.payment_intent?.client_secret !== order.payment_intent.client_secret',
        );
      }

      if (paymentResult === 'failed' || paymentResult === 'timeout') {
        this.setError('' /*i18n.t('errors:paymentSystemError')*/);
        this.setIsLoading(false);
        throw new Error('Invalid paymentResult');
      }

      if (paymentResult.error) {
        this.setError(paymentResult.error.message || '');
        Sentry.withScope((scope) => {
          scope.setExtras({
            response: paymentResult,
          });
          Sentry.captureMessage(
            '[Checkout] Stripe confirmCardPayment error',
            'warning',
          );
        });
        this.setIsLoading(false);
        return;
      }

      await this.finalizePayment();
      this.setIsLoading(false);
    } catch (error) {
      if (
        this.paymentIntent?.payment_intent?.client_secret ===
        order.payment_intent.client_secret
      ) {
        this.setError('' /*i18n.t('errors:paymentSystemError')*/);
        this.setIsLoading(false);
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error,
        });
        Sentry.captureMessage(
          '[Checkout] Stripe confirmCardPayment exception',
          'warning',
        );
      });
    }
  }

  async finalizePayment() {
    if (
      !this.paymentIntent ||
      !this.paymentIntent.order ||
      !this.paymentIntent.payment_intent
    ) {
      throw new Error('!this.paymentIntent');
    }

    const isCardPayment = (
      [
        ETAPaymentMethod.Card,
        // AdditionalPaymentMethod.KNET,
        AdditionalPaymentMethod.VisaMastercard,
      ] as PaymentMethod[]
    ).includes(this.activePaymentMethod || ETAPaymentMethod.Card);

    try {
      this.setError(null);
      let count =
        (orderStore.paymentSystem === PaymentSystem.myfatoorah ||
          orderStore.paymentSystem === PaymentSystem.amazon) &&
        isCardPayment
          ? 60
          : 6;
      let bonuses = null;
      const waitingExpires = Date.now() + PAYMENT_WAITING_TIME * 1000;
      while (count > 0) {
        this.setIsLoading(true);
        if (!this.paymentIntent || !this.paymentIntent.order) {
          mainStore.sendToRN('closeInAppBrowser');
          this.setPaymentIntent(null);
          this.setError('' /*i18n.t('errors:paymentSystemError')*/);
          this.setIsLoading(false);
          throw new Error('!this.paymentIntent || !this.paymentIntent.order');
        }
        if (
          orderStore.paymentSystem === PaymentSystem.myfatoorah &&
          isCardPayment &&
          Date.now() >= waitingExpires
        ) {
          mainStore.sendToRN('closeInAppBrowser');
          this.setPaymentIntent(null);
          this.setError('' /*i18n.t('errors:paymentSystemError')*/);
          this.setIsLoading(false);
          throw new Error('waiting expires');
        }

        const { status, received_bonuses } = await orderStore.checkOrderPayment(
          this.paymentIntent.order.id,
        );

        bonuses = received_bonuses;
        count--;
        if (!status || ['created', 'reserved'].includes(status)) {
          if (count) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            continue;
          } else {
            mainStore.sendToRN('closeInAppBrowser');
            this.setPaymentIntent(null);
            this.setError('' /*i18n.t('errors:paymentSystemError')*/);
            this.setIsLoading(false);
            throw new Error('!status');
          }
        }
        if (status === 'cancelled') {
          mainStore.sendToRN('closeInAppBrowser');
          this.setPaymentIntent(null);
          this.setError('' /*i18n.t('errors:paymentSystemError')*/);
          this.setIsLoading(false);
          throw new Error('cancelled');
        }
        break;
      }

      mainStore.sendToRN('closeInAppBrowser');
      await orderStore
        .updateOrdersAnalytics()
        .catch((error) => error && console.error(error));
      orderStore.setOrderStatus('accepted');
      orderStore.setIsFirstOrder(false);
      mainStore.setNewDeliveryMethodForPromocodePopover(null);
      orderStore.setActiveOrderProductCount(catalogStore.totalCartCount);
      orderStore.setActiveOrderID(this.paymentIntent.order.id);
      this.setOrderId(this.paymentIntent.order.id);
      orderStore.setActiveOrderBonuses(bonuses);
      orderStore.setFreezeETAExpired(0);
      if (catalogStore.promocode.coupon) {
        runInAction(() => {
          const promocode = catalogStore.promocode.value.toUpperCase();
          if (
            PRESENT_PROMOCODES.includes(promocode) ||
            catalogStore.promocode.discountLimit
          ) {
            catalogStore.promotionStore.addUsedPromocodes(promocode);
          }
          mainStore.analytics.promocodeUsedCount += 1;
          mainStore.sendToRN('setUserProperties', {
            'Commerce: promocodes used': mainStore.analytics.promocodeUsedCount,
          });
        });
      }
      this.purchaseCompletedAnalytics(this.paymentIntent.order.id);
      orderStore.setActiveGift(null);
      orderStore.setGift(null);
      catalogStore.emptySelectedItemsFromCart();
      checkoutStore.setSeletectedDateExpress();
      catalogStore.promotionStore.resetPromocode();
      checkoutStore.setUseBonuses(false);
      if (!userStore.deliveryAddress?.address1) {
        userStore.setDeliveryAddress1(this.addressVal);
      }
      if (!userStore.deliveryAddress?.address2) {
        userStore.setDeliveryAddress2(this.address2Val);
      }
      if (!userStore.deliveryAddress?.addressFloor) {
        userStore.setDeliveryAddressFloor(this.addressFloorVal);
      }
      if (!userStore.deliveryAddress?.addressBld) {
        userStore.setDeliveryAddressBld(this.addressBldVal);
      }
      if (!userStore.deliveryAddress?.addressApt) {
        userStore.setDeliveryAddressApt(this.addressAptVal);
      }
    } catch (e) {
      this.setError('' /*i18n.t('errors:paymentSystemError')*/);
      throw e;
    }
  }

  async syncAddress() {
    try {
      if (userStore.deliveryAddress) {
        let isChangedAddress = false;
        if (this.commentVal !== userStore.deliveryAddress.comment) {
          isChangedAddress = true;
          runInAction(() => {
            if (!userStore.deliveryAddress) {
              return;
            }
            userStore.deliveryAddress.comment = this.commentVal;
          });
        }
        if (
          Object.values(this.instructionList).length !==
          userStore.deliveryAddress.instructions?.length
        ) {
          runInAction(() => {
            if (!userStore.deliveryAddress) {
              return;
            }
            if (
              catalogStore.isAdultItemInCart &&
              Object.values(this.instructionList).length === 2 &&
              userStore.deliveryAddress.instructions?.length === 3
            ) {
              return;
            }
            isChangedAddress = true;
            userStore.deliveryAddress.instructions = Object.values(
              this.instructionList,
            );
          });
        }
        if (!userStore.deliveryAddress.addressId) {
          await this.addNewAddress().catch(
            (error) => error && console.error(error),
          );
        } else {
          if (isChangedAddress) {
            const isSuccess = await userStore
              .updateAddress(userStore.deliveryAddress.addressId, {
                comment: userStore.deliveryAddress.comment || '',
                instructions: userStore.deliveryAddress.instructions || [],
              })
              .catch((error) => error && console.error(error));
            if (isSuccess) {
              return;
            }
            await this.addNewAddress().catch(
              (error) => error && console.error(error),
            );
          }
        }
      }
    } catch (error) {
      error && console.error(error);
    }
  }

  async addNewAddress() {
    if (!userStore.deliveryAddress) {
      return;
    }
    try {
      const address = await userStore
        .addAddress({
          street_address_1: userStore.deliveryAddress.address1,
          street_address_2: userStore.deliveryAddress.address2,
          building: userStore.deliveryAddress.addressBld,
          floor: userStore.deliveryAddress.addressFloor,
          apartment: userStore.deliveryAddress.addressApt,
          city: userStore.deliveryAddress.city,
          postcode: userStore.deliveryAddress.zip,
          latitude: userStore.deliveryAddress.coordinates.lat,
          longitude: userStore.deliveryAddress.coordinates.lng,
          country: userStore.deliveryAddress.country,
          comment: userStore.deliveryAddress.comment || '',
          instructions: userStore.deliveryAddress.instructions || [],
          type: userStore.deliveryAddress.type || 'home',
        })
        .catch((error) => error && console.error(error));
      runInAction(() => {
        if (!userStore.deliveryAddress) {
          return;
        }
        userStore.deliveryAddress.addressId = address.id || null;
        userStore.setDeliveryAddress2(address.street_address_2);
      });
    } catch (error) {
      error && console.error(error);
    }
  }

  resetStore() {
    this.addressVal = '';
    this.phoneVal = '';
    this.isPhoneValid = true;
    this.isEmailSync = true;
    this.isPersonalDataSync = true;
    this.nameVal = '';
    this.emailVal = '';
    this.commentVal = '';
    this.recipientPhoneVal = '';
    this.isRecipientPhoneValid = false;
    this.recipientNameVal = '';
    this.recipientEmailVal = '';
    this.recipientTempPhoneVal = '';
    this.isRecipientTempPhoneValid = false;
    this.recipientTempNameVal = '';
    this.recipientTempEmailVal = '';
    this.agreeSaveCard = true;
    this.error = null;
    this.isLoading = false;
    this.isDisabled = true;
    this.isPersonalDataChanged = false;
    this.isShowRecipientPopover = false;
    this.isShowTimeoutPopover = false;
    this.isUnableConfirmCardPayment = false;
    this.isAddNewCard = false;
    this.paymentIntent = null;
    this.paymentMethod = '';
    this.orderId = '';
    this.instructionList = {};
    this.stateData = null;
    this.isRefreshed = false;
    this.isUpdatedDeliveryPopover = false;
    this.sessionId = undefined;
    this.isDeliverTogether = company.combineItemsByDefaut;
    if (
      !this.availableDeliveryMethods.length ||
      this.availableDeliveryMethods.includes(
        ETADeliveryMethodType.JiffyDelivery,
      )
    ) {
      this.setDeliveryMethod(ETADeliveryMethodType.JiffyDelivery);
    } else {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      this.setDeliveryMethod(this.availableDeliveryMethods[0]);
    }
  }

  destroy() {}

  private getExactAmountInstruction() {
    return 'Client will prepare the exact amount';
  }

  private getChangeFromAmountInstruction(amount: number) {
    return `Prepare the change from ${amount} ${company.currency}`;
  }
}

export const checkoutStore = new CheckoutStore();

autorun(() => {
  if (!checkoutStore.deliveries && checkoutStore.sortedDeliverySlotsList) {
    checkoutStore.calculateDeliveries(checkoutStore.sortedDeliverySlotsList);
  }
});

reaction(
  () => checkoutStore.isDeliverTogether,
  () => {
    checkoutStore.calculateDeliveries(checkoutStore.sortedDeliverySlotsList);
  },
);

reaction(
  () => checkoutStore.deliveryMethod,
  async () => {
    try {
      await orderStore.requestETA();
      checkoutStore.calculateDeliveries(checkoutStore.sortedDeliverySlotsList);
      await orderStore.getDeliveryCost();
    } finally {
      checkoutStore.deliveryMethodChangeMeta.setLoading(false);
    }
  },
);
