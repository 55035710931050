export enum ApplyPromocodeCallerSource {
  INTERNAL = 'internal',
  CHECKOUT = 'checkout',
  CART = 'cart',
}

export interface ProposedPromocode {
  name: string;
  discount: string;
  title: string;
  text: string;
}
