import { useTranslation } from 'react-i18next';

import Badge from '../Badge/Badge';

type ExpressDeliveryBadgeProps = {
  className?: string;
  size?: 'xs' | 's' | 'm';
};
const ExpressDeliveryBadge = ({
  className,
  size = 's',
}: ExpressDeliveryBadgeProps) => {
  const { t } = useTranslation();

  return (
    <Badge size={size} theme="primary" className={className}>
      {t('express')}
    </Badge>
  );
};

export default ExpressDeliveryBadge;
