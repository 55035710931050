import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import { CartItem } from '~/stores/CatalogStore';
import { Product } from '~/stores/CategoriesStore';

export const useCategoryAnalytics = () => {
  const handleClickCategory = (id: number | string, source?: string) => () => {
    analyticsEventsEmitter.emit(EventsName.CATALOG_CATEGORY_CLICKED, {
      id: `${id}`,
      source: source || '',
    });
    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_CLICKED,
      {
        id,
        source: source || '',
      },
    );
    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_VISITED,
      {
        id,
        source: source || '',
      },
    );
  };

  const handleClickProduct = (product: Product | CartItem) => () => {
    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_CLICKED,
      product,
    );
    analyticsEventsEmitter.emit(
      EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_VISITED,
      product,
    );
  };

  return {
    handleClickCategory,
    handleClickProduct,
  };
};
