import { uniqBy } from 'lodash-es';
import { makeObservable, observable, action, runInAction } from 'mobx';

import { CatalogStorePartial } from '~/stores/CatalogStore';
import { Offer } from '~/stores/CategoriesStore';
import { BaseStore } from '~/stores/interfaces/BaseStore';
import { isProductBundle } from '~/types/Product';

import {
  CalculateStockErrorInstance,
  UnavailableStockItem,
} from './interfaces';

// TODO: Implement checkboxes for unavailableCartItems and ability to remove certain cart items
class CartStockChanges implements BaseStore {
  isOpen: boolean = false;
  unavailableCartItems: UnavailableStockItem[] = [];

  private readonly _catalogStore: CatalogStorePartial;

  constructor(catalogStore: CatalogStorePartial) {
    this._catalogStore = catalogStore;

    makeObservable(this, {
      isOpen: observable,
      unavailableCartItems: observable,
      openNotifierModal: action.bound,
      destroy: action.bound,
    });
  }

  private _fillUnavailableCartItems(error: CalculateStockErrorInstance): void {
    const { items, bundles } = error;
    this.unavailableCartItems = uniqBy(
      items
        .map((error) => {
          const isBundleError = Boolean(error.bundle_id);

          const targetCartItem = this._catalogStore.cart.find((cartItem) => {
            const isBundleItem = isProductBundle(cartItem);

            if (isBundleError) {
              if (!isBundleItem) {
                return false;
              }
              return `${cartItem.id}` === error.bundle_id;
            }

            if (isBundleItem) {
              return false;
            }
            return cartItem.sku === error.sku;
          });

          if (!targetCartItem) {
            return;
          }

          const isBundle = isProductBundle(targetCartItem);
          const bundleSellable =
            isBundle && bundles && bundles[targetCartItem.id]
              ? bundles[targetCartItem.id]
              : null;

          return {
            cartItem: targetCartItem,
            error,
            bundleError: bundleSellable,
          };
        })
        .filter(Boolean) as UnavailableStockItem[],
      (item) => item.cartItem.id,
    );
  }

  private _setUpdatedCart() {
    this._catalogStore.cart = this._catalogStore.cart.map((cartItem) => {
      const isCartItemBundle = isProductBundle(cartItem);

      const targetCartItem = this.unavailableCartItems.find((item) => {
        const isBundle = isProductBundle(item.cartItem);

        if (!isCartItemBundle) {
          if (isBundle) {
            return false;
          }
          return (item.cartItem as Offer).sku === (cartItem as Offer).sku;
        }

        if (!isBundle) {
          return false;
        }
        return `${item.error.bundle_id}` === `${cartItem.id}`;
      });

      if (!targetCartItem) {
        return cartItem;
      }

      const actualStock = this._getActualStockForErrorItem(targetCartItem);

      return {
        ...cartItem,
        count: actualStock,
        sellable: actualStock,
      };
    });
  }

  private _getActualStockForErrorItem(
    unavailableCartItem: UnavailableStockItem,
  ): number {
    const isBundle = isProductBundle(unavailableCartItem.cartItem);

    if (!isBundle) {
      return unavailableCartItem.error.on_stock >= 0
        ? unavailableCartItem.error.on_stock
        : 0;
    }

    const { sellable = 0 } = unavailableCartItem.bundleError || {};

    return sellable >= 0 ? sellable : 0;
  }

  openNotifierModal(error: CalculateStockErrorInstance) {
    if (!error.items.length) {
      return;
    }

    runInAction(() => {
      this._fillUnavailableCartItems(error);

      this.isOpen = true;
    });
  }

  closeNotifierModal() {
    runInAction(() => {
      this.isOpen = false;
    });
  }

  updateCartAndCloseNotifierModal() {
    runInAction(() => {
      this._setUpdatedCart();

      this.isOpen = false;
    });
  }

  destroy() {
    this.isOpen = false;
    this.unavailableCartItems = [];
  }
}

export default CartStockChanges;
