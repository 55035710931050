import { CloudflareResizeOptions } from './CloudflareResizeOptions';

export const removeSlash = (src: string) =>
  src.startsWith('/') ? src.slice(1) : src;

export const getCloudflareUrl = (
  src: string,
  domain?: string,
  options?: CloudflareResizeOptions,
): string => {
  if (!options) {
    return src;
  }

  const params = Object.entries(options)
    .map(([key, value]) => `${key}=${value}`)
    .join(',');

  return `${domain ?? ''}/cdn-cgi/image/${params}/${removeSlash(src)}`;
};
