import { times } from 'lodash-es';
import React, { ReactNode } from 'react';

type SkeletonListRowsProps = {
  children: ReactNode;
  rows: number;
};

const SkeletonListRows = ({ children, rows = 1 }: SkeletonListRowsProps) => {
  return (
    <>
      {times(rows, (i) => (
        <React.Fragment key={i}>{children}</React.Fragment>
      ))}
    </>
  );
};

export default SkeletonListRows;
