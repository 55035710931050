import { FC, useEffect, useRef } from 'react';

import ProductsCarouselSliderOneBlockSkeleton from '~/components/CategoryBlocks/ProductsCarouselSliderOneBlock/ProductsCarouselSliderOneBlockSkeleton';

interface ScrollPaginationSkeletonProps {
  onLoadMore(): void;
}

export const ScrollPaginationSkeleton: FC<ScrollPaginationSkeletonProps> = ({
  onLoadMore,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            onLoadMore();
          }
        });
      },
      { rootMargin: `${window.innerHeight}px` },
    );

    // FIXME
    setTimeout(() => {
      ref.current && observer.observe(ref.current);
    }, 2000);

    return () => observer.disconnect();
  }, [onLoadMore]);

  return <ProductsCarouselSliderOneBlockSkeleton ref={ref} />;
};
