import { classes } from 'html-classes';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';

import styles from './CollapsibleList.module.scss';

type Props = {
  itemsToShow: ReactNode[];
  itemsToHide: ReactNode[];
  showMoreText?: string;
  showLessText?: string;
  sublistClassName?: string;
  sublistClassNameCollapsible?: string;
};

export const CollapsibleList = ({
  itemsToShow,
  itemsToHide,
  showMoreText,
  showLessText,
  sublistClassName,
  sublistClassNameCollapsible,
}: Props) => {
  const { t } = useTranslation();

  const [isListOpen, setIsListOpen] = useState(false);
  const collapsibleRef = useRef<HTMLDivElement>(null);
  const baseRef = useRef<HTMLSpanElement>(null);

  const expandButtonHandler = () => {
    setIsListOpen(!isListOpen);
    if (isListOpen && baseRef?.current) {
      const position =
        window.scrollY + baseRef.current.getBoundingClientRect().top - 220;
      if (position) {
        window.scrollTo({
          top: position,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      <i className={styles.baseAnchor} ref={baseRef} />
      {itemsToShow}
      {itemsToHide.length > 0 && (
        <div
          className={classes(['__collapsible', sublistClassNameCollapsible])}
          style={{
            height:
              !isListOpen || !collapsibleRef
                ? '0px'
                : `${collapsibleRef.current?.offsetHeight}px`,
          }}
        >
          <div className={sublistClassName} ref={collapsibleRef}>
            {itemsToHide}
          </div>
        </div>
      )}
      {itemsToHide.length > 0 && (
        <div className={styles.buttonContainer} data-company={company.name}>
          <button
            className={classes(['button _bordered', styles.button])}
            onClick={expandButtonHandler}
          >
            {isListOpen
              ? showLessText ?? t('showLess')
              : showMoreText ?? t('showMore')}{' '}
            <Icon
              type="chevron-2-fill"
              className={classes([
                styles.buttonIcon,
                isListOpen && styles.open,
              ])}
              size={16}
            />
          </button>
        </div>
      )}
    </>
  );
};
