import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { company } from '~/company/Company';

import { useModal } from '../../hooks/useModal';

import { ModalProps } from './interface';

export default observer(
  ({
    isShow,
    children,
    isBackdrop = true,
    onBackdropDismiss,
    className,
    containerClassName,
  }: ModalProps) => {
    const { closeModal } = useModal();
    const refModal = useRef<HTMLDivElement>(null);

    const handleBackdropDismiss = () => {
      if (onBackdropDismiss) {
        onBackdropDismiss(closeModal);
      } else {
        closeModal();
      }
    };

    useEffect(() => {
      const closeByEsc = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          closeModal();
        }
      };

      document.addEventListener('keydown', closeByEsc);
      return () => document.removeEventListener('keydown', closeByEsc);
    }, []);

    return (
      <CSSTransition
        in={isShow}
        timeout={{ enter: 350, exit: 250 }}
        mountOnEnter
        unmountOnExit
        classNames="modal"
        nodeRef={refModal}
      >
        <div className={classes(['modal', containerClassName])} ref={refModal}>
          <div
            className={classes(['modal__bg', !isBackdrop && '_transparent'])}
            onClick={handleBackdropDismiss}
          />
          <div
            className={classes(['modal__body', className])}
            data-company={company.name}
          >
            {children}
          </div>
        </div>
      </CSSTransition>
    );
  },
);
