import { ENDPOINT } from '~/api/constants';
import { RequestAPI } from '~/api/Requests';

export interface Message {
  name: string;
  phone: string;
  email: string;
  message: string;
}

export const MessageRequest = {
  send: (data: Message) => RequestAPI.post(ENDPOINT.message.send, data),
};
