import { addMinutes, set, differenceInSeconds } from 'date-fns';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Icon from '~/components/Icon/Icon';
import { Time } from '~/constants/variables';
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { orderStore } from '~/stores/OrderStore';

import styles from './NavButtons.module.scss';

type DeliveryInfo = ReturnType<typeof orderStore.getDeliveryLabel>;

const DURATION = 10000;
const DELAY_FOR_SLOT = 10; // Minutes
const DELAY_FOR_EXPRESS = 30; // Minutes
const MAX_TIMESTAMPS_DIFF = 30; // Seconds

const getExpressDeliveryExpireTime = (
  expressDelivery: DeliveryInfo['expressDelivery'] | undefined,
) => {
  if (!expressDelivery) {
    return null;
  }

  return dateByTime(expressDelivery.closing).valueOf();
};

const getSlotExpireTime = (slot: DeliveryInfo['slot'] | undefined) => {
  if (!slot) {
    return null;
  }

  return slot.cut_off_time * 1000;
};

const dateByTime = (time: string) => {
  const [h, m, s] = time.split(':');
  const toNumber = (value: unknown) => Number(value) || 0;

  return set(new Date(), {
    milliseconds: 0,
    seconds: toNumber(s),
    minutes: toNumber(m),
    hours: toNumber(h),
  });
};

type Schedule = Record<
  string,
  {
    timestamp: number;
    minutesToDelay: number;
    isDone: boolean;
    openedAt: Nullable<number>;
  }
>;

type Props = { classNames?: string };

export const CartNotification = observer(({ classNames }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [message, setMessage] = useState('');
  const [style, setStyle] = useState<CSSProperties>({});
  const [schedule, setSchedule] = useLocalStorage<Schedule>(
    'cartNotification',
    {},
  );

  const { expressDelivery, slot } = orderStore.getDeliveryLabel();

  const closeNotification = useCallback((message: string) => {
    setStyle({});
    setSchedule((schedule) => {
      const item = schedule?.[message];

      return {
        ...schedule,
        [message]: {
          isDone: true,
          openedAt: item?.openedAt || null,
          timestamp: item?.timestamp || Date.now(),
          minutesToDelay: item?.minutesToDelay || 0,
        },
      };
    });
  }, []);

  const scheduleNotification = useCallback(
    (expireTimestamp: number, minutesToDelay: number, message: string) => {
      setSchedule((schedule) => {
        const scheduleItem = schedule?.[message];

        if (
          !scheduleItem ||
          scheduleItem.minutesToDelay !== minutesToDelay ||
          Math.abs(
            differenceInSeconds(scheduleItem.timestamp, expireTimestamp),
          ) > MAX_TIMESTAMPS_DIFF
        ) {
          return {
            ...schedule,
            [message]: {
              minutesToDelay,
              timestamp: expireTimestamp,
              isDone: false,
              openedAt: null,
            },
          };
        }

        return schedule;
      });
    },
    [],
  );

  useEffect(() => {
    const slotExpire = getSlotExpireTime(slot);
    const deliveryExpire = getExpressDeliveryExpireTime(expressDelivery);
    const now = Date.now();

    if (deliveryExpire && deliveryExpire > now) {
      scheduleNotification(
        deliveryExpire,
        DELAY_FOR_EXPRESS,
        'expressDeliveryNotification',
      );
    }

    if (
      slotExpire &&
      deliveryExpire &&
      Math.abs(differenceInSeconds(slotExpire, deliveryExpire)) <
        MAX_TIMESTAMPS_DIFF
    ) {
      scheduleNotification(
        Math.max(now, Math.min(slotExpire, deliveryExpire)),
        DELAY_FOR_SLOT,
        'termsChangingNotification',
      );
    } else if (!deliveryExpire && slotExpire && slotExpire > now) {
      scheduleNotification(
        slotExpire,
        DELAY_FOR_SLOT,
        'slotExpireNotification',
      );
    }
  }, [
    JSON.stringify(expressDelivery),
    JSON.stringify(slot),
    scheduleNotification,
  ]);

  useEffect(() => {
    if (!schedule) {
      return;
    }

    const timeouts: NodeJS.Timeout[] = [];

    Object.entries(schedule).forEach(([message, item]) => {
      const { timestamp, minutesToDelay, isDone, openedAt } = item;
      const time =
        addMinutes(timestamp, -Math.abs(minutesToDelay)).valueOf() - Date.now();
      const isAvailablePage =
        pathname === '/' ||
        pathname === '/shop' ||
        pathname.includes('/c/') ||
        pathname.includes('/p/');

      if (!isAvailablePage || time < -Time.Minute) {
        return;
      }

      if (!isDone) {
        const timeout = setTimeout(
          () => {
            setMessage(message);
            setStyle({ opacity: 1, pointerEvents: 'unset' });
            setSchedule((prev) => ({
              ...prev,
              [message]: { ...item, isDone: true, openedAt: Date.now() },
            }));
          },
          Math.max(time, 0),
        );

        timeouts.push(timeout);
      } else if (time < 0 && openedAt && Date.now() - openedAt < DURATION) {
        setMessage(message);
        setStyle({
          opacity: 1,
          pointerEvents: 'unset',
          transition: 'unset',
        });
      }
    });

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [schedule, pathname]);

  return (
    <div
      style={style}
      className={classes([styles.cartNotification, classNames])}
      onClick={(event) => event.stopPropagation()}
    >
      <Icon type="time" />
      <span className={styles.content}>{t(message)}</span>
      <button
        className={classes([styles.close, 'button'])}
        onClick={() => closeNotification(message)}
      >
        {t('okButton')}
      </button>
    </div>
  );
});
