import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';

import { Rate } from '~/stores/CatalogStore';

import Icon from '../Icon/Icon';

import styles from './StarRate.module.scss';

const StarRate = ({
  rate,
  setRate,
}: {
  rate: Rate;
  setRate: (i: Rate) => void;
}) => {
  return (
    <div className={styles.root}>
      {([1, 2, 3, 4, 5] as Rate[]).map((i) => (
        <div key={i} onClick={() => setRate(i)}>
          <Icon
            type="star-fill"
            size={48}
            className={classes([styles.star, rate >= i && styles.filledStart])}
          />
        </div>
      ))}
    </div>
  );
};

export default observer(StarRate);
