import { classes } from 'html-classes';
import { ReactElement } from 'react';

import { company } from '~/company/Company';

import styles from './Checkbox.module.scss';

type CheckboxProps = {
  onChange: () => void;
  checked: boolean;
  children?: ReactElement;
};

const Checkbox = ({ checked, onChange, children }: CheckboxProps) => {
  const anchorClassContainer = 'checkbox_container';
  const anchorClass = 'control__checkbox';
  const anchorClassInput = 'control__checkbox-input';
  const anchorClassMark = 'control__checkbox-mark';
  return (
    <div className={classes([styles.container, anchorClassContainer])}>
      {children && <div className={styles.children}>{children}</div>}
      <label className={classes([anchorClass, styles.checkbox])}>
        <input
          type="checkbox"
          className={classes([anchorClassInput, styles.checkboxInput])}
          onChange={onChange}
          checked={checked}
        />
        <span
          data-company={company.name}
          className={classes([anchorClassMark, styles.checkboxMark])}
        />
      </label>
    </div>
  );
};

export default Checkbox;
