import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale, type Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { KYCStatus } from '~/api/KYCStatus';
import { company } from '~/company/Company';
import { ModalType } from '~/components/Modal/interface';
import { ModalSlotsChangedEvent } from '~/components/ModalSlotsChangedEvent';
import { useModal } from '~/hooks/useModal';
import CheckoutContent from '~/pages/Checkout/CheckoutContent';
import { catalogStore } from '~/stores/CatalogStore';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { ApplyPromocodeCallerSource } from '~/stores/PromotionsStore/interfaces';
import { userStore } from '~/stores/UserStore';

import CheckoutSkeleton from './CheckoutSkeleton';

const Checkout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const [stripe, setStripe] = useState<Stripe>();
  const [isReady, setIsReady] = useState(false);
  const init = useCallback(async () => {
    if (catalogStore.promotionStore.promocode.value) {
      catalogStore.promotionStore
        .applyPromocode(
          catalogStore.promotionStore.promocode.value,
          ApplyPromocodeCallerSource.CHECKOUT,
        )
        .then((isPromocodeApplied) => {
          if (!isPromocodeApplied) {
            throw new Error('Promocode is not applied');
          }
        })
        .catch(() => {
          mainStore.setIsInvalidPromocodePopover(true);
        });
    }

    try {
      checkoutStore.setIsUpdatedDeliveryPopover(false);
      await catalogStore.calculateCart();
      await userStore.requestPersonalData();
      if (!orderStore.freezeETAExpired) {
        await orderStore.getDeliveryCost();
      }

      checkoutStore.setPhoneVal(userStore.personalData.phone ?? '');
      checkoutStore.setNameVal(userStore.fullName);
      checkoutStore.setEmailVal(userStore.personalData.email ?? '');
      checkoutStore.initStateData();
      // FIXME: what we are trying to catch here?
    } catch (error) {
      checkoutStore.setNameVal('');
      checkoutStore.setEmailVal('');
    }

    // FIXME: move it somewhere
    mainStore.sendAnalytics(['BI', 'analytics', 'yaMetrika'], {
      name: 'Purchase: start checkout',
      params: {
        cart_id: undefined,
        products_amount: catalogStore.cart.reduce(
          (sum, item) => sum + item.count,
          0,
        ),
        items_amount: catalogStore.cart.length,
        price: catalogStore.totalCartPrice.base,
        final_price: catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });

    // FIXME: move it somewhere
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'begin_checkout',
      params: {
        currency: orderStore.currency.toUpperCase(),
        value: mainStore.toFloat(catalogStore.finalPrice),
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promotionStore.promocode.value,
      },
    });

    setIsReady(true);
  }, [catalogStore.promotionStore.promocode.value]);

  useEffect(() => {
    checkoutStore.resetStore();
    const initStripe = async () => {
      if (company.envVariables.REACT_APP_STRIPE_API_KEY) {
        const stripe = await loadStripe(
          company.envVariables.REACT_APP_STRIPE_API_KEY,
          // FIXME: specify locale
          { locale: 'en-GB' },
        );

        if (!stripe) {
          mainStore.pushAlert('error', t('errors:paymentSystemNotLoaded'));
          throw new Error('Something went wrong');
        }

        setStripe(stripe);
      }
    };
    initStripe();
  }, []);

  useEffect(() => {
    if (
      !userStore.deliveryAddress ||
      !orderStore.etaCalculation?.warehouse.availability.availableNow
    ) {
      openModal(ModalType.DeliveryAddress);
      return;
    }

    if (!catalogStore.cart.length) {
      navigate('/cart', { replace: true });
      // TODO: report to sentry
      return;
    }

    if (orderStore.paymentSystem === undefined) {
      return;
    }

    init();

    return;
  }, [
    orderStore.paymentSystem,
    userStore.deliveryAddress,
    orderStore.etaCalculation?.warehouse.availability.availableNow,
  ]);

  useEffect(() => {
    if (
      company.needVerification &&
      userStore.personalData.kycStatus !== KYCStatus.Verified
    ) {
      navigate('/cabinet/personal-data');
    }
  }, [userStore.personalData.kycStatus]);

  useEffect(() => {
    catalogStore.promotionStore.loadStoredPromoCodes();
  }, []);

  return (
    <>
      {stripe && isReady ? (
        <Elements
          stripe={stripe}
          options={{ locale: (userStore.lang ?? 'en') as StripeElementLocale }}
        >
          <CheckoutContent />
        </Elements>
      ) : (
        <CheckoutSkeleton />
      )}

      <ModalSlotsChangedEvent />
    </>
  );
};

export default observer(Checkout);
