import { useEffect, useLayoutEffect, useRef, useState } from 'react';

export const IS_SSR = typeof window === 'undefined';

export const useIsomorphicLayoutEffect = IS_SSR ? useEffect : useLayoutEffect;

export const useOffsetTop = () => {
  const [offset, setOffset] = useState(0);
  const offsetRef = useRef(null);

  useIsomorphicLayoutEffect(() => {
    const updateOffsetTop = () => {
      const currentRect = offsetRef?.current?.getBoundingClientRect();
      setOffset(currentRect ? currentRect.top : 0);
    };

    window.addEventListener('scroll', updateOffsetTop);
    updateOffsetTop();
    return () => window.removeEventListener('scroll', updateOffsetTop);
  }, []);

  return { offset, offsetRef };
};
