import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Cheers from '~/assets/img/cheers.png';
import Logo from '~/assets/img/logo_cd_without_text.svg';
import { company } from '~/company/Company';
import Collapsible from '~/components/Collapsible/Collapsible';
import Icon from '~/components/Icon/Icon';
import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './FAQ.module.scss';

interface AccordionItem {
  title: string;
  content: string;
}

const faqData = [
  {
    title: 'Where are you located?',
    content: `Thanks for contacting us!</br> CityDrinks operates exclusively online. Visit our website <a href=${company.config.canonical} target="_blank">Citydrinks.com</a>, <br /> or download our easy-to-use app:
      <div style="display: flex; justify-content: center;margin-top: 12px;">
        <a href=${company.config.appStoreUrl.ios} style="cursor: pointer; color: transparent; margin-right: 16px;" target="_blank" class="_container_gtu3z_5 header-top-download__storeButton" rel="noreferrer" data-company="citydrinks"><img src='/images/apple_store.png' style="height: 32px" /></a>
        <a href=${company.config.appStoreUrl.android} style="cursor: pointer; color: transparent" target="_blank" class="_container_gtu3z_5" rel="noreferrer" data-company="citydrinks"><img src='/images/google_play.png' style="height: 32px" /></a>
      </div>`,
  },
  {
    title: 'How much is the delivery fee?',
    content: `You can find the terms of delivery on the website <a href=${company.config.canonical} target="_blank">Shop Alcohol In Abu Dhabi - Express Delivery In 30 Minutes <i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M17.9125 10.7209H14.8225V3.52087C14.8225 1.84087 13.9125 1.50087 12.8025 2.76087L12.0025 3.67087L5.23245 11.3709C4.30245 12.4209 4.69245 13.2809 6.09245 13.2809H9.18245V20.4809C9.18245 22.1609 10.0925 22.5009 11.2025 21.2409L12.0025 20.3309L18.7725 12.6309C19.7025 11.5809 19.3125 10.7209 17.9125 10.7209Z" fill="#AA263E"/>
</svg></i> CityDrinks</a>`,
  },
  {
    title: 'Do you offer same-day delivery?',
    content:
      'Yes. Our delivery service is the fastest in town! Products listed with the Express badge signify that they are dispatched swiftly through our Expressfleet.',
  },
  {
    title: 'What is the accepted mode of payment?',
    content:
      'We accept Apple Pay, Google Pay, all major credit and bank cards, and cash-on-delivery.  <br /> <br /> Please note that Islamic banks do not allow their customers to purchase alcoholic drinks from our platform (ADIB, EIB, etc).',
  },
  {
    title: `I'm on a tourist visa. Can I make an order?`,
    content:
      'Yes, of course! <br />You simply need to have a valid ID to prove you are over 21.',
  },
  {
    title: 'What drinks do you have?',
    content:
      'We offer a wide range of refreshing sodas and exquisite beverages. Our diverse selection caters to every flavor preference. Plus, all our products are always in stock. Check out our app and website to explore our collection!',
  },
  {
    title: 'Do you deliver to my area?',
    content:
      'We deliver to locations in Abu Dhabi.<br /> Pin your location on our app and website to see all products available in your location.',
  },
  {
    title: 'Is this legal?',
    content: 'Yes, CityDrinks is fully licensed in the Emirate of Abu Dhabi.',
  },
  {
    title: 'Do I need to provide an ID?',
    content:
      'Yes, you must pass our one-time in-app ID verification to be able to place an order, and you will have to present a valid ID to our couriers upon delivery.',
  },
];

export default observer(() => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const { state } = useLocation();
  const source = state?.source || 'home';
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      {isTablet && (
        <MobileHeaderLayout content={<MobileHeaderTitle text={t('FAQ')} />} />
      )}
      <div className={classes(['content-layout', styles.root])}>
        {!isTablet && (
          <>
            <Breadcrumbs
              items={
                [
                  {
                    text: t('home'),
                    link: '/',
                  },
                  source === 'cabinet' && {
                    text: t('cabinet'),
                    link: '/cabinet',
                  },
                  {
                    text: t('FAQ'),
                    link: '/FAQ',
                  },
                ].filter(Boolean) as BreadcrumbsItem[]
              }
              className={styles.breadcrumbs}
            />
          </>
        )}
        <section className={styles.header}>
          <p className={styles.header__welcome}>
            Frequently&nbsp;Asked Questions
          </p>

          {faqData.map((item: AccordionItem, index: number) => (
            <div
              key={index}
              className={styles.header_accordion_item}
              onClick={() => toggleAccordion(index)}
            >
              <Collapsible
                titleElem={
                  <div className={styles.header__title}>
                    <span>{item.title}</span>
                    <Icon type="chevron" size={16} />
                  </div>
                }
                isCollapsed={activeIndex !== index}
                bodyContainerClassName={styles.bodyContainer}
              >
                <div className={styles.header_accordion_content}>
                  <img
                    src={Logo}
                    className={styles.header_accordion_content_logoImg}
                  />
                  <div dangerouslySetInnerHTML={{ __html: item.content }} />
                </div>
              </Collapsible>
            </div>
          ))}
          <div className={styles.cheers_message}>
            <img className={styles.cheersImage} src={Cheers} alt="Cheers" />
            <div>
              <h4>Cheers!</h4>
              <p>The Citydrinks Team</p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
});
