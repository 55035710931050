import React from 'react';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

interface DesktopLayoutProps {
  children: React.ReactElement;
  layoutRef: React.Ref<HTMLDivElement>;
}

const DesktopLayout = ({ children, layoutRef }: DesktopLayoutProps) => {
  return (
    <div className="page-layout" ref={layoutRef}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default DesktopLayout;
