import mitt from 'mitt';

import { KYCStatus } from '~/api/KYCStatus';
import { deliveryAddressStore } from '~/components/ModalDeliveryAddress/DeliveryAddressStore';
import { CartItem, Rate, catalogStore } from '~/stores/CatalogStore';
import { Offer, Product } from '~/stores/CategoriesStore';
import { GetRequestObj, mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { userStore } from '~/stores/UserStore';

export enum EventsName {
  VIEW_CART = 'view_cart',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  ONBOARDING_SUBSCRIBE_DIALOG_SHOWN = 'onboarding_subscribe_dialog_shown',
  ONBOARDING_COMPLETE = 'onboarding_complete',
  ONBOARDING_SUBSCRIBE_DIALOG_CLICKED = 'onboarding_subscribe_dialog_clicked',
  SELECT_ITEM = 'select_item',
  LOGIN_SCREEN_SHOWN = 'login_screen_shown',
  CATALOG_SEARCH_VISITED = 'catalog_search_visited',
  CATALOG_SEARCH_CLICKED = 'catalog_search_clicked',
  CATALOG_CATEGORY_VISITED = 'catalog_category_visited',
  CATALOG_CATEGORY_CLICKED = 'catalog_category_clicked',
  COMMON_ANALYTICS_CATALOG_CATEGORY_CLICKED = 'Catalog: category clicked',
  COMMON_ANALYTICS_CATALOG_CATEGORY_VISITED = 'Catalog: category visited',
  COMMON_ANALYTICS_CATALOG_PRODUCT_VISITED = 'Catalog: product page visited',
  COMMON_ANALYTICS_CATALOG_PRODUCT_CLICKED = 'Catalog: product page clicked',
  COMMON_ANALYTICS_CATALOG_SEARCH_VISITED = 'Catalog: search visited',
  COMMON_ANALYTICS_CATALOG_SEARCH_CLICKED = 'Catalog: search clicked',
  COMMON_ANALYTICS_LOGIN_SCREEN_SHOWN = 'Login: screen shown',
  COMMON_ANALYTICS_VIEW_CART = 'Purchase: cart viewed',
  COMMON_ANALYTICS_ONBOARDING_COMPLETE = 'Onboarding: complete',
  COMMON_ANALYTICS_ONBOARDING_SUBSCRIBE_DIALOG_SHOWN = 'Onboarding: subscribe dialog shown',
  COMMON_ANALYTICS_ONBOARDING_SUBSCRIBE_DIALOG_CLICKED = 'Onboarding: subscribe dialog clicked',
  COMMON_ANALYTICS_CART_CHANGED = 'Purchase: cart changed',
  COMMON_ANALYTICS_REFERRAL_PROGRAM_OPEN = 'Referral program: Open',
  COMMON_ANALYTICS_REFERRAL_PROGRAM_SHARE = 'Referral program: Share',
  COMMON_ANALYTICS_RATE_PRODUCT_DETAILED = 'Rate: product detailed',
  COMMON_ANALYTICS_KYC_VERIFICATION_STATUS = 'KYC: verification status',
}

type Events = {
  [EventsName.ONBOARDING_COMPLETE]: undefined;
  [EventsName.ONBOARDING_SUBSCRIBE_DIALOG_SHOWN]: Record<string, unknown>;
  [EventsName.ONBOARDING_SUBSCRIBE_DIALOG_CLICKED]: {
    answer: 'skip' | 'next';
  };
  [EventsName.VIEW_ITEM]: Record<string, unknown>;
  [EventsName.VIEW_ITEM_LIST]: Record<string, unknown>;
  [EventsName.VIEW_CART]: undefined;
  [EventsName.SELECT_ITEM]: Offer;
  [EventsName.LOGIN_SCREEN_SHOWN]: undefined;
  [EventsName.CATALOG_SEARCH_VISITED]: string;
  [EventsName.CATALOG_SEARCH_CLICKED]: string;
  [EventsName.COMMON_ANALYTICS_CART_CHANGED]: undefined;
  [EventsName.CATALOG_CATEGORY_VISITED]: {
    id: string | number;
    source: string;
  };
  [EventsName.CATALOG_CATEGORY_CLICKED]: {
    id: string | number;
    source: string;
  };
  [EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_CLICKED]: {
    id: number | string;
    source: string;
  };
  [EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_VISITED]: {
    id: number | string;
    source: string;
  };

  [EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_CLICKED]: Product | CartItem;
  [EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_VISITED]: Product | CartItem;

  [EventsName.COMMON_ANALYTICS_CATALOG_SEARCH_CLICKED]: string;
  [EventsName.COMMON_ANALYTICS_CATALOG_SEARCH_VISITED]: string;
  [EventsName.COMMON_ANALYTICS_LOGIN_SCREEN_SHOWN]: undefined;
  [EventsName.COMMON_ANALYTICS_VIEW_CART]: undefined;
  [EventsName.COMMON_ANALYTICS_ONBOARDING_COMPLETE]: undefined;
  [EventsName.COMMON_ANALYTICS_ONBOARDING_SUBSCRIBE_DIALOG_SHOWN]: undefined;
  [EventsName.COMMON_ANALYTICS_ONBOARDING_SUBSCRIBE_DIALOG_CLICKED]: {
    answer: 'skip' | 'next';
  };
  [EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_OPEN]: undefined;
  [EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_SHARE]: undefined;
  [EventsName.COMMON_ANALYTICS_RATE_PRODUCT_DETAILED]: {
    productId: number;
    mark: Rate;
    content: string | null;
  };
  [EventsName.COMMON_ANALYTICS_KYC_VERIFICATION_STATUS]: {
    status: KYCStatus;
  };
};

const analyticsEventsEmitter = mitt<Events>();

analyticsEventsEmitter.on(EventsName.VIEW_CART, () => {
  sendFbEvent(EventsName.VIEW_CART, {
    currency: orderStore.currency.toUpperCase(),
    value: mainStore.toFloat(catalogStore.finalPrice),
    items: catalogStore.cartForFirebase,
  });
  sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_VIEW_CART, {
    cart_id: undefined,
    in_opening_hours: false,
    products_amount: catalogStore.cart.reduce(
      (sum, item) => sum + item.count,
      0,
    ),
    items_amount: catalogStore.cart.length,
    price: catalogStore.totalCartPrice.base,
    final_price: catalogStore.finalPrice,
    eta_min: orderStore.etaCalculation?.duration.min || 0,
    eta_max: orderStore.etaCalculation?.duration.max || 0,
    delivery_fee: orderStore.fee.shippingPounds || 0,
    threshold: orderStore.fee.thresholdPounds || 0,
    is_surger: orderStore.etaCalculation?.highDemand || false,
  });
});

analyticsEventsEmitter.on(EventsName.VIEW_ITEM, (e) => {
  sendFbEvent(EventsName.VIEW_ITEM, e);
});

analyticsEventsEmitter.on(EventsName.VIEW_ITEM_LIST, (e) => {
  sendFbEvent(EventsName.VIEW_ITEM_LIST, e);
});

analyticsEventsEmitter.on(EventsName.ONBOARDING_SUBSCRIBE_DIALOG_SHOWN, () => {
  const eventData = {
    type: 'no_store',
    postcode: userStore.deliveryAddress?.zip || '',
    lat: userStore.deliveryAddress?.coordinates.lat || '',
    lon: userStore.deliveryAddress?.coordinates.lng || '',
  };
  sendFbEvent(EventsName.ONBOARDING_SUBSCRIBE_DIALOG_SHOWN, eventData);
  sendAnalyticsEvent(
    EventsName.COMMON_ANALYTICS_ONBOARDING_SUBSCRIBE_DIALOG_SHOWN,
    eventData,
  );
});

analyticsEventsEmitter.on(EventsName.ONBOARDING_COMPLETE, () => {
  const eventData = {
    eta: deliveryAddressStore.etaCalculation?.duration.min || 0,
    type: 'passed',
  };
  sendFbEvent(EventsName.ONBOARDING_COMPLETE, eventData);
  sendAnalyticsEvent(
    EventsName.COMMON_ANALYTICS_ONBOARDING_COMPLETE,
    eventData,
  );
});

analyticsEventsEmitter.on(
  EventsName.ONBOARDING_SUBSCRIBE_DIALOG_CLICKED,
  (e) => {
    const eventData = {
      answer: e.answer,
      type: 'no_store',
      postcode: userStore.deliveryAddress?.zip || '',
      lat: userStore.deliveryAddress?.coordinates.lat || '',
      lon: userStore.deliveryAddress?.coordinates.lng || '',
    };
    sendFbEvent(EventsName.ONBOARDING_SUBSCRIBE_DIALOG_CLICKED, eventData);

    sendAnalyticsEvent(
      EventsName.COMMON_ANALYTICS_ONBOARDING_SUBSCRIBE_DIALOG_CLICKED,
      eventData,
    );
  },
);

analyticsEventsEmitter.on(EventsName.SELECT_ITEM, (offer) => {
  sendFbEvent(EventsName.SELECT_ITEM, {
    currency: orderStore.currency.toUpperCase(),
    items: [
      {
        item_id: offer.sku,
        item_name: offer.name,
        quantity: 1,
        promotion_id: '',
        promotion_name: '',
        affiliation: '',
        coupon: catalogStore.promocode.value,
        creative_name: '',
        creative_slot: '',
        discount: offer.discountPrice
          ? mainStore.toFloat(
              mainStore.convertPenceToPounds(offer.price - offer.discountPrice),
            )
          : 0,
        index: 0,
        item_brand: '',
        item_category: offer.categoryName,
        item_list_name: offer.categoryName,
        item_list_id: offer.categoryId,
        item_variant: '',
        location_id: '',
        tax: 0,
        price: mainStore.toFloat(offer.priceFormatted),
        currency: orderStore.currency.toUpperCase(),
      },
    ],
    value: mainStore.toFloat(offer.priceFormatted),
  });
});

analyticsEventsEmitter.on(EventsName.LOGIN_SCREEN_SHOWN, () => {
  sendFbEvent(EventsName.LOGIN_SCREEN_SHOWN, {});
});

analyticsEventsEmitter.on(EventsName.CATALOG_SEARCH_VISITED, (source) => {
  sendFbEvent(EventsName.CATALOG_SEARCH_VISITED, {
    source,
  });
});

analyticsEventsEmitter.on(EventsName.CATALOG_SEARCH_CLICKED, (source) => {
  sendFbEvent(EventsName.CATALOG_SEARCH_CLICKED, { source });
});

analyticsEventsEmitter.on(
  EventsName.CATALOG_CATEGORY_VISITED,
  ({ id, source }) => {
    sendFbEvent(EventsName.CATALOG_CATEGORY_VISITED, { category: id, source });
  },
);

analyticsEventsEmitter.on(
  EventsName.CATALOG_CATEGORY_CLICKED,
  ({ id, source }) => {
    sendFbEvent(EventsName.CATALOG_CATEGORY_CLICKED, { category: id, source });
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_CLICKED,
  ({ id, source }) => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_CLICKED, {
      categoryId: id,
      source,
    });
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_VISITED,
  ({ id, source }) => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_CATALOG_CATEGORY_VISITED, {
      categoryId: id,
      source,
    });
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_CLICKED,
  (product) => {
    sendAnalyticsEvent<Product | CartItem>(
      EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_CLICKED,
      product,
    );
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_VISITED,
  (product) => {
    sendAnalyticsEvent<Product | CartItem>(
      EventsName.COMMON_ANALYTICS_CATALOG_PRODUCT_VISITED,
      product,
    );
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_CATALOG_SEARCH_CLICKED,
  (source) => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_CATALOG_SEARCH_CLICKED, {
      source,
    });
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_CATALOG_SEARCH_VISITED,
  (source) => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_CATALOG_SEARCH_VISITED, {
      source,
    });
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_LOGIN_SCREEN_SHOWN,
  () => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_LOGIN_SCREEN_SHOWN, {});
  },
);

analyticsEventsEmitter.on(EventsName.COMMON_ANALYTICS_CART_CHANGED, () => {
  sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_CART_CHANGED, {
    cart_id: undefined,
    type: 'stock',
    products_amount: catalogStore.cart.reduce(
      (sum, item) => sum + item.count,
      0,
    ),
    items_amount: catalogStore.cart.length,
    price: catalogStore.totalCartPrice.base,
    final_price: catalogStore.finalPrice,
    eta_min: orderStore.etaCalculation?.duration.min || 0,
    eta_max: orderStore.etaCalculation?.duration.max || 0,
    delivery_fee: orderStore.fee.shippingPounds || 0,
    threshold: orderStore.fee.thresholdPounds || 0,
    is_surger: orderStore.etaCalculation?.highDemand || false,
    stage: 'checkout',
  });
});

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_OPEN,
  () => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_OPEN, {});
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_SHARE,
  () => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_REFERRAL_PROGRAM_SHARE, {});
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_RATE_PRODUCT_DETAILED,
  (data) => {
    sendAnalyticsEvent(EventsName.COMMON_ANALYTICS_RATE_PRODUCT_DETAILED, data);
  },
);

analyticsEventsEmitter.on(
  EventsName.COMMON_ANALYTICS_KYC_VERIFICATION_STATUS,
  (data) => {
    sendAnalyticsEvent(
      EventsName.COMMON_ANALYTICS_KYC_VERIFICATION_STATUS,
      data,
    );
  },
);

const sendAnalyticsEvent = <T>(
  eventName: keyof Events,
  params: T | Record<string, unknown>,
) => {
  mainStore.sendToRN('analytics', {
    name: eventName,
    params,
  });
  if (!mainStore.isRN) {
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: eventName,
      params: params as GetRequestObj,
    });
  }
};

const sendFbEvent = (
  eventName: keyof Events,
  params: Record<string, unknown>,
) => {
  mainStore.sendToRN('firebaseAnalytics', {
    name: eventName,
    params,
  });
};

export default analyticsEventsEmitter;
