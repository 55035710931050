import { classes } from 'html-classes';

import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import { Category } from '~/stores/CategoriesStore/Category';

import styles from './BannerLinkBlock.module.scss';

interface BannerLinkBlockProps {
  category: Category;
}

const BannerLinkBlock = ({ category }: BannerLinkBlockProps) => {
  return (
    <div className={classes([styles.container])}>
      <CloudflareResponseImage
        src={encodeURI(category.previewImage || '')}
        srcParams={{ width: 180, height: 180 }}
        srcSetParams={defaultSrcSetParams}
        alt=""
        className={styles.img}
        loading="lazy"
      />
      <div className={styles.description}>
        <div className={styles.title}>{category.name}</div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: category.previewText,
          }}
        />
      </div>
    </div>
  );
};

export default BannerLinkBlock;
