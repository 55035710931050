import { ProductAPI } from './Catalog';
import { ENDPOINT } from './constants';
import { RequestAPI } from './Requests';
import { getLangHeaders } from './utils';

export interface FavoritesResponse {
  data: ProductAPI[];
  pagination: {
    total: number;
    page: number;
    size: number;
  };
}

interface PurchasedResponse {
  data: ProductAPI[];
  pagination: {
    total: number;
    page: number;
    size: number;
  };
}

interface FavoritesRequestsInterface {
  getFavorites(data: {
    warehouseCode: string;
    isSafeRequest?: boolean;
  }): Promise<FavoritesResponse>;

  addManyFavorites(data: { ids: number[] }): Promise<void>;

  removeFavorite(id: number): Promise<void>;

  getPurchasedItems(
    warehouseCode: string,
    pageNumber: number,
    limit: number,
  ): Promise<PurchasedResponse>;
}

export const FavoritesRequest: FavoritesRequestsInterface = {
  getFavorites: (data) =>
    RequestAPI.get(ENDPOINT.favorites.get, data, getLangHeaders()),

  addManyFavorites: (data) =>
    RequestAPI.post(ENDPOINT.favorites.addMany, data, getLangHeaders()),

  removeFavorite: (id) => RequestAPI.delete(ENDPOINT.favorites.remove + id),

  getPurchasedItems: (warehouseCode, pageNumber, limit) =>
    RequestAPI.get(
      ENDPOINT.orders.boughtItems +
        `?warehouse_code=${warehouseCode}&page=${pageNumber}&limit=${limit}`,
      {},
      getLangHeaders(),
    ),
};
