import { JSX, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../../stores/CatalogStore';

import MobileHeaderLayout from '../../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../../Main/MobileHeader/MobileHeaderTitle';

import styles from '../Legals.module.scss';

import mdStyles from './LegalsKids.module.scss';

import '../styles.scss';

type Props = {
  handleLink: (anchor: string) => void;
};

const LEGALS_NAV = [
  {
    link: 'terms-and-conditions',
    title: 'Privacy Policy',
  },
];

const text = `
<h2 id="terms-and-conditions">Privacy Policy</h2>

This privacy policy was last updated: 01.12.2024

Ourkids Company ("we", "our", "us" or "Ourkids") is committed to protecting the privacy of all users of our website
shop-ourkids.com, or mobile applications (together, the "Sites"). Please read the following privacy policy that
explains how we use and protect your information. We are the "data controller" of the information we process,
unless otherwise stated.

1. ### Contact Details

    If you have any queries or requests concerning this privacy policy or how we handle your data more generally, please get in touch by contacting our general customer services team at: support@shop-ourkids.com

2. ### How We Collect Your Information

    We collect your personal information when you interact with us or use our services, such as when you use our Sites to place an order. We also look at how visitors use our Sites, to help us improve our services and optimize customer experience.

    We collect information:
    1. when you create an account with us or you change your account settings;
    2. when you place an order with us and during the order process (including for payment and order delivery);
    3. when you give us your consent to contact you via email, phone, post, message or via our chat function to send you marketing campaigns, or to invite you to participate in surveys about our services, or our partners' services;
    4. when you contact us directly via email, phone, post, message or via our chat function; and
    5. when you browse and use our Sites (before and after you create an account with us).

    We also collect information from third party sites, such as advertising platforms and our fraud detection provider.

3. ### Information We Collect From You

    As part of our commitment to the privacy of our customers and visitors to our Sites more generally, we want to be clear about the sorts of information we will collect from you.
    
    When you visit the Sites or make an Ourkids order through the Sites, you are asked to provide information about yourself including your name, contact details, delivery address, order details and payment information such as credit or debit card information. We may also collect your date of birth to verify your age when you purchase age restricted items.
    
    We also collect information about your usage of the Sites and information about you from any messages you post to the Sites or when you contact us or provide us with feedback, including via e-mail, letter, phone or chat function. If you contact us by phone, we record the call for training and service improvement purposes and make notes in relation to your call.
    
    We collect technical information from your mobile device or computer, such as its operating system, the device and connection type and the IP address from which you are accessing our Sites.
    
    We also collect technical information about your use of our services through a mobile device, for example, carrier, location data and performance data such as mobile payment methods, interaction with other retail technology such as use of NFC Tags, QR Codes and/or use of mobile vouchers. Unless you have elected to remain anonymous through your device and/or platform settings, this information may be collected and used by us automatically if you use the service through your mobile device(s) via any Ourkids mobile application, through your mobile's browser or otherwise.
    
    We process health information about you only where you volunteer and consent to this, for example if you specify any food allergies.

4. ### Use Of Your Information

    We will only process the data we collect about you if there is a reason for doing so, and if that reason is permitted under data protection law.

    Where we need to in order to provide you with the service you have requested or to enter into a contract, we use your information:
   - to enable us to provide you with access to the relevant parts of the Sites;
   - to supply the services you have requested;
   - to enable us to collect payment from you; and
   - to contact you where necessary concerning our services, such as to resolve issues you may have with your order or gather feedback about your order.

    We also process your data where we have a legitimate interest for doing so— for example personalization of our service, including processing data to make it easier and faster for you to place orders. We have listed these reasons below:
   - to improve the effectiveness and quality of service that our customers can expect from us in the future;
   - to tailor content that we or advertising partners display to you, for example so that we can show you products which are in your area or make sure you see the advertising, which is most relevant to you, based on characteristics determined by us;
   - to enable our customer support team to help you with any inquiries or complaints in the most efficient way possible and to provide a positive customer experience;
   - to contact you for your views and feedback on our services or our partners' services and/or products and to notify you if there are any important changes or developments to the Sites or our services, including letting you know that our services are operating in a new area, where you have asked us to do so;
   - to send you information by post about our products, services and promotions (if you do not want to receive these, you can let us know by getting in touch (see Contact Details));
   - to analyze your activity on the Sites so that we can administer, support, improve and develop our business and for statistical and analytical purposes and to help us to prevent fraud;

    We also process your data to enforce our contractual terms with you and any other agreement, and for the exercise or defense of legal claims and to protect the rights of Ourkids, riders, or others (including to prevent fraud);

    If you submit comments and feedback regarding the Sites and the services, we may use such comments and feedback on the Sites and in any marketing or advertising materials. We will only identify you for this purpose by your first name and the city in which you live. Where you have chosen to receive push notifications from us through our mobile application, we may send you push notifications relating to the services that you have requested from us and information about our services and offers. You can choose to stop receiving push notifications from us at any time by changing your preferences on your mobile device or by getting in touch (see Contact Details).

    We will also analyze data about your use of our services from your location data to create profiles relating to you and for you. This means that we may make certain assumptions about what you may be interested in and use this, for example, to send you more tailored marketing communications, to present you with restaurants that we think you will prefer, or to let you know about special offers or products which we think you may be interested in. This activity is referred to as profiling. You have certain rights in relation to this type of processing. Please see 'Your Rights' section below for more information.

    Where we rely on legitimate interest as a basis for processing your personal information, we carry out a 'balancing test' to ensure that our processing is necessary and that your fundamental rights of privacy are not outweighed by our legitimate interests. You can find out more information about these balancing tests by contacting us using the details above.

    Where we are under a legal obligation to do so we may use your information to create a record of your order(s) and comply with any legal obligation or regulatory requirement to which we are subject.

5. ### Cookies

    You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the Site may become inaccessible or not function properly. For more information about the cookies we use, please see our Cookie Policy https://shop- ourkids.com/cookies.

6. ### Direct Marketing

    Where you have given your consent or where we have a legitimate interest for doing so (and are permitted to do so by law) we will use your information to let you know about our other products and services that may be of interest to you and we may contact you to do so by email, post or phone. You can control your marketing preferences by:
   - visiting our mobile application;
   - clicking on "Menu" at the main page of the app
   - scrolling down to "Notification settings"

7. ### Automated Decision Making

    We conduct fraud checks on all customers. Where we believe we may detect fraudulent activity we may block you from placing an order and using our Sites.

    We undertake fraud checks on all customers because this is necessary for us to perform our contracted services to customers, by ensuring that the services we provide are duly paid for, and also so that individuals themselves are protected from fraudulent transactions on their cards.

    Given the volumes of customers and orders we deal with, along with manual checks, we may use automated systems including a third party fraud detection providers in order to make automated decisions as to whether or not we will accept an order.

    The checks and decisions that are made look at various components including known industry indicators of fraud which our expert fraud detection provider makes available to us, as well as fraud patterns we have detected on our Sites.

    You have certain rights in respect of this activity - please see 'Your Rights' section below for more information. Our fraud detection is in place to protect all of our customers as well as Ourkids. You have the right to contest any fraud decision made about you and to be given more information about why any such decision was made by contacting us as set out in section 1 above.

8. ### Retention Of Your Information

    We will not retain your information for any longer than we think is necessary.

    Information that we collect will be retained for as long as needed to fulfil the purposes outlined in the 'Use of your information' section above, in line with our legitimate interest or for a period specially required by applicable regulations or laws, such as retaining the information for regulatory reporting purposes.

    When determining the relevant retention periods, we will take into account factors including:
   - our contractual obligations and rights in relation to the information involved;
   - legal obligation(s) under applicable law to retain data for a certain period of time;
   - statute of limitations under applicable law(s);
   - our legitimate interests where we have carried out balancing tests (see section on 'How we use your personal information' above);
   - (potential) disputes; and
   - guidelines issued by relevant data protection authorities.

    Otherwise, we securely erase your information where we no longer require your information for the purposes collected.

9. ### Disclosure Of Your Information

    The information we collect about you will be transferred to and stored on our servers located within the UK. We are very careful and transparent about who else your information is shared with.

    We share your information with third party service providers which provide services on our behalf. The types of third party service providers whom we share your information with include for example:
   - Payment providers (including online payment providers and fraud detection providers) for the purposes of providing services to us, for example when they process information such as credit card payments for us, provide support services to you or carry out fraud checks for us;
   - IT service providers (including cloud providers) for the purposes of data storage and analysis;
   - Riders so they can deliver your order to you;
   - Customer support partners who will help us to resolve any issues you may have with our services; and
   - Marketing and advertising partners so that they can ensure that you see advertising which is more relevant to you and send you email and postal marketing on our behalf;
   - Other partners with your consent to the disclosure

    Ourkids will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy when it is transferred to third parties.

    If our business enters into a joint venture with, purchases or is sold to or merged with another business entity, your information may be disclosed or transferred to the target company, our new business partners or owners or their advisors.

    We may also share your information:
   - if we are under a duty to disclose or share your information in order to comply with (and/or where we believe we are under a duty to comply with) any legal obligation or regulatory requirement. This includes exchanging information with other companies and other organizations for the purposes of fraud protection and prevention;
   - in order to enforce our contractual terms with you and any other agreement;
   - to protect the rights of Ourkids, riders, or others, including to prevent fraud; and
   - with such third parties as we reasonably consider necessary in order to prevent crime, e.g. the police or for health and safety purposes.

10. ### International transfers of data

    Your personal information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different to the laws of your country.

    Specifically, our servers are located in UK and third party service providers and partners operate around the world. This means that when we collect your personal information, we may process it in any of these countries.

    However, we have taken appropriate safeguards to require that your personal information will remain protected in accordance with this Privacy Notice.

11. ### Security

    We adopt robust technologies and policies to ensure the personal information we hold about you is suitably protected.

    We take steps to protect your information from unauthorized access and against unlawful processing, accidental loss, destruction and damage.

    Where you have chosen a password that allows you to access certain parts of the Sites, you are responsible for keeping this password confidential. We advise you not to share your password with anyone.

    Unfortunately, the transmission of information via the internet is not completely secure. Although we will take steps to protect your information, we cannot guarantee the security of your data transmitted to the Sites; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.

12. ### Your Rights

    Subject to applicable law, you may have a number of rights concerning the data we hold about you. If you wish to exercise any of these rights, please contact us using the contact details set out above. For additional information on your rights please contact your data protection authority and see below.

    To the extent provided by the law of your jurisdiction, you may request access to the personal information we maintain about you or request that we correct, update, amend, or delete your information, or that we restrict the processing of such information by contacting us as indicated below.

    You may have the right to obtain your personal information in an accessible and transferable format so that you can re-use it for your own purposes across different service providers.

    Where provided by law, you may withdraw any consent you previously provided to us or object at any time on legitimate grounds to the processing of your personal information, and we will apply your preferences going forward. This will not affect the lawfulness of our use of your information based on your consent before its withdrawal.

    You can object by changing your marketing preferences, disabling cookies as set out in sections 7 and 8 above or by getting in touch (see Contact Details).

13. ### Changes To Our Privacy Policy

    Any changes to our privacy policy will be posted to the Sites and, where appropriate, we may notify you of the changes for example by email or push notification.

14. ### Complaints

    If you're not satisfied with our response to any complaint or believe our processing of your information does not comply with data protection law, you can make a complaint to the Information Commissioner's Office (ICO).
`;

const LegalsKids = ({ handleLink }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();

  useEffect(() => {
    setTimeout(() => {
      const anchor = window.location.hash.slice(1);
      handleLink(anchor);
    }, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      <MobileHeaderLayout content={<MobileHeaderTitle text={t('legals')} />} />
      <div className="content-layout legals">
        <div className={styles.root}>
          {!isTablet && (
            <>
              <Breadcrumbs
                items={
                  [
                    {
                      text: t('home'),
                      link: '/',
                    },
                    {
                      text: t('legals'),
                      link: '/legals',
                    },
                  ].filter(Boolean) as BreadcrumbsItem[]
                }
              />

              <h2 className={styles.title}>{t('legals')}</h2>
            </>
          )}

          <div className={styles.page}>
            <div className={styles.page__links_container}>
              <ul className={styles.page__links}>
                {LEGALS_NAV.map((i) => (
                  <li className={styles.page__links_item} key={i.link}>
                    <a
                      href={`#${i.link}`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleLink(i.link);
                      }}
                    >
                      {i.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.page__content}>
              <ReactMarkdown
                className={mdStyles.mdStyles}
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              >
                {text}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalsKids;
