import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { deliveryAddressStore, mapCenter } from '../DeliveryAddressStore';

import { MAP_DEBOUNCE_TIME } from './constants';

const Geocoding = () => {
  useEffect(() => {
    if (deliveryAddressStore.isMapBusy) {
      return;
    }

    if (
      deliveryAddressStore.deliveryAddress &&
      deliveryAddressStore.deliveryAddress.coordinates.lat.toFixed(7) ===
        deliveryAddressStore.mapCenter.lat.toFixed(7) &&
      deliveryAddressStore.deliveryAddress.coordinates.lng.toFixed(7) ===
        deliveryAddressStore.mapCenter.lng.toFixed(7)
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      deliveryAddressStore.setIsLoading(true);
      deliveryAddressStore
        .geocoding(
          deliveryAddressStore.mapCenter.lng === mapCenter.lng &&
            deliveryAddressStore.mapCenter.lat === mapCenter.lat,
          deliveryAddressStore.mapCenter,
        )
        .catch((error) => error && console.error(error))
        .finally(() => deliveryAddressStore.setIsLoading(false));
    }, MAP_DEBOUNCE_TIME);

    return () => clearTimeout(timeout);
  }, [deliveryAddressStore.isMapBusy, deliveryAddressStore.mapCenter]);

  return <></>;
};

export default observer(Geocoding);
