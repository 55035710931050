import { observer } from 'mobx-react-lite';

import { company } from '~/company/Company';
import { Category } from '~/stores/CategoriesStore';

import CategoryElement from './CategoryElement';
import styles from './ListOfCategoriesOneBlock.module.scss';

interface ListOfCategoriesOneBlockProps {
  category: Category;
}

const ListOfCategoriesOneBlock = ({
  category,
}: ListOfCategoriesOneBlockProps) => {
  return (
    <div className={styles.container} data-company={company.name}>
      <div className={styles.content}>
        {category.subcategory.map((category) => (
          <CategoryElement
            category={category}
            key={category.id}
            nonCloseable={company.nonCloseableNavMobileShopPage}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(ListOfCategoriesOneBlock);
