import { company } from '~/company/Company';

export const API_URL: string = import.meta.env.SSR
  ? process.env.REACT_APP_API_ROOT
  : company.envVariables.REACT_APP_API_ROOT ||
    window.location.origin.replace('frontend-cd-', 'api2-') + '/';

export const REQUEST_TIMEOUT = 20 * 1_000;

export const ENDPOINT = {
  customer: {
    code: {
      request: 'customer/sms/send',
      verify: 'customer/sms/verify',
    },
    logout: 'customer/auth/logout',
    personalData: {
      request: 'customer/',
      update: 'customer/',
      delete: 'customer/',
    },
    deviceData: {
      create: 'customer/auth/device/create',
      update: 'customer/auth/device/update/',
    },
    subscription: {
      update: 'customer/auth/subscription/update/',
    },
    token: {
      refresh: 'customer/auth/token-refresh',
    },
    address: {
      request: 'customer/address',
      add: 'customer/address',
      update: 'customer/address/',
      delete: 'customer/address/',
    },
    kyc: {
      status: 'customer/v1/kyc/session',
    },
    reviews: {
      requestAll: 'customer/v1/reviews',
      newReview: 'customer/v1/reviews',
    },
  },
  bonuses: {
    balance: 'payment/v1/bonuses/balance',
    history: 'payment/v1/bonuses/history',
  },

  settings: {
    getBackorderLogic: '/orders/v1/settings/backorder_logic',
  },
  orders: {
    calculate: 'orders/v2/cart/calculate',
    new: 'orders/v2/orders/new',
    boughtItems: 'orders/v1/orders/bought-items',
    paid: 'orders/v1/orders/paid/',
    cancelByUser: 'orders/v1/orders/cancel-by-user',
    status: 'orders/v1/orders',
    getCurrency: 'orders/v1/settings/currency',
    paymentMethods: {
      list: 'payment/v1/payments/methods',
      add: 'payment/v1/payments/methods/add',
      remove: 'payment/v1/payments/methods/remove',
      setupIntent: 'payment/v1/payments/methods/setup-intent',
      setDefault: 'payment/v1/payments/methods/set-default',
      status3ds: 'payment/v1/payments/order-id/',
    },
    feedback: {
      create: 'orders/v1/feedback/create',
      fill: 'orders/v1/feedback/fill/',
    },
  },
  promocodes: {
    get: 'promocodes-service/v1/promocodes/code/',
    getAffiliateCode: 'promocodes-service/v1/promocodes/affiliate/code/',
    getRefereeCode:
      'promocodes-service/v1/promocodes/referral_reward/referee_code/',
    getCustomerRewards:
      'promocodes-service/v1/promocodes/referral_reward/rewards/',
    getReferralInfo:
      '/promocodes-service/v1/promocodes/referral_reward/referee_return_info',
    getRewardsNotifications:
      '/promocodes-service/v1/promocodes/referral_reward/rewards/notify/',
    getRewardStatus:
      '/promocodes-service/v1/promocodes/referral_reward/rewards/status/',
  },
  gifts: {
    getMechanisms: 'orders/v1/gift/gift-mechanisms?warehouse_code=',
  },
  tips: {
    pay: 'delivery/tips/',
    check: 'delivery/tips?order_id=',
  },
  payments: {
    prioritySystem: 'payment/v1/payments/priority-system',
    myfatoorahInitialize: 'payment/v1/payments/myfatoorah/initialize',
  },
  catalog: {
    category: {
      list: 'catalog/v1/client/categories/tree',
    },
    product: {
      listByCategory: 'catalog/v2/tree/categories/',
      listByCategoryPages: '/catalog/v1/client/categories/',
      listByBanner: 'catalog/v1/banner/products',
      request: 'catalog/v1/client/products/',
      bestsellers: 'catalog/v1/bestsellers',
      recommendations: 'catalog/v1/bi/recommendations?warehouse_code=',
      offers: 'catalog/v1/client/offers',
    },
    banner: {
      list: 'catalog/v1/banner',
    },
    search: {
      request: 'catalog/v2/search',
      history: {
        list: 'catalog/v1/search-history',
        delete: 'catalog/v1/search-history/delete',
      },
    },
    filters: {
      list: 'catalog/v1/filters',
    },
    recommendations: {
      list: '/catalog/v2/recommendations',
    },
  },
  deliveryAddress: {
    autocomplete: 'geocoder/v1/api/autocomplete',
    zones: {
      request: 'eta/v1/api/zones',
    },
  },
  stories: {
    list: 'stories/v1/stories/byWarehouseCode/',
  },
  eta: {
    get: 'eta/v1/api/eta',
    getSlot: 'eta/v1/api/eta-slot',
    getDeliveryCost: 'orders/v1/surge/delivery-cost',
  },
  favorites: {
    get: 'catalog/v1/favourites',
    addMany: 'catalog/v1/favourites',
    add: 'catalog/v1/favourites/',
    remove: 'catalog/v1/favourites/',
  },
  geocoder: {
    reverseGeocode: 'geocoder/v1/api/reverse-geocode',
  },
  message: {
    send: 'promocodes-service/v1/promocodes/general/contact_to_us',
  },
};
