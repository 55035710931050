import { makeObservable, observable, action } from 'mobx';

import { BaseStore } from '~/stores/interfaces/BaseStore';

export class MetaStore implements BaseStore {
  isLoading: boolean = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      setLoading: action.bound,
    });
  }

  setLoading(value: boolean): void {
    this.isLoading = value;
  }

  destroy() {
    this.setLoading(false);
  }
}
