import { observer } from 'mobx-react-lite';

import Icon from '~/components/Icon/Icon';
import { ICON_TYPES } from '~/components/Icon/IconTypes';

const Icons = () => {
  return (
    <div className="icons-grid">
      {ICON_TYPES.map((iconType) => (
        <div className="icons-grid__item" title={iconType} key={iconType}>
          <Icon type={iconType} size={64} />
          <div className="icons-grid__title">{iconType}</div>
        </div>
      ))}
    </div>
  );
};

export default observer(Icons);
