import isNumber from 'lodash-es/isNumber.js';
import { CSSProperties, RefObject, useEffect } from 'react';

export interface UseStickyOptions {
  innerRef: RefObject<HTMLElement>;
  outerRef: RefObject<HTMLElement>;
  topSpacing?: number;
  bottomSpacing?: number;
  disable?: boolean;
}

interface Styles {
  inner: CSSProperties;
  outer: CSSProperties;
}

export const useSticky = ({
  outerRef,
  innerRef,
  topSpacing = 0,
  bottomSpacing = 0,
  disable,
}: UseStickyOptions) => {
  useEffect(() => {
    const outer = outerRef.current;
    const inner = innerRef.current;
    const container = outer?.parentElement;
    const eventOptions = { passive: true, capture: false };

    if (disable || !inner || !outer || !container) {
      return;
    }

    let affixedType = 'STATIC';
    let direction = 'down';
    let isInitialized = false;
    let needForceUpdate = false;
    let inProgress = false;
    const sizes = {
      translateY: 0,
      maxTranslateY: 0,
      topSpacing: 0,
      lastTopSpacing: 0,
      bottomSpacing: 0,
      lastBottomSpacing: 0,
      targetHeight: 0,
      targetWidth: 0,
      targetLeft: 0,
      containerTop: 0,
      containerHeight: 0,
      containerBottom: 0,
      viewportHeight: 0,
      viewportTop: 0,
      viewportLeft: 0,
      viewportBottom: 0,
      lastViewportTop: 0,
    };

    const getRelativeOffset = (element: HTMLElement | null) => {
      let top = 0;
      let left = 0;

      while (element) {
        const offsetTop = element.offsetTop;
        const offsetLeft = element.offsetLeft;
        const nextElement =
          'BODY' === element.tagName
            ? element.parentElement
            : element.offsetParent;

        if (!isNaN(offsetTop)) {
          top += offsetTop;
        }

        if (!isNaN(offsetLeft)) {
          left += offsetLeft;
        }

        element = nextElement as HTMLElement;
      }

      return { top, left };
    };

    const calcSizes = () => {
      sizes.containerTop = getRelativeOffset(container).top;
      sizes.containerHeight = container.clientHeight;
      sizes.containerBottom = sizes.containerTop + sizes.containerHeight;
      sizes.targetHeight = inner.offsetHeight;
      sizes.targetWidth = inner.offsetWidth;
      sizes.viewportHeight = window.innerHeight;
      sizes.maxTranslateY = sizes.containerHeight - sizes.targetHeight;

      calcSizesWithScroll();
    };

    const calcSizesWithScroll = () => {
      sizes.targetLeft = getRelativeOffset(outer).left;

      sizes.viewportTop = document.documentElement.scrollTop;
      sizes.viewportBottom = sizes.viewportTop + sizes.viewportHeight;
      sizes.viewportLeft = document.documentElement.scrollLeft;

      sizes.topSpacing = topSpacing;
      sizes.bottomSpacing = bottomSpacing;

      if (affixedType === 'VIEWPORT-TOP') {
        if (sizes.topSpacing < sizes.lastTopSpacing) {
          sizes.translateY += sizes.lastTopSpacing - sizes.topSpacing;
          needForceUpdate = true;
        }
      } else if (affixedType === 'VIEWPORT-BOTTOM') {
        if (sizes.bottomSpacing < sizes.lastBottomSpacing) {
          sizes.translateY += sizes.lastBottomSpacing - sizes.bottomSpacing;
          needForceUpdate = true;
        }
      }

      sizes.lastTopSpacing = sizes.topSpacing;
      sizes.lastBottomSpacing = sizes.bottomSpacing;
    };

    const isFitsViewport = () => {
      const offset = sizes.lastTopSpacing;
      return sizes.targetHeight + offset < sizes.viewportHeight;
    };

    const observeScrollDir = () => {
      if (sizes.lastViewportTop === sizes.viewportTop) {
        return;
      }

      const furthest = 'down' === direction ? Math.min : Math.max;

      if (
        sizes.viewportTop === furthest(sizes.viewportTop, sizes.lastViewportTop)
      ) {
        direction = 'down' === direction ? 'up' : 'down';
      }
    };

    const getAffixType = () => {
      calcSizesWithScroll();

      const colliderTop = sizes.viewportTop + sizes.topSpacing;
      let affixType;

      if (
        colliderTop <= sizes.containerTop ||
        sizes.containerHeight <= sizes.targetHeight
      ) {
        sizes.translateY = 0;
        affixType = 'STATIC';
      } else {
        affixType =
          'up' === direction
            ? getAffixTypeScrollingUp()
            : getAffixTypeScrollingDown();
      }

      const y = Math.max(0, sizes.translateY);

      sizes.translateY = Math.round(Math.min(sizes.containerHeight, y));
      sizes.lastViewportTop = sizes.viewportTop;

      return affixType;
    };

    const getAffixTypeScrollingDown = () => {
      const targetBottom = sizes.targetHeight + sizes.containerTop;
      const colliderTop = sizes.viewportTop + sizes.topSpacing;
      const colliderBottom = sizes.viewportBottom - sizes.bottomSpacing;
      let affixType = affixedType;

      if (isFitsViewport()) {
        if (sizes.targetHeight + colliderTop >= sizes.containerBottom) {
          sizes.translateY = sizes.containerBottom - targetBottom;
          affixType = 'CONTAINER-BOTTOM';
        } else if (colliderTop >= sizes.containerTop) {
          sizes.translateY = colliderTop - sizes.containerTop;
          affixType = 'VIEWPORT-TOP';
        }
      } else {
        if (sizes.containerBottom <= colliderBottom) {
          sizes.translateY = sizes.containerBottom - targetBottom;
          affixType = 'CONTAINER-BOTTOM';
        } else if (targetBottom + sizes.translateY <= colliderBottom) {
          sizes.translateY = colliderBottom - targetBottom;
          affixType = 'VIEWPORT-BOTTOM';
        } else if (
          sizes.containerTop + sizes.translateY <= colliderTop &&
          0 !== sizes.translateY &&
          sizes.maxTranslateY !== sizes.translateY
        ) {
          affixType = 'VIEWPORT-UNBOTTOM';
        }
      }

      return affixType;
    };

    const getAffixTypeScrollingUp = () => {
      const targetBottom = sizes.targetHeight + sizes.containerTop;
      const colliderTop = sizes.viewportTop + sizes.topSpacing;
      const colliderBottom = sizes.viewportBottom - sizes.bottomSpacing;
      let affixType = affixedType;

      if (colliderTop <= sizes.translateY + sizes.containerTop) {
        sizes.translateY = colliderTop - sizes.containerTop;
        affixType = 'VIEWPORT-TOP';
      } else if (sizes.containerBottom <= colliderBottom) {
        sizes.translateY = sizes.containerBottom - targetBottom;
        affixType = 'CONTAINER-BOTTOM';
      } else if (!isFitsViewport()) {
        if (
          sizes.containerTop <= colliderTop &&
          0 !== sizes.translateY &&
          sizes.maxTranslateY !== sizes.translateY
        ) {
          affixType = 'VIEWPORT-UNBOTTOM';
        }
      }

      return affixType;
    };

    const getStyle = (affixType: string): Styles => {
      const style = { inner: {}, outer: {} };

      switch (affixType) {
        case 'VIEWPORT-TOP':
          style.inner = {
            position: 'fixed',
            top: sizes.topSpacing,
            left: sizes.targetLeft - sizes.viewportLeft,
            width: sizes.targetWidth,
          };
          break;
        case 'VIEWPORT-BOTTOM':
          style.inner = {
            position: 'fixed',
            top: 'auto',
            left: sizes.targetLeft,
            bottom: sizes.bottomSpacing,
            width: sizes.targetWidth,
          };
          break;
        case 'CONTAINER-BOTTOM':
        case 'VIEWPORT-UNBOTTOM':
          style.inner = { transform: `translate(0, ${sizes.translateY}px)` };
          break;
      }

      switch (affixType) {
        case 'VIEWPORT-TOP':
        case 'VIEWPORT-BOTTOM':
        case 'VIEWPORT-UNBOTTOM':
        case 'CONTAINER-BOTTOM':
          style.outer = { height: sizes.targetHeight, position: 'relative' };
          break;
      }

      style.outer = Object.assign({ height: '', position: '' }, style.outer);
      style.inner = Object.assign(
        {
          position: 'relative',
          top: '',
          left: '',
          bottom: '',
          width: '',
          transform: '',
        },
        style.inner,
      );

      return style;
    };

    const stickyPosition = (force: boolean) => {
      const affixType = getAffixType();
      const style = getStyle(affixType);

      if (affixedType !== affixType || force) {
        Object.keys(style.outer).forEach((key) => {
          const value = style.outer[key as keyof CSSProperties] as never;
          outer.style[key as any] = isNumber(value) ? `${value}px` : value;
        });

        Object.keys(style.inner).forEach((key) => {
          const value = style.inner[key as keyof CSSProperties] as never;
          inner.style[key as any] = isNumber(value) ? `${value}px` : value;
        });
      } else if (isInitialized) {
        inner.style.left = style.inner.left as string;
      }

      affixedType = affixType;
    };

    const handleEvent = (event?: Event) => {
      if (inProgress) {
        return;
      }

      inProgress = true;

      requestAnimationFrame(() => {
        if (event?.type === 'scroll') {
          calcSizesWithScroll();
          observeScrollDir();
          stickyPosition(needForceUpdate);
        } else {
          calcSizes();
          stickyPosition(true);
        }

        inProgress = false;
      });
    };

    const resizeObserver = new ResizeObserver(() => {
      handleEvent();
    });

    calcSizes();
    stickyPosition(needForceUpdate);

    resizeObserver.observe(inner);
    resizeObserver.observe(container);
    window.addEventListener('resize', handleEvent, eventOptions);
    window.addEventListener('scroll', handleEvent, eventOptions);

    isInitialized = true;

    return () => {
      resizeObserver.disconnect();
      outer.removeAttribute('style');
      inner.removeAttribute('style');
      window.removeEventListener('resize', handleEvent, eventOptions);
      window.removeEventListener('scroll', handleEvent, eventOptions);
    };
  }, [disable, topSpacing, bottomSpacing]);
};
