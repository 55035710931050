import { useEffect, useRef } from 'react';

import { company } from '~/company/Company';
import { CategorySkeleton } from '~/pages/Catalog/CatalogSkeleton';

interface InfinityScrollLoaderProps {
  onLoadMore(): void;
}

export const InfinityScrollLoader = ({
  onLoadMore,
}: InfinityScrollLoaderProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || !onLoadMore) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        const item = entries[0];

        if (item && item.intersectionRatio > 0) {
          onLoadMore();
        }
      },
      { rootMargin: `${window.innerHeight}px` },
    );

    ref.current && observer.observe(ref.current);

    return () => observer.disconnect();
  }, [onLoadMore]);

  return (
    <div ref={ref} data-company={company.name} className="block-products">
      <CategorySkeleton num={4} />
    </div>
  );
};
