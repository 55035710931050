import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import cdConfig from '~/assets/mapStyle/cd.json';
import localeeConfig from '~/assets/mapStyle/localee.json';
import { company } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import { DeliveryZoneRef } from '~/components/ModalDeliveryAddress/GMap/interfaces';
import { GOOGLE_API_KEY } from '~/stores/constants';
import { userStore } from '~/stores/UserStore';

import {
  deliveryAddressStore,
  mapZoom,
  mapCenter,
} from '../DeliveryAddressStore';

import { createPolygonForZone } from './utils';

const mapStyle: Record<CompanyName, any> = {
  [CompanyName.CityDrinks]: cdConfig,
  [CompanyName.Localee]: localeeConfig,
  [CompanyName.CircleK]: localeeConfig,
  [CompanyName.Vilo]: localeeConfig,
  [CompanyName.Jiffy]: localeeConfig,
  [CompanyName.Kids]: localeeConfig,
};

const GMap = ({ draggable = true }: { draggable?: boolean }) => {
  const [defaultCenter] = useState<GeoCoordinates>(() =>
    deliveryAddressStore.isActiveGeolocation &&
    deliveryAddressStore.geolocationCoordinates
      ? deliveryAddressStore.geolocationCoordinates
      : deliveryAddressStore.deliveryAddress?.coordinates ||
        deliveryAddressStore.mapCenter,
  );

  const handleMapChange = (e: ChangeEventValue) => {
    if (deliveryAddressStore.isActiveGeolocation) {
      return;
    }

    if (
      (e.center.lat.toFixed(7) === mapCenter.lat.toFixed(7) &&
        e.center.lng.toFixed(7) === mapCenter.lng.toFixed(7)) ||
      !Object.keys(toJS(deliveryAddressStore.renderedZonesOnMap)).length
    ) {
      return;
    }

    const selectedZone = deliveryAddressStore.getSelectedPolygon(e.center);

    deliveryAddressStore.setMapCenter(e.center);
    deliveryAddressStore.setMapZoom(e.zoom);
    deliveryAddressStore.setSelectedZone(selectedZone);
  };

  const handleMapAction = (flag: boolean, shouldDropGeolocation: boolean) => {
    deliveryAddressStore.setIsMapBusy(flag);

    if (shouldDropGeolocation) {
      /**
       * Because "handleMapAction" is called when after zoom animation,
       * it is drop geolocation flag, and rewrite map center coordinates
       * To avoid it, we need to drop geolocation flag only when user drag map,
       * but not when map was just zoomed
       * */
      deliveryAddressStore.setIsActiveGeolocation(false);
    }
    deliveryAddressStore.setIsManuallyInput(false);
    deliveryAddressStore.setInputAddressFocused(false);
    deliveryAddressStore.setIsAddressNotCovered(false);
    deliveryAddressStore.setIsAddressNotExist(false);
  };

  const handleMapClick = () => {
    deliveryAddressStore.setIsManuallyInput(false);
    deliveryAddressStore.setInputAddressFocused(false);
    if (document.activeElement) {
      const activeEl: HTMLInputElement =
        document.activeElement as HTMLInputElement;
      activeEl.blur();
    }
  };

  const handleApiLoaded = async ({ map }: { map: google.maps.Map }) => {
    try {
      const coverages = await userStore.getDeliveryZones();
      if (!coverages.length) {
        return;
      }

      const polygonsRef: Record<string, DeliveryZoneRef> = {};

      coverages.forEach((el) => {
        new Map(
          el.zones
            .sort((a, b) => a.priority - b.priority)
            .map((zone) => {
              zone.warehouseId = el.warehouse.id;
              return [zone.name, zone];
            }),
        ).forEach((zone) => {
          const drawPolygons = createPolygonForZone(zone);
          drawPolygons.setMap(map);

          const zoneRef = {
            zone,
            polygon: drawPolygons,
          };

          if (zone.warehouseId) {
            polygonsRef[`${zone.name}-${zone.warehouseId}`] = zoneRef;
          }
        });
      });

      deliveryAddressStore.renderedZonesOnMap = polygonsRef;

      if (userStore.isAuthorized) {
        await userStore.fetchAddresses();
      }
    } catch (error) {
      error && console.error(error);
    }
  };

  const center: GeoCoordinates =
    deliveryAddressStore.isActiveGeolocation &&
    deliveryAddressStore.geolocationCoordinates
      ? deliveryAddressStore.geolocationCoordinates
      : deliveryAddressStore.deliveryAddress?.coordinates ||
        deliveryAddressStore.mapCenter;

  if (defaultCenter === null) {
    return <></>;
  }

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: GOOGLE_API_KEY,
        language: 'en-GB',
        region: 'GB',
      }}
      defaultCenter={defaultCenter}
      defaultZoom={mapZoom}
      zoom={deliveryAddressStore.mapZoom}
      center={center}
      options={{
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
        styles: mapStyle[company.name],
        draggable,
      }}
      onGoogleApiLoaded={handleApiLoaded}
      shouldUnregisterMapOnUnmount={true}
      onChange={handleMapChange}
      onClick={handleMapClick}
      onDrag={() => handleMapAction(true, true)}
      onDragEnd={() => handleMapAction(false, true)}
      onZoomAnimationStart={() => handleMapAction(true, false)}
      onZoomAnimationEnd={() => handleMapAction(false, false)}
      style={{
        pointerEvents: deliveryAddressStore.isChangeDeliveryAreaPopover
          ? 'none'
          : 'auto',
      }}
    />
  );
};

export default observer(GMap);
