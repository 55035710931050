import { isFunction, isNull } from 'lodash-es';
import {
  useCallback,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import LocalStorage from '~/stores/LocalStorage';

type Setter<T> = Dispatch<SetStateAction<Nullable<T>>>;

export const useLocalStorage = <T>(key: string, defaultValue?: T) => {
  const [state, _setState] = useState<Nullable<T>>(defaultValue || null);

  useEffect(() => {
    try {
      const state = LocalStorage.get(key);

      if (!isNull(state)) {
        _setState(state);
      } else if (defaultValue) {
        setState(defaultValue);
      }
    } catch (error) {
      console.error(error);
    }
  }, [key]);

  const setState: Setter<T> = useCallback(
    (value) => {
      _setState((prevState) => {
        const newValue = isFunction(value) ? value(prevState) : value;

        LocalStorage.set(key, newValue);

        return newValue;
      });
    },
    [key],
  );

  return [state, setState] as const;
};
