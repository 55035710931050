import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useGlobal } from '~/hooks/useGlobal';

import { ALERT_DURATION } from '../../stores/constants';
import { mainStore } from '../../stores/MainStore';

export default observer(() => {
  const { isSSR } = useGlobal();

  const handleDismiss = () => {
    const alert = mainStore.popAlert();
    if (alert && alert.onDismiss) {
      alert.onDismiss();
    }
  };

  useEffect(() => {
    if (!mainStore.alerts.length) {
      return;
    }
    const timeout = setTimeout(() => {
      mainStore.popAlert();
    }, ALERT_DURATION);
    return () => clearTimeout(timeout);
  });

  if (!mainStore.alerts.length || isSSR) {
    return <></>;
  }

  return (
    <>
      {mainStore.alerts.slice(-1).map((item) => (
        <div
          className={classes([`alert _fixed _${item.type}`, item.className])}
          key={item.id}
          onClick={handleDismiss}
          dangerouslySetInnerHTML={{ __html: item.message }}
        />
      ))}
    </>
  );
});
