import { classes } from 'html-classes';
import { ReactNode, useState } from 'react';

import Icon from '../Icon/Icon';

type CollapsibleProps = {
  children?: ReactNode;
  title?: string;
  titleElem?: ReactNode;
  className?: string;
  isCollapsed?: boolean;
  innerClassName?: string;
  titleTextClassName?: string;
  titleIconClassName?: string;
  bodyContainerClassName?: string;
};

const Collapsible = ({
  children,
  title,
  titleElem,
  className,
  isCollapsed = true,
  innerClassName,
  titleTextClassName,
  titleIconClassName,
  bodyContainerClassName,
}: CollapsibleProps) => {
  const [isOpen, setIsOpen] = useState(!isCollapsed);
  const [isTransitionEnded, setIsTransitionEnded] = useState(!isCollapsed);

  return (
    <div
      className={classes(['control__collapsible', className, isOpen && 'open'])}
    >
      <div
        className="control__collapsible-header"
        onClick={() => {
          setIsOpen((prev) => !prev);
          setIsTransitionEnded(false);
        }}
      >
        {titleElem ? (
          titleElem
        ) : (
          <>
            <p
              className={classes([
                'control__collapsible-header-title',
                titleTextClassName,
              ])}
            >
              {title}
            </p>
            <Icon type="chevron" size={24} className={titleIconClassName} />
          </>
        )}
      </div>
      <div
        className={classes([
          'control__collapsible-body',
          isTransitionEnded && 'animated',
          isOpen && innerClassName,
        ])}
        onTransitionEnd={() => setIsTransitionEnded(true)}
      >
        <div
          className={classes([
            'control__collapsible-body-container',
            bodyContainerClassName,
          ])}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Collapsible;
