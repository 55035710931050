import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DEFAULT_COMPANIES, company } from '~/company/Company';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { formatVolume } from '~/components/ProductCard/utils';
import { Product } from '~/stores/CategoriesStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from '../Product.module.scss';

type Props = {
  productState?: Nullable<Product>;
};

const BundleListLocalee = ({ productState }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.bundleList} data-company={company.name}>
      <p className={styles.bundleList_title}>
        {t('bundleList')}{' '}
        <span className={styles.count}>({productState?.offers.length})</span>
      </p>
      <ul className={styles.bundleList_content}>
        {productState?.offers.map((i) => {
          const measure = formatVolume(i.properties);
          const price = i.discountPrice
            ? i.discountPriceFormatted
            : i.priceFormatted;

          return (
            <Link key={i.id} to={`/p/${i.productSlug}`}>
              <li className={styles.bundleList_contentItem}>
                <CloudflareResponseImage
                  src={i.previewImageThumb}
                  srcParams={{ width: 100, height: 140 }}
                  className={styles.bundleList_contentItem_image}
                  loading="lazy"
                />
                <div
                  className={
                    styles.bundleList_contentItem_contentDescriptionContainer
                  }
                >
                  <div
                    className={styles.bundleList_contentItem_contentDescription}
                  >
                    <p className={styles.name}>{i.name}</p>
                    {!DEFAULT_COMPANIES.includes(company.name) && (
                      <p className={styles.measure}>
                        {formatPriceWithCurrency(price)}
                        {' • '}
                        {measure ?? '1x'}
                      </p>
                    )}
                    {DEFAULT_COMPANIES.includes(company.name) && measure && (
                      <p className={styles.measure}>{measure}</p>
                    )}
                    <div className={styles.priceContainer}>
                      <p className={styles.price}>
                        <span>
                          {formatPriceWithCurrency(
                            +price * (i.bundleQuantity ?? 1),
                          )}
                          &nbsp;
                        </span>
                        {/* {DEFAULT_COMPANIES.includes(
                          company.name,
                        ) && (
                          <span className={styles.measure}>
                            {' • '}
                            {formatPrice(price)}/{t('pc_')}
                          </span>
                        )} */}
                      </p>
                    </div>
                  </div>
                  <div className={styles.bundleList_contentItem_contentCount}>
                    <p>{i.bundleQuantity ?? 1}x</p>
                  </div>
                </div>
              </li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default observer(BundleListLocalee);
