import { classes } from 'html-classes';
import { useEffect, ChangeEvent, useCallback, useRef, useState } from 'react';
import ReactSlider from 'react-slider';

import { company } from '~/company/Company';
import { useGlobal } from '~/hooks/useGlobal';
import { catalogStore } from '~/stores/CatalogStore';
import { userStore } from '~/stores/UserStore';
import isNumber from '~/utils/isNumber';

import {
  INPUT_CHANGE_DEBOUNCE_TIME,
  DEFAULT_MIN,
  DEFAULT_MAX,
} from './constants';
import { RangeInputProps, MinMaxValue } from './interfaces';
import { makeNumber, formatPriceValue } from './utils';

const RangeInput = ({
  min = DEFAULT_MIN,
  max = DEFAULT_MAX,
  step = 1,
  onChange,
  currentMax,
  currentMin,
  isLoading,
}: RangeInputProps) => {
  const timeoutRef = useRef<Nullable<ReturnType<typeof setTimeout>>>(null);
  const [inputValues, setInputValue] = useState<MinMaxValue<string>>({
    min: formatPriceValue(currentMin || min),
    max: formatPriceValue(currentMax || max),
  });
  const [sliderValue, setSliderValue] = useState<MinMaxValue<number>>(() => ({
    min: currentMin || min,
    max: currentMax || max,
  }));

  const { isMobile } = useGlobal();

  const onChangeDebounced = useCallback(
    (value: MinMaxValue<string>, timeout = INPUT_CHANGE_DEBOUNCE_TIME) => {
      const handler = () => {
        let [newMin, newMax] = [makeNumber(value.min), makeNumber(value.max)];

        if (!isNumber(newMin) || newMin < min) {
          newMin = min;
        }

        if (!isNumber(newMax) || newMax <= 0 || newMax > max) {
          newMax = max;
        }

        if (newMin > newMax) {
          [newMax, newMin] = [newMin, newMax];
        }

        const numericNewValue = { min: newMin, max: newMax };

        setInputValue((previous: MinMaxValue<string>) => {
          const formattedNewMin = formatPriceValue(newMin);
          const formattedNewMax = formatPriceValue(newMax);
          const valuesToChange: Partial<MinMaxValue<string>> = {};

          if (formattedNewMin !== previous.min) {
            valuesToChange.min = formattedNewMin;
          }

          if (formattedNewMax !== previous.max) {
            valuesToChange.max = formattedNewMax;
          }

          if (Object.keys(valuesToChange).length) {
            return {
              ...previous,
              ...valuesToChange,
            };
          }

          return previous;
        });
        setSliderValue(numericNewValue);
        onChange?.(numericNewValue);
      };

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      if (!timeout) {
        handler();
        return;
      }

      timeoutRef.current = setTimeout(() => {
        handler();
        timeoutRef.current = null;
      }, timeout);
    },
    [min, max, onChange, setSliderValue, setInputValue],
  );

  const changeInputValueHandlerFactory =
    (direction: 'min' | 'max') => (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      setInputValue((prev) => {
        const result = {
          ...prev,
          [direction]: value,
        };

        if (!isMobile) {
          onChangeDebounced(result);
        }

        return result;
      });
    };

  const handleSliderChange = (values: [number, number]) => {
    const [min, max] = values;

    setSliderValue({ min, max });
    setInputValue({ min: formatPriceValue(min), max: formatPriceValue(max) });
  };

  useEffect(() => {
    setInputValue((prev) => {
      const numericVal = {
        min: makeNumber(prev.min),
        max: makeNumber(prev.max),
      };

      const valuesToChange: Partial<MinMaxValue<string>> = {};

      if (isNumber(min) && !isNumber(currentMin) && min !== numericVal.min) {
        valuesToChange.min = formatPriceValue(min);
      }

      if (isNumber(max) && !isNumber(currentMax) && max !== numericVal.max) {
        valuesToChange.max = formatPriceValue(max);
      }

      if (Object.keys(valuesToChange).length > 0) {
        return {
          ...prev,
          ...valuesToChange,
        };
      }

      return prev;
    });

    setSliderValue((prev) => {
      const valuesToChange: Partial<MinMaxValue<number>> = {};

      if (isNumber(min) && !isNumber(currentMin) && min !== prev.min) {
        valuesToChange.min = min;
      }

      if (isNumber(max) && !isNumber(currentMax) && max !== prev.max) {
        valuesToChange.max = max;
      }

      if (Object.keys(valuesToChange).length > 0) {
        return {
          ...prev,
          ...valuesToChange,
        };
      }

      return prev;
    });
  }, [min, max, setInputValue, setSliderValue, currentMin, currentMax]);

  useEffect(() => {
    /**
     * In the mobile app we handle onChange on apply button click.
     */
    const handler = () => {
      onChangeDebounced(inputValues, 0);
    };

    if (isMobile) {
      catalogStore.applyFiltersEventEmitter.on('submit', handler);
    }

    return () => {
      catalogStore.applyFiltersEventEmitter.off('submit', handler);
    };
  }, [onChangeDebounced, inputValues, isMobile]);

  return (
    <div className="range-input" data-company={company.name}>
      <div
        className={classes([
          'range-input__header',
          userStore.dir === 'rtl' && 'rtl',
        ])}
      >
        <input
          className="range-input__header-input"
          value={inputValues.min}
          onChange={changeInputValueHandlerFactory('min')}
          disabled={isLoading}
        />
        <input
          className="range-input__header-input"
          value={inputValues.max}
          onChange={changeInputValueHandlerFactory('max')}
          disabled={isLoading}
        />
      </div>
      {isNumber(min) && isNumber(max) && min < max && (
        <div className="range-input__body">
          <ReactSlider<[number, number]>
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            value={[sliderValue.min, sliderValue.max]}
            min={min}
            max={max}
            ariaLabel={['Lower price', 'Upper price']}
            ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
            disabled={isLoading}
            pearling
            minDistance={step}
            onChange={handleSliderChange}
            invert={userStore.dir === 'rtl'}
            onAfterChange={([newMin, newMax]) =>
              onChangeDebounced(
                {
                  min: formatPriceValue(newMin),
                  max: formatPriceValue(newMax),
                },
                0,
              )
            }
          />
        </div>
      )}
    </div>
  );
};

export default RangeInput;
