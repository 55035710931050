import { ReactNode } from 'react';

import styles from './SearchResults.module.scss';

interface RecommendationsItem {
  title: ReactNode;
  action?: ReactNode;
  items: string[];
}

interface SearchRecommendationsProps {
  data: RecommendationsItem[];
  onSelect(value: string, item: RecommendationsItem): void;
}

export const SearchRecommendations = ({
  data,
  onSelect,
}: SearchRecommendationsProps) => {
  const handleSelect = (value: string, item: RecommendationsItem) => {
    return () => {
      onSelect(value, item);
    };
  };

  if (!data.length) {
    return null;
  }

  return (
    <>
      {data.map((item, index) => (
        <div key={index} className={styles.recommendations}>
          <div className={styles.recommendations__header}>
            <h2 className={styles.recommendations__title}>{item.title}</h2>
            {item.action}
          </div>
          <div className={styles.recommendations__list}>
            {item.items.map((value, index) => (
              <div
                key={index + value}
                className={styles.recommendations__badge}
                onClick={handleSelect(value, item)}
              >
                {value}
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};
