import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, ReactNode, InputHTMLAttributes } from 'react';
import { RefCallBack } from 'react-hook-form';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';

import styles from './InputText.module.scss';

interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleReset?: () => void;
  children?: ReactNode;
  classNameWrapper?: string;
  className?: string;
  classNameInput?: string;
  classNameLabel?: string;
  classNameLabelError?: string;
  classNameInputError?: string;
  externalLabel?: string;
  type?: string;
  isValid?: boolean;
  isError?: boolean;
  error?: string;
  refObj?: React.RefObject<HTMLInputElement> | RefCallBack;
  required?: boolean;
}

export default observer(
  ({
    label,
    placeholder,
    onChange,
    onFocus,
    onBlur,
    handleReset,
    externalLabel,
    classNameWrapper,
    children,
    className,
    classNameInput,
    classNameLabel,
    classNameLabelError,
    classNameInputError,
    type,
    isValid,
    error,
    isError,
    maxLength,
    refObj,
    required,
    ...props
  }: InputTextProps) => {
    return (
      <div className={classNameWrapper}>
        {externalLabel && (
          <div
            className={classes([
              'input-text__externalLabel',
              error && classNameLabelError,
            ])}
          >
            {externalLabel}
            {required && error && ' *'}
          </div>
        )}
        <div
          className={classes([
            'input-text__wrap',
            className,
            { active: props.value, _error: isError },
          ])}
        >
          {children ? (
            children
          ) : (
            <div className={styles.inputContainer} data-company={company.name}>
              {isValid && (
                <div className="icon icon-check input-text__icon _check" />
              )}
              <input
                className={classes([
                  'input-text',
                  classNameInput,
                  { noLabel: !label },
                  error && classNameInputError,
                ])}
                type={type || 'text'}
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                placeholder={placeholder}
                maxLength={maxLength}
                ref={refObj}
                {...props}
              />
              {handleReset && (
                <button className={styles.resetBotton} onClick={handleReset}>
                  <Icon
                    type="burger-close"
                    size={24}
                    className={styles.resetBottonIcon}
                  />
                </button>
              )}
            </div>
          )}
          {label && (
            <div
              className={classes([
                'input-text__label text-truncate',
                classNameLabel,
                {
                  _active: placeholder,
                },
              ])}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          )}
          {!!error && <p className="input-text_errorText error">{error}</p>}
        </div>
      </div>
    );
  },
);
