import { AxiosError } from 'axios';
import i18n, { t } from 'i18next';
import { makeObservable, observable, action, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { CheckPromocodeResponse, OrderRequests } from '~/api/Order';
import {
  ReferralProgramRequests,
  RewarderPromoCode,
  RewardStatusResponse,
} from '~/api/ReferralProgram';
import { ApiErrorResponse } from '~/api/Requests';
import {
  Promocode,
  CatalogStorePartial,
} from '~/stores/CatalogStore/interfaces';
import { notSSRFormatPriceWithCurrency } from '~/utils/formaters';
import { clearStoredPromocode, getStoredPromocode } from '~/utils/referralLink';

import { PICKUP_ALCOHOL_COUPON_CODE, PRESENT_PROMOCODES } from '../constants';
import { BaseStore } from '../interfaces/BaseStore';
import { storage as localStorage } from '../LocalStorage';
import { mainStore } from '../MainStore';
import { orderStore } from '../OrderStore';
import { MetaStore } from '../shared/MetaStore';
import { userStore } from '../UserStore';

import { ProposedPromocode, ApplyPromocodeCallerSource } from './interfaces';
import { truncateItemName } from './utils';

export class PromotionStore implements BaseStore {
  private readonly _catalogStore: CatalogStorePartial;
  private readonly _abortController: AbortController = new AbortController();

  readonly applyPromocodeMeta: MetaStore = new MetaStore();
  readonly rewardedPromocodeMeta: MetaStore = new MetaStore();

  readonly usedPromocodes: Map<string, string[]> = new Map();
  readonly proposedPromocodes: Set<ProposedPromocode> = new Set();

  unseenAwardedPromocodesCount: number = 0;
  rewardedPromocodeInstances: RewarderPromoCode[] = [];
  promocodeRewardStatus: RewardStatusResponse = {
    new: 0,
    total: 0,
  };

  promocode: Promocode = {
    coupon: null,
    value: '',
    errorType: null,
    message: '',
    success: null,
    discountLimit: null,
  };

  constructor(catalogStore: CatalogStorePartial) {
    this._catalogStore = catalogStore;

    makeObservable(this, {
      promocode: observable,
      unseenAwardedPromocodesCount: observable,
      promocodeRewardStatus: observable,
      rewardedPromocodeInstances: observable,
      usedPromocodes: observable,
      proposedPromocodes: observable,
      applyPromocode: action.bound,
      resetPromocode: action.bound,
      removeUsedPromocode: action.bound,
      addUsedPromocodes: action.bound,
      loadStoredPromoCodes: action.bound,
      getUnseenPromoRewards: action.bound,
      getRewardedPromocodesList: action.bound,
      getRewardedPromocodesStatus: action.bound,
    });
    makePersistable(this, {
      name: 'PromotionStore',
      properties: ['promocode', 'usedPromocodes'],
      storage: localStorage,
    });
  }

  static generateErrorPromocode(
    code: string,
    message = 'errors:promocodeError',
    messageArgs?: Record<string, unknown>,
  ): Promocode {
    return {
      coupon: null,
      value: code,
      errorType: 'error',
      message: i18n.t(message, messageArgs),
      success: false,
      discountLimit: null,
    };
  }

  async applyPromocode(
    code: string,
    source: ApplyPromocodeCallerSource,
  ): Promise<boolean> {
    this.applyPromocodeMeta.setLoading(true);

    try {
      const response = await OrderRequests.checkPromocode(code, {
        signal: this._abortController.signal,
      });

      const validated = this.validatePromocode(response, code);

      runInAction(() => {
        // This assign will trigger useEffect that calls `calculateCart`
        // And then `calculateCart` will execute `handleCalculateWithPromocodeResponse` handler.
        this.promocode = validated;
      });
    } catch (error) {
      const promocodeWithError = this._handleError(code, error, source);

      runInAction(() => {
        this.promocode = promocodeWithError;
      });
    } finally {
      if (!this.promocode.coupon) {
        this.applyPromocodeMeta.setLoading(false);
      }
    }

    if (!this.promocode.coupon) {
      this.removeUsedPromocode(code);
      return false;
    }

    this._emitUsedPromocodeEvent();
    return true;
  }

  async getUnseenPromoRewards() {
    const userId = userStore.personalData.id;

    if (!userId) {
      return;
    }

    try {
      const response = await ReferralProgramRequests.getRewardsNotifications(
        userId,
      );
      // Receiving response from the server as text/plain. Safely extract value as number
      const unseenPromocodesCount = Number(response);

      runInAction(() => {
        if (isNaN(unseenPromocodesCount)) {
          this.unseenAwardedPromocodesCount = 0;
          return;
        }

        this.unseenAwardedPromocodesCount = unseenPromocodesCount;
        this.promocodeRewardStatus.new += unseenPromocodesCount;
        this.promocodeRewardStatus.total += unseenPromocodesCount;
      });

      return unseenPromocodesCount;
    } catch (error: unknown) {
      await mainStore.errorHandler(error as AxiosError<ApiErrorResponse>, '');
    }
  }

  async getRewardedPromocodesList() {
    const userId = userStore.personalData.id;

    if (!userId) {
      return;
    }

    this.rewardedPromocodeMeta.setLoading(true);

    try {
      this.rewardedPromocodeInstances =
        await ReferralProgramRequests.getCustomerRewards(userId);

      runInAction(() => {
        this.promocodeRewardStatus.new = 0;
      });
    } catch (error: unknown) {
      await mainStore.errorHandler(error as AxiosError<ApiErrorResponse>, '');
    } finally {
      this.rewardedPromocodeMeta.setLoading(false);
    }
  }

  async getRewardedPromocodesStatus() {
    const userId = userStore.personalData.id;

    if (!userId) {
      return;
    }

    try {
      const response = await ReferralProgramRequests.getRewardStatus(userId);

      runInAction(() => {
        this.promocodeRewardStatus = response;
      });
    } catch (error: unknown) {
      await mainStore.errorHandler(error as AxiosError<ApiErrorResponse>, '');
    }
  }

  addUsedPromocodes(promocode: string) {
    if (!userStore.isAuthorized || !userStore.personalData.id) {
      return;
    }

    const userId = userStore.personalData.id;
    const usedByUser = this.usedPromocodes.get(userId);

    promocode = promocode.toUpperCase();

    if (!usedByUser) {
      this.usedPromocodes.set(userId, [promocode]);
      return;
    }

    if (usedByUser.includes(promocode)) {
      return;
    }

    this.usedPromocodes.set(
      userId,
      PRESENT_PROMOCODES.includes(promocode)
        ? [...usedByUser, promocode]
        : [promocode, ...usedByUser],
    );
  }

  removeUsedPromocode(code: string): void {
    if (!userStore.isAuthorized || !userStore.personalData.id) {
      return;
    }

    const { id } = userStore.personalData;
    const value = this.usedPromocodes.get(id);

    if (!value) {
      return;
    }

    const codeUpper = code.toUpperCase();

    if (!value.includes(codeUpper)) {
      return;
    }

    runInAction(() => {
      this.usedPromocodes.set(
        id,
        value.filter((item) => item !== code),
      );
    });
  }

  resetPromocode() {
    if (this.promocode.success === null) {
      return;
    }
    this.promocode = {
      coupon: null,
      value: '',
      errorType: null,
      message: '',
      success: null,
      discountLimit: null,
    };
    clearStoredPromocode();
  }

  validatePromocode(data: CheckPromocodeResponse, code: string): Promocode {
    if (!data.isActive) {
      return PromotionStore.generateErrorPromocode(
        code,
        'errors:promocodeNotFoundText',
      );
    }

    if (!data.applyToDiscountedItems) {
      const discountedItem = this._catalogStore.cart.filter(
        (item) => item.selected && Boolean(item.discountPrice),
      );

      if (discountedItem.length) {
        PromotionStore.generateErrorPromocode(
          code,
          'errors:promocodeDeniedDiscount',
          {
            names: discountedItem
              .map((item) => truncateItemName(item.name))
              .join(', '),
          },
        );
      }
    }

    const minimumPurchase = Number(
      mainStore.convertPenceToPounds(data.minimumPurchase),
    );

    if (
      minimumPurchase &&
      Number(this._catalogStore.totalCartPrice.base) > 0 &&
      Number(this._catalogStore.totalCartPrice.base) < minimumPurchase
    ) {
      return PromotionStore.generateErrorPromocode(
        code,
        'errors:promocodeMinimumPurchase',
        {
          amount: notSSRFormatPriceWithCurrency(minimumPurchase),
        },
      );
    }

    let deliveryMethod: ETADeliveryMethodType | undefined;

    if (data.code.toUpperCase() === PICKUP_ALCOHOL_COUPON_CODE) {
      if (!orderStore.isPickupAvailable) {
        return PromotionStore.generateErrorPromocode(
          code,
          'errors:promocodeNotFoundText',
        );
      }

      deliveryMethod = ETADeliveryMethodType.ClickAndCollect;
    }

    return {
      coupon: this._normalizeData(data),
      value: code,
      errorType: null,
      message: '',
      success: null,
      discountLimit: null,
      deliveryMethod,
    };
  }

  handleCalculateWithPromocodeResponse(errorCode: Nullable<string>) {
    runInAction(() => {
      this.applyPromocodeMeta.setLoading(false);

      if (errorCode) {
        this.promocode.errorType = 'error';
        this.promocode.message = i18n.t(`errors:${errorCode}`, {
          count: this.promocode.coupon?.orderNumberRange[0] || 0,
          amount: notSSRFormatPriceWithCurrency(
            mainStore.convertPenceToPounds(
              this.promocode.coupon?.minimumPurchase || 0,
            ),
          ),
        });
        this.promocode.success = false;
        return;
      }

      this.promocode.errorType = null;
      this.promocode.message = t('promocodeSuccessfullyApplied');
      this.promocode.success = true;
    });
  }

  loadStoredPromoCodes() {
    const storedPromocode = getStoredPromocode();

    if (!storedPromocode) {
      return;
    }

    const { code, customerId } = storedPromocode;

    if (
      userStore.personalData?.id &&
      customerId === userStore.personalData.id
    ) {
      return;
    }

    this.applyPromocode(code, ApplyPromocodeCallerSource.INTERNAL);
  }

  private _normalizeData(data: CheckPromocodeResponse): CheckPromocodeResponse {
    if (data.type === 'FIXED' && data.value && !data.minimumPurchase) {
      return {
        ...data,
        minimumPurchase: data.value,
      };
    }

    return data;
  }

  private _emitUsedPromocodeEvent() {
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'select_promotion',
      params: {
        items: this._catalogStore.cartForFirebase,
        location_id: '',
      },
    });

    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Purchase: promocode entered',
      params: {
        ...this._getAnalyticsInstance(),
        success: true,
        products_amount: this._catalogStore.cart.reduce(
          (sum, item) => sum + item.count,
          0,
        ),
        items_amount: this._catalogStore.cart.length,
        price: this._catalogStore.totalCartPrice.base,
        final_price: this._catalogStore.finalPrice,
        eta_min: orderStore.etaCalculation?.duration.min || 0,
        eta_max: orderStore.etaCalculation?.duration.max || 0,
        delivery_fee: orderStore.fee.shippingPounds || 0,
        threshold: orderStore.fee.thresholdPounds || 0,
        is_surger: orderStore.etaCalculation?.highDemand || false,
      },
    });
  }

  private _getAnalyticsInstance(): Record<string, unknown> {
    const { value, coupon } = this.promocode;

    if (!coupon) {
      return { code: value };
    }

    return {
      code: value,
      discount:
        coupon.type === 'FIXED'
          ? mainStore.convertPenceToPounds(coupon.value || 0)
          : coupon.value || 0,
      type: coupon.type || '',
    };
  }

  private _handleError(
    code: string,
    error: unknown,
    source: ApplyPromocodeCallerSource,
  ): Promocode {
    if (!(error instanceof AxiosError)) {
      return PromotionStore.generateErrorPromocode(code);
    }

    const { response } = error;
    const errorCode = response?.data?.data?.err_code;

    if (!errorCode) {
      return PromotionStore.generateErrorPromocode(code);
    }

    if (source === ApplyPromocodeCallerSource.CHECKOUT) {
      return PromotionStore.generateErrorPromocode(
        code,
        errorCode === 'DISCOUNT.COUPONS.ONLY_FIRST_ORDER_ALLOWED'
          ? 'errors:notFirstOrderPromocodeFreeDelivery'
          : 'errors:promocodeNotFoundText',
      );
    }

    return PromotionStore.generateErrorPromocode(code, `errors:${errorCode}`);
  }

  destroy() {
    this.applyPromocodeMeta.destroy();
    this.rewardedPromocodeMeta.destroy();
    this._abortController.abort();
  }
}
