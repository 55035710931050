import { classes } from 'html-classes';
import { noop } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import {
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { OrderItemBundle } from '~/api/Catalog';
import { ETADeliveryMethodType } from '~/api/ETADeliveryMethodType';
import { company, DEFAULT_COMPANIES } from '~/company/Company';
import { CompanyName } from '~/company/interface';
import { ModalType } from '~/components/Modal/interface';
import { PartiallyPicked } from '~/components/PartiallyPicked/PartiallyPicked';
import { RateAppAlert } from '~/components/RateAppAlert';
import { useGlobal } from '~/hooks/useGlobal';
import { STATUS_ICONS } from '~/pages/Order/components/OrderStatusBadge/constants';
import { Product } from '~/stores/CategoriesStore';
import { userStore } from '~/stores/UserStore';
import { formatPriceWithCurrency } from '~/utils/formaters';

import {
  Order,
  OrderCartItem,
  OrderCartItemStatus,
  OrderShort,
} from '../../api/Order';
import Icon from '../../components/Icon/Icon';
import MainSidebar from '../../components/MainSidebar/MainSidebar';
import { catalogStore } from '../../stores/CatalogStore';
import { mainStore } from '../../stores/MainStore';
import { OrderStatusStore, orderStore } from '../../stores/OrderStore';
import { normalizeOrderId } from '../../utils/normalizeOrderId';
import { pollingBuilder } from '../../utils/pollingBuilder';

import {
  DeliveringStatus,
  DELIVERY_INDICATORS,
} from './components/DeliveringStatus';
import { OrderBottomSheet } from './components/OrderBottomSheet';
import { OrderDateText } from './components/OrderDateText';
import { PickUpOrderHeader } from './components/PickUpOrderHeader';
import { ShareBlock } from './components/ShareBlock';
import styles from './Order.module.scss';
import OrderProductCard from './OrderProductCard';
import OrderSkeleton from './OrderSkeleton';

import './styles.scss';

const PATH = '/cabinet/orders';

const OrderPage = observer(() => {
  const { t } = useTranslation();
  const { orderId } = useParams<{ orderId: string }>();
  const { state } = useLocation();
  const [order, setOrder] = useState<Order | OrderShort | null>();
  const [, setBrokenOrderType] = useState<
    'missingItems' | 'notDelivered' | null
  >(null);
  const [publicStatus, setPublicStatus] =
    useState<OrderStatusStore>('accepted');
  const [products, setProducts] = useState<Record<string, Product>>({});
  const stopWatchRef = useRef<(() => void) | null>(null);
  const { isTablet, isMobile } = useGlobal();
  const navigate = useNavigate();

  const isPickUpOrder =
    order?.delivery_method === ETADeliveryMethodType.ClickAndCollect;

  const showMapStatus = Boolean(
    order &&
      DELIVERY_INDICATORS.includes(order.public_status) &&
      !isPickUpOrder,
  );

  const isMapFullscreen = showMapStatus && (isTablet || isMobile);

  useEffect(() => {
    if (order) {
      mainStore.sendAnalytics(['BI', 'analytics'], {
        name: 'Order page view',
        params: { orderId: order.id },
      });
    }
  }, [order]);

  const orderStatus = (
    <div className={classes(['header__info-status', order?.public_status])}>
      {order && <Icon type={STATUS_ICONS[order.public_status]} size={20} />}
      {t(publicStatus)}
    </div>
  );
  const [searchParams] = useSearchParams();
  const openedModal = searchParams.get('modal');

  const goBackSection = useMemo(() => {
    const path = state && state.tab ? `${PATH}/${state.tab}` : PATH;

    if (
      company.name === CompanyName.CityDrinks &&
      isMobile &&
      !isMapFullscreen
    ) {
      return null;
    }

    if (company.variant({ '1': !isMapFullscreen, '2': false })) {
      return (
        <Link to={path} replace={true} className="go-back">
          <Icon type="chevron" className="icon__rotate-90" />
          <span>{t('phrases:backToOrderList')}</span>
        </Link>
      );
    }
    if (isTablet && !showMapStatus) {
      return (
        <Link
          data-company={company.name}
          to={path}
          replace={true}
          className="go-back-portalled w-text fullwidth"
          dir={userStore.dir}
        >
          <Icon
            type="arrow"
            className="icon__rotate-180"
            data-company={company.name}
          />
          {order && (
            <span>
              {t('phrases:orderNumber', {
                number: normalizeOrderId(order?.order_id),
              })}
            </span>
          )}
          {orderStatus}
        </Link>
      );
    }
    return createPortal(
      <Link
        to={path}
        data-company={company.name}
        className="go-back-portalled"
        replace={true}
        dir={userStore.dir}
      >
        <Icon
          type="arrow"
          className="icon__rotate-180 dark"
          data-company={company.name}
        />
      </Link>,
      document.body,
    );
  }, [isTablet, order, isMapFullscreen, isMobile]);

  const updateStatus = (order: OrderShort) => {
    let status: OrderStatusStore;
    switch (order.public_status) {
      case 'in_delivery':
        status = 'inDelivery';
        break;
      case 'ready_to_ship':
        status = 'readyToShip';
        break;
      case 'ready_for_pickup':
        status = 'readyForPickup';
        break;
      default:
        status = order.public_status;
        break;
    }
    setPublicStatus(status);
  };

  useLayoutEffect(() => {
    const fetchOrder = async () => {
      if (!orderId) {
        return;
      }

      try {
        return await orderStore.requestOrder(orderId);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProducts = async (
      order: Order | OrderShort,
    ): Promise<Record<string, Product>> => {
      const products: Record<string, Product> = {};

      for (const item of order.items) {
        try {
          const product = await Product.requestById(item.product_id).catch(
            noop,
          );

          if (!product) {
            continue;
          }

          products[product.id] = product;
        } catch (error) {
          console.error(error);
        }
      }

      if (order.bundles) {
        for (const item of order.bundles) {
          try {
            const product = await Product.requestById(item.catalog_bundle_id);

            if (!product) {
              continue;
            }

            products[product.id] = product;
          } catch (error) {
            console.error(error);
          }
        }
      }

      return products;
    };

    const requestData = async () => {
      const order = await fetchOrder();

      setOrder(order);

      if (!order) {
        return;
      }

      updateStatus(order);

      const products = await fetchProducts(order);

      setProducts(products);

      const itemBrokenStatuses: OrderCartItemStatus[] = [
        'missing',
        'picked_partially',
        'out_of_stock',
      ];
      const allItemsMissing = order.items.every(
        (item) => itemBrokenStatuses.indexOf(item.status) !== -1,
      );
      if (allItemsMissing) {
        setBrokenOrderType('notDelivered');
      } else {
        const someItemsMissing = order.items.some(
          (item) => itemBrokenStatuses.indexOf(item.status) !== -1,
        );
        if (someItemsMissing) {
          setBrokenOrderType('missingItems');
        }
      }
    };

    void requestData();
  }, [orderId]);

  const handleFavorite = (productId: string) => {
    const product = products[productId];
    if (!product) {
      return;
    }
    catalogStore.toggleFavorite(product, 'product_main');
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });
  };

  useEffect(() => {
    stopWatchRef.current?.();

    if (!orderId) {
      return;
    }

    stopWatchRef.current = pollingBuilder({
      promiseBuilder: () => orderStore.requestOrder(orderId),
      conditionFn: (response) => {
        if (!response) {
          return false;
        }

        setOrder(response);
        updateStatus(response);

        return response.public_status !== 'delivered';
      },
    });

    return () => {
      stopWatchRef.current?.();
    };
  }, [orderId]);

  const getItemsNum = () => {
    const bundles = order?.bundles?.length ?? 0;
    const items = order?.items.filter((i) => !i.bundle_id).length ?? 0;

    return bundles + items;
  };

  const isFeedbackAlertShown = () => {
    const allowedStatuses = [
      'accepted',
      'picking',
      'ready_to_ship',
      'ready_for_pickup',
      'in_delivery',
    ];
    return (
      mainStore.isRN &&
      !userStore.personalData.isRateApp &&
      allowedStatuses.includes(`${order?.public_status}`)
    );
  };

  const orderNumberText = t('phrases:orderNumber', {
    number: normalizeOrderId(order?.order_id ?? ''),
  });

  const reorder = useCallback(async () => {
    try {
      if (!order?.items.length) {
        return;
      }

      const reorderableProducts = order.items.filter(
        (i) => !i.is_gift && !i.bundle_id,
      );
      const reorderableBundles = order.bundles || [];

      const products = await Promise.all(
        reorderableProducts.map((item) => Product.requestById(item.product_id)),
      );
      const bundleProducts = await Promise.all(
        reorderableBundles.map((item) =>
          Product.requestById(item.catalog_bundle_id),
        ),
      );

      catalogStore.emptyCart();

      reorderableProducts.forEach((item, index) => {
        const product = products[index];
        const count = Math.max(
          item.actual_quantity || item.picked_quantity || 1,
          1,
        );

        if (!product) {
          throw new Error();
        }

        const offer =
          product.sku === item.sku
            ? product
            : product.offers.find(({ sku }) => sku === item.sku);

        if (!offer) {
          throw new Error();
        }

        catalogStore.setCartItemCountByProduct(
          offer,
          count,
          'add',
          'product_main',
        );
      });

      reorderableBundles?.forEach((item, index) => {
        const product = bundleProducts[index];
        const count = Math.max(item.quantity || 1, 1);

        if (!product) {
          throw new Error();
        }

        catalogStore.setCartItemCountByProduct(
          product,
          count,
          'add',
          'product_main',
        );
      });

      mainStore.sendAnalytics(['BI', 'analytics'], {
        name: 'Reorder button clicked',
        params: { orderId: order.id },
      });

      navigate('/cart');
    } catch (error) {
      mainStore.pushAlert('error', t('errors:oops'));
      console.error(error);
      catalogStore.emptyCart();
    }
  }, [order, navigate]);

  const buttons = (
    <>
      {order?.receipt_url && (
        <a
          href={order.receipt_url}
          target="_blank"
          rel="noreferrer"
          className="button _bordered _white"
          onClick={() => {
            mainStore.sendAnalytics(['BI', 'analytics'], {
              name: 'Check button clicked',
              params: { orderId: order.id },
            });
          }}
        >
          <Icon type="receipt" size={isMobile ? 16 : 24} />
          Check
        </a>
      )}
      {company.match({
        circlek: (
          <a
            className="button _bordered _white contact-support-mobile__button"
            href={`tel:${company.config.phoneNumber}`}
          >
            <Icon type="whatsapp" size={isMobile ? 16 : 24} />
            {t('contactSupport')}
          </a>
        ),
        ourkids: (
          <a
            className="button _bordered _white contact-support-mobile__button"
            href={`tel:${company.config.phoneNumber}`}
          >
            <Icon type="whatsapp" size={isMobile ? 16 : 24} />
            {t('contactSupport')}
          </a>
        ),
        default: company.config.links?.whatsapp && (
          <a
            className="button _bordered _white contact-support-mobile__button"
            href={company.config.links.whatsapp}
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              mainStore.sendAnalytics(['BI', 'analytics'], {
                name: 'Contact support button clicked',
                params: { orderId: order?.id },
              });
            }}
          >
            <Icon type="whatsapp" size={isMobile ? 16 : 24} />
            {t('contactSupport')}
          </a>
        ),
      })}
      <button className="button _bordered _white" onClick={reorder}>
        <Icon type="reorder" size={isMobile ? 16 : 24} />
        {t('reorder')}
      </button>
    </>
  );

  const isPartiallyPicked = useMemo(() => {
    return order?.items.some(
      (i: OrderCartItem) => i.requested_quantity - i.actual_quantity > 0,
    );
  }, [order]);

  const partiallyPickedMessage = isPartiallyPicked &&
    company.showPartiallyPickedInfo &&
    !['inDelivery', 'readyToShip'].includes(publicStatus) && (
      <PartiallyPicked />
    );

  const paidTotal =
    order &&
    formatPriceWithCurrency(
      mainStore.convertPenceToPounds(order.paid_total || 0),
    );
  const baseTotal =
    order &&
    formatPriceWithCurrency(
      order.base_total
        ? mainStore.convertPenceToPounds(
            order.base_total - (order.discount_total ?? 0),
          )
        : 0,
    );

  return (
    <div
      className={classes(['order content', styles.pickUpOrderContent])}
      data-company={company.name}
    >
      <aside className="sidebar">
        <MainSidebar />
      </aside>
      <main className={classes(['container', styles.root])}>
        {!order ? (
          <OrderSkeleton />
        ) : (
          <>
            {isPickUpOrder ? (
              <PickUpOrderHeader order={order} />
            ) : (
              goBackSection
            )}

            <OrderBottomSheet
              enabled={isMapFullscreen && openedModal !== ModalType.RateApp}
              className={classes([
                'order-sheet-content',
                isPickUpOrder && styles.pickUpOrderSheet,
              ])}
              withBackdrop={false}
            >
              {!isPickUpOrder &&
                company.variant({ '1': true, '2': !isMobile }) && (
                  <section className="header skeleton">
                    <Icon
                      type="arrow"
                      size={24}
                      className="icon__rotate-180"
                      onClick={() => navigate('/cabinet/orders')}
                    />
                    <div className="title">
                      {DEFAULT_COMPANIES.includes(company.name) ? (
                        <>
                          {t('orderList')} <Icon type="chevron" size={16} />{' '}
                          <span>{orderNumberText}</span>
                        </>
                      ) : (
                        orderNumberText
                      )}
                    </div>
                    <Icon type="copy" size={24} />
                    <div className="header__info">
                      <div className="header__info-time">
                        <OrderDateText createdAt={order.created_at} />
                      </div>
                      {orderStatus}
                    </div>
                    {company.variant({
                      '1': isMobile && (
                        <>
                          <div className="header__time">
                            <OrderDateText createdAt={order.created_at} />
                          </div>
                          <div className="header__buttons">{buttons}</div>
                        </>
                      ),
                    })}
                  </section>
                )}

              {showMapStatus && (
                <DeliveringStatus order={order} className="delivery-status" />
              )}

              <div
                className={classes([
                  styles.shareBlockWr,
                  !isPickUpOrder && styles._oder3,
                ])}
              >
                <ShareBlock orderId={order.id} />
              </div>

              {isFeedbackAlertShown() && (
                <div className={styles.rateFeedback}>
                  <RateAppAlert />
                </div>
              )}

              <div
                className={classes([
                  'content-wrapper',
                  isPickUpOrder && styles.pickUpContentWrapper,
                ])}
              >
                <section className="order__details">
                  <div className="order__details-content">
                    <div className="order__details-content-main">
                      <div className="order__details-item">
                        <Icon
                          type="location"
                          size={24}
                          className="order__details-item-icon"
                        />
                        <div className="order__details-item-content">
                          <p className="order__details-item-content-title">
                            {isPickUpOrder
                              ? t('deliveryMethods:pickupFrom')
                              : t('deliverTo')}
                          </p>
                          <p className="order__details-item-content-value">
                            {isPickUpOrder
                              ? orderStore.etaCalculation?.warehouse.address
                              : order.address?.address_1}
                          </p>
                        </div>
                      </div>
                      <div className="order__details-item">
                        <Icon
                          type="person"
                          size={24}
                          className="order__details-item-icon"
                        />
                        <div className="order__details-item-content">
                          <p className="order__details-item-content-title">
                            {t('receiver')}
                          </p>
                          <p className="order__details-item-content-value">
                            {order.recipient?.full_name},{' '}
                            {order.recipient?.phone}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="order__details-content-payment">
                      <div className="order__details-payment-info">
                        {!company.isDefaultCompany &&
                          (!isMobile || !isPickUpOrder) && (
                            <div className="order__details-payment-info-item _big">
                              <div
                                className={classes([
                                  isPickUpOrder && styles.pickUpOrderPaid,
                                ])}
                              >
                                <p
                                  className={classes([
                                    !isPickUpOrder && 'payment-paid',
                                  ])}
                                >
                                  {t('total')}
                                </p>
                                <p className="payment-method">
                                  {isMobile && <>&nbsp;</>}
                                  {t(`by${order.payment_method.toLowerCase()}`)}
                                </p>
                              </div>
                              <p>{paidTotal}</p>
                            </div>
                          )}
                        <div
                          className={classes([
                            'order__details-payment-info-item _list d-hide',
                            !isPickUpOrder && '_order-1',
                            isPickUpOrder && styles.pickUpOrderValue,
                            isMobile &&
                              company.isDefaultCompany &&
                              'top_padding',
                          ])}
                        >
                          <div className="order__details-payment-info-item">
                            {(!company.isDefaultCompany && !isPickUpOrder) ||
                            !isMobile ? (
                              <>
                                <p>{t('orderValue')}</p>
                                <p>{baseTotal}</p>
                              </>
                            ) : (
                              <>
                                <p>{t('total')}</p>
                                <p>{paidTotal}</p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="order__details-payment-info-item _list">
                          <div className="order__details-payment-info-item">
                            <p>
                              {t('phrases:vat', { percent: order.tax_percent })}
                            </p>
                            <p>
                              {formatPriceWithCurrency(
                                mainStore.convertPenceToPounds(
                                  order.actual_tax_amount || 0,
                                ),
                              )}
                            </p>
                          </div>
                          {!!order.promocode_discount && (
                            <div className="order__details-payment-info-item">
                              <p>{t('phrases:promoCode')}</p>
                              <p>
                                -
                                {formatPriceWithCurrency(
                                  mainStore.convertPenceToPounds(
                                    order.promocode_discount || 0,
                                  ),
                                )}
                              </p>
                            </div>
                          )}
                          {!isPickUpOrder && (
                            <div className="order__details-payment-info-item">
                              <p>
                                {t(
                                  company.variant({
                                    '1': 'deliveryFeeLong',
                                    '2': 'deliveryFee',
                                  }),
                                )}
                              </p>
                              <p>
                                {formatPriceWithCurrency(
                                  mainStore.convertPenceToPounds(
                                    order.delivery_price || 0,
                                  ),
                                )}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="order__details-payment-info-item _bold m-hide">
                          <p>{t('total')}</p>
                          <p>{paidTotal}</p>
                        </div>
                        {isMobile &&
                          company.match({
                            citydrinks: null,
                            default: (
                              <div className="order__details-buttons">
                                {buttons}
                              </div>
                            ),
                          })}
                      </div>
                    </div>
                    {!isMobile && (
                      <div className="order__details-buttons">{buttons}</div>
                    )}
                  </div>
                </section>

                <section className="order__items">
                  <div className={styles.orderListItems__info}>
                    <p className="order__items-title">
                      {getItemsNum()}{' '}
                      {getItemsNum() > 1 ? t('items') : t('item')}
                    </p>
                    {partiallyPickedMessage}
                  </div>
                  {isMobile && showMapStatus && (
                    <div className="header__info mobile-order-header">
                      {t('phrases:orderNumber', {
                        number: normalizeOrderId(order?.order_id),
                      })}
                      {orderStatus}
                    </div>
                  )}
                  <ul className="order__items-list">
                    {order.bundles?.map((item) => (
                      <li
                        key={item.id}
                        className={classes([
                          (item as OrderItemBundle).is_picked_partialy &&
                            'partiallyDelivered__anchor',
                        ])}
                      >
                        <OrderProductCard
                          item={item}
                          handleFavorite={handleFavorite}
                          isBundle
                          product={products[item.catalog_bundle_id]}
                        />
                      </li>
                    ))}
                    {order.items.reduce((acc: ReactNode[], item) => {
                      !item.bundle_id &&
                        acc.push(
                          <li
                            key={item.id}
                            className={classes([
                              (item as OrderCartItem).requested_quantity >
                                (item as OrderCartItem).actual_quantity &&
                                'partiallyDelivered__anchor',
                            ])}
                          >
                            <OrderProductCard
                              item={item}
                              handleFavorite={handleFavorite}
                              product={products[item.product_id]}
                            />
                          </li>,
                        );
                      return acc;
                    }, [])}
                  </ul>
                </section>
              </div>
              {/* <div className="content-layout contact-support-mobile">
                {company.match({
                  circlek: (
                    <a
                      className="button _bordered contact-support-mobile__button"
                      href={`tel:${company.config.phoneNumber}`}
                    >
                      {t('contactSupport')}
                    </a>
                  ),
                  default: company.config.links?.whatsapp && (
                    <a
                      className="button _bordered contact-support-mobile__button"
                      href={company.config.links.whatsapp}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('contactSupport')}
                    </a>
                  ),
                })}
              </div> */}
            </OrderBottomSheet>
          </>
        )}
      </main>
    </div>
  );
});

export default OrderPage;
