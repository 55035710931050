import { Coords } from 'google-map-react';

import { DeliveryZone } from '~/api/DeliveryAddress';
import { DELIVERY_ZONES_STYLE } from '~/components/ModalDeliveryAddress/GMap/constants';
import { mainStore } from '~/stores/MainStore';

export const isInsideZone = (position: Coords, polygon: any): boolean => {
  const result = google.maps.geometry?.poly.containsLocation(
    new google.maps.LatLng(position.lat, position.lng),
    polygon,
  );

  return Boolean(result);
};

export const createPolygonForZone = (
  zone: DeliveryZone,
): google.maps.Polygon => {
  const convert = mainStore.flatArray(
    zone.polygon.segments.map((segment) => {
      return Object.values(segment).map((coord) => ({
        lat: coord.latitude,
        lng: coord.longitude,
      }));
    }),
  );

  const style =
    DELIVERY_ZONES_STYLE[zone.priority as number] || DELIVERY_ZONES_STYLE[100];

  return new google.maps.Polygon({
    paths: convert,
    strokeWeight: 1,
    fillColor: zone.color,
    strokeColor: zone.color,
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    fillOpacity: style.fillOpacity,
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    strokeOpacity: style.strokeOpacity,
  });
};
