import * as Sentry from '@sentry/react';
import React, { ReactNode } from 'react';

type Props = {
  parent?: string;
  children: ReactNode;
};

interface Error {
  stack?: string;
}

export default class SwiperErrorBoundary extends React.Component<
  Props,
  { hasError: boolean }
> {
  children: ReactNode;
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureMessage(
      `[Swiper ${
        parent ?? 'unknown'
      }] Swiper error exception - ${error} - ${errorInfo}`,
      'warning',
    );
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return null;
    }

    return this.props.children;
  }
}
