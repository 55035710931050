export const BREADCRUMBS = [
  {
    label: 'home',
    link: '/',
  },
  {
    label: 'referralProgram',
    link: '/cabinet/referral',
  },
  {
    label: 'referralProgram:rewards',
    link: '/cabinet/referral/rewards',
  },
];
