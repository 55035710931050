import ProductCardSkeleton from '~/components/ProductCard/ProductCardSkeleton';
import SkeletonListRows from '~/components/Skeleton/SkeletonList';

import CategoryBlockTitle from '../CategoryBlockTitle';

import styles from './ProductsGridBlock.module.scss';

const ProductsGridBlockSkeleton = () => {
  return (
    <div className="content-layout">
      <div className={styles.container}>
        <CategoryBlockTitle />
        <div className={styles.grid}>
          <SkeletonListRows rows={3}>
            <ProductCardSkeleton />
          </SkeletonListRows>
        </div>
      </div>
    </div>
  );
};

export default ProductsGridBlockSkeleton;
