import { classes } from 'html-classes';
import { ReactNode } from 'react';

import styles from './AddressAutocompleteDropDown.module.scss';

interface AddressAutocompleteDropDownProps {
  children: ReactNode;
  isOpen: boolean;
  className?: string;
}

const AddressAutocompleteDropDown = ({
  isOpen,
  children,
  className,
}: AddressAutocompleteDropDownProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className={classes([styles.container, className])}>{children}</div>
  );
};

export default AddressAutocompleteDropDown;
