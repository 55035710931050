export const breadcrumbItems = [
  {
    text: 'home',
    link: '/',
  },
  {
    text: 'cabinet',
    link: '/cabinet',
  },
  {
    text: 'referralProgram',
    link: '/cabinet/referral',
  },
];

export const breadcrumbItemsVariant1 = [
  {
    text: 'home',
    link: '/',
  },
  {
    text: 'referralProgram',
    link: '/cabinet/referral',
  },
];

export const steps = [
  {
    step: 1,
    title: 'referralProgram:steps.one.title',
    caption: 'referralProgram:steps.one.caption',
  },
  {
    step: 2,
    title: 'referralProgram:steps.two.title',
    caption: 'referralProgram:steps.two.caption',
  },
  {
    step: 3,
    title: 'referralProgram:steps.three.title',
    caption: 'referralProgram:steps.three.caption',
  },
];
