import { arEG, enGB } from 'date-fns/locale/index.js';
import { useEffect, useState } from 'react';

import { company } from '~/company/Company';
import { getCookieLang } from '~/utils/cookies';

const LOCALES = {
  en: enGB,
  ar: arEG,
};

export const useDateLang = () => {
  const [dateLang, setDateLang] = useState(
    LOCALES[getCookieLang() as keyof typeof LOCALES] ??
      LOCALES[company.config.lang as keyof typeof LOCALES] ??
      enGB,
  );
  const currentLang = getCookieLang();

  useEffect(() => {
    setDateLang(
      LOCALES[currentLang as keyof typeof LOCALES] ??
        LOCALES[company.config.lang as keyof typeof LOCALES] ??
        enGB,
    );
  }, [currentLang]);

  return { locale: dateLang };
};
