import { isAxiosError } from 'axios';
import { makeAutoObservable } from 'mobx';

import {
  CatalogRequests,
  FilterChoiceAPI,
  ProductPropertiesKeyAPI,
  ProductsPagedResponse,
  SortingFieldName,
  SortingOrder,
} from '~/api/Catalog';
import analyticsEventsEmitter, { EventsName } from '~/services/AnalyticsEvents';
import {
  catalogStore,
  ProductFilters,
  ProductPropertyFilter,
  ProductRangeFilter,
} from '~/stores/CatalogStore';
import { Category } from '~/stores/CategoriesStore/Category';
import { Product } from '~/stores/CategoriesStore/Product';
import { mainStore } from '~/stores/MainStore';
import { Pagination } from '~/stores/shared/Pagination';

type Sorting = [SortingFieldName, SortingOrder];

interface ProductParams {
  sorting?: Nullable<Sorting>;
  filters?: Nullable<ProductFilters>;
}

const ignoreParams = [
  'sorting',
  'sortingDir',
  'priceFrom',
  'priceTo',
  'modal',
  'editAddress',
  'page',
];

export class CatalogCategory {
  private category: Nullable<Category> = null;
  private readonly id: string | number;

  private static preloadedData = new Map<string, ProductsPagedResponse>();

  constructor(categoryId: string | number) {
    this.id = categoryId;

    this.init();
    makeAutoObservable(this);
  }

  public static request(
    categoryId: string | number,
    page: number,
    warehouse: string,
    serverLang?: string,
    { filters, sorting }: ProductParams = {},
  ) {
    const params = catalogStore.getProductFilters(
      filters || catalogStore.currentFilters,
      sorting || catalogStore.currentSorting,
      page,
    );

    return CatalogRequests.getProductsPaged(
      String(categoryId),
      {
        warehouseCode: warehouse,
        isSafeRequest: mainStore.isSafeRequest || undefined,
        ...params,
      },
      serverLang,
    );
  }

  public static async preload(
    categoryId: string | number,
    warehouse: string,
    serverLang?: string,
    params?: ProductParams,
  ) {
    const data = await CatalogCategory.request(
      categoryId,
      1,
      warehouse,
      serverLang,
      params,
    );

    CatalogCategory.preloadedData.set(String(categoryId), data);

    return data;
  }

  public static removePreloadedData(categoryId: string | number) {
    CatalogCategory.preloadedData.delete(String(categoryId));
  }

  public static parseSearchParams(
    search: URLSearchParams | string,
  ): ProductParams {
    try {
      const searchParams = toSearchParams(search);
      const sorting = parseSorting(searchParams);
      const properties = parseProperties(searchParams);
      const price = parsePrice(searchParams);

      return {
        sorting,
        filters: { properties, price },
      };
    } catch {
      return {};
    }
  }

  public get data(): Nullable<Omit<Category, 'products' | 'productsRequest'>> {
    return this.category;
  }

  public get products() {
    return this.paginationRequest.result;
  }

  public get types(): FilterChoiceAPI[] {
    if (!this.category) {
      return [];
    }

    return this.category.subcategory.map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    }));
  }

  public get isNotFound() {
    return isAxiosError(this.paginationRequest.lastError)
      ? this.paginationRequest.lastError.response?.status === 404
      : false;
  }

  public get isReady() {
    return Boolean(this.category);
  }

  public readonly paginationRequest = new Pagination(
    async (page, warehouse: string) => {
      const isSSR = typeof window === 'undefined';
      if (!isSSR) {
        delete window.__INITIAL_STATE__?.catalogCategory;
      }
      const { data, pagination } = await CatalogCategory.request(
        this.id,
        page,
        warehouse,
      );

      this.category = new Category(data);

      analyticsEventsEmitter.emit(EventsName.VIEW_ITEM_LIST, {
        item_list_name: this.category.name,
        item_list_id: this.category.id,
      });

      return {
        pagination,
        data: data.products?.map((data) => new Product(data)) || [],
      };
    },
    { debounce: 700 },
  );

  private init() {
    const isSSR = typeof window === 'undefined';
    const preloadedData =
      (!isSSR ? window.__INITIAL_STATE__?.catalogCategory : null) ||
      CatalogCategory.preloadedData.get(String(this.id));

    if (!preloadedData || preloadedData.data.category.slug !== this.id) {
      return;
    }

    const { data, pagination } = preloadedData;

    this.category = new Category(data);
    this.paginationRequest.setState({
      pagination,
      data: data.products?.map((data) => new Product(data)) || [],
    });
  }
}

const parseProperties = (searchParams: URLSearchParams) => {
  return Array.from(searchParams.keys()).reduce((acc, key) => {
    if (ignoreParams.includes(key)) {
      return acc;
    }

    return {
      ...acc,
      [key as ProductPropertiesKeyAPI]: searchParams
        .getAll(key)
        .flatMap((param) => param.split(',')),
    };
  }, {} as ProductPropertyFilter);
};

const parsePrice = (searchParams: URLSearchParams) => {
  const price: ProductRangeFilter = {};
  const priceFrom = +String(searchParams.get('priceFrom'));
  const priceTo = +String(searchParams.get('priceTo'));

  if (priceFrom) {
    price.from = priceFrom;
  }

  if (priceTo) {
    price.to = priceTo;
  }

  return price;
};

const parseSorting = (searchParams: URLSearchParams) => {
  const sorting = searchParams.get('sorting');
  const sortingDir = searchParams.get('sortingDir');

  if (sorting && sortingDir) {
    return [sorting, sortingDir] as [SortingFieldName, SortingOrder];
  }
};

const toSearchParams = (url: string | URLSearchParams): URLSearchParams => {
  if (url instanceof URLSearchParams) {
    return url;
  }

  const search = url.split('?')[1];

  return new URLSearchParams(search);
};
