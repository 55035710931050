import { motion } from 'framer-motion';
import { classes } from 'html-classes';
import { forwardRef } from 'react';

import styles from './bottomSheetDrawer.module.scss';
import { useSheetContext, useSheetScrollerContext } from './context';
import { useDragConstraints } from './hooks';
import { SheetDraggableProps } from './types';
import { mergeRefs } from './utils';

const SheetContent = forwardRef<any, SheetDraggableProps>(
  ({ children, style, disableDrag, className = '', ...rest }, ref) => {
    const sheetContext = useSheetContext();
    const sheetScrollerContext = useSheetScrollerContext();
    const { constraintsRef, onMeasureDragConstraints } = useDragConstraints();

    const dragProps =
      disableDrag || sheetScrollerContext.disableDrag
        ? undefined
        : sheetContext.dragProps;

    return (
      <motion.div
        {...rest}
        ref={mergeRefs([ref, constraintsRef])}
        className={classes([
          'react-modal-sheet-content',
          className,
          styles.content,
        ])}
        style={{ ...style }}
        {...dragProps}
        dragConstraints={constraintsRef}
        onMeasureDragConstraints={onMeasureDragConstraints}
      >
        {children}
      </motion.div>
    );
  },
);

SheetContent.displayName = 'SheetContent';

export default SheetContent;
