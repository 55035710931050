import { zodResolver } from '@hookform/resolvers/zod';
import { classes } from 'html-classes';
import { t } from 'i18next';
import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';

import Icon from '~/components/Icon/Icon';
import InputText from '~/components/InputText/InputText';
import {
  OnboardingValidationSchema,
  OnboardingSchema,
} from '~/utils/validators';

import styles from './ContentBottomSheet.module.scss';

type ContentBottomSheetProps = {
  onNextPress: () => void;
  onPrevPress: () => void;
  onLaterPress: () => void;
  onSubmitForm: (data: { name: string; email: string }) => void;
  title: string;
  description: string;
  currentPage: number;
  isVisibleInputs: boolean;
  buttonsHeader: ReactElement | boolean;
};

const ContentBottomSheet = ({
  onNextPress,
  onPrevPress,
  onSubmitForm,
  onLaterPress,
  title,
  description,
  currentPage,
  isVisibleInputs,
  buttonsHeader,
}: ContentBottomSheetProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardingSchema>({
    resolver: zodResolver(OnboardingValidationSchema),
  });
  const firstNameProps = register('name');
  const emailProps = register('email');
  const hasContent = isVisibleInputs || title || description;
  return (
    <div
      className={classes([styles.wrapper, isVisibleInputs && styles.shadowed])}
    >
      <div
        className={classes([
          styles.container,
          !title && !description && styles.fullWidth,
        ])}
      >
        {hasContent && (
          <div className={styles.contentContainer}>
            {title && <div className={styles.title}>{title}</div>}
            {description && (
              <div className={styles.contentText}>{description}</div>
            )}
            {isVisibleInputs && (
              <form>
                <InputText
                  classNameWrapper={styles.inputWrapper}
                  classNameInput={styles.input}
                  placeholder={t('onboarding:sign_up:first_name_placeholder')}
                  externalLabel={t('onboarding:sign_up:first_name_label')}
                  error={errors.name?.message ?? ''}
                  refObj={firstNameProps.ref}
                  {...firstNameProps}
                />
                <InputText
                  classNameWrapper={styles.inputWrapper}
                  classNameInput={styles.input}
                  placeholder={t('onboarding:sign_up:email_placeholder')}
                  externalLabel={t('onboarding:sign_up:email_label')}
                  error={errors.email?.message ?? ''}
                  refObj={emailProps.ref}
                  {...emailProps}
                />
              </form>
            )}
          </div>
        )}
        {buttonsHeader && (
          <div
            className={classes([
              styles.buttonsHeader,
              isVisibleInputs && styles.isLarge,
            ])}
          >
            {buttonsHeader}
          </div>
        )}
        <div
          className={classes([
            styles.buttonsContainer,
            isVisibleInputs && styles.isLarge,
            buttonsHeader && styles.new,
          ])}
        >
          {currentPage > 0 && (
            <button
              onClick={isVisibleInputs ? onLaterPress : onPrevPress}
              className={classes([styles.buttonPrev, 'button _bordered _med'])}
            >
              {isVisibleInputs ? (
                t('onboarding:later_btn')
              ) : (
                <Icon type="arrow" className={styles.buttonPrevIcon} />
              )}
            </button>
          )}
          <button
            onClick={isVisibleInputs ? handleSubmit(onSubmitForm) : onNextPress}
            className={classes([
              styles.buttonNext,
              currentPage === 0 && styles.fullWidth,
              'button _primary _med',
            ])}
          >
            {isVisibleInputs ? (
              t('onboarding:sign_up_btn')
            ) : (
              <>
                {t('onboarding:next_btn')} <Icon type="arrow" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContentBottomSheet;
