import { sortBy } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import QRApp from '~/assets/img/qr_footer.png';
import { company } from '~/company/Company';
import { IconTypes } from '~/components/Icon/IconTypes';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { userStore } from '~/stores/UserStore';

import Icon from '../Icon/Icon';
import { ModalType } from '../Modal/interface';

import styles from './LayoutCD.module.scss';
import { PaymentMethodsList } from './PaymentMethodsList';

interface SeoItem {
  order: number;
  title: string;
  caption: string;
  icon?: string;
}

export const LayoutCD = observer(() => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const handleReferClick = (event) => {
    if (!userStore.isAuthorized) {
      openModal(ModalType.Auth, 'footer', { redirect: '/cabinet/referral' });
      event.preventDefault();
    }
  };
  const seoItems: Array<SeoItem> = [
    {
      order: 0,
      title: 'Shop Alcohol in Abu Dhabi & Enjoy the Fastest Delivery in Town',
      caption:
        'At CityDrinks, your good time is our mission. We’re tastemakers and party-starters.\nWe’re your personal concierge for life’s everyday celebrations. Cheers to convenience,\ncheers to exceptional choices with CityDrinks, your trusted liquor store in Abu Dhabi.',
    },
    {
      order: 1,
      icon: 'express-fill',
      title: 'Lightning-Fast Delivery',
      caption:
        'Get your drinks under 2 hours,\nwith some orders eligible\nto arrive within 15 minutes.',
    },
    {
      order: 2,
      icon: 'shop-building',
      title: 'On-Hand Stocks',
      caption:
        'We take pride in offering the most\nreliable stock availability, because\nfrankly, no one likes an empty glass.',
    },
    {
      order: 3,
      icon: 'empty-wallet',
      title: 'Premium, But Not Pricey',
      caption:
        'Top-shelf drinks for the friendliest\nprice. Unlock even more savings\nwhen you shop via our app!',
    },
  ];

  const downloadAppIcons = (
    <div className={styles.footer_app}>
      <a
        href={company.config.appStoreUrl.ios}
        className={styles.footer_app__item}
        target="_blank"
        rel="noreferrer"
      >
        <Icon type="apple" size={28} />
      </a>
      <a
        href={company.config.appStoreUrl.android}
        className={styles.footer_app__item}
        target="_blank"
        rel="noreferrer"
      >
        <Icon type="gplay" size={28} />
      </a>
    </div>
  );

  return (
    <footer className={styles.footer} data-company={company.name}>
      <div className="content-layout">
        <div className={styles.footer__content}>
          <div className={styles.footer__inner}>
            <div className={styles.footer__innerTop}>
              {sortBy(seoItems, 'order').map((item) => (
                <div className={styles.footer__innerTop__item} key={item.order}>
                  <p>
                    {item.icon && <Icon type={item.icon as IconTypes} />}
                    {item.title}
                  </p>
                  {isMobile && item.order === 0 ? (
                    <span>{item.caption}</span>
                  ) : (
                    item.caption
                      .split('\n')
                      .map((line) => <span key={line}>{line}</span>)
                  )}
                </div>
              ))}
            </div>
            <div className={styles.footer__innerBottom}>
              <Link to="/" className={styles.footer_logo} />
              <div className={styles.footer_contacts}>
                {company.config.phoneNumber && (
                  <a
                    href={`tel:${company.config.phoneNumber}`}
                    className={styles.footer_contacts__phone}
                  >
                    {company.config.phoneNumber}
                  </a>
                )}
                <div className={styles.footer_contacts__info}>
                  {t('phrases:contactUsIfQuestion')}
                </div>
                <div className={styles.footer_social}>
                  {company.config.links.instagram && (
                    <a
                      href={company.config.links.instagram}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.footer_social__item}
                    >
                      <Icon type="insta" size={20} />
                    </a>
                  )}
                  {company.config.links.facebook && (
                    <a
                      href={company.config.links.facebook}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.footer_social__item}
                    >
                      <Icon type="fb" size={20} />
                    </a>
                  )}
                  {company.config.links.tiktok && (
                    <a
                      href={company.config.links.tiktok}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.footer_social__item}
                    >
                      <Icon type="tiktok" size={20} />
                    </a>
                  )}
                  {company.config.links.youtube && (
                    <a
                      href={company.config.links.youtube}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.footer_social__item}
                    >
                      <Icon type="youtube" size={20} />
                    </a>
                  )}
                </div>
              </div>

              {isMobile && (
                <div className={styles.footer__mobileApp}>
                  <div className={styles.footer__mobileApp__info}>
                    {t('phrases:downloadOurApp')}
                  </div>
                  {downloadAppIcons}
                </div>
              )}

              <div className={styles.footer_menu}>
                <div className={styles.footer_menu__col}>
                  <div className={styles.footer_menu__title}>
                    {t('company')}
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to="/our-story">{t('ourStory')}</Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to="/brand">{t('brand')}</Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to="/contact-us">{t('contactUs')}</Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to="/careers">{t('careers')}</Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to={'/legals'} state={{ source: 'home' }}>
                      {t('legals')}
                    </Link>
                  </div>
                </div>
                <div className={styles.footer_menu__col}>
                  <div className={styles.footer_menu__title}>{t('media')}</div>
                  {/* <div className={styles.footer_menu__item}>
                    <Link to="/press">{t('press')}</Link>
                  </div> */}
                  <div className={styles.footer_menu__item}>
                    <Link to="/influencers">{t('influencers')}</Link>
                  </div>
                </div>
                <div className={styles.footer_menu__col}>
                  <div className={styles.footer_menu__title}>
                    {t('information')}
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to="/shipping-return">{t('policies')}</Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to="/refund">{t('refundPolicy')}</Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to={'/faq'}>{t('faq')}</Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link to={'/cabinet/referral'} onClick={handleReferClick}>
                      {t('referAfriend')}
                    </Link>
                  </div>
                  <div className={styles.footer_menu__item}>
                    <Link
                      to={company.config.links.blog || ''}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('blog')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <PaymentMethodsList classNames={styles.footer__paymentMethods} />
          </div>
          <div className={styles.footer__qr}>
            <img src={QRApp} alt="" className={styles.footer__qr__img} />
            <div className={styles.footer__qr__info}>
              {t('phrases:pointYourCamera')}
            </div>
            {downloadAppIcons}
          </div>
        </div>
        <div className={styles.footer_bottom}>
          <div className={styles.footer_copyright}>
            <h3>&copy; {t('phrases:generalTrading')}</h3>
            <p>{t('copyRightAddress:line1')}</p>
            <p>{t('copyRightAddress:line2')}</p>
          </div>
          <div className={styles.footer_license}>
            {t('phrases:licenseNumber')}
            <br />
            {t('phrases:taxRegistrationNumber')}
          </div>
        </div>
      </div>
    </footer>
  );
});
