import { classes } from 'html-classes';
import { CSSProperties, JSX, MouseEvent, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { company } from '~/company/Company';
import Icon from '~/components/Icon/Icon';
import { userStore } from '~/stores/UserStore';

import {
  SHEET_FOOTER_HEIGHT,
  SHEET_HEADER_HEIGHT,
  SHEET_HEIGHT,
} from './constants';
import { MobileActionDialogProps } from './interfaces';
import styles from './MobileActionDialog.module.scss';

const MobileActionDialog = (
  props: MobileActionDialogProps,
): JSX.Element | null => {
  const {
    open,
    title,
    footer,
    headerHeight = SHEET_HEADER_HEIGHT,
    contentHeight = SHEET_HEIGHT,
    footerHeight = footer ? SHEET_FOOTER_HEIGHT : '0px',
    className,
    onClose,
    children,
  } = props;
  const cardRef = useRef<HTMLDivElement | null>(null);

  const handleBackdropClick = (e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (!cardRef.current?.contains(target)) {
      onClose?.(e);
    }
  };

  const buildSheetStyleConfig = (): CSSProperties =>
    ({
      '--sheet-height': contentHeight,
      '--header-height': headerHeight,
      '--footer-height': footerHeight,
    }) as CSSProperties;

  const content = (
    <section
      className={styles.root}
      style={buildSheetStyleConfig()}
      onClick={handleBackdropClick}
      data-company={company.name}
      dir={userStore.dir}
    >
      <div className={styles.card} ref={cardRef}>
        <div className={styles.header}>
          {title && <div className={styles.headerText}>{title}</div>}
          {onClose && (
            <span className={styles.close} onClick={onClose} role="button">
              <Icon type="close" size={24} />
            </span>
          )}
        </div>
        <div className={classes([styles.content, className])}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </section>
  );

  useEffect(() => {
    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    if (open && !document.body.classList.contains(styles.noScroll)) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      document.body.classList.add(styles.noScroll);
    }

    // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
    if (!open && document.body.classList.contains(styles.noScroll)) {
      // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
      document.body.classList.remove(styles.noScroll);
    }
  }, [open]);

  if (!open) {
    return null;
  }
  return createPortal(content, document.body);
};

export default MobileActionDialog;
