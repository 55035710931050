import { useDebouncedEffect } from '@react-hookz/web';
import IMask from 'imask';
import Masked from 'imask/esm/masked/base';
import { FactoryOpts } from 'imask/esm/masked/factory';
import { observer } from 'mobx-react-lite';
import {
  ChangeEvent,
  KeyboardEvent,
  SyntheticEvent,
  ClipboardEvent,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AnyMasked } from 'react-imask';

import { mainStore } from '~/stores/MainStore';

import InputText from '../InputText/InputText';

import { DEFAULT_PLACEHOLDER } from './constants';

export type InputPhoneProps = {
  value: string;
  label: string;
  onValidate: (flag: boolean) => void;
  onChange?: (val: string) => void;
  onFocus?: (val: string) => void;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  maxLength?: number;
};

const maskData: ((Masked | FactoryOpts) & { startsWith: string })[] = [
  {
    mask: '+00 (0000) 000-000',
    startsWith: '44',
    lazy: false,
  },
  {
    mask: '+0 (000) 000-00-00',
    startsWith: '7',
    lazy: false,
  },
  {
    mask: '+000 (00) 000 0000',
    startsWith: '971',
    lazy: false,
  },
  {
    mask: '+0000000000[00000]',
    startsWith: '',
  },
];

const maskDispatch = (
  appended: string,
  dynamicMasked: AnyMasked,
): AnyMasked => {
  const number = (dynamicMasked.value + appended).replace(/\D/g, '');
  return dynamicMasked.compiledMasks.find(
    (m: AnyMasked) => number.indexOf(m.startsWith) === 0,
  );
};

const validatePhoneValue = (ASCIICode: number, value: string): boolean => {
  // 43 - is a `+` key
  if (ASCIICode === 43) {
    return !value.includes('+');
  }

  // If the ASCII code of the pressed key is not a number
  // Preventing event.
  return !(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57));
};

const validateCountryCodePlus = (value: string): string => {
  if (!value || value === '+') {
    return value;
  }

  if (!value.startsWith('+')) {
    return `+${value}`;
  }

  const plusRemoved = value.replace(/\+/gi, '');
  return `+${plusRemoved}`;
};

const InputPhone = ({
  value,
  label,
  onValidate,
  onChange,
  onFocus,
  placeholder = DEFAULT_PLACEHOLDER,
  className,
  disabled = false,
  autoFocus,
  maxLength,
}: InputPhoneProps) => {
  const isDevEnv = mainStore.environment !== 'production';
  const { t } = useTranslation();

  const [isValid, setIsValid] = useState(false);
  const [validationError, setValidationError] = useState('');
  const handleChange = (e: SyntheticEvent) => {
    if (!onChange) {
      return;
    }

    const target = e.target as HTMLInputElement;
    const value = target.value.replace(/\s/gi, '');

    onChange(validateCountryCodePlus(value));
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const ASCIICode = e.key.charCodeAt(0);

    if (!validatePhoneValue(ASCIICode, target.value)) {
      e.preventDefault();
      return false;
    }
  };

  const handlePaste = (e: ClipboardEvent) => {
    const clipboardData = e.clipboardData.getData('text/plain');

    e.preventDefault();

    const formatData = clipboardData.replace(/[\s+]/gi, '');
    const value = Array.from(formatData)
      .filter((letter) => {
        const ASCIICode = letter.charCodeAt(0);
        return validatePhoneValue(ASCIICode, formatData);
      })
      .join('');
    onChange?.(validateCountryCodePlus(value));
  };

  useDebouncedEffect(
    () => {
      if (!value) {
        onValidate(false);
        setIsValid(false);
        setValidationError('');
        return;
      }

      if (!isDevEnv && (value.startsWith('0') || value.startsWith('+0'))) {
        onValidate(false);
        setIsValid(false);
        setValidationError(t('prodPhoneValidationError'));
        return;
      } else {
        setValidationError('');
      }

      const masked = IMask.createMask({
        mask: maskData,
        dispatch: maskDispatch,
      });

      masked.resolve(value);
      onValidate(masked.isComplete);
      setIsValid(masked.isComplete);
    },
    [value],
    200,
  );
  return (
    <InputText
      type="tel"
      className={className}
      label={label}
      value={value}
      placeholder={placeholder}
      maxLength={maxLength}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      disabled={disabled}
      onFocus={(e: ChangeEvent<HTMLInputElement>) => {
        onFocus?.(e.target.value);
      }}
      autoFocus={autoFocus}
      isValid={isValid}
      error={validationError}
      onPaste={handlePaste}
    />
  );
};

export default observer(InputPhone);
