import { classes } from 'html-classes';
import { ReactNode } from 'react';

interface TileProps {
  className?: string;
  children?: ReactNode;
  size?: 's' | 'm' | 'l';
}

const Tile = ({ className, children, size = 's' }: TileProps) => {
  return (
    <div className={classes(['tile', `size-${size}`, className])}>
      {children}
    </div>
  );
};

export default Tile;
