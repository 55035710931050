import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { awsPaymentStore } from '~/stores/AwsPaymentStore';
import { orderStore } from '~/stores/OrderStore';

import { useModal } from '../../hooks/useModal';

import Icon from '../Icon/Icon';

export default observer(() => {
  const { closeModal } = useModal();
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

  const backHandler = () => closeModal();

  useEffect(() => {
    const element = document.getElementsByClassName('modal_3ds')[0];
    const positionInfo = element.getBoundingClientRect();
    const height = positionInfo.height;
    const width = positionInfo.width;
    setDimensions({ width: width - 64, height: height - 70 });

    return () => {
      awsPaymentStore.setPostMessageReceived(false);
      awsPaymentStore.setIsActive(false);
      awsPaymentStore.setIsIframeLoading(false);
    };
  }, []);

  return (
    <div className="popover-container">
      <button
        className="button _no-padding _no-color input-form__back"
        onClick={backHandler}
      >
        <Icon type="close" className="icon__rotate-180" size={24} />
      </button>
      <div className="popover">
        <iframe
          frameBorder="0"
          border="0"
          cellSpacing="0"
          width={dimensions.width}
          src={orderStore.url3Ds}
          height={dimensions.height}
        />
      </div>
    </div>
  );
});
