import { differenceInDays } from 'date-fns';
import { format } from 'date-fns-tz';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { JSX, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';
import { OrderStatusStore } from '~/stores/OrderStore';

import { normalizeOrderId } from '../../../utils/normalizeOrderId';

import Icon from '../../Icon/Icon';

import styles from './ActiveOrderCard.module.scss';
import { DATE_FORMAT, ORDER_STATUS, TIME_FORMAT } from './constants';
import { IActiveOrderCardProps } from './interfaces';

const ActiveOrderCard = (props: IActiveOrderCardProps): JSX.Element => {
  const { order, className } = props;
  const [publicStatus, setPublicStatus] =
    useState<OrderStatusStore>('accepted');
  const { t } = useTranslation();
  const { locale } = useDateLang();

  useEffect(() => {
    let status: OrderStatusStore;
    switch (order.public_status) {
      case 'in_delivery':
        status = 'inDelivery';
        break;
      case 'ready_to_ship':
        status = 'readyToShip';
        break;
      case 'ready_for_pickup':
        status = 'readyForPickup';
        break;
      default:
        status = order.public_status;
        break;
    }
    setPublicStatus(status);
  }, []);

  const normalizedOrderId = normalizeOrderId(order.order_id);

  const estimatedTime = useMemo(() => {
    const estimationDate = new Date(order.sla.deliver_by_max);
    const difference = differenceInDays(new Date(), estimationDate);

    if (!difference) {
      return t('phrases:todayAt', {
        time: format(estimationDate, TIME_FORMAT, {
          timeZone: company.config.timeZone,
          locale,
        }),
      });
    }

    return format(estimationDate, `${DATE_FORMAT} 'at' ${TIME_FORMAT}`, {
      timeZone: company.config.timeZone,
      locale,
    });
  }, [order, t, locale]);

  return (
    <Link
      to={`/order/${order.id}`}
      className={classes([styles.deliveringWrapper, className])}
      data-company={company.name}
    >
      <div className={styles.blockOne}>
        <div className={styles.leftBlock}>
          <div className={styles.orderTitleWrapper}>
            <div className={styles.title}>
              {ORDER_STATUS[publicStatus] && (
                <Icon type={ORDER_STATUS[publicStatus]!.icon} size={24} />
              )}
              {t(`orderStatus:${order.public_status}.value`)}
            </div>
            <div className={styles.track}>
              <span className={styles.trackNumber}>#{normalizedOrderId}</span>{' '}
              {format(new Date(order.created_at), 'dd.MM', {
                timeZone: company.config.timeZone,
                locale,
              })}
            </div>
          </div>
          <div className={styles.delivering} />
        </div>
        <div className={styles.content}>
          <div className={styles.rightBlock}>
            <div className={styles.estimateWrapper}>
              <Icon type="time-fill" size={16} />
              <div className={styles.text}>
                <span>
                  {t('phrases:estTimeOfDeliveryShort')}: {estimatedTime}
                </span>
              </div>
            </div>
          </div>
          <div className={styles.rightBlock}>
            <div className={styles.estimateWrapper}>
              <Icon type="location-fill-pin" size={16} />
              <div className={classes([styles.text, styles.caption])}>
                <span>{order.address?.address_1}</span>
                <span className={styles.short}>{order.address?.address_1}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/cabinet/referral" className={styles.inviteWrapper}>
        <div className={styles.text}>{t('phrases:referFriend')}</div>
      </Link>
    </Link>
  );
};

export default observer(ActiveOrderCard);
