import { classes } from 'html-classes';
import React from 'react';

import styles from './CartDeliveryStatus.module.scss';

export const CartDeliveryStatus = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className={styles.expressUnavailable}>{children}</div>;
};

const Image = ({ src }: { src?: string }) => {
  if (!src) {
    return null;
  }

  return <img className={styles.expressUnavailableImage} src={src} />;
};

const Text = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.textContainerMain}>{children}</div>;
};

const Header = ({
  children,
  isAllHeader,
}: {
  children: React.ReactNode;
  isAllHeader?: boolean;
}) => {
  return (
    <p
      className={classes([
        styles.expressUnavailableHeader,
        isAllHeader && styles.allHeader,
      ])}
    >
      {children}
    </p>
  );
};

const Description = ({ children }: { children: React.ReactNode }) => {
  return <p className={styles.expressUnavailableDescription}>{children}</p>;
};

const Content = ({ children }: { children: React.ReactNode }) => {
  return <div className={styles.textContainer}>{children}</div>;
};

const Info = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <button className={styles.textContainerInfo} onClick={() => onClick?.()}>
      {children}
    </button>
  );
};

CartDeliveryStatus.Image = Image;
CartDeliveryStatus.Content = Content;
CartDeliveryStatus.Text = Text;
CartDeliveryStatus.Header = Header;
CartDeliveryStatus.Description = Description;
CartDeliveryStatus.Info = Info;
