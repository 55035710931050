import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import Icon from '~/components/Icon/Icon';
import { catalogStore } from '~/stores/CatalogStore';
import { pollingBuilder } from '~/utils/pollingBuilder';

import { POLLING_TIMEOUT } from './constants';
import styles from './PromocodeNotifications.module.scss';

const PromocodeNotifications = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [closed, setClosed] = useState<boolean>(false);
  const stopWatchRef = useRef<(() => void) | null>(null);

  const isOnRewardedPromocodesPage = pathname === '/cabinet/referral/rewards/';
  const promotion = catalogStore.promotionStore;

  useEffect(() => {
    promotion.getRewardedPromocodesStatus();

    stopWatchRef.current = pollingBuilder({
      promiseBuilder: () => promotion.getUnseenPromoRewards(),
      conditionFn: (response) => {
        if (response === undefined) {
          return true;
        }

        setClosed(false);

        if (response > 0 && isOnRewardedPromocodesPage) {
          promotion.getRewardedPromocodesList();
        }

        return true;
      },
      timeout: POLLING_TIMEOUT,
    });

    return () => {
      stopWatchRef.current?.();
    };
  }, [isOnRewardedPromocodesPage]);

  const handleDismiss = () => {
    setClosed(true);
  };

  const isShowNotification =
    promotion.unseenAwardedPromocodesCount > 0 && !closed;

  return (
    <>
      {isShowNotification && (
        <div
          className={classes(['alert', '_fixed', '_success', styles.root])}
          onClick={handleDismiss}
        >
          <div className={styles.message}>
            <Icon type="promocode" />
            <span className={styles.message__text}>
              {t('referralProgram:unseenPromocodeNotification')}
            </span>
          </div>

          {!isOnRewardedPromocodesPage && (
            <Link
              to="/cabinet/referral/rewards"
              role="button"
              className={styles.goToButton}
            >
              {t('goTo')}
            </Link>
          )}
        </div>
      )}
    </>
  );
};

export default observer(PromocodeNotifications);
