import { toJS } from 'mobx';
import { useEffect } from 'react';

import {
  deliveryAddressStore,
  mapCenter,
} from '~/components/ModalDeliveryAddress/DeliveryAddressStore';
import { MODAL_EDIT_ADDRESS_PARAM } from '~/hooks/useModal';
import { orderStore } from '~/stores/OrderStore';
import { UserStore } from '~/stores/UserStore';

const useModalEditEffect = ({
  params,
  userStore,
}: {
  params: URLSearchParams;
  userStore: UserStore;
}) => {
  useEffect(() => {
    if (params.has(MODAL_EDIT_ADDRESS_PARAM)) {
      const address = userStore.addressList.find(
        ({ id }) => id === params.get(MODAL_EDIT_ADDRESS_PARAM),
      );
      const deliveryAddress = userStore.deliveryAddress;

      if (!address && !deliveryAddress) {
        return;
      }

      const coords = address
        ? {
            lat: address.latitude,
            lng: address.longitude,
          }
        : {
            lat: deliveryAddress?.coordinates.lat,
            lng: deliveryAddress?.coordinates.lng,
          };
      const addressType = address?.type ?? 'home';
      const addressComment = address?.comment ?? deliveryAddress?.comment;
      const addressInstructions =
        address?.instructions ?? deliveryAddress?.instructions;
      const addressId = address?.id;
      const addressStreet =
        address?.street_address_2 ?? deliveryAddress?.address2;
      const addressCountry = address?.country_code ?? deliveryAddress?.country;
      const addressZip = address?.postcode ?? deliveryAddress?.zip;
      const addressShort =
        address?.street_address_1 ?? deliveryAddress?.address1;
      const addressCity = address?.city ?? deliveryAddress?.city;
      const addressBld = address?.building ?? deliveryAddress?.addressBld;
      const addressFloor = address?.floor ?? '';
      const addressApt = address?.apartment ?? '';

      const coordinates =
        coords.lat && coords.lng
          ? {
              lat: coords.lat,
              lng: coords.lng,
            }
          : mapCenter;

      const selectedZone = deliveryAddressStore.getSelectedPolygon(coordinates);

      deliveryAddressStore.setEditAddressData({
        addressId: addressId ?? null,
        address2: addressStreet ?? '',
        addressFloor: addressFloor ?? '',
        addressBld: addressBld ?? addressStreet ?? '',
        addressApt: addressApt ?? '',
        type: addressType ?? 'home',
        comment: addressComment ?? '',
        instructions: addressInstructions ?? [],
      });
      deliveryAddressStore.setMapCenter(coordinates);
      deliveryAddressStore.setSelectedZone(selectedZone);

      deliveryAddressStore.setDeliveryAddress({
        address1: addressShort ?? '',
        addressId: addressId ?? '',
        comment: addressComment ?? '',
        address2: addressStreet ?? '',
        addressFloor: addressFloor ?? '',
        addressBld: addressBld ?? addressStreet ?? '',
        addressApt: addressApt ?? '',
        coordinates,
        shortAddress: addressShort ?? '',
        instructions: addressInstructions ?? [],
        city: addressCity ?? '',
        country: addressCountry ?? '',
        placeId: '',
        //  FIXME: what is correct region?
        region: '',
        zip: addressZip ?? '000000',
        type: addressType ?? 'home',
      });

      deliveryAddressStore.setEtaCalculation(null);
      deliveryAddressStore.setInputAddressValue(addressShort ?? '');
      deliveryAddressStore.geocoding(false, coordinates);
      return;
    }

    const center = toJS(userStore.deliveryAddress?.coordinates || mapCenter);
    const selectedZone = deliveryAddressStore.getSelectedPolygon(center);
    deliveryAddressStore.setSelectedZone(selectedZone);
    deliveryAddressStore.setMapCenter(center);
    deliveryAddressStore.setDeliveryAddress(toJS(userStore.deliveryAddress));
    deliveryAddressStore.setEditAddressData({
      addressId: null,
      address2: toJS(userStore.deliveryAddress?.address2) ?? '',
      addressFloor: toJS(userStore.deliveryAddress?.addressFloor) ?? '',
      addressBld: toJS(userStore.deliveryAddress?.addressBld) ?? '',
      addressApt: toJS(userStore.deliveryAddress?.addressApt) ?? '',
      type: toJS(userStore.deliveryAddress?.type) ?? 'home',
      comment: '',
      instructions: [],
    });
    deliveryAddressStore.setEtaCalculation(toJS(orderStore.etaCalculation));
    if (deliveryAddressStore.deliveryAddress?.shortAddress) {
      deliveryAddressStore.setInputAddressValue(
        deliveryAddressStore.deliveryAddress?.shortAddress,
      );
    }
  }, [userStore.addressList.length, userStore.deliveryAddress]);
};

export default useModalEditEffect;
