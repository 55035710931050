import i18n from 'i18next';

import { IconTypes } from '~/components/Icon/IconTypes';
import { OrderStatusStore } from '~/stores/OrderStore';

import { OrderPublicStatus } from '../../../api/Order';

export const STATUS_INDICATORS: OrderPublicStatus[] = [
  'accepted',
  'picking',
  'ready_to_ship',
  'in_delivery',
];

export const ORDER_STATUS: Partial<
  Record<OrderStatusStore, { title: string; icon: IconTypes }>
> = {
  inDelivery: {
    title: i18n.t('orderStatus.in_delivery'),
    icon: 'status-delivering',
  },
  accepted: {
    title: i18n.t('orderStatus.accepted'),
    icon: 'status-delivered',
  },
  readyToShip: {
    title: i18n.t('orderStatus.ready_to_ship'),
    icon: 'status-packaging',
  },
  readyForPickup: {
    title: i18n.t('orderStatus.ready_to_ship'),
    icon: 'status-packaging',
  },
};

export const TIME_FORMAT = 'hh:mm aaaa';
export const DATE_FORMAT = 'MM/dd/yyyy';
