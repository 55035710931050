import { useTranslation } from 'react-i18next';

import { useModal } from '~/hooks/useModal';

import Icon from '../Icon/Icon';
import { ModalType } from '../Modal/interface';

export const ModalEnterDeliveryAddress = () => {
  const { t } = useTranslation();
  const { closeModal, openModal } = useModal();

  const handleEnter = () => {
    openModal(ModalType.DeliveryAddress);
  };

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__back"
          onClick={closeModal}
        >
          <Icon type="arrow" className="icon__rotate-180" size={24} />
        </button>
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={closeModal}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <div className="input-form__form">
          <div className="input-form__form-header">
            <p className="input-form__form-header-title">
              {t('phrases:enterDeliveryAddress')}
            </p>
            <p className="input-form__form-header-subtitle">
              {t('phrases:enterDeliveryAddressDescription')}
            </p>
          </div>
          <div className="input-form__form-footer">
            <div className="input-form__form-footer-buttons">
              <button onClick={handleEnter} className="button _primary _med">
                {t('enter')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
