import { classes } from 'html-classes';

import { company } from '~/company/Company';

import Icon from '../Icon/Icon';

import styles from './Contactinformation.module.scss';

interface СontactInformationProps {
  title?: string;
  content: string;
  email?: string;
  className?: string;
  titleClassName?: string;
  subtitleClassName?: string;
  buttonClassName?: string;
}

const СontactInformation = ({
  title,
  content,
  email,
  className,
  titleClassName,
  subtitleClassName,
  buttonClassName,
}: СontactInformationProps) => {
  return (
    <div className={classes([styles.contactInformation, className])}>
      <h2 className={classes([titleClassName])}>
        {title ?? 'Contact Information'}
      </h2>
      <p
        className={subtitleClassName}
        dangerouslySetInnerHTML={{
          __html:
            content ??
            `If you have any further questions or concerns, <br /> please don't hesitate to contact us at,`,
        }}
      />
      <a
        href={`mailto:${email ?? company.config.email}`}
        className={classes([styles.contactEmail, buttonClassName])}
      >
        <Icon type="envelope" size={24} /> {email ?? company.config.email}
      </a>
    </div>
  );
};

export default СontactInformation;
