import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Payment } from '~/api/Order';
import { useModal } from '~/hooks/useModal';
import CARD_BRANDS from '~/pages/Checkout/cardBrands';
import { paymentStore } from '~/pages/PaymentMethod/PaymentMethod';
import { orderStore } from '~/stores/OrderStore';

import Icon from '../Icon/Icon';

export default observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const [currentMethod, setCurrentMethod] = useState<Payment | null>(null);

  const submitDeleteMethod = async () => {
    if (paymentStore.methodToDelete) {
      await paymentStore.addItemToDelete(paymentStore.methodToDelete);
      backHandler();
    }
  };

  const backHandler = () => {
    paymentStore.setMethodToDelete(null);
    closeModal();
  };

  useEffect(() => {
    const method = orderStore.paymentCards.find(
      ({ id }) => id === paymentStore.methodToDelete,
    );

    if (method) {
      setCurrentMethod(method);
    }
  }, []);

  const { last4, brand } = currentMethod?.card ?? {};
  const cardBrand = brand ? CARD_BRANDS[brand] : undefined;

  return (
    <div className="popover-container input-form">
      <div className="popover">
        <button
          className="button _no-padding _no-color input-form__back"
          onClick={backHandler}
        >
          <Icon type="arrow" className="icon__rotate-180" size={24} />
        </button>
        <button
          className="button _no-padding _no-color input-form__close"
          onClick={backHandler}
        >
          <Icon type="plus" className="icon__rotate-45" size={36} />
        </button>
        <div className="input-form__form">
          <div className="input-form__form-header">
            <p className="input-form__form-header-title">
              {t('phrases:wantToDeleteCard')}
            </p>
            <p className="input-form__form-header-subtitle">
              {cardBrand && cardBrand.title} •• {last4}
            </p>
          </div>
          <div className="input-form__form-footer">
            <div className="input-form__form-footer-buttons">
              <button className="button _bordered _med" onClick={backHandler}>
                {t('goBack')}
              </button>
              <button
                className="button _primary _med"
                onClick={submitDeleteMethod}
              >
                {t('delete')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
