import { company } from '~/company/Company';

export const LOCALE = {
  en: {
    img: company.images.locale?.en,
    title: 'EN',
  },
  ar: {
    img: company.images.locale?.ar,
    title: 'AR',
  },
};
