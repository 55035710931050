import { JSX } from 'react';

import { company } from '~/company/Company';
import Sheet from '~/components/BottomSheetDrawer';

import { OrderBottomSheetProps } from './interfaces';

const OrderBottomSheet = (props: OrderBottomSheetProps): JSX.Element => {
  const { className, children, enabled = true, onChange, withBackdrop } = props;

  if (!enabled) {
    return (
      <div className={className} data-company={company.name}>
        {children}
      </div>
    );
  }

  const onSnap = (id: number) => {
    onChange?.(id === 0);
  };

  return (
    <Sheet
      isOpen={true}
      snapPoints={[0.75, 0.25]}
      initialSnap={1}
      onSnap={onSnap}
    >
      <Sheet.Container>
        <Sheet.Header />

        <Sheet.Content style={{ y: 20 }}>
          <Sheet.Scroller className={className}>{children}</Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop visible={withBackdrop} />
    </Sheet>
  );
};

export default OrderBottomSheet;
