import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { company } from '~/company/Company';
import { mainStore } from '~/stores/MainStore';
import { isIOS } from '~/utils/platform';

import { useGlobal } from './useGlobal';

export const useStoreRedirect = () => {
  const { isMobile } = useGlobal();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const redirectToStore = searchParams.get('store');

  useEffect(() => {
    if (!redirectToStore) {
      return;
    }

    if (!mainStore.isRN && isMobile) {
      window.location.href = isIOS()
        ? company.config.appStoreUrl.ios
        : company.config.appStoreUrl.android;
      searchParams.delete('store');
    } else {
      navigate('/');
      searchParams.delete('store');
    }
  }, [redirectToStore]);
};
