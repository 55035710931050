import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';

import styles from './BannerMainBlock.module.scss';

const BannerMainBlockSkeleton = () => {
  return (
    <div className="content-layout">
      <div className={classes([styles.container, styles.skeleton])}>
        <div className={styles.skeleton__container}>
          <Skeleton className={classes([styles.skeleton__item])} />
        </div>
      </div>
    </div>
  );
};

export default BannerMainBlockSkeleton;
