import { RewarderPromoCode } from '~/api/ReferralProgram';

export const sortByNovelty = (
  a: RewarderPromoCode,
  b: RewarderPromoCode,
): number => {
  if (a.is_new && !b.is_new) {
    return -1;
  }
  if (!a.is_new && b.is_new) {
    return 1;
  }
  return 0;
};
