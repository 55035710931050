import { classes } from 'html-classes';
import { useTranslation } from 'react-i18next';

import styles from './BundleBadge.module.scss';
import { BundleBadgeProps } from './interfaces';

const BundleBadge = (props: BundleBadgeProps) => {
  const { className } = props;
  const { t } = useTranslation();

  return <div className={classes([styles.root, className])}>{t('bundle')}</div>;
};

export default BundleBadge;
