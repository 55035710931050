import { classes } from 'html-classes';
import { useTranslation } from 'react-i18next';

import { OrderPublicStatus } from '~/api/Order';
import Icon from '~/components/Icon/Icon';

import { STATUS_ICONS } from './constants';
import styles from './OrderStatusBadge.module.scss';

const OrderStatusBadge = ({ status }: { status: OrderPublicStatus }) => {
  const { t } = useTranslation();

  return (
    <div className={classes([styles.root, styles[status]])}>
      <Icon type={STATUS_ICONS[status]} size={20} />
      {t(`orderStatus:${status}.value`)}
    </div>
  );
};

export default OrderStatusBadge;
