import { classes } from 'html-classes';
import { times } from 'lodash-es';

import styles from './ProgressIndicator.module.scss';

type ProgressIndicatorProps = {
  stepsNumber: number;
  activeStep: number;
  noPadding: boolean;
};

const ProgressIndicator = ({
  stepsNumber,
  activeStep,
  noPadding,
}: ProgressIndicatorProps) => {
  return (
    <div className={classes([styles.container, noPadding && styles.noPadding])}>
      {times(stepsNumber).map((_, index) => (
        <div
          className={classes([
            styles.dot,
            index === activeStep && styles.active,
            noPadding && styles.noPadding,
          ])}
          key={index}
        />
      ))}
    </div>
  );
};

export default ProgressIndicator;
