import { FC } from 'react';

import image from '~/assets/img/card_disclaimer.png';

export const CardDisclaimer: FC = () => {
  return (
    <div className="card-disclaimer">
      <div className="card-disclaimer__header">
        <div className="card-disclaimer__title">Information</div>
        <img className="card-disclaimer__image" src={image} alt="" />
      </div>
      <div className="card-disclaimer__body">
        Cards issued by Abu Dhabi Islamic Bank cannot be used for payment due to
        restrictions set by the bank. Please use another card or pay for the
        order in cash.
      </div>
    </div>
  );
};
