import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import About_l1 from '~/assets/img/about_l1.png';
import About_l2_1 from '~/assets/img/about_l2_1.png';
import About_l2_2 from '~/assets/img/about_l2_2.png';
import About_l2_3 from '~/assets/img/about_l2_3.png';
import About_l3 from '~/assets/img/about_l3.png';
import About_l4_1 from '~/assets/img/about_l4_1.png';
import About_l4_2 from '~/assets/img/about_l4_2.png';
import About_l4_3 from '~/assets/img/about_l4_3.png';
import About_l5 from '~/assets/img/about_l5.png';
import Cheers from '~/assets/img/cheers.png';
import Logo from '~/assets/img/logo_cd_color.svg';
import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './OurStory.module.scss';

export default observer(() => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useGlobal();
  const { state } = useLocation();
  const source = state?.source || 'home';

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      {isTablet && (
        <MobileHeaderLayout
          content={<MobileHeaderTitle text={t('ourStory')} />}
        />
      )}
      <div className={classes(['content-layout', styles.root])}>
        {!isTablet && (
          <>
            <Breadcrumbs
              items={
                [
                  {
                    text: t('home'),
                    link: '/',
                  },
                  source === 'cabinet' && {
                    text: t('cabinet'),
                    link: '/cabinet',
                  },
                  {
                    text: t('ourStory'),
                    link: '/our-story',
                  },
                ].filter(Boolean) as BreadcrumbsItem[]
              }
            />
          </>
        )}
        <section className={styles.header}>
          <p className={styles.header__welcome}>Welcome to</p>
          <div className={styles.header__title}>
            <img src={Logo} />
          </div>
          <p className={styles.header__description}>
            Your premiere online alcohol delivery platform, and the definitive
            solution to enjoying premium alcoholic beverages in the comfort of
            your own home.
          </p>
          <img src={About_l1} className={styles.image} />
          <div className={styles.text_sub_container}>
            <p
              className={classes([
                styles.header__subdescription,
                styles.text_sub,
              ])}
            >
              Born from a deep love of fine spirits, exquisite wines, and craft
              beers, Citydrinks is headquartered in the bustling heart of Abu
              Dhabi. We proudly provide an extensive range of delectable choices
              that reflect the sophisticated palate of our clientele.
            </p>
          </div>
        </section>
        <section className={styles.content}>
          <article className={styles.content__article}>
            <p className={styles.content__article_first}>
              In the vibrant heart of the Arabian desert
            </p>
            <p className={styles.content__article_second}>
              where tradition blends seamlessly with modernity, CityDrinks
              embodies the spirit of this remarkable city. We bring the world of
              premium beverages to your doorstep, echoing Abu Dhabi&apos;s role
              as a crossroad of global cultures.
            </p>
            <div className={styles.imageGroup}>
              <img src={About_l2_1} className={styles.image} />
              <img src={About_l2_2} className={styles.image} />
              <img src={About_l2_3} className={styles.image} />
            </div>
          </article>
          <article className={styles.content__article}>
            <p className={styles.content__article_first}>
              Behind CityDrinks stands a team of dedicated enthusiasts
            </p>
            <p className={styles.content__article_second}>
              connoisseurs who traverse the globe in search of exceptional
              beverages. We are as passionate about a finely aged whisky as we
              are about a newly released artisanal gin. We believe that every
              drink has a story, and we are here to share those stories with
              you.
            </p>
            <img src={About_l3} className={styles.image} />
          </article>
          <article className={styles.content__article}>
            <p className={styles.content__article_first}>
              We understand that your time is valuable
            </p>
            <p className={styles.content__article_second}>
              That&apos;s why we are committed to offering a seamless online
              shopping experience, complete with swift, reliable delivery.
              Whether you&apos;re hosting a grand event or simply winding down
              after a long day, we ensure that your favourite drinks arrive at
              your door swiftly and securely.
            </p>
            <div className={styles.imageGroup}>
              <img src={About_l4_1} className={styles.image} />
              <img src={About_l4_2} className={styles.image} />
              <img src={About_l4_3} className={styles.image} />
            </div>
          </article>
          <article className={styles.content__article}>
            <p className={styles.content__article_first}>
              At CityDrinks, we believe in responsible enjoyment
            </p>
            <p className={styles.content__article_second}>
              We are dedicated to promoting a culture of moderation and respect
              towards alcohol consumption. While we celebrate the myriad
              pleasures that our beverages can provide, we advocate for their
              mindful, judicious use.
            </p>
            <img src={About_l5} className={styles.image} />
          </article>
          <article className={styles.content__article}>
            <p className={styles.content__article_first}>
              Welcome to CityDrinks
            </p>
            <p className={styles.content__article_second}>
              - your oasis of flavour, discovery, and sophistication. Together,
              let&apos;s toast to the good times. Here&apos;s to an elevated
              drinking experience right at your fingertips, anywhere, anytime.
            </p>
          </article>
          <article className={styles.content__article}>
            <p className={styles.content__article_second}>
              Join us on this exciting journey of taste, tradition, and
              innovation.
            </p>
          </article>
        </section>
        <section className={styles.footer}>
          {isMobile && <img src={Cheers} className={styles.cheersImage} />}
          <div className={styles.foote__content}>
            <p className={styles.footer__fin}>Cheers!</p>
            <p className={styles.footer__sign}>The CityDrinks Team</p>
          </div>
        </section>
      </div>
    </>
  );
});
