import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import cd_card_01 from '~/assets/img/cd_card_01.png';
import cd_card_02 from '~/assets/img/cd_card_02.png';
import cd_card_03 from '~/assets/img/cd_card_03.png';
import cd_card_04 from '~/assets/img/cd_card_04.png';
import CD_Influencer from '~/assets/img/CD_Influencer.png';
import { company } from '~/company/Company';
import ContactInformation from '~/components/ContactInformation';
import FeatureCard from '~/components/FeatureCard';
import { useGlobal } from '~/hooks/useGlobal';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from '../../stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import styles from './Influencers.module.scss';

interface Items {
  img: string;
  title: string;
  description: string;
}

const dataOfBenefits: Items[] = [
  {
    img: cd_card_01,
    title: 'Exclusive Product Collaborations',
    description:
      'Enjoy the opportunity to collaborate on exclusive product launches and promotions.',
  },
  {
    img: cd_card_02,
    title: 'Access to Premium Beverages',
    description:
      'Receive a curated selection of premium beverages to feature on your platforms.',
  },
  {
    img: cd_card_03,
    title: 'Engage Your Audience',
    description:
      'Enhance engagement with your audience by showcasing the artistry behind each drink.',
  },
  {
    img: cd_card_04,
    title: 'Marketing Support',
    description:
      'Receive marketing support, including exclusive content, banners, and promotional materials.',
  },
];

const influencerProgram: Partial<Items>[] = [
  {
    title: 'Apply:',
    description: `Submit your application for the CityDrinks Influencer Program to <a href="mailto:${company.config.marketingEmail}">${company.config.marketingEmail}</a>.`,
  },
  {
    title: 'Approval Process:',
    description:
      'Our team will review your application and notify you upon approval.',
  },
  {
    title: 'Collaborate:',
    description:
      'Collaborate with CityDrinks on exclusive product features, reviews, and promotions.',
  },
  {
    title: 'Showcase Premium Beverages:',
    description:
      'Feature a curated selection of premium beverages on your platforms. ',
  },
];

export default observer(() => {
  const { t } = useTranslation();
  const { isTablet } = useGlobal();
  const { state } = useLocation();
  const source = state?.source || 'home';

  return (
    <>
      <Helmet>
        <meta data-react-helmet="true" name="robots" content="index, follow" />
      </Helmet>
      {isTablet && (
        <MobileHeaderLayout
          content={<MobileHeaderTitle text={t('influencers')} />}
        />
      )}
      <div className={classes(['content-layout', styles.root])}>
        {!isTablet && (
          <>
            <Breadcrumbs
              items={
                [
                  {
                    text: t('home'),
                    link: '/',
                  },
                  source === 'cabinet' && {
                    text: t('cabinet'),
                    link: '/cabinet',
                  },
                  {
                    text: t('influencers'),
                    link: '/influencers',
                  },
                ].filter(Boolean) as BreadcrumbsItem[]
              }
              className={styles.breadcrumbs}
            />
          </>
        )}
        <section className={styles.header}>
          <h1 className={styles.header__welcome}>
            CityDrinks Influencer Program:
            <br /> Elevate Your Influence with Premium Beverages
          </h1>

          <img src={CD_Influencer} className={styles.image} />

          <h3 className={styles.content__article_first}>
            Why Join the CityDrinks Influencer Program?
          </h3>
          <p className={styles.content__article_text}>
            CityDrinks is on the lookout for influencers who share our passion
            for quality beverages. Our Influencer Program offers a unique
            opportunity for influencers to collaborate with us and showcase the
            world of premium spirits, wines, and craft beers to their audience.
          </p>

          <h3 className={styles.content__article_first}>
            Benefits of Being a&nbsp;CityDrinks Influencer:
          </h3>
          <div className={styles.benefits_container}>
            {dataOfBenefits?.map((x: Items, i: number) => (
              <FeatureCard
                key={i}
                imageSrc={x?.img}
                number={Number(i) + 1}
                title={x?.title}
                description={x?.description}
              />
            ))}
          </div>

          <h3 className={styles.content__article_first}>
            How the Influencer Program Works:
          </h3>
          <div className={styles.application_card_cantainer}>
            {influencerProgram?.map((x: Partial<Items>, index: number) => (
              <div key={index} className={styles.application_card}>
                <div className={styles.application_card_content}>
                  <span className={styles.application_card_title}>
                    {x?.title}
                  </span>
                  <span
                    className={styles.application_card_content_text}
                    dangerouslySetInnerHTML={{ __html: x?.description ?? '' }}
                  />
                </div>
              </div>
            ))}
          </div>

          <h3 className={styles.content__article_first}>
            Join Us in Elevating the Drinking Experience!
          </h3>
          <p className={styles.content__article_text}>
            CityDrinks is proud to be the catalyst for creating a community of
            enthusiasts, influencers, and brands that appreciate the artistry
            behind premium beverages. If you&apos;re ready to share your love
            for quality drinks with your audience, we invite you to join our
            Influencer Program.
          </p>

          <ContactInformation
            content={`For influencer program inquiries, <br/> please contact our influencer team at`}
            email={company.config.marketingEmail}
            className={styles.contactInfo}
          />
        </section>
      </div>
    </>
  );
});
