import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CartCalculationOrderResponse } from '~/api/Catalog';
import { company } from '~/company/Company';
import CloudflareResponseImage from '~/components/CloudflareResponsiveImage/CloudflareResponseImage';
import { defaultSrcSetParams } from '~/components/CloudflareResponsiveImage/constants';
import FreeDeliveryProgressBar from '~/components/FreeDeliveryProgressBar/FreeDeliveryProgressBar';
import Icon from '~/components/Icon/Icon';
import { ModalType } from '~/components/Modal/interface';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useDateLang } from '~/hooks/useDateLang';
import { useModal } from '~/hooks/useModal';
import { getSelectedDaySplited } from '~/pages/Checkout/utils';
import { catalogStore } from '~/stores/CatalogStore';
import Delivery from '~/stores/CheckoutStore/Delivery';
import { mainStore } from '~/stores/MainStore';
import { orderStore } from '~/stores/OrderStore';
import { isProductBundle } from '~/types/Product';
import { formatPriceWithCurrency } from '~/utils/formaters';

import styles from './DeliveryMobile.module.scss';
import { useSlots } from './useSlots';

interface DeliveryMobileProps {
  handleSelectDateModal: () => void;
  delivery: Delivery;
  slotNumber?: number;
  order: CartCalculationOrderResponse;
}

const DeliveryMobile = ({
  handleSelectDateModal,
  delivery,
  slotNumber,
  order,
}: DeliveryMobileProps) => {
  const { t } = useTranslation();
  const { handleClickProduct } = useCategoryAnalytics();
  const { locale } = useDateLang();

  let selectedDay, selectedTime;
  if (!delivery.slot) {
    selectedDay = t('phrases:deliveryInMin', {
      time: orderStore.etaCalculation?.duration.range,
    });
  } else {
    [selectedDay, selectedTime] = getSelectedDaySplited(delivery.slot, locale);
  }

  const deliveryItems = catalogStore.selectedCartItems.filter(({ sku }) =>
    delivery.itemsIds.includes(sku),
  );

  const { showSelectTimeButton } = useSlots({ deliveryItems });

  const deliveryPrice = formatPriceWithCurrency(
    mainStore.convertPenceToPounds(order.delivery_info.paid_price),
  );

  const isDeliveryFree = order.is_delivery_free;

  const freeDeliveryThreshold = order.delivery_info.threshold;

  const leftUntilFreeDelivery = mainStore.convertPenceToPounds(
    freeDeliveryThreshold - order.paid_without_delivery,
  );
  const { openModal } = useModal();
  const openDeliveryModal = () => {
    openModal(ModalType.DeliveryInfo, 'checkout');
  };
  const deliveryTooltip = company.isDeliveryInfoModalEnabled && (
    <div onClick={openDeliveryModal} className={styles.deliveryTooltip}>
      <Icon type="info" size={16} />
    </div>
  );
  return (
    <li className={classes(['slot-list__slot', styles.container])}>
      <div className={styles.content}>
        <div className={styles.contentInfo}>
          <div className={styles.contentHeader}>
            {!slotNumber ? (
              <p className={styles.slotType}>
                <span className={styles.slotTypeDay}>
                  <span className={styles.slotTypeLine}>
                    {t('expressDelivery')}
                  </span>
                  {deliveryTooltip}
                </span>
                <span className={styles.slotTypeLine}>{selectedDay}</span>
              </p>
            ) : (
              <p className={styles.slotType}>
                <span className={styles.slotTypeDay}>
                  <span className={styles.slotTypeLine}>{selectedDay}</span>
                  {deliveryTooltip}
                </span>
                <span className={styles.slotTypeLine}>{selectedTime}</span>
              </p>
            )}
            {isDeliveryFree ? (
              <p className={styles.deliveryFree}>{t('deliveryFree')}</p>
            ) : (
              <p className="slot-list__slot-fee">
                {t('deliveryFee')}: {deliveryPrice}
              </p>
            )}
          </div>
          {showSelectTimeButton && (
            <button
              className={styles.selectTimeBtn}
              onClick={handleSelectDateModal}
            >
              {t('selectTime')}
            </button>
          )}
        </div>
        <ul className="slot-list__slot-images">
          <Swiper spaceBetween={4} slidesPerView="auto" modules={[Navigation]}>
            {deliveryItems.map((item) => (
              <SwiperSlide
                className="banner-carousel__item1"
                key={item.previewImageThumb}
              >
                <Link
                  onClick={handleClickProduct(item)}
                  to={
                    `/p/${item.slug}` +
                    (isProductBundle(item) ? '' : `?sku=${item.sku}`)
                  }
                  state={{
                    ...(isProductBundle(item) ? '' : { offerSku: item.sku }),
                  }}
                >
                  <div className="slot-list__slot-images-item">
                    <CloudflareResponseImage
                      src={item.previewImageThumb || ''}
                      srcParams={{ width: 80, height: 110 }}
                      srcSetParams={defaultSrcSetParams}
                      alt={item.name}
                      loading="lazy"
                    />
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </ul>
        {!isDeliveryFree && (
          <FreeDeliveryProgressBar
            leftUntilFreeDelivery={formatPriceWithCurrency(
              leftUntilFreeDelivery,
            )}
            cssWidth={`${
              (order.paid_without_delivery / freeDeliveryThreshold) * 100
            }%`}
          />
        )}
      </div>
    </li>
  );
};

export default observer(DeliveryMobile);
