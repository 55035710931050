import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';

import Icon from '~/components/Icon/Icon';

import styles from './InfoItem.module.scss';

type Props = {
  name: string;
  value?: string;
  additionalValue?: string;
  required?: boolean;
  onClick?: () => void;
};

const InfoItem = ({
  name,
  value,
  additionalValue,
  required = false,
  onClick,
}: Props) => {
  const isAlert = !value && required;

  return (
    <button
      className={classes([
        styles.root,
        onClick && styles.interactive,
        isAlert && styles.alert,
      ])}
      onClick={onClick}
    >
      <div className={styles.content}>
        <p className={styles.contentName}>{name}</p>
        {value && (
          <p className={styles.contentValue}>
            {value}
            {additionalValue && (
              <span
                className={classes([styles.contentValue, styles.additionsl])}
              >
                {additionalValue}
              </span>
            )}
          </p>
        )}
      </div>
      {onClick && <Icon type="chevron" size={24} className={styles.icon} />}
    </button>
  );
};

export default observer(InfoItem);
