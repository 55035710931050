import Skeleton from 'react-loading-skeleton';

import SkeletonListRows from '~/components/Skeleton/SkeletonList';

import CategoryBlockTitle from '../CategoryBlockTitle';

import styles from './CategoriesGridOneSBlock.module.scss';

const CategoriesGridOneSBlockSkeleton = () => {
  return (
    <div className="content-layout">
      <div className={styles.container}>
        <CategoryBlockTitle />
        <div className={styles.grid}>
          <SkeletonListRows rows={4}>
            <Skeleton className={styles.item} />
          </SkeletonListRows>
        </div>
      </div>
    </div>
  );
};

export default CategoriesGridOneSBlockSkeleton;
