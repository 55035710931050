import { classes } from 'html-classes';
import Skeleton from 'react-loading-skeleton';

import SkeletonListRows from '~/components/Skeleton/SkeletonList';

import styles from './Address.module.scss';

const AddressItemsSkeleton = () => {
  return (
    <SkeletonListRows rows={12}>
      <div
        className={classes([styles.itemContainer, 'item-container skeleton'])}
      >
        <Skeleton containerClassName="control" className="control" />
        <Skeleton containerClassName="content" className="content" />
      </div>
    </SkeletonListRows>
  );
};

export default AddressItemsSkeleton;
