import { isToday, isTomorrow } from 'date-fns';
import { format as formatTZ, getTimezoneOffset } from 'date-fns-tz';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';
import { useDateLang } from '~/hooks/useDateLang';
import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';

interface DateItemProps {
  timeStamp: string;
  isDisabled: boolean;
  isChecked: boolean;
  onChange: (value: string) => void;
}

const DateItem = ({
  timeStamp,
  isDisabled,
  isChecked,
  onChange,
}: DateItemProps) => {
  const { t } = useTranslation();
  const { locale } = useDateLang();
  const inputId = useId();

  const offset = getTimezoneOffset(company.config.timeZone);
  const timeStampOffseted = +timeStamp + offset;

  let day = formatTZ(new Date(timeStampOffseted), 'd MMMM', {
    timeZone: company.config.timeZone,
    locale,
  });
  if (isToday(+timeStampOffseted)) {
    day = t('today');
  }
  if (isTomorrow(+timeStampOffseted)) {
    day = t('tomorrow');
  }

  const order = checkoutStore.slotSelectionModalStore.selectedOrder;

  if (!order) {
    throw new Error('Order not found');
  }

  return (
    <li className="list-item">
      <input
        type="radio"
        value={timeStamp}
        id={inputId}
        name="date"
        checked={isChecked}
        onChange={({ target }) => {
          onChange(target.value);
        }}
        disabled={isDisabled}
      />
      <label htmlFor={inputId}>
        <span className="text-primary">{day}</span>
      </label>
    </li>
  );
};

export default DateItem;
