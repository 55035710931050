import { useTranslation } from 'react-i18next';

import Icon from '~/components/Icon/Icon';

import styles from './ResctrictionBlock.module.scss';

type RestrictionBlockProps = {
  currentCount: number;
  laterCount?: number | string;
  date: string;
};
export const RestrictionBlock = ({
  currentCount,
  laterCount,
  date,
}: RestrictionBlockProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Icon type="info" />
      <div className={styles.textBlock}>
        <div>
          {t('phrases:canBeDeliveredFast', {
            count: currentCount,
          })}
        </div>
        <div>
          {t('phrases:canBeDeliveredLater', {
            count: laterCount as number,
            when: date,
          })}
        </div>
      </div>
    </div>
  );
};
