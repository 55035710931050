import { Locale, isToday, isTomorrow } from 'date-fns';
import { format } from 'date-fns-tz';
import { t } from 'i18next';

import { DeliverySlot } from '~/api/ETA';

export const getSelectedDay = (slot: DeliverySlot, locale?: Locale): string => {
  const [selectedDay, selectedTime] = getSelectedDaySplited(slot, locale);

  return `${selectedDay} ${selectedTime}`;
};

export const getSelectedDaySplited = (
  slot: DeliverySlot,
  locale?: Locale,
): [string, string] => {
  const selectedTime = slot
    ? `${format(new Date(slot.delivery_min_time * 1000), 'hh:mm aaaa', {
        timeZone: slot.time_zone,
        locale,
      })}
    -
    ${format(new Date(slot.delivery_max_time * 1000), 'hh:mm aaaa', {
      timeZone: slot.time_zone,
      locale,
    })}`
    : '';

  let selectedDay =
    slot &&
    `${format(new Date(+slot.delivery_min_time * 1000), 'd MMMM', {
      timeZone: slot.time_zone,
      locale,
    })}`;

  if (slot && isToday(new Date(+slot.delivery_min_time * 1000))) {
    selectedDay = t('today');
  } else if (slot && isTomorrow(new Date(+slot.delivery_min_time * 1000))) {
    selectedDay = t('tomorrow');
  }

  return [selectedDay, selectedTime];
};
