import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CartContent from '~/pages/Cart/CartContent';
import EmptyCart from '~/pages/Cart/EmptyCart';
import { catalogStore } from '~/stores/CatalogStore';

import MobileHeaderLayout from '../Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '../Main/MobileHeader/MobileHeaderTitle';

import { useUserVerificationStatusChecker } from './hooks';

const Cart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  useUserVerificationStatusChecker();

  const onBackClick = () => {
    navigate('/');
  };

  return catalogStore.cart.length > 0 ? (
    <>
      <MobileHeaderLayout
        content={
          <MobileHeaderTitle text={t('myCart')} onBackClick={onBackClick} />
        }
      />
      <div className="content-layout">
        <CartContent />
      </div>
    </>
  ) : (
    <EmptyCart />
  );
};

export default observer(Cart);
