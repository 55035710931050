import { classes } from 'html-classes';
import { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { company } from '~/company/Company';

import { MultibuyBadgeProps } from './interfaces';
import styles from './MultibuyBadge.module.scss';

const MultibuyBadge = (props: MultibuyBadgeProps) => {
  const { className, value, maxValue = 99 } = props;

  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);

  const { t } = useTranslation();

  const rootElementRef = useRef<HTMLDivElement | null>(null);

  const getValue = () => {
    if (typeof value !== 'number') {
      return 0;
    }

    if (value >= maxValue) {
      return maxValue;
    }

    return value;
  };

  const renderTooltip = () => {
    if (!isTooltipOpen) {
      return;
    }

    const tooltipText = t('phrases:addMoreToDiscount', { count: value });

    if (typeof document === 'undefined') {
      return (
        <div data-company={company.name} role="tooltip">
          <div className={styles.tooltipRoot}>{tooltipText}</div>
        </div>
      );
    }

    return createPortal(
      <div data-company={company.name} role="tooltip">
        <div className={styles.tooltipRoot} ref={setTooltipPosition}>
          {tooltipText}
        </div>
      </div>,
      document.body,
    );
  };

  const setTooltipPosition = (node: HTMLDivElement | null): void => {
    const rect = rootElementRef.current?.getBoundingClientRect();
    if (!node || !rect) {
      return;
    }

    const nodeRect = node.getBoundingClientRect();

    const top = rect.top - (nodeRect.height + 8);
    const left = rect.left + rect.width / 2 - nodeRect.width / 2;

    node.style.top = top > 0 ? `${top}px` : '0';
    node.style.left = left > 0 ? `${left}px` : '0';
  };

  useEffect(() => {
    if (typeof document === 'undefined') {
      return;
    }

    const listener = () => {
      setIsTooltipOpen(false);
    };

    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [isTooltipOpen, setIsTooltipOpen]);

  return (
    <div
      className={classes([styles.root, className])}
      ref={rootElementRef}
      role="button"
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseOver={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
      data-company={company.name}
    >
      {renderTooltip()}
      {getValue()}+
    </div>
  );
};

export default MultibuyBadge;
