import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { checkoutStore } from '~/stores/CheckoutStore/CheckoutStore';

import { ETADeliveryMethodType } from '../../../api/ETADeliveryMethodType';
import { useModal } from '../../../hooks/useModal';
import { mainStore } from '../../../stores/MainStore';
import { orderStore } from '../../../stores/OrderStore';
import { userStore } from '../../../stores/UserStore';

import { deliveryAddressStore } from '../DeliveryAddressStore';

export const SaveButton = observer(() => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const navigate = useNavigate();
  const handleSaveAddress = async () => {
    deliveryAddressStore.setIsLoading(true);
    let deliveryAddress = toJS(deliveryAddressStore.deliveryAddress);
    const editDeliveryAddress = toJS(deliveryAddressStore.editAddressData);
    const deliveryMethod =
      deliveryAddressStore.getDeliveryMethodBySelectedZone();

    if (!deliveryAddress?.city) {
      mainStore.pushAlert('error', t('phrases:determineAddress'));
      deliveryAddressStore.setIsLoading(false);
      return;
    }

    mainStore.sendToRN('analytics', {
      name: 'General: address changed',
      params: {
        postcode: deliveryAddress?.zip || '',
        warehouse_code:
          deliveryAddressStore.etaCalculation?.warehouse.code || '',
        source: '',
        eta_min: deliveryAddressStore.etaCalculation?.duration.min || 0,
        eta_max: deliveryAddressStore.etaCalculation?.duration.max || 0,
        delivery_fee:
          deliveryAddressStore.etaCalculation?.cost[
            ETADeliveryMethodType.JiffyDelivery
          ]?.shippingPounds || 0,
        threshold:
          deliveryAddressStore.etaCalculation?.cost[
            ETADeliveryMethodType.JiffyDelivery
          ]?.thresholdPounds || 0,
        is_surger: deliveryAddressStore.etaCalculation?.highDemand || false,
        lat: deliveryAddress?.coordinates.lat || '',
        lon: deliveryAddress?.coordinates.lng || '',
      },
    });

    if (!deliveryAddress || !deliveryAddressStore.etaCalculation) {
      mainStore.pushAlert('error', "Your address isn't in a delivery zone yet");
      deliveryAddressStore.setIsLoading(false);
      return;
    }

    const buildAddress2 = (bld?: string, floor?: string, apt?: string) => {
      const parts = [];

      if (bld) {
        parts.push(`Bulding: ${bld}`);
      }
      if (floor) {
        parts.push(`Floor: ${floor}`);
      }
      if (apt) {
        parts.push(`Apartment: ${apt}`);
      }

      return parts.join(', ');
    };

    if (userStore.isAuthorized) {
      if (deliveryAddress.addressId) {
        const updatedAddress = await userStore.updateAddress(
          deliveryAddress.addressId,
          {
            street_address_1: deliveryAddress.address1,
            street_address_2:
              editDeliveryAddress.address2 || deliveryAddress.address2,
            building:
              editDeliveryAddress.addressBld || deliveryAddress.addressBld,
            floor: editDeliveryAddress.addressFloor,
            apartment: editDeliveryAddress.addressApt,
            postcode: deliveryAddress.zip,
            latitude: deliveryAddress.coordinates.lat,
            longitude: deliveryAddress.coordinates.lng,
            type: editDeliveryAddress.type || deliveryAddress.type,
          },
        );

        await userStore.fetchAddresses();

        userStore.setDeliveryAddress({
          ...deliveryAddress,
          ...editDeliveryAddress,
          addressId:
            editDeliveryAddress.addressId ?? deliveryAddress.addressId ?? null,
          address2:
            updatedAddress?.data.street_address_2 ??
            deliveryAddress.address2 ??
            editDeliveryAddress.address2,
          addressBld: updatedAddress?.data.building ?? '',
          addressFloor: updatedAddress?.data.floor ?? '',
          addressApt: updatedAddress?.data.apartment ?? '',
        });

        checkoutStore.setAddress2Val(
          updatedAddress?.data.street_address_2 ??
            deliveryAddress.address2 ??
            editDeliveryAddress.address2,
        );
      } else {
        const address = await userStore.addAddress({
          street_address_1: deliveryAddress.address1,
          street_address_2:
            editDeliveryAddress.address2 || deliveryAddress.address2,
          building:
            editDeliveryAddress.addressBld || deliveryAddress.addressBld,
          floor:
            editDeliveryAddress.addressFloor || deliveryAddress.addressFloor,
          apartment:
            editDeliveryAddress.addressApt || deliveryAddress.addressApt,
          city: deliveryAddress.city,
          postcode: deliveryAddress.zip,
          latitude: deliveryAddress.coordinates.lat,
          longitude: deliveryAddress.coordinates.lng,
          country: deliveryAddress.country,
          type: editDeliveryAddress.type || deliveryAddress.type,
        });

        await userStore.fetchAddresses();

        deliveryAddress = {
          ...deliveryAddress,
          addressId: address?.id || null,
          address2:
            address?.street_address_2 ??
            deliveryAddress.address2 ??
            editDeliveryAddress.address2,
          addressBld: address?.building ?? '',
          addressFloor: address?.floor ?? '',
        };

        userStore.setDeliveryAddress({
          ...deliveryAddress,
          ...editDeliveryAddress,
          addressId:
            address?.id ??
            editDeliveryAddress.addressId ??
            deliveryAddress.addressId ??
            null,
          address2:
            address?.street_address_2 ??
            deliveryAddress.address2 ??
            editDeliveryAddress.address2,
        });

        checkoutStore.setAddress2Val(
          address?.street_address_2 ??
            deliveryAddress.address2 ??
            editDeliveryAddress.address2,
        );
      }
    } else {
      const address2 = buildAddress2(
        editDeliveryAddress.addressBld,
        editDeliveryAddress.addressFloor,
        editDeliveryAddress.addressApt,
      );

      userStore.setDeliveryAddress({
        ...deliveryAddress,
        ...editDeliveryAddress,
        addressId:
          editDeliveryAddress.addressId ?? deliveryAddress.addressId ?? null,
        address2,
      });
    }

    orderStore.setEtaCalculation(toJS(deliveryAddressStore.etaCalculation));

    if (deliveryMethod) {
      checkoutStore.setDeliveryMethod(deliveryMethod);
    }

    await orderStore.requestETA();

    mainStore.sendToRN('trackOneSignalOutcome', {
      name: 'warehouse_selected',
    });

    mainStore.sendToRN('setUserProperties', {
      'Address: postcode': deliveryAddress.zip || '',
    });

    mainStore.sendToRN('setUserProperties', {
      'Address: lat': deliveryAddress.coordinates.lat,
    });

    mainStore.sendToRN('setUserProperties', {
      'Address: lon': deliveryAddress.coordinates.lng,
    });

    if (userStore.isFirstLaunch) {
      mainStore.sendToRN('analytics', {
        name: 'Onboarding: warehouse selected',
        params: {
          warehouse_code: deliveryAddressStore.etaCalculation?.warehouse.code,
          postcode: deliveryAddress.zip || '',
          lat: deliveryAddress.coordinates.lat,
          lon: deliveryAddress.coordinates.lng,
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'onboarding_warehouse_selected',
        params: {
          warehouse_code: deliveryAddressStore.etaCalculation?.warehouse.code,
          postcode: deliveryAddress.zip || '',
          lat: deliveryAddress.coordinates.lat,
          lon: deliveryAddress.coordinates.lng,
        },
      });
      mainStore.yaMetrika('Onboarding: warehouse selected', {
        warehouse_code: deliveryAddressStore.etaCalculation?.warehouse.code,
        postcode: deliveryAddress.zip || '',
        lat: deliveryAddress.coordinates.lat,
        lon: deliveryAddress.coordinates.lng,
      });

      if (
        !deliveryAddressStore.deliveryAddress ||
        !(deliveryAddressStore.etaCalculation ?? orderStore.etaCalculation)
      ) {
        mainStore.pushAlert(
          'error',
          "Your address isn't in a delivery zone yet",
        );
      }
      deliveryAddressStore.setIsLoading(false);
      closeModal();
      return;
    }
    if (mainStore.deferedDeeplink) {
      navigate(mainStore.deferedDeeplink, {
        state: { isDeeplink: true },
      });
      mainStore.setDeferedDeeplink('');
      return;
    }
    closeModal();
  };

  return (
    <button
      className="button _primary _med"
      disabled={
        !deliveryAddressStore.deliveryAddress ||
        !deliveryAddressStore.editAddressData.addressBld.trim() ||
        !deliveryAddressStore.editAddressData.type.trim() ||
        deliveryAddressStore.inputAddressFocused ||
        deliveryAddressStore.isLoading ||
        (!userStore.isFirstLaunch && !deliveryAddressStore.etaCalculation)
      }
      onClick={handleSaveAddress}
    >
      {deliveryAddressStore.isLoading ? (
        <span className="spinner _white" />
      ) : (
        t('deliverHere')
      )}
    </button>
  );
});
