import { observer } from 'mobx-react-lite';

import { company } from '~/company/Company';
import { useOnWarehouseChange } from '~/hooks/useOnWarehouseChange';
import { Category, Product } from '~/stores/CategoriesStore';

import ProductCard from '../../ProductCard/ProductCard';

import CategoryBlockTitle from '../CategoryBlockTitle';
import { createCategoryUrl } from '../utils';

import styles from './ProductsGridBlock.module.scss';

interface ProductsGridBlockProps {
  category: Category;
}

const ProductsGridBlock = ({ category }: ProductsGridBlockProps) => {
  useOnWarehouseChange(() => {
    category.productsRequest.run();
  });

  if (!category.products.length && !category.productsRequest.isLoading) {
    return null;
  }

  return (
    <div data-company={company.name} className={styles.container}>
      <CategoryBlockTitle
        text={category?.name ?? 'n/a'}
        categoryId={category?.subcategory?.[0]?.id}
        // @ts-expect-error FIXME: migrate to noUncheckedIndexedAccess: true
        linkTo={
          category.subcategory?.[0]?.id
            ? createCategoryUrl({ id: category.subcategory?.[0]?.id })
            : '/shop'
        }
      />
      <div data-company={company.name} className={styles.grid}>
        {category?.products?.map((product: Product) => (
          <ProductCard
            product={product}
            className={styles.product}
            key={product?.id}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(ProductsGridBlock);
