import { classes } from 'html-classes';
import { useTranslation, Trans } from 'react-i18next';

import styles from './Welcome.module.scss';

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className={classes(['content-layout', styles.welcome])}>
      <h2 className={styles.title}>{t('welcome')}</h2>
      <p className={styles.description}>
        <Trans
          t={t}
          i18nKey="welcomeText"
          components={{
            h2: <h2 className={styles.cdDescriptionH2} />,
            h3: <h3 className={styles.cdDescriptionH3} />,
          }}
        />
      </p>
    </div>
  );
};

export default Welcome;
