import { company } from '~/company/Company';

export default () =>
  [
    {
      position: 'bottom',
    },
    {
      position: 'bottom',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
    {
      position: 'top',
    },
  ].map((configItem, index) => ({
    ...configItem,
    img: company.images.onboarding?.[index] || '',
  }));
