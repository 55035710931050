import { Link } from 'react-router-dom';

import { createCategoryUrl } from '~/components/CategoryBlocks/utils';
import { useGlobal } from '~/hooks/useGlobal';
import { Category } from '~/stores/CategoriesStore';

import styles from './BannerPromoBlock.module.scss';

interface BannerPromoBlockProps {
  category: Category;
}

const BannerPromoBlock = ({ category }: BannerPromoBlockProps) => {
  const { isMobile } = useGlobal();

  const { name, previewImage, previewImageThumb, subcategory } = category;
  const categoryId = subcategory[0]?.id;
  const imageSrc = (isMobile && previewImageThumb) || previewImage;

  const img = (
    <img src={imageSrc} alt={name} loading="lazy" className={styles.img} />
  );

  if (!categoryId) {
    return <div className={styles.promo}>{img}</div>;
  }

  return (
    <Link to={createCategoryUrl({ id: categoryId })} className={styles.promo}>
      {img}
    </Link>
  );
};

export default BannerPromoBlock;
