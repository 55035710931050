import { useEffect } from 'react';

import { settingStore } from '~/stores/Settings';

import { catalogStore } from '../stores/CatalogStore';
import { checkoutStore } from '../stores/CheckoutStore/CheckoutStore';
import {
  REQUEST_ETA_INTERVAL,
  ANALYTICS_PRODUCTS_SHOWN_INTERVAL,
} from '../stores/constants';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import { userStore } from '../stores/UserStore';
import { listenerOnResize } from '../UI';

export const useRequestCycle = () => {
  useEffect(() => {
    listenerOnResize();
    if (!userStore.deliveryAddress?.coordinates) {
      userStore.setDeliveryAddress(null);
    }
    orderStore.requestCurrency();
    settingStore.requestSettings();
    if (userStore.isAuthorized) {
      userStore
        .requestPersonalData()
        .catch((error) => error && console.error(error));
      userStore
        .requestBonuses()
        .catch((error) => error && console.error(error));
    } else {
      userStore.resetCustomerType();
    }
    mainStore.setSentryUser({
      id: userStore.personalData.id || '',
      name: userStore.fullName,
      email: userStore.personalData.email || '',
      phone: userStore.personalData.phone || '',
    });
    setInterval(() => {
      if (mainStore.inBackground) {
        return;
      }
      mainStore.sendProductsShownToBI();
    }, ANALYTICS_PRODUCTS_SHOWN_INTERVAL);

    if (!userStore.deviceId && !mainStore.isRN) {
      userStore.setDeviceId('00000000-0000-0000-0000-000000000000');
    }
  }, []);

  useEffect(() => {
    if (catalogStore.cart.length > 0) {
      catalogStore.setCalculationProcess({
        requestId: catalogStore.calculationProcess.requestId + 1,
        isError: false,
        isLoading: true,
      });
      catalogStore.debouncedCalculateCart();
    }
  }, [
    JSON.stringify([catalogStore.promocode]),
    checkoutStore.useBonuses,
    orderStore.etaWarehouseCode,
  ]);

  useEffect(() => {
    orderStore.requestETA();
    const interval = setInterval(() => {
      if (!orderStore.etaCalculation || mainStore.inBackground) {
        return;
      }
      orderStore.requestETA();
    }, REQUEST_ETA_INTERVAL);
    return () => clearInterval(interval);
  }, [userStore.isFirstLaunch]);

  useEffect(() => {
    if (!userStore.isAuthorized) {
      return;
    }
    orderStore.requestPriorityPaymentSystem();
  }, [userStore.isAuthorized]);
};
