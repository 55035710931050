import { OrderPublicStatus } from '~/api/Order';
import { IconTypes } from '~/components/Icon/IconTypes';

export const STATUS_ICONS: Record<OrderPublicStatus, IconTypes> = {
  accepted: 'check-round',
  picking: 'shopping-bag',
  ready_for_pickup: 'shopping-bag-check',
  ready_to_ship: 'status-packaging',
  in_delivery: 'status-delivering',
  delivered: 'done-all',
  cancelled: 'close',
  failed: 'close-round',
};
